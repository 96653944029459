// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { Col, Row } from 'antd';
import React, { useContext, useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wallet } from 'src/types';

import Web2Login from '../../components/Login/Web2Login';
import Web3Login from '../../components/Login/Web3Login';
import { UserDetailsContext } from '../../context/UserDetailsContext';

const Login = () => {
	const currentUser = useContext(UserDetailsContext);
	const navigate = useNavigate();
	const [displayWeb, setDisplayWeb] = useState(2);
	const [chosenWallet, setChosenWallet] = useState<Wallet>();
	const [walletError, setWalletError] =  useState<string | undefined>();

	const setDisplayWeb2 = () => setDisplayWeb(2);

	const onWalletSelect = (wallet: Wallet) => {
		setChosenWallet(wallet);

		setDisplayWeb(3);
	};

	useEffect(() => {
		if (currentUser?.id) {
			navigate('/');
		}
	}, [navigate, currentUser, currentUser?.id]);

	return (
		<>
			<Row justify='center' align='middle' className='h-full -mt-5'>
				<Col className='min-w-full sm:min-w-[500px]'>
					{ displayWeb === 2
						? <Web2Login walletError={walletError} onWalletSelect={onWalletSelect}/> : null}

					{
						displayWeb === 3 && chosenWallet &&
						<Web3Login setWalletError={setWalletError} chosenWallet={chosenWallet} setDisplayWeb2={setDisplayWeb2} />
					}
				</Col>
			</Row>
		</>
	);
};

export default Login;
