// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React from 'react';
import { BountyPostFragment, MotionPostFragment, PipPostFragment, ProposalPostFragment, ReferendumPostFragment, TechCommitteeProposalPostFragment,TipPostFragment, TreasuryProposalPostFragment } from 'src/generated/graphql';

import TimelineContainer from './TimelineContainer';

interface Props {
	className?: string;
	isBounty?: boolean;
	isMotion?: boolean;
	isProposal?: boolean;
	isReferendum?: boolean;
	isUpgrade?: boolean;
	isCommunity?: boolean;
	isTechnical?: boolean;
	isTreasuryProposal?: boolean;
	pipPost?: PipPostFragment | undefined;
	isTechCommitteeProposal?: boolean;
	isTipProposal?: boolean;
	referendumPost: ReferendumPostFragment | undefined;
	proposalPost: ProposalPostFragment | undefined;
	motionPost: MotionPostFragment | undefined;
	treasuryPost: TreasuryProposalPostFragment | undefined;
	tipPost: TipPostFragment | undefined;
	bountyPost: BountyPostFragment | undefined;
	techCommitteeProposalPost: TechCommitteeProposalPostFragment | undefined;
}

const PostTimeline = ({
	className,
	isBounty,
	isMotion,
	isProposal,
	isReferendum,
	isUpgrade,
	isTechnical,
	isCommunity,
	pipPost,
	isTreasuryProposal,
	isTechCommitteeProposal,
	isTipProposal,
	referendumPost,
	proposalPost,
	motionPost,
	treasuryPost,
	tipPost,
	bountyPost,
	techCommitteeProposalPost
} : Props) => {
	return (
		<div className={`${className} p-10`}>
			{ isTechCommitteeProposal &&
				<TimelineContainer
					statuses={techCommitteeProposalPost?.onchain_link?.onchain_tech_committee_proposal?.[0]?.status?.map(s => ({
						blockNumber: s.blockNumber?.number || 0,
						status: s.status || ''
					})) || []}
					title='Tech Committee Proposal'
				/>
			}
			{ isBounty &&
				<TimelineContainer
					statuses={bountyPost?.onchain_link?.onchain_bounty?.[0]?.bountyStatus?.map(s => ({
						blockNumber: s.blockNumber?.number || 0,
						status: s.status || ''
					})) || []}
					title='Bounty'
				/>
			}
			{ isMotion &&
				<>
					<TimelineContainer
						statuses={motionPost?.onchain_link?.onchain_motion?.[0]?.motionStatus?.map(s => ({
							blockNumber: s.blockNumber?.number || 0,
							status: (s as any).status || ''
						})) || []}
						title='Motion'
					/>
					<TimelineContainer
						statuses={motionPost?.onchain_link?.onchain_referendum?.[0]?.referendumStatus?.map(s => ({
							blockNumber: s.blockNumber?.number || 0,
							status: (s as any).status || ''
						})) || []}
						title='Referendum'
					/>
				</>
			}
			{ isProposal &&
				<>
					<TimelineContainer
						statuses={proposalPost?.onchain_link?.onchain_proposal?.[0]?.proposalStatus?.map(s => ({
							blockNumber: s.blockNumber?.number || 0,
							status: s.status || ''
						})) || []}
						title='Proposal'
					/>
					<TimelineContainer
						statuses={proposalPost?.onchain_link?.onchain_referendum?.[0]?.referendumStatus?.map((s: any) => ({
							blockNumber: s.blockNumber?.number || 0,
							status: s.status || ''
						})) || []}
						title='Referendum'
					/>
				</>
			}
			{ isReferendum &&
					<>
						<TimelineContainer
							statuses={referendumPost?.onchain_link?.onchain_proposal?.[0]?.proposalStatus?.map(s => ({
								blockNumber: s.blockNumber?.number || 0,
								status: (s as any).status || ''
							})) || []}
							title='Proposal'
						/>
						<TimelineContainer
							statuses={referendumPost?.onchain_link?.onchain_motion?.[0]?.motionStatus?.map(s => ({
								blockNumber: s.blockNumber?.number || 0,
								status: (s as any).status || ''
							})) || []}
							title='Motion'
						/>
						<TimelineContainer
							statuses={referendumPost?.onchain_link?.onchain_referendum?.[0]?.referendumStatus?.map(s => ({
								blockNumber: s.blockNumber?.number || 0,
								status: s.status || ''
							})) || []}
							title='Referendum'
						/>
					</>
			}
			{ isTreasuryProposal &&
			<>
				<TimelineContainer
					statuses={treasuryPost?.onchain_link?.onchain_treasury_spend_proposal?.[0]?.treasuryStatus?.map(s => ({
						blockNumber: s.blockNumber?.number || 0,
						status: s.status || ''
					})) || []}
					title='Treasury Proposal'
				/>
				<TimelineContainer
					statuses={treasuryPost?.onchain_link?.onchain_motion?.[0]?.motionStatus?.map(s => ({
						blockNumber: s.blockNumber?.number || 0,
						status: (s as any).status || ''
					})) || []}
					title='Motion'
				/>
			</>
			}
			{ isTipProposal &&
					<TimelineContainer
						statuses={tipPost?.onchain_link?.onchain_tip?.[0]?.tipStatus?.map(s => ({
							blockNumber: s.blockNumber?.number || 0,
							status: s.status || ''
						})) || []}
						title='Tip'
					/>
			}
			{ (isUpgrade || isTechnical || isCommunity) &&
					<TimelineContainer
						statuses={pipPost?.onchain_link?.onchain_pip?.[0]?.pipStatus?.map(s => ({
							blockNumber: s.blockNumber?.number || 0,
							status: s.status || ''
						})) || []}
						title='Pip'
					/>
			}
		</div>
	);
};

export default PostTimeline;
