import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** Raw JSON value */
  Json: any;
  /** The `Long` scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
  bpchar: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type About = {
  __typename?: 'About';
  address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  address?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public_key?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type AddressLinkType = {
  __typename?: 'AddressLinkType';
  address_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  sign_message?: Maybe<Scalars['String']>;
};

export type AddressLoginType = {
  __typename?: 'AddressLoginType';
  message?: Maybe<Scalars['String']>;
  signMessage?: Maybe<Scalars['String']>;
};

export type AggregateBlockIndex = {
  __typename?: 'AggregateBlockIndex';
  count: Scalars['Int'];
};

export type AggregateBlockNumber = {
  __typename?: 'AggregateBlockNumber';
  count: Scalars['Int'];
};

export type AggregateBounty = {
  __typename?: 'AggregateBounty';
  count: Scalars['Int'];
};

export type AggregateBountyStatus = {
  __typename?: 'AggregateBountyStatus';
  count: Scalars['Int'];
};

export type AggregateChildBounty = {
  __typename?: 'AggregateChildBounty';
  count: Scalars['Int'];
};

export type AggregateChildBountyStatus = {
  __typename?: 'AggregateChildBountyStatus';
  count: Scalars['Int'];
};

export type AggregateCouncil = {
  __typename?: 'AggregateCouncil';
  count: Scalars['Int'];
};

export type AggregateCouncilMember = {
  __typename?: 'AggregateCouncilMember';
  count: Scalars['Int'];
};

export type AggregateMotion = {
  __typename?: 'AggregateMotion';
  count: Scalars['Int'];
};

export type AggregateMotionProposalArgument = {
  __typename?: 'AggregateMotionProposalArgument';
  count: Scalars['Int'];
};

export type AggregateMotionStatus = {
  __typename?: 'AggregateMotionStatus';
  count: Scalars['Int'];
};

export type AggregatePip = {
  __typename?: 'AggregatePip';
  count: Scalars['Int'];
};

export type AggregatePipArgument = {
  __typename?: 'AggregatePipArgument';
  count: Scalars['Int'];
};

export type AggregatePipStatus = {
  __typename?: 'AggregatePipStatus';
  count: Scalars['Int'];
};

export type AggregatePreimage = {
  __typename?: 'AggregatePreimage';
  count: Scalars['Int'];
};

export type AggregatePreimageArgument = {
  __typename?: 'AggregatePreimageArgument';
  count: Scalars['Int'];
};

export type AggregatePreimageStatus = {
  __typename?: 'AggregatePreimageStatus';
  count: Scalars['Int'];
};

export type AggregateProposal = {
  __typename?: 'AggregateProposal';
  count: Scalars['Int'];
};

export type AggregateProposalStatus = {
  __typename?: 'AggregateProposalStatus';
  count: Scalars['Int'];
};

export type AggregateReferendum = {
  __typename?: 'AggregateReferendum';
  count: Scalars['Int'];
};

export type AggregateReferendumStatus = {
  __typename?: 'AggregateReferendumStatus';
  count: Scalars['Int'];
};

export type AggregateTechCommitteeProposal = {
  __typename?: 'AggregateTechCommitteeProposal';
  count: Scalars['Int'];
};

export type AggregateTechCommitteeProposalArgument = {
  __typename?: 'AggregateTechCommitteeProposalArgument';
  count: Scalars['Int'];
};

export type AggregateTechCommitteeProposalStatus = {
  __typename?: 'AggregateTechCommitteeProposalStatus';
  count: Scalars['Int'];
};

export type AggregateTip = {
  __typename?: 'AggregateTip';
  count: Scalars['Int'];
};

export type AggregateTipStatus = {
  __typename?: 'AggregateTipStatus';
  count: Scalars['Int'];
};

export type AggregateTreasurySpendProposal = {
  __typename?: 'AggregateTreasurySpendProposal';
  count: Scalars['Int'];
};

export type AggregateTreasuryStatus = {
  __typename?: 'AggregateTreasuryStatus';
  count: Scalars['Int'];
};

export type BatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'];
};

export type BlockIndex = Node & {
  __typename?: 'BlockIndex';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  index: Scalars['Int'];
  network: Scalars['String'];
  startFrom: Scalars['Int'];
};

/** A connection to a list of items. */
export type BlockIndexConnection = {
  __typename?: 'BlockIndexConnection';
  aggregate: AggregateBlockIndex;
  /** A list of edges. */
  edges: Array<Maybe<BlockIndexEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BlockIndexCreateInput = {
  id?: Maybe<Scalars['ID']>;
  identifier: Scalars['String'];
  index: Scalars['Int'];
  network: Scalars['String'];
  startFrom: Scalars['Int'];
};

/** An edge in a connection. */
export type BlockIndexEdge = {
  __typename?: 'BlockIndexEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BlockIndex;
};

export enum BlockIndexOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IdentifierAsc = 'identifier_ASC',
  IdentifierDesc = 'identifier_DESC',
  IndexAsc = 'index_ASC',
  IndexDesc = 'index_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StartFromAsc = 'startFrom_ASC',
  StartFromDesc = 'startFrom_DESC'
}

export type BlockIndexPreviousValues = {
  __typename?: 'BlockIndexPreviousValues';
  id: Scalars['ID'];
  identifier: Scalars['String'];
  index: Scalars['Int'];
  network: Scalars['String'];
  startFrom: Scalars['Int'];
};

export type BlockIndexSubscriptionPayload = {
  __typename?: 'BlockIndexSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<BlockIndex>;
  previousValues?: Maybe<BlockIndexPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type BlockIndexSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BlockIndexSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BlockIndexSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BlockIndexSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<BlockIndexWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type BlockIndexUpdateInput = {
  identifier?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  startFrom?: Maybe<Scalars['Int']>;
};

export type BlockIndexUpdateManyMutationInput = {
  identifier?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  startFrom?: Maybe<Scalars['Int']>;
};

export type BlockIndexWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BlockIndexWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BlockIndexWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BlockIndexWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  identifier?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  identifier_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  identifier_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  identifier_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  identifier_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  identifier_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  identifier_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  identifier_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  identifier_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  identifier_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  identifier_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  identifier_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  identifier_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  identifier_starts_with?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  index_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  index_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  index_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  index_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  index_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  index_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  index_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  startFrom?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  startFrom_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  startFrom_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  startFrom_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  startFrom_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  startFrom_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  startFrom_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  startFrom_not_in?: Maybe<Array<Scalars['Int']>>;
};

export type BlockIndexWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  identifier?: Maybe<Scalars['String']>;
};

export type BlockNumber = Node & {
  __typename?: 'BlockNumber';
  authoredBy: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['ID'];
  network: Scalars['String'];
  networkHash: Scalars['String'];
  networkNumber: Scalars['String'];
  number: Scalars['Int'];
  startDateTime: Scalars['DateTime'];
};

/** A connection to a list of items. */
export type BlockNumberConnection = {
  __typename?: 'BlockNumberConnection';
  aggregate: AggregateBlockNumber;
  /** A list of edges. */
  edges: Array<Maybe<BlockNumberEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BlockNumberCreateInput = {
  authoredBy: Scalars['String'];
  hash: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  networkHash: Scalars['String'];
  networkNumber: Scalars['String'];
  number: Scalars['Int'];
  startDateTime: Scalars['DateTime'];
};

export type BlockNumberCreateOneInput = {
  connect?: Maybe<BlockNumberWhereUniqueInput>;
  create?: Maybe<BlockNumberCreateInput>;
};

/** An edge in a connection. */
export type BlockNumberEdge = {
  __typename?: 'BlockNumberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BlockNumber;
};

export enum BlockNumberOrderByInput {
  AuthoredByAsc = 'authoredBy_ASC',
  AuthoredByDesc = 'authoredBy_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkHashAsc = 'networkHash_ASC',
  NetworkHashDesc = 'networkHash_DESC',
  NetworkNumberAsc = 'networkNumber_ASC',
  NetworkNumberDesc = 'networkNumber_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  NumberAsc = 'number_ASC',
  NumberDesc = 'number_DESC',
  StartDateTimeAsc = 'startDateTime_ASC',
  StartDateTimeDesc = 'startDateTime_DESC'
}

export type BlockNumberPreviousValues = {
  __typename?: 'BlockNumberPreviousValues';
  authoredBy: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['ID'];
  network: Scalars['String'];
  networkHash: Scalars['String'];
  networkNumber: Scalars['String'];
  number: Scalars['Int'];
  startDateTime: Scalars['DateTime'];
};

export type BlockNumberSubscriptionPayload = {
  __typename?: 'BlockNumberSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<BlockNumber>;
  previousValues?: Maybe<BlockNumberPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type BlockNumberSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BlockNumberSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BlockNumberSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BlockNumberSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<BlockNumberWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type BlockNumberUpdateDataInput = {
  authoredBy?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  networkNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
};

export type BlockNumberUpdateInput = {
  authoredBy?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  networkNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
};

export type BlockNumberUpdateManyMutationInput = {
  authoredBy?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  networkNumber?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  startDateTime?: Maybe<Scalars['DateTime']>;
};

export type BlockNumberUpdateOneRequiredInput = {
  connect?: Maybe<BlockNumberWhereUniqueInput>;
  create?: Maybe<BlockNumberCreateInput>;
  update?: Maybe<BlockNumberUpdateDataInput>;
  upsert?: Maybe<BlockNumberUpsertNestedInput>;
};

export type BlockNumberUpsertNestedInput = {
  create: BlockNumberCreateInput;
  update: BlockNumberUpdateDataInput;
};

export type BlockNumberWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BlockNumberWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BlockNumberWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BlockNumberWhereInput>>;
  authoredBy?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  authoredBy_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  authoredBy_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  authoredBy_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  authoredBy_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  authoredBy_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  authoredBy_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  authoredBy_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  authoredBy_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  authoredBy_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  authoredBy_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  authoredBy_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  authoredBy_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  authoredBy_starts_with?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  hash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  hash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  hash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  hash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  hash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  hash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  hash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  hash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  hash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  hash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  hash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  hash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  hash_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkHash_starts_with?: Maybe<Scalars['String']>;
  networkNumber?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkNumber_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkNumber_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkNumber_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkNumber_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkNumber_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkNumber_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkNumber_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkNumber_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkNumber_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkNumber_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkNumber_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkNumber_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkNumber_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  number_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  number_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  number_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  number_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  number_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  number_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  number_not_in?: Maybe<Array<Scalars['Int']>>;
  startDateTime?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  startDateTime_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  startDateTime_gte?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  startDateTime_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  startDateTime_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  startDateTime_lte?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  startDateTime_not?: Maybe<Scalars['DateTime']>;
  /** All values that are not contained in given list. */
  startDateTime_not_in?: Maybe<Array<Scalars['DateTime']>>;
};

export type BlockNumberWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  networkHash?: Maybe<Scalars['String']>;
  networkNumber?: Maybe<Scalars['String']>;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type Bounty = {
  __typename?: 'Bounty';
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  bountyId: Scalars['Int'];
  bountyStatus?: Maybe<Array<BountyStatus>>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  network: Scalars['String'];
  networkBountyId: Scalars['String'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};


export type BountyBountyStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BountyStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BountyStatusWhereInput>;
};

/** A connection to a list of items. */
export type BountyConnection = {
  __typename?: 'BountyConnection';
  aggregate: AggregateBounty;
  /** A list of edges. */
  edges: Array<Maybe<BountyEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BountyCreateInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  bountyId: Scalars['Int'];
  bountyStatus?: Maybe<BountyStatusCreateManyWithoutBountyInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkBountyId: Scalars['String'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};

export type BountyCreateOneWithoutBountyStatusInput = {
  connect?: Maybe<BountyWhereUniqueInput>;
  create?: Maybe<BountyCreateWithoutBountyStatusInput>;
};

export type BountyCreateWithoutBountyStatusInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  bountyId: Scalars['Int'];
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkBountyId: Scalars['String'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};

/** An edge in a connection. */
export type BountyEdge = {
  __typename?: 'BountyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Bounty;
};

export enum BountyOrderByInput {
  BeneficiaryAsc = 'beneficiary_ASC',
  BeneficiaryDesc = 'beneficiary_DESC',
  BondAsc = 'bond_ASC',
  BondDesc = 'bond_DESC',
  BountyIdAsc = 'bountyId_ASC',
  BountyIdDesc = 'bountyId_DESC',
  CuratorDepositAsc = 'curatorDeposit_ASC',
  CuratorDepositDesc = 'curatorDeposit_DESC',
  CuratorAsc = 'curator_ASC',
  CuratorDesc = 'curator_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkBountyIdAsc = 'networkBountyId_ASC',
  NetworkBountyIdDesc = 'networkBountyId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposerAsc = 'proposer_ASC',
  ProposerDesc = 'proposer_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type BountyPreviousValues = {
  __typename?: 'BountyPreviousValues';
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  bountyId: Scalars['Int'];
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  network: Scalars['String'];
  networkBountyId: Scalars['String'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};

export type BountyStatus = Node & {
  __typename?: 'BountyStatus';
  blockNumber: BlockNumber;
  bounty: Bounty;
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type BountyStatusConnection = {
  __typename?: 'BountyStatusConnection';
  aggregate: AggregateBountyStatus;
  /** A list of edges. */
  edges: Array<Maybe<BountyStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type BountyStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  bounty: BountyCreateOneWithoutBountyStatusInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type BountyStatusCreateManyWithoutBountyInput = {
  connect?: Maybe<Array<BountyStatusWhereUniqueInput>>;
  create?: Maybe<Array<BountyStatusCreateWithoutBountyInput>>;
};

export type BountyStatusCreateWithoutBountyInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type BountyStatusEdge = {
  __typename?: 'BountyStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: BountyStatus;
};

export enum BountyStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type BountyStatusPreviousValues = {
  __typename?: 'BountyStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type BountyStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BountyStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BountyStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BountyStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type BountyStatusSubscriptionPayload = {
  __typename?: 'BountyStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<BountyStatus>;
  previousValues?: Maybe<BountyStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type BountyStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BountyStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BountyStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BountyStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<BountyStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type BountyStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  bounty?: Maybe<BountyUpdateOneRequiredWithoutBountyStatusInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type BountyStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type BountyStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type BountyStatusUpdateManyWithWhereNestedInput = {
  data: BountyStatusUpdateManyDataInput;
  where: BountyStatusScalarWhereInput;
};

export type BountyStatusUpdateManyWithoutBountyInput = {
  connect?: Maybe<Array<BountyStatusWhereUniqueInput>>;
  create?: Maybe<Array<BountyStatusCreateWithoutBountyInput>>;
  delete?: Maybe<Array<BountyStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<BountyStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<BountyStatusWhereUniqueInput>>;
  set?: Maybe<Array<BountyStatusWhereUniqueInput>>;
  update?: Maybe<Array<BountyStatusUpdateWithWhereUniqueWithoutBountyInput>>;
  updateMany?: Maybe<Array<BountyStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<BountyStatusUpsertWithWhereUniqueWithoutBountyInput>>;
};

export type BountyStatusUpdateWithWhereUniqueWithoutBountyInput = {
  data: BountyStatusUpdateWithoutBountyDataInput;
  where: BountyStatusWhereUniqueInput;
};

export type BountyStatusUpdateWithoutBountyDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type BountyStatusUpsertWithWhereUniqueWithoutBountyInput = {
  create: BountyStatusCreateWithoutBountyInput;
  update: BountyStatusUpdateWithoutBountyDataInput;
  where: BountyStatusWhereUniqueInput;
};

export type BountyStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BountyStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BountyStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BountyStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  bounty?: Maybe<BountyWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type BountyStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type BountySubscriptionPayload = {
  __typename?: 'BountySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Bounty>;
  previousValues?: Maybe<BountyPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type BountySubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BountySubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BountySubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BountySubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<BountyWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type BountyUpdateInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  bountyId?: Maybe<Scalars['Int']>;
  bountyStatus?: Maybe<BountyStatusUpdateManyWithoutBountyInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkBountyId?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BountyUpdateManyMutationInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  bountyId?: Maybe<Scalars['Int']>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkBountyId?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BountyUpdateOneRequiredWithoutBountyStatusInput = {
  connect?: Maybe<BountyWhereUniqueInput>;
  create?: Maybe<BountyCreateWithoutBountyStatusInput>;
  update?: Maybe<BountyUpdateWithoutBountyStatusDataInput>;
  upsert?: Maybe<BountyUpsertWithoutBountyStatusInput>;
};

export type BountyUpdateWithoutBountyStatusDataInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  bountyId?: Maybe<Scalars['Int']>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkBountyId?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type BountyUpsertWithoutBountyStatusInput = {
  create: BountyCreateWithoutBountyStatusInput;
  update: BountyUpdateWithoutBountyStatusDataInput;
};

export type BountyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BountyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BountyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BountyWhereInput>>;
  beneficiary?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  beneficiary_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  beneficiary_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  beneficiary_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  beneficiary_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  beneficiary_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  beneficiary_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  beneficiary_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  beneficiary_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  beneficiary_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  beneficiary_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  beneficiary_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  beneficiary_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  beneficiary_starts_with?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  bond_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  bond_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  bond_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  bond_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bond_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  bond_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  bond_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  bond_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  bond_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  bond_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bond_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bond_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  bond_starts_with?: Maybe<Scalars['String']>;
  bountyId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  bountyId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  bountyId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  bountyId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  bountyId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  bountyId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  bountyId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  bountyId_not_in?: Maybe<Array<Scalars['Int']>>;
  bountyStatus_every?: Maybe<BountyStatusWhereInput>;
  bountyStatus_none?: Maybe<BountyStatusWhereInput>;
  bountyStatus_some?: Maybe<BountyStatusWhereInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curatorDeposit_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curatorDeposit_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curatorDeposit_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curatorDeposit_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curatorDeposit_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curatorDeposit_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curatorDeposit_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curatorDeposit_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curatorDeposit_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curatorDeposit_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curatorDeposit_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curatorDeposit_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curatorDeposit_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curator_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curator_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curator_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curator_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curator_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curator_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curator_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curator_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curator_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curator_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curator_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curator_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curator_starts_with?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  fee_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  fee_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  fee_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  fee_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fee_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  fee_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  fee_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  fee_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  fee_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  fee_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fee_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  fee_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  fee_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  networkBountyId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkBountyId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkBountyId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkBountyId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkBountyId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkBountyId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkBountyId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkBountyId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkBountyId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkBountyId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkBountyId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkBountyId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkBountyId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkBountyId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposer_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposer_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposer_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposer_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposer_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposer_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposer_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposer_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposer_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposer_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposer_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposer_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposer_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type BountyWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Bounty = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<BountyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<BountyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<BountyWhereInput>>;
  beneficiary?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  beneficiary_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  beneficiary_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  beneficiary_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  beneficiary_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  beneficiary_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  beneficiary_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  beneficiary_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  beneficiary_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  beneficiary_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  beneficiary_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  beneficiary_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  beneficiary_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  beneficiary_starts_with?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  bond_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  bond_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  bond_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  bond_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bond_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  bond_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  bond_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  bond_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  bond_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  bond_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bond_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bond_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  bond_starts_with?: Maybe<Scalars['String']>;
  bountyId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  bountyId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  bountyId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  bountyId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  bountyId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  bountyId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  bountyId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  bountyId_not_in?: Maybe<Array<Scalars['Int']>>;
  bountyStatus_every?: Maybe<BountyStatusWhereInput>;
  bountyStatus_none?: Maybe<BountyStatusWhereInput>;
  bountyStatus_some?: Maybe<BountyStatusWhereInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curatorDeposit_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curatorDeposit_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curatorDeposit_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curatorDeposit_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curatorDeposit_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curatorDeposit_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curatorDeposit_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curatorDeposit_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curatorDeposit_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curatorDeposit_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curatorDeposit_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curatorDeposit_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curatorDeposit_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curator_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curator_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curator_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curator_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curator_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curator_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curator_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curator_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curator_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curator_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curator_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curator_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curator_starts_with?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  fee_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  fee_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  fee_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  fee_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fee_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  fee_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  fee_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  fee_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  fee_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  fee_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fee_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  fee_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  fee_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkBountyId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkBountyId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkBountyId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkBountyId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkBountyId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkBountyId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkBountyId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkBountyId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkBountyId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkBountyId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkBountyId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkBountyId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkBountyId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposer_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposer_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposer_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposer_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposer_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposer_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposer_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposer_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposer_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposer_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposer_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposer_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposer_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type BountyWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkBountyId?: Maybe<Scalars['String']>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type ChangeResponse = {
  __typename?: 'ChangeResponse';
  message?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type ChildBounty = {
  __typename?: 'ChildBounty';
  beneficiary?: Maybe<Scalars['String']>;
  childBountyId: Scalars['Int'];
  childBountyStatus?: Maybe<Array<ChildBountyStatus>>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  network: Scalars['String'];
  networkChildBountyId: Scalars['String'];
  parentBountyId: Scalars['Int'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};


export type ChildBountyChildBountyStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChildBountyStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ChildBountyStatusWhereInput>;
};

/** A connection to a list of items. */
export type ChildBountyConnection = {
  __typename?: 'ChildBountyConnection';
  aggregate: AggregateChildBounty;
  /** A list of edges. */
  edges: Array<Maybe<ChildBountyEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ChildBountyCreateInput = {
  beneficiary?: Maybe<Scalars['String']>;
  childBountyId: Scalars['Int'];
  childBountyStatus?: Maybe<ChildBountyStatusCreateManyWithoutChildBountyInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkChildBountyId: Scalars['String'];
  parentBountyId: Scalars['Int'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};

export type ChildBountyCreateOneWithoutChildBountyStatusInput = {
  connect?: Maybe<ChildBountyWhereUniqueInput>;
  create?: Maybe<ChildBountyCreateWithoutChildBountyStatusInput>;
};

export type ChildBountyCreateWithoutChildBountyStatusInput = {
  beneficiary?: Maybe<Scalars['String']>;
  childBountyId: Scalars['Int'];
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkChildBountyId: Scalars['String'];
  parentBountyId: Scalars['Int'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};

/** An edge in a connection. */
export type ChildBountyEdge = {
  __typename?: 'ChildBountyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ChildBounty;
};

export enum ChildBountyOrderByInput {
  BeneficiaryAsc = 'beneficiary_ASC',
  BeneficiaryDesc = 'beneficiary_DESC',
  ChildBountyIdAsc = 'childBountyId_ASC',
  ChildBountyIdDesc = 'childBountyId_DESC',
  CuratorDepositAsc = 'curatorDeposit_ASC',
  CuratorDepositDesc = 'curatorDeposit_DESC',
  CuratorAsc = 'curator_ASC',
  CuratorDesc = 'curator_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  FeeAsc = 'fee_ASC',
  FeeDesc = 'fee_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkChildBountyIdAsc = 'networkChildBountyId_ASC',
  NetworkChildBountyIdDesc = 'networkChildBountyId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ParentBountyIdAsc = 'parentBountyId_ASC',
  ParentBountyIdDesc = 'parentBountyId_DESC',
  ProposerAsc = 'proposer_ASC',
  ProposerDesc = 'proposer_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type ChildBountyPreviousValues = {
  __typename?: 'ChildBountyPreviousValues';
  beneficiary?: Maybe<Scalars['String']>;
  childBountyId: Scalars['Int'];
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  network: Scalars['String'];
  networkChildBountyId: Scalars['String'];
  parentBountyId: Scalars['Int'];
  proposer: Scalars['String'];
  value: Scalars['String'];
};

export type ChildBountyStatus = Node & {
  __typename?: 'ChildBountyStatus';
  blockNumber: BlockNumber;
  childBounty: ChildBounty;
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type ChildBountyStatusConnection = {
  __typename?: 'ChildBountyStatusConnection';
  aggregate: AggregateChildBountyStatus;
  /** A list of edges. */
  edges: Array<Maybe<ChildBountyStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ChildBountyStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  childBounty: ChildBountyCreateOneWithoutChildBountyStatusInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type ChildBountyStatusCreateManyWithoutChildBountyInput = {
  connect?: Maybe<Array<ChildBountyStatusWhereUniqueInput>>;
  create?: Maybe<Array<ChildBountyStatusCreateWithoutChildBountyInput>>;
};

export type ChildBountyStatusCreateWithoutChildBountyInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type ChildBountyStatusEdge = {
  __typename?: 'ChildBountyStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ChildBountyStatus;
};

export enum ChildBountyStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type ChildBountyStatusPreviousValues = {
  __typename?: 'ChildBountyStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type ChildBountyStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChildBountyStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChildBountyStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChildBountyStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type ChildBountyStatusSubscriptionPayload = {
  __typename?: 'ChildBountyStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ChildBountyStatus>;
  previousValues?: Maybe<ChildBountyStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type ChildBountyStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChildBountyStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChildBountyStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChildBountyStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<ChildBountyStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type ChildBountyStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  childBounty?: Maybe<ChildBountyUpdateOneRequiredWithoutChildBountyStatusInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ChildBountyStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ChildBountyStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ChildBountyStatusUpdateManyWithWhereNestedInput = {
  data: ChildBountyStatusUpdateManyDataInput;
  where: ChildBountyStatusScalarWhereInput;
};

export type ChildBountyStatusUpdateManyWithoutChildBountyInput = {
  connect?: Maybe<Array<ChildBountyStatusWhereUniqueInput>>;
  create?: Maybe<Array<ChildBountyStatusCreateWithoutChildBountyInput>>;
  delete?: Maybe<Array<ChildBountyStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ChildBountyStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ChildBountyStatusWhereUniqueInput>>;
  set?: Maybe<Array<ChildBountyStatusWhereUniqueInput>>;
  update?: Maybe<Array<ChildBountyStatusUpdateWithWhereUniqueWithoutChildBountyInput>>;
  updateMany?: Maybe<Array<ChildBountyStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<ChildBountyStatusUpsertWithWhereUniqueWithoutChildBountyInput>>;
};

export type ChildBountyStatusUpdateWithWhereUniqueWithoutChildBountyInput = {
  data: ChildBountyStatusUpdateWithoutChildBountyDataInput;
  where: ChildBountyStatusWhereUniqueInput;
};

export type ChildBountyStatusUpdateWithoutChildBountyDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ChildBountyStatusUpsertWithWhereUniqueWithoutChildBountyInput = {
  create: ChildBountyStatusCreateWithoutChildBountyInput;
  update: ChildBountyStatusUpdateWithoutChildBountyDataInput;
  where: ChildBountyStatusWhereUniqueInput;
};

export type ChildBountyStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChildBountyStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChildBountyStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChildBountyStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  childBounty?: Maybe<ChildBountyWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type ChildBountyStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ChildBountySubscriptionPayload = {
  __typename?: 'ChildBountySubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ChildBounty>;
  previousValues?: Maybe<ChildBountyPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type ChildBountySubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChildBountySubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChildBountySubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChildBountySubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<ChildBountyWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type ChildBountyUpdateInput = {
  beneficiary?: Maybe<Scalars['String']>;
  childBountyId?: Maybe<Scalars['Int']>;
  childBountyStatus?: Maybe<ChildBountyStatusUpdateManyWithoutChildBountyInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkChildBountyId?: Maybe<Scalars['String']>;
  parentBountyId?: Maybe<Scalars['Int']>;
  proposer?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ChildBountyUpdateManyMutationInput = {
  beneficiary?: Maybe<Scalars['String']>;
  childBountyId?: Maybe<Scalars['Int']>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkChildBountyId?: Maybe<Scalars['String']>;
  parentBountyId?: Maybe<Scalars['Int']>;
  proposer?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ChildBountyUpdateOneRequiredWithoutChildBountyStatusInput = {
  connect?: Maybe<ChildBountyWhereUniqueInput>;
  create?: Maybe<ChildBountyCreateWithoutChildBountyStatusInput>;
  update?: Maybe<ChildBountyUpdateWithoutChildBountyStatusDataInput>;
  upsert?: Maybe<ChildBountyUpsertWithoutChildBountyStatusInput>;
};

export type ChildBountyUpdateWithoutChildBountyStatusDataInput = {
  beneficiary?: Maybe<Scalars['String']>;
  childBountyId?: Maybe<Scalars['Int']>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkChildBountyId?: Maybe<Scalars['String']>;
  parentBountyId?: Maybe<Scalars['Int']>;
  proposer?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ChildBountyUpsertWithoutChildBountyStatusInput = {
  create: ChildBountyCreateWithoutChildBountyStatusInput;
  update: ChildBountyUpdateWithoutChildBountyStatusDataInput;
};

export type ChildBountyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChildBountyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChildBountyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChildBountyWhereInput>>;
  beneficiary?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  beneficiary_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  beneficiary_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  beneficiary_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  beneficiary_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  beneficiary_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  beneficiary_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  beneficiary_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  beneficiary_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  beneficiary_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  beneficiary_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  beneficiary_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  beneficiary_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  beneficiary_starts_with?: Maybe<Scalars['String']>;
  childBountyId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  childBountyId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  childBountyId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  childBountyId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  childBountyId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  childBountyId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  childBountyId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  childBountyId_not_in?: Maybe<Array<Scalars['Int']>>;
  childBountyStatus_every?: Maybe<ChildBountyStatusWhereInput>;
  childBountyStatus_none?: Maybe<ChildBountyStatusWhereInput>;
  childBountyStatus_some?: Maybe<ChildBountyStatusWhereInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curatorDeposit_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curatorDeposit_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curatorDeposit_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curatorDeposit_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curatorDeposit_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curatorDeposit_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curatorDeposit_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curatorDeposit_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curatorDeposit_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curatorDeposit_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curatorDeposit_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curatorDeposit_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curatorDeposit_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curator_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curator_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curator_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curator_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curator_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curator_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curator_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curator_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curator_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curator_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curator_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curator_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curator_starts_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  description_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  description_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  description_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  description_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  fee_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  fee_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  fee_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  fee_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fee_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  fee_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  fee_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  fee_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  fee_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  fee_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fee_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  fee_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  fee_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  networkChildBountyId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkChildBountyId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkChildBountyId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkChildBountyId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkChildBountyId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkChildBountyId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkChildBountyId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkChildBountyId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkChildBountyId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkChildBountyId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkChildBountyId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkChildBountyId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkChildBountyId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkChildBountyId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  parentBountyId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  parentBountyId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  parentBountyId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  parentBountyId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  parentBountyId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  parentBountyId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  parentBountyId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  parentBountyId_not_in?: Maybe<Array<Scalars['Int']>>;
  proposer?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposer_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposer_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposer_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposer_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposer_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposer_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposer_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposer_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposer_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposer_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposer_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposer_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposer_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type ChildBountyWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Child_Bounty = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ChildBountyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ChildBountyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ChildBountyWhereInput>>;
  beneficiary?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  beneficiary_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  beneficiary_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  beneficiary_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  beneficiary_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  beneficiary_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  beneficiary_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  beneficiary_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  beneficiary_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  beneficiary_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  beneficiary_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  beneficiary_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  beneficiary_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  beneficiary_starts_with?: Maybe<Scalars['String']>;
  childBountyId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  childBountyId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  childBountyId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  childBountyId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  childBountyId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  childBountyId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  childBountyId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  childBountyId_not_in?: Maybe<Array<Scalars['Int']>>;
  childBountyStatus_every?: Maybe<ChildBountyStatusWhereInput>;
  childBountyStatus_none?: Maybe<ChildBountyStatusWhereInput>;
  childBountyStatus_some?: Maybe<ChildBountyStatusWhereInput>;
  curator?: Maybe<Scalars['String']>;
  curatorDeposit?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curatorDeposit_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curatorDeposit_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curatorDeposit_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curatorDeposit_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curatorDeposit_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curatorDeposit_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curatorDeposit_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curatorDeposit_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curatorDeposit_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curatorDeposit_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curatorDeposit_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curatorDeposit_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curatorDeposit_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  curator_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  curator_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  curator_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  curator_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  curator_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  curator_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  curator_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  curator_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  curator_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  curator_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  curator_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  curator_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  curator_starts_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  description_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  description_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  description_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  description_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  description_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  fee_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  fee_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  fee_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  fee_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fee_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  fee_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  fee_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  fee_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  fee_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  fee_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  fee_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  fee_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  fee_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkChildBountyId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkChildBountyId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkChildBountyId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkChildBountyId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkChildBountyId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkChildBountyId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkChildBountyId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkChildBountyId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkChildBountyId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkChildBountyId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkChildBountyId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkChildBountyId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkChildBountyId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  parentBountyId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  parentBountyId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  parentBountyId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  parentBountyId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  parentBountyId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  parentBountyId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  parentBountyId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  parentBountyId_not_in?: Maybe<Array<Scalars['Int']>>;
  proposer?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposer_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposer_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposer_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposer_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposer_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposer_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposer_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposer_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposer_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposer_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposer_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposer_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposer_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type ChildBountyWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkChildBountyId?: Maybe<Scalars['String']>;
};

export type Council = Node & {
  __typename?: 'Council';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  members?: Maybe<Array<CouncilMember>>;
  network: Scalars['String'];
};


export type CouncilMembersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CouncilMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CouncilMemberWhereInput>;
};

/** A connection to a list of items. */
export type CouncilConnection = {
  __typename?: 'CouncilConnection';
  aggregate: AggregateCouncil;
  /** A list of edges. */
  edges: Array<Maybe<CouncilEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CouncilCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  members?: Maybe<CouncilMemberCreateManyWithoutCouncilsInput>;
  network: Scalars['String'];
};

export type CouncilCreateManyWithoutMembersInput = {
  connect?: Maybe<Array<CouncilWhereUniqueInput>>;
  create?: Maybe<Array<CouncilCreateWithoutMembersInput>>;
};

export type CouncilCreateWithoutMembersInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
};

/** An edge in a connection. */
export type CouncilEdge = {
  __typename?: 'CouncilEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Council;
};

export type CouncilMember = Node & {
  __typename?: 'CouncilMember';
  address: Scalars['String'];
  councils?: Maybe<Array<Council>>;
  id: Scalars['ID'];
  network: Scalars['String'];
};


export type CouncilMemberCouncilsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CouncilOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CouncilWhereInput>;
};

/** A connection to a list of items. */
export type CouncilMemberConnection = {
  __typename?: 'CouncilMemberConnection';
  aggregate: AggregateCouncilMember;
  /** A list of edges. */
  edges: Array<Maybe<CouncilMemberEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CouncilMemberCreateInput = {
  address: Scalars['String'];
  councils?: Maybe<CouncilCreateManyWithoutMembersInput>;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
};

export type CouncilMemberCreateManyWithoutCouncilsInput = {
  connect?: Maybe<Array<CouncilMemberWhereUniqueInput>>;
  create?: Maybe<Array<CouncilMemberCreateWithoutCouncilsInput>>;
};

export type CouncilMemberCreateWithoutCouncilsInput = {
  address: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
};

/** An edge in a connection. */
export type CouncilMemberEdge = {
  __typename?: 'CouncilMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: CouncilMember;
};

export enum CouncilMemberOrderByInput {
  AddressAsc = 'address_ASC',
  AddressDesc = 'address_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC'
}

export type CouncilMemberPreviousValues = {
  __typename?: 'CouncilMemberPreviousValues';
  address: Scalars['String'];
  id: Scalars['ID'];
  network: Scalars['String'];
};

export type CouncilMemberScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CouncilMemberScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CouncilMemberScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CouncilMemberScalarWhereInput>>;
  address?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  address_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  address_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  address_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  address_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  address_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  address_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  address_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  address_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  address_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  address_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  address_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  address_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  address_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
};

export type CouncilMemberSubscriptionPayload = {
  __typename?: 'CouncilMemberSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<CouncilMember>;
  previousValues?: Maybe<CouncilMemberPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type CouncilMemberSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CouncilMemberSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CouncilMemberSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CouncilMemberSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<CouncilMemberWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type CouncilMemberUpdateInput = {
  address?: Maybe<Scalars['String']>;
  councils?: Maybe<CouncilUpdateManyWithoutMembersInput>;
  network?: Maybe<Scalars['String']>;
};

export type CouncilMemberUpdateManyDataInput = {
  address?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
};

export type CouncilMemberUpdateManyMutationInput = {
  address?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
};

export type CouncilMemberUpdateManyWithWhereNestedInput = {
  data: CouncilMemberUpdateManyDataInput;
  where: CouncilMemberScalarWhereInput;
};

export type CouncilMemberUpdateManyWithoutCouncilsInput = {
  connect?: Maybe<Array<CouncilMemberWhereUniqueInput>>;
  create?: Maybe<Array<CouncilMemberCreateWithoutCouncilsInput>>;
  delete?: Maybe<Array<CouncilMemberWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CouncilMemberScalarWhereInput>>;
  disconnect?: Maybe<Array<CouncilMemberWhereUniqueInput>>;
  set?: Maybe<Array<CouncilMemberWhereUniqueInput>>;
  update?: Maybe<Array<CouncilMemberUpdateWithWhereUniqueWithoutCouncilsInput>>;
  updateMany?: Maybe<Array<CouncilMemberUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<CouncilMemberUpsertWithWhereUniqueWithoutCouncilsInput>>;
};

export type CouncilMemberUpdateWithWhereUniqueWithoutCouncilsInput = {
  data: CouncilMemberUpdateWithoutCouncilsDataInput;
  where: CouncilMemberWhereUniqueInput;
};

export type CouncilMemberUpdateWithoutCouncilsDataInput = {
  address?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
};

export type CouncilMemberUpsertWithWhereUniqueWithoutCouncilsInput = {
  create: CouncilMemberCreateWithoutCouncilsInput;
  update: CouncilMemberUpdateWithoutCouncilsDataInput;
  where: CouncilMemberWhereUniqueInput;
};

export type CouncilMemberWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CouncilMemberWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CouncilMemberWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CouncilMemberWhereInput>>;
  address?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  address_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  address_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  address_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  address_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  address_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  address_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  address_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  address_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  address_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  address_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  address_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  address_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  address_starts_with?: Maybe<Scalars['String']>;
  councils_every?: Maybe<CouncilWhereInput>;
  councils_none?: Maybe<CouncilWhereInput>;
  councils_some?: Maybe<CouncilWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
};

export type CouncilMemberWhereUniqueInput = {
  address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export enum CouncilOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC'
}

export type CouncilPreviousValues = {
  __typename?: 'CouncilPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
};

export type CouncilScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CouncilScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CouncilScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CouncilScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
};

export type CouncilSubscriptionPayload = {
  __typename?: 'CouncilSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Council>;
  previousValues?: Maybe<CouncilPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type CouncilSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CouncilSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CouncilSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CouncilSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<CouncilWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type CouncilUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  members?: Maybe<CouncilMemberUpdateManyWithoutCouncilsInput>;
  network?: Maybe<Scalars['String']>;
};

export type CouncilUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
};

export type CouncilUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
};

export type CouncilUpdateManyWithWhereNestedInput = {
  data: CouncilUpdateManyDataInput;
  where: CouncilScalarWhereInput;
};

export type CouncilUpdateManyWithoutMembersInput = {
  connect?: Maybe<Array<CouncilWhereUniqueInput>>;
  create?: Maybe<Array<CouncilCreateWithoutMembersInput>>;
  delete?: Maybe<Array<CouncilWhereUniqueInput>>;
  deleteMany?: Maybe<Array<CouncilScalarWhereInput>>;
  disconnect?: Maybe<Array<CouncilWhereUniqueInput>>;
  set?: Maybe<Array<CouncilWhereUniqueInput>>;
  update?: Maybe<Array<CouncilUpdateWithWhereUniqueWithoutMembersInput>>;
  updateMany?: Maybe<Array<CouncilUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<CouncilUpsertWithWhereUniqueWithoutMembersInput>>;
};

export type CouncilUpdateWithWhereUniqueWithoutMembersInput = {
  data: CouncilUpdateWithoutMembersDataInput;
  where: CouncilWhereUniqueInput;
};

export type CouncilUpdateWithoutMembersDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
};

export type CouncilUpsertWithWhereUniqueWithoutMembersInput = {
  create: CouncilCreateWithoutMembersInput;
  update: CouncilUpdateWithoutMembersDataInput;
  where: CouncilWhereUniqueInput;
};

export type CouncilWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<CouncilWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<CouncilWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<CouncilWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  members_every?: Maybe<CouncilMemberWhereInput>;
  members_none?: Maybe<CouncilMemberWhereInput>;
  members_some?: Maybe<CouncilMemberWhereInput>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
};

export type CouncilWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};


/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


export type LoginResponse = {
  __typename?: 'LoginResponse';
  token?: Maybe<Scalars['String']>;
};


export type Message = {
  __typename?: 'Message';
  message?: Maybe<Scalars['String']>;
};

export type Motion = {
  __typename?: 'Motion';
  author: Scalars['String'];
  id: Scalars['Int'];
  memberCount: Scalars['Int'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motionProposalArguments?: Maybe<Array<MotionProposalArgument>>;
  motionProposalHash: Scalars['String'];
  motionProposalId: Scalars['Int'];
  motionStatus?: Maybe<Array<MotionStatus>>;
  network: Scalars['String'];
  networkMotionProposalId: Scalars['String'];
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<Preimage>;
  preimageHash?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  treasurySpendProposal?: Maybe<TreasurySpendProposal>;
};


export type MotionMotionProposalArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionProposalArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionProposalArgumentWhereInput>;
};


export type MotionMotionStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionStatusWhereInput>;
};

/** A connection to a list of items. */
export type MotionConnection = {
  __typename?: 'MotionConnection';
  aggregate: AggregateMotion;
  /** A list of edges. */
  edges: Array<Maybe<MotionEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MotionCreateInput = {
  author: Scalars['String'];
  memberCount: Scalars['Int'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motionProposalArguments?: Maybe<MotionProposalArgumentCreateManyWithoutMotionInput>;
  motionProposalHash: Scalars['String'];
  motionProposalId: Scalars['Int'];
  motionStatus?: Maybe<MotionStatusCreateManyWithoutMotionInput>;
  network: Scalars['String'];
  networkMotionProposalId: Scalars['String'];
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageCreateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  treasurySpendProposal?: Maybe<TreasurySpendProposalCreateOneWithoutMotionInput>;
};

export type MotionCreateOneWithoutMotionProposalArgumentsInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutMotionProposalArgumentsInput>;
};

export type MotionCreateOneWithoutMotionStatusInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutMotionStatusInput>;
};

export type MotionCreateOneWithoutPreimageInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutPreimageInput>;
};

export type MotionCreateOneWithoutTreasurySpendProposalInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutTreasurySpendProposalInput>;
};

export type MotionCreateWithoutMotionProposalArgumentsInput = {
  author: Scalars['String'];
  memberCount: Scalars['Int'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motionProposalHash: Scalars['String'];
  motionProposalId: Scalars['Int'];
  motionStatus?: Maybe<MotionStatusCreateManyWithoutMotionInput>;
  network: Scalars['String'];
  networkMotionProposalId: Scalars['String'];
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageCreateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  treasurySpendProposal?: Maybe<TreasurySpendProposalCreateOneWithoutMotionInput>;
};

export type MotionCreateWithoutMotionStatusInput = {
  author: Scalars['String'];
  memberCount: Scalars['Int'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motionProposalArguments?: Maybe<MotionProposalArgumentCreateManyWithoutMotionInput>;
  motionProposalHash: Scalars['String'];
  motionProposalId: Scalars['Int'];
  network: Scalars['String'];
  networkMotionProposalId: Scalars['String'];
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageCreateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  treasurySpendProposal?: Maybe<TreasurySpendProposalCreateOneWithoutMotionInput>;
};

export type MotionCreateWithoutPreimageInput = {
  author: Scalars['String'];
  memberCount: Scalars['Int'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motionProposalArguments?: Maybe<MotionProposalArgumentCreateManyWithoutMotionInput>;
  motionProposalHash: Scalars['String'];
  motionProposalId: Scalars['Int'];
  motionStatus?: Maybe<MotionStatusCreateManyWithoutMotionInput>;
  network: Scalars['String'];
  networkMotionProposalId: Scalars['String'];
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  section: Scalars['String'];
  treasurySpendProposal?: Maybe<TreasurySpendProposalCreateOneWithoutMotionInput>;
};

export type MotionCreateWithoutTreasurySpendProposalInput = {
  author: Scalars['String'];
  memberCount: Scalars['Int'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motionProposalArguments?: Maybe<MotionProposalArgumentCreateManyWithoutMotionInput>;
  motionProposalHash: Scalars['String'];
  motionProposalId: Scalars['Int'];
  motionStatus?: Maybe<MotionStatusCreateManyWithoutMotionInput>;
  network: Scalars['String'];
  networkMotionProposalId: Scalars['String'];
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageCreateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section: Scalars['String'];
};

/** An edge in a connection. */
export type MotionEdge = {
  __typename?: 'MotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Motion;
};

export enum MotionOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MemberCountAsc = 'memberCount_ASC',
  MemberCountDesc = 'memberCount_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  MotionProposalHashAsc = 'motionProposalHash_ASC',
  MotionProposalHashDesc = 'motionProposalHash_DESC',
  MotionProposalIdAsc = 'motionProposalId_ASC',
  MotionProposalIdDesc = 'motionProposalId_DESC',
  NetworkMotionProposalIdAsc = 'networkMotionProposalId_ASC',
  NetworkMotionProposalIdDesc = 'networkMotionProposalId_DESC',
  NetworkPreimageHashAsc = 'networkPreimageHash_ASC',
  NetworkPreimageHashDesc = 'networkPreimageHash_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PreimageHashAsc = 'preimageHash_ASC',
  PreimageHashDesc = 'preimageHash_DESC',
  SectionAsc = 'section_ASC',
  SectionDesc = 'section_DESC'
}

export type MotionPreviousValues = {
  __typename?: 'MotionPreviousValues';
  author: Scalars['String'];
  id: Scalars['Int'];
  memberCount: Scalars['Int'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motionProposalHash: Scalars['String'];
  motionProposalId: Scalars['Int'];
  network: Scalars['String'];
  networkMotionProposalId: Scalars['String'];
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  section: Scalars['String'];
};

export type MotionProposalArgument = Node & {
  __typename?: 'MotionProposalArgument';
  id: Scalars['ID'];
  motion: Motion;
  name: Scalars['String'];
  value: Scalars['String'];
};

/** A connection to a list of items. */
export type MotionProposalArgumentConnection = {
  __typename?: 'MotionProposalArgumentConnection';
  aggregate: AggregateMotionProposalArgument;
  /** A list of edges. */
  edges: Array<Maybe<MotionProposalArgumentEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MotionProposalArgumentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  motion: MotionCreateOneWithoutMotionProposalArgumentsInput;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type MotionProposalArgumentCreateManyWithoutMotionInput = {
  connect?: Maybe<Array<MotionProposalArgumentWhereUniqueInput>>;
  create?: Maybe<Array<MotionProposalArgumentCreateWithoutMotionInput>>;
};

export type MotionProposalArgumentCreateWithoutMotionInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

/** An edge in a connection. */
export type MotionProposalArgumentEdge = {
  __typename?: 'MotionProposalArgumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MotionProposalArgument;
};

export enum MotionProposalArgumentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type MotionProposalArgumentPreviousValues = {
  __typename?: 'MotionProposalArgumentPreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type MotionProposalArgumentScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionProposalArgumentScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionProposalArgumentScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionProposalArgumentScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type MotionProposalArgumentSubscriptionPayload = {
  __typename?: 'MotionProposalArgumentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MotionProposalArgument>;
  previousValues?: Maybe<MotionProposalArgumentPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type MotionProposalArgumentSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionProposalArgumentSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionProposalArgumentSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionProposalArgumentSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<MotionProposalArgumentWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type MotionProposalArgumentUpdateInput = {
  motion?: Maybe<MotionUpdateOneRequiredWithoutMotionProposalArgumentsInput>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MotionProposalArgumentUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MotionProposalArgumentUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MotionProposalArgumentUpdateManyWithWhereNestedInput = {
  data: MotionProposalArgumentUpdateManyDataInput;
  where: MotionProposalArgumentScalarWhereInput;
};

export type MotionProposalArgumentUpdateManyWithoutMotionInput = {
  connect?: Maybe<Array<MotionProposalArgumentWhereUniqueInput>>;
  create?: Maybe<Array<MotionProposalArgumentCreateWithoutMotionInput>>;
  delete?: Maybe<Array<MotionProposalArgumentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MotionProposalArgumentScalarWhereInput>>;
  disconnect?: Maybe<Array<MotionProposalArgumentWhereUniqueInput>>;
  set?: Maybe<Array<MotionProposalArgumentWhereUniqueInput>>;
  update?: Maybe<Array<MotionProposalArgumentUpdateWithWhereUniqueWithoutMotionInput>>;
  updateMany?: Maybe<Array<MotionProposalArgumentUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<MotionProposalArgumentUpsertWithWhereUniqueWithoutMotionInput>>;
};

export type MotionProposalArgumentUpdateWithWhereUniqueWithoutMotionInput = {
  data: MotionProposalArgumentUpdateWithoutMotionDataInput;
  where: MotionProposalArgumentWhereUniqueInput;
};

export type MotionProposalArgumentUpdateWithoutMotionDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MotionProposalArgumentUpsertWithWhereUniqueWithoutMotionInput = {
  create: MotionProposalArgumentCreateWithoutMotionInput;
  update: MotionProposalArgumentUpdateWithoutMotionDataInput;
  where: MotionProposalArgumentWhereUniqueInput;
};

export type MotionProposalArgumentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionProposalArgumentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionProposalArgumentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionProposalArgumentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  motion?: Maybe<MotionWhereInput>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type MotionProposalArgumentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type MotionStatus = Node & {
  __typename?: 'MotionStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  motion: Motion;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type MotionStatusConnection = {
  __typename?: 'MotionStatusConnection';
  aggregate: AggregateMotionStatus;
  /** A list of edges. */
  edges: Array<Maybe<MotionStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MotionStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  motion: MotionCreateOneWithoutMotionStatusInput;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type MotionStatusCreateManyWithoutMotionInput = {
  connect?: Maybe<Array<MotionStatusWhereUniqueInput>>;
  create?: Maybe<Array<MotionStatusCreateWithoutMotionInput>>;
};

export type MotionStatusCreateWithoutMotionInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type MotionStatusEdge = {
  __typename?: 'MotionStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: MotionStatus;
};

export enum MotionStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type MotionStatusPreviousValues = {
  __typename?: 'MotionStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type MotionStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type MotionStatusSubscriptionPayload = {
  __typename?: 'MotionStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<MotionStatus>;
  previousValues?: Maybe<MotionStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type MotionStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<MotionStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type MotionStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  motion?: Maybe<MotionUpdateOneRequiredWithoutMotionStatusInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type MotionStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type MotionStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type MotionStatusUpdateManyWithWhereNestedInput = {
  data: MotionStatusUpdateManyDataInput;
  where: MotionStatusScalarWhereInput;
};

export type MotionStatusUpdateManyWithoutMotionInput = {
  connect?: Maybe<Array<MotionStatusWhereUniqueInput>>;
  create?: Maybe<Array<MotionStatusCreateWithoutMotionInput>>;
  delete?: Maybe<Array<MotionStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<MotionStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<MotionStatusWhereUniqueInput>>;
  set?: Maybe<Array<MotionStatusWhereUniqueInput>>;
  update?: Maybe<Array<MotionStatusUpdateWithWhereUniqueWithoutMotionInput>>;
  updateMany?: Maybe<Array<MotionStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<MotionStatusUpsertWithWhereUniqueWithoutMotionInput>>;
};

export type MotionStatusUpdateWithWhereUniqueWithoutMotionInput = {
  data: MotionStatusUpdateWithoutMotionDataInput;
  where: MotionStatusWhereUniqueInput;
};

export type MotionStatusUpdateWithoutMotionDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type MotionStatusUpsertWithWhereUniqueWithoutMotionInput = {
  create: MotionStatusCreateWithoutMotionInput;
  update: MotionStatusUpdateWithoutMotionDataInput;
  where: MotionStatusWhereUniqueInput;
};

export type MotionStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  motion?: Maybe<MotionWhereInput>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type MotionStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type MotionSubscriptionPayload = {
  __typename?: 'MotionSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Motion>;
  previousValues?: Maybe<MotionPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type MotionSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<MotionWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type MotionUpdateInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motionProposalArguments?: Maybe<MotionProposalArgumentUpdateManyWithoutMotionInput>;
  motionProposalHash?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  motionStatus?: Maybe<MotionStatusUpdateManyWithoutMotionInput>;
  network?: Maybe<Scalars['String']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalUpdateOneWithoutMotionInput>;
};

export type MotionUpdateManyMutationInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motionProposalHash?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type MotionUpdateOneRequiredWithoutMotionProposalArgumentsInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutMotionProposalArgumentsInput>;
  update?: Maybe<MotionUpdateWithoutMotionProposalArgumentsDataInput>;
  upsert?: Maybe<MotionUpsertWithoutMotionProposalArgumentsInput>;
};

export type MotionUpdateOneRequiredWithoutMotionStatusInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutMotionStatusInput>;
  update?: Maybe<MotionUpdateWithoutMotionStatusDataInput>;
  upsert?: Maybe<MotionUpsertWithoutMotionStatusInput>;
};

export type MotionUpdateOneWithoutPreimageInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutPreimageInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MotionUpdateWithoutPreimageDataInput>;
  upsert?: Maybe<MotionUpsertWithoutPreimageInput>;
};

export type MotionUpdateOneWithoutTreasurySpendProposalInput = {
  connect?: Maybe<MotionWhereUniqueInput>;
  create?: Maybe<MotionCreateWithoutTreasurySpendProposalInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<MotionUpdateWithoutTreasurySpendProposalDataInput>;
  upsert?: Maybe<MotionUpsertWithoutTreasurySpendProposalInput>;
};

export type MotionUpdateWithoutMotionProposalArgumentsDataInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motionProposalHash?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  motionStatus?: Maybe<MotionStatusUpdateManyWithoutMotionInput>;
  network?: Maybe<Scalars['String']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalUpdateOneWithoutMotionInput>;
};

export type MotionUpdateWithoutMotionStatusDataInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motionProposalArguments?: Maybe<MotionProposalArgumentUpdateManyWithoutMotionInput>;
  motionProposalHash?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalUpdateOneWithoutMotionInput>;
};

export type MotionUpdateWithoutPreimageDataInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motionProposalArguments?: Maybe<MotionProposalArgumentUpdateManyWithoutMotionInput>;
  motionProposalHash?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  motionStatus?: Maybe<MotionStatusUpdateManyWithoutMotionInput>;
  network?: Maybe<Scalars['String']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalUpdateOneWithoutMotionInput>;
};

export type MotionUpdateWithoutTreasurySpendProposalDataInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motionProposalArguments?: Maybe<MotionProposalArgumentUpdateManyWithoutMotionInput>;
  motionProposalHash?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  motionStatus?: Maybe<MotionStatusUpdateManyWithoutMotionInput>;
  network?: Maybe<Scalars['String']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutMotionInput>;
  preimageHash?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type MotionUpsertWithoutMotionProposalArgumentsInput = {
  create: MotionCreateWithoutMotionProposalArgumentsInput;
  update: MotionUpdateWithoutMotionProposalArgumentsDataInput;
};

export type MotionUpsertWithoutMotionStatusInput = {
  create: MotionCreateWithoutMotionStatusInput;
  update: MotionUpdateWithoutMotionStatusDataInput;
};

export type MotionUpsertWithoutPreimageInput = {
  create: MotionCreateWithoutPreimageInput;
  update: MotionUpdateWithoutPreimageDataInput;
};

export type MotionUpsertWithoutTreasurySpendProposalInput = {
  create: MotionCreateWithoutTreasurySpendProposalInput;
  update: MotionUpdateWithoutTreasurySpendProposalDataInput;
};

export type MotionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  memberCount?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  memberCount_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  memberCount_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  memberCount_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  memberCount_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  memberCount_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  memberCount_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  memberCount_not_in?: Maybe<Array<Scalars['Int']>>;
  metaDescription?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  metaDescription_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  metaDescription_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  metaDescription_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  metaDescription_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  metaDescription_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  metaDescription_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  metaDescription_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  metaDescription_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  metaDescription_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  metaDescription_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  metaDescription_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  metaDescription_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  metaDescription_starts_with?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  method_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  method_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  method_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  method_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  method_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  method_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  method_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  method_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  method_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  method_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  method_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  method_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  method_starts_with?: Maybe<Scalars['String']>;
  motionProposalArguments_every?: Maybe<MotionProposalArgumentWhereInput>;
  motionProposalArguments_none?: Maybe<MotionProposalArgumentWhereInput>;
  motionProposalArguments_some?: Maybe<MotionProposalArgumentWhereInput>;
  motionProposalHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  motionProposalHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  motionProposalHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  motionProposalHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  motionProposalHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  motionProposalHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  motionProposalHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  motionProposalHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  motionProposalHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  motionProposalHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  motionProposalHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  motionProposalHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  motionProposalHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  motionProposalHash_starts_with?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  motionProposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  motionProposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  motionProposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  motionProposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  motionProposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  motionProposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  motionProposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  motionStatus_every?: Maybe<MotionStatusWhereInput>;
  motionStatus_none?: Maybe<MotionStatusWhereInput>;
  motionStatus_some?: Maybe<MotionStatusWhereInput>;
  network?: Maybe<Scalars['String']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkMotionProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkMotionProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkMotionProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkMotionProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkMotionProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkMotionProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkMotionProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkMotionProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkMotionProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkMotionProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkMotionProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkMotionProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkMotionProposalId_starts_with?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkPreimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkPreimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkPreimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkPreimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkPreimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkPreimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkPreimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkPreimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkPreimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkPreimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkPreimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkPreimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkPreimageHash_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  section_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  section_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  section_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  section_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  section_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  section_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  section_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  section_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  section_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  section_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  section_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  section_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  section_starts_with?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalWhereInput>;
};

export type MotionWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Motion = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<MotionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<MotionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<MotionWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  memberCount?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  memberCount_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  memberCount_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  memberCount_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  memberCount_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  memberCount_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  memberCount_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  memberCount_not_in?: Maybe<Array<Scalars['Int']>>;
  metaDescription?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  metaDescription_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  metaDescription_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  metaDescription_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  metaDescription_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  metaDescription_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  metaDescription_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  metaDescription_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  metaDescription_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  metaDescription_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  metaDescription_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  metaDescription_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  metaDescription_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  metaDescription_starts_with?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  method_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  method_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  method_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  method_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  method_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  method_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  method_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  method_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  method_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  method_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  method_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  method_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  method_starts_with?: Maybe<Scalars['String']>;
  motionProposalArguments_every?: Maybe<MotionProposalArgumentWhereInput>;
  motionProposalArguments_none?: Maybe<MotionProposalArgumentWhereInput>;
  motionProposalArguments_some?: Maybe<MotionProposalArgumentWhereInput>;
  motionProposalHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  motionProposalHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  motionProposalHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  motionProposalHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  motionProposalHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  motionProposalHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  motionProposalHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  motionProposalHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  motionProposalHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  motionProposalHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  motionProposalHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  motionProposalHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  motionProposalHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  motionProposalHash_starts_with?: Maybe<Scalars['String']>;
  motionProposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  motionProposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  motionProposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  motionProposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  motionProposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  motionProposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  motionProposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  motionProposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  motionStatus_every?: Maybe<MotionStatusWhereInput>;
  motionStatus_none?: Maybe<MotionStatusWhereInput>;
  motionStatus_some?: Maybe<MotionStatusWhereInput>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkMotionProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkMotionProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkMotionProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkMotionProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkMotionProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkMotionProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkMotionProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkMotionProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkMotionProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkMotionProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkMotionProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkMotionProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkMotionProposalId_starts_with?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkPreimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkPreimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkPreimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkPreimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkPreimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkPreimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkPreimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkPreimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkPreimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkPreimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkPreimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkPreimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkPreimageHash_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  section_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  section_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  section_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  section_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  section_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  section_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  section_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  section_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  section_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  section_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  section_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  section_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  section_starts_with?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalWhereInput>;
};

export type MotionWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkMotionProposalId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addProfile?: Maybe<Message>;
  addressLinkConfirm?: Maybe<ChangeResponse>;
  addressLinkStart?: Maybe<AddressLinkType>;
  addressLogin?: Maybe<LoginResponse>;
  addressLoginStart?: Maybe<AddressLoginType>;
  addressSignupConfirm?: Maybe<LoginResponse>;
  addressSignupStart?: Maybe<AddressLoginType>;
  addressUnlink?: Maybe<ChangeResponse>;
  changeAbout?: Maybe<Message>;
  changeEmail?: Maybe<ChangeResponse>;
  changeNotificationPreference?: Maybe<ChangeResponse>;
  changePassword?: Maybe<Message>;
  changeUsername?: Maybe<ChangeResponse>;
  createPostConfirm?: Maybe<Message>;
  createPostStart?: Maybe<AddressLoginType>;
  createProposalTracker?: Maybe<Message>;
  deleteAccount?: Maybe<Message>;
  editPostConfirm?: Maybe<Message>;
  editPostStart?: Maybe<AddressLoginType>;
  login?: Maybe<LoginResponse>;
  logout?: Maybe<Message>;
  metamaskLinkConfirm?: Maybe<ChangeResponse>;
  metamaskLinkStart?: Maybe<AddressLoginType>;
  metamaskLogin?: Maybe<LoginResponse>;
  metamaskLoginStart?: Maybe<AddressLoginType>;
  metamaskSignupConfirm?: Maybe<LoginResponse>;
  metamaskSignupStart?: Maybe<AddressLoginType>;
  multisigLinkConfirm?: Maybe<ChangeResponse>;
  multisigLinkStart?: Maybe<AddressLoginType>;
  postSubscribe?: Maybe<Message>;
  postUnsubscribe?: Maybe<Message>;
  reportContent?: Maybe<Message>;
  requestResetPassword?: Maybe<Message>;
  resendVerifyEmailToken?: Maybe<Message>;
  resetPassword?: Maybe<Message>;
  setCredentialsConfirm?: Maybe<ChangeResponse>;
  setCredentialsStart?: Maybe<AddressLoginType>;
  setDefaultAddress?: Maybe<ChangeResponse>;
  signup?: Maybe<LoginResponse>;
  undoEmailChange?: Maybe<UndoEmailChangeResponse>;
  updateProposalTracker?: Maybe<Message>;
  verifyEmail?: Maybe<ChangeResponse>;
};


export type MutationAddProfileArgs = {
  badges?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
};


export type MutationAddressLinkConfirmArgs = {
  address_id: Scalars['Int'];
  signature: Scalars['String'];
};


export type MutationAddressLinkStartArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
};


export type MutationAddressLoginArgs = {
  address: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationAddressLoginStartArgs = {
  address: Scalars['String'];
};


export type MutationAddressSignupConfirmArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationAddressSignupStartArgs = {
  address: Scalars['String'];
};


export type MutationAddressUnlinkArgs = {
  address: Scalars['String'];
};


export type MutationChangeAboutArgs = {
  address: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  signature: Scalars['String'];
  title: Scalars['String'];
};


export type MutationChangeEmailArgs = {
  email: Scalars['String'];
  network: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangeNotificationPreferenceArgs = {
  notificationPreferences?: Maybe<NotificationPreferencesInput>;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


export type MutationChangeUsernameArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationCreatePostConfirmArgs = {
  address: Scalars['String'];
  content: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
  title: Scalars['String'];
};


export type MutationCreatePostStartArgs = {
  address: Scalars['String'];
};


export type MutationCreateProposalTrackerArgs = {
  deadline: Scalars['String'];
  network: Scalars['String'];
  onchain_network_proposal_id: Scalars['String'];
  start_time: Scalars['String'];
  status: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  password: Scalars['String'];
};


export type MutationEditPostConfirmArgs = {
  address: Scalars['String'];
  content: Scalars['String'];
  network: Scalars['String'];
  proposalId: Scalars['String'];
  proposalType: Scalars['String'];
  signature: Scalars['String'];
  title: Scalars['String'];
};


export type MutationEditPostStartArgs = {
  address: Scalars['String'];
};


export type MutationLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationMetamaskLinkConfirmArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationMetamaskLinkStartArgs = {
  address: Scalars['String'];
};


export type MutationMetamaskLoginArgs = {
  address: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationMetamaskLoginStartArgs = {
  address: Scalars['String'];
};


export type MutationMetamaskSignupConfirmArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
};


export type MutationMetamaskSignupStartArgs = {
  address: Scalars['String'];
};


export type MutationMultisigLinkConfirmArgs = {
  address: Scalars['String'];
  addresses: Scalars['String'];
  network: Scalars['String'];
  signatory: Scalars['String'];
  signature: Scalars['String'];
  ss58Prefix: Scalars['Int'];
  threshold: Scalars['Int'];
};


export type MutationMultisigLinkStartArgs = {
  address: Scalars['String'];
};


export type MutationPostSubscribeArgs = {
  post_id: Scalars['Int'];
};


export type MutationPostUnsubscribeArgs = {
  post_id: Scalars['Int'];
};


export type MutationReportContentArgs = {
  comments?: Maybe<Scalars['String']>;
  content_id: Scalars['String'];
  network: Scalars['String'];
  reason: Scalars['String'];
  type: Scalars['String'];
};


export type MutationRequestResetPasswordArgs = {
  email: Scalars['String'];
  network: Scalars['String'];
};


export type MutationResendVerifyEmailTokenArgs = {
  network: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['Int'];
};


export type MutationSetCredentialsConfirmArgs = {
  address: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  password: Scalars['String'];
  signature: Scalars['String'];
  username: Scalars['String'];
};


export type MutationSetCredentialsStartArgs = {
  address: Scalars['String'];
};


export type MutationSetDefaultAddressArgs = {
  address: Scalars['String'];
};


export type MutationSignupArgs = {
  email?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationUndoEmailChangeArgs = {
  token: Scalars['String'];
};


export type MutationUpdateProposalTrackerArgs = {
  id: Scalars['Int'];
  network: Scalars['String'];
  status: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  token: Scalars['String'];
};

export enum MutationType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED'
}

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars['ID'];
};

export type NotificationPreferences = {
  __typename?: 'NotificationPreferences';
  newProposal?: Maybe<Scalars['Boolean']>;
  ownProposal?: Maybe<Scalars['Boolean']>;
  postCreated?: Maybe<Scalars['Boolean']>;
  postParticipated?: Maybe<Scalars['Boolean']>;
};

export type NotificationPreferencesInput = {
  newProposal?: Maybe<Scalars['Boolean']>;
  ownProposal?: Maybe<Scalars['Boolean']>;
  postCreated?: Maybe<Scalars['Boolean']>;
  postParticipated?: Maybe<Scalars['Boolean']>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Pip = Node & {
  __typename?: 'Pip';
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  pipArguments?: Maybe<Array<PipArgument>>;
  pipStatus?: Maybe<Array<PipStatus>>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId: Scalars['Int'];
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};


export type PipPipArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipArgumentWhereInput>;
};


export type PipPipStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipStatusWhereInput>;
};

export type PipArgument = Node & {
  __typename?: 'PipArgument';
  id: Scalars['ID'];
  name: Scalars['String'];
  pip: Pip;
  value?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type PipArgumentConnection = {
  __typename?: 'PipArgumentConnection';
  aggregate: AggregatePipArgument;
  /** A list of edges. */
  edges: Array<Maybe<PipArgumentEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PipArgumentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  pip: PipCreateOneWithoutPipArgumentsInput;
  value?: Maybe<Scalars['String']>;
};

export type PipArgumentCreateManyWithoutPipInput = {
  connect?: Maybe<Array<PipArgumentWhereUniqueInput>>;
  create?: Maybe<Array<PipArgumentCreateWithoutPipInput>>;
};

export type PipArgumentCreateWithoutPipInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type PipArgumentEdge = {
  __typename?: 'PipArgumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PipArgument;
};

export enum PipArgumentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type PipArgumentPreviousValues = {
  __typename?: 'PipArgumentPreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type PipArgumentScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipArgumentScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipArgumentScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipArgumentScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type PipArgumentSubscriptionPayload = {
  __typename?: 'PipArgumentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PipArgument>;
  previousValues?: Maybe<PipArgumentPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type PipArgumentSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipArgumentSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipArgumentSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipArgumentSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<PipArgumentWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type PipArgumentUpdateInput = {
  name?: Maybe<Scalars['String']>;
  pip?: Maybe<PipUpdateOneRequiredWithoutPipArgumentsInput>;
  value?: Maybe<Scalars['String']>;
};

export type PipArgumentUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PipArgumentUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PipArgumentUpdateManyWithWhereNestedInput = {
  data: PipArgumentUpdateManyDataInput;
  where: PipArgumentScalarWhereInput;
};

export type PipArgumentUpdateManyWithoutPipInput = {
  connect?: Maybe<Array<PipArgumentWhereUniqueInput>>;
  create?: Maybe<Array<PipArgumentCreateWithoutPipInput>>;
  delete?: Maybe<Array<PipArgumentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PipArgumentScalarWhereInput>>;
  disconnect?: Maybe<Array<PipArgumentWhereUniqueInput>>;
  set?: Maybe<Array<PipArgumentWhereUniqueInput>>;
  update?: Maybe<Array<PipArgumentUpdateWithWhereUniqueWithoutPipInput>>;
  updateMany?: Maybe<Array<PipArgumentUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<PipArgumentUpsertWithWhereUniqueWithoutPipInput>>;
};

export type PipArgumentUpdateWithWhereUniqueWithoutPipInput = {
  data: PipArgumentUpdateWithoutPipDataInput;
  where: PipArgumentWhereUniqueInput;
};

export type PipArgumentUpdateWithoutPipDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PipArgumentUpsertWithWhereUniqueWithoutPipInput = {
  create: PipArgumentCreateWithoutPipInput;
  update: PipArgumentUpdateWithoutPipDataInput;
  where: PipArgumentWhereUniqueInput;
};

export type PipArgumentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipArgumentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipArgumentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipArgumentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  pip?: Maybe<PipWhereInput>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type PipArgumentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** A connection to a list of items. */
export type PipConnection = {
  __typename?: 'PipConnection';
  aggregate: AggregatePip;
  /** A list of edges. */
  edges: Array<Maybe<PipEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PipCreateInput = {
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  pipArguments?: Maybe<PipArgumentCreateManyWithoutPipInput>;
  pipStatus?: Maybe<PipStatusCreateManyWithoutPipInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId: Scalars['Int'];
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PipCreateOneWithoutPipArgumentsInput = {
  connect?: Maybe<PipWhereUniqueInput>;
  create?: Maybe<PipCreateWithoutPipArgumentsInput>;
};

export type PipCreateOneWithoutPipStatusInput = {
  connect?: Maybe<PipWhereUniqueInput>;
  create?: Maybe<PipCreateWithoutPipStatusInput>;
};

export type PipCreateWithoutPipArgumentsInput = {
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  pipStatus?: Maybe<PipStatusCreateManyWithoutPipInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId: Scalars['Int'];
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PipCreateWithoutPipStatusInput = {
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  pipArguments?: Maybe<PipArgumentCreateManyWithoutPipInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId: Scalars['Int'];
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type PipEdge = {
  __typename?: 'PipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Pip;
};

export enum PipOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  BalanceAsc = 'balance_ASC',
  BalanceDesc = 'balance_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  IdentityIdAsc = 'identityId_ASC',
  IdentityIdDesc = 'identityId_DESC',
  MaybeBlockNumberAsc = 'maybeBlockNumber_ASC',
  MaybeBlockNumberDesc = 'maybeBlockNumber_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  NetworkProposalIdAsc = 'networkProposalId_ASC',
  NetworkProposalIdDesc = 'networkProposalId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  OptionDescriptionAsc = 'optionDescription_ASC',
  OptionDescriptionDesc = 'optionDescription_DESC',
  OptionUrlAsc = 'optionUrl_ASC',
  OptionUrlDesc = 'optionUrl_DESC',
  ProposalHashAsc = 'proposalHash_ASC',
  ProposalHashDesc = 'proposalHash_DESC',
  ProposalIdAsc = 'proposalId_ASC',
  ProposalIdDesc = 'proposalId_DESC',
  ProposedByAsc = 'proposedBy_ASC',
  ProposedByDesc = 'proposedBy_DESC',
  SectionAsc = 'section_ASC',
  SectionDesc = 'section_DESC'
}

export type PipPreviousValues = {
  __typename?: 'PipPreviousValues';
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId: Scalars['Int'];
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PipStatus = Node & {
  __typename?: 'PipStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  network: Scalars['String'];
  pip: Pip;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type PipStatusConnection = {
  __typename?: 'PipStatusConnection';
  aggregate: AggregatePipStatus;
  /** A list of edges. */
  edges: Array<Maybe<PipStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PipStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  pip: PipCreateOneWithoutPipStatusInput;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type PipStatusCreateManyWithoutPipInput = {
  connect?: Maybe<Array<PipStatusWhereUniqueInput>>;
  create?: Maybe<Array<PipStatusCreateWithoutPipInput>>;
};

export type PipStatusCreateWithoutPipInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type PipStatusEdge = {
  __typename?: 'PipStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PipStatus;
};

export enum PipStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type PipStatusPreviousValues = {
  __typename?: 'PipStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type PipStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type PipStatusSubscriptionPayload = {
  __typename?: 'PipStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PipStatus>;
  previousValues?: Maybe<PipStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type PipStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<PipStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type PipStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  pip?: Maybe<PipUpdateOneRequiredWithoutPipStatusInput>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type PipStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type PipStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type PipStatusUpdateManyWithWhereNestedInput = {
  data: PipStatusUpdateManyDataInput;
  where: PipStatusScalarWhereInput;
};

export type PipStatusUpdateManyWithoutPipInput = {
  connect?: Maybe<Array<PipStatusWhereUniqueInput>>;
  create?: Maybe<Array<PipStatusCreateWithoutPipInput>>;
  delete?: Maybe<Array<PipStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PipStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<PipStatusWhereUniqueInput>>;
  set?: Maybe<Array<PipStatusWhereUniqueInput>>;
  update?: Maybe<Array<PipStatusUpdateWithWhereUniqueWithoutPipInput>>;
  updateMany?: Maybe<Array<PipStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<PipStatusUpsertWithWhereUniqueWithoutPipInput>>;
};

export type PipStatusUpdateWithWhereUniqueWithoutPipInput = {
  data: PipStatusUpdateWithoutPipDataInput;
  where: PipStatusWhereUniqueInput;
};

export type PipStatusUpdateWithoutPipDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type PipStatusUpsertWithWhereUniqueWithoutPipInput = {
  create: PipStatusCreateWithoutPipInput;
  update: PipStatusUpdateWithoutPipDataInput;
  where: PipStatusWhereUniqueInput;
};

export type PipStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  pip?: Maybe<PipWhereInput>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type PipStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type PipSubscriptionPayload = {
  __typename?: 'PipSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Pip>;
  previousValues?: Maybe<PipPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type PipSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<PipWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type PipUpdateInput = {
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  pipArguments?: Maybe<PipArgumentUpdateManyWithoutPipInput>;
  pipStatus?: Maybe<PipStatusUpdateManyWithoutPipInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PipUpdateManyMutationInput = {
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PipUpdateOneRequiredWithoutPipArgumentsInput = {
  connect?: Maybe<PipWhereUniqueInput>;
  create?: Maybe<PipCreateWithoutPipArgumentsInput>;
  update?: Maybe<PipUpdateWithoutPipArgumentsDataInput>;
  upsert?: Maybe<PipUpsertWithoutPipArgumentsInput>;
};

export type PipUpdateOneRequiredWithoutPipStatusInput = {
  connect?: Maybe<PipWhereUniqueInput>;
  create?: Maybe<PipCreateWithoutPipStatusInput>;
  update?: Maybe<PipUpdateWithoutPipStatusDataInput>;
  upsert?: Maybe<PipUpsertWithoutPipStatusInput>;
};

export type PipUpdateWithoutPipArgumentsDataInput = {
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  pipStatus?: Maybe<PipStatusUpdateManyWithoutPipInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PipUpdateWithoutPipStatusDataInput = {
  author?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  identityId?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  optionDescription?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  pipArguments?: Maybe<PipArgumentUpdateManyWithoutPipInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  proposedBy?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PipUpsertWithoutPipArgumentsInput = {
  create: PipCreateWithoutPipArgumentsInput;
  update: PipUpdateWithoutPipArgumentsDataInput;
};

export type PipUpsertWithoutPipStatusInput = {
  create: PipCreateWithoutPipStatusInput;
  update: PipUpdateWithoutPipStatusDataInput;
};

export type PipWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  balance_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  balance_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  balance_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  balance_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  balance_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  balance_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  balance_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  balance_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  balance_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  balance_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  balance_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  balance_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  balance_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  identityId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  identityId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  identityId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  identityId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  identityId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  identityId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  identityId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  identityId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  identityId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  identityId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  identityId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  identityId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  identityId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  identityId_starts_with?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  maybeBlockNumber_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  maybeBlockNumber_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  maybeBlockNumber_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  maybeBlockNumber_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  maybeBlockNumber_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  maybeBlockNumber_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  maybeBlockNumber_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  maybeBlockNumber_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  maybeBlockNumber_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  maybeBlockNumber_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  maybeBlockNumber_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  maybeBlockNumber_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  maybeBlockNumber_starts_with?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  method_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  method_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  method_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  method_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  method_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  method_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  method_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  method_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  method_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  method_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  method_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  method_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  method_starts_with?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  optionDescription?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  optionDescription_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  optionDescription_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  optionDescription_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  optionDescription_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  optionDescription_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  optionDescription_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  optionDescription_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  optionDescription_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  optionDescription_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  optionDescription_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  optionDescription_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  optionDescription_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  optionDescription_starts_with?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  optionUrl_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  optionUrl_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  optionUrl_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  optionUrl_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  optionUrl_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  optionUrl_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  optionUrl_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  optionUrl_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  optionUrl_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  optionUrl_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  optionUrl_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  optionUrl_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  optionUrl_starts_with?: Maybe<Scalars['String']>;
  pipArguments_every?: Maybe<PipArgumentWhereInput>;
  pipArguments_none?: Maybe<PipArgumentWhereInput>;
  pipArguments_some?: Maybe<PipArgumentWhereInput>;
  pipStatus_every?: Maybe<PipStatusWhereInput>;
  pipStatus_none?: Maybe<PipStatusWhereInput>;
  pipStatus_some?: Maybe<PipStatusWhereInput>;
  proposalHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposalHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposalHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposalHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposalHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposalHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposalHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposalHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposalHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposalHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposalHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposalHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposalHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposalHash_starts_with?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  proposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  proposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  proposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  proposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  proposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  proposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  proposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  proposedBy?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposedBy_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposedBy_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposedBy_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposedBy_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposedBy_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposedBy_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposedBy_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposedBy_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposedBy_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposedBy_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposedBy_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposedBy_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposedBy_starts_with?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  section_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  section_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  section_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  section_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  section_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  section_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  section_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  section_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  section_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  section_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  section_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  section_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  section_starts_with?: Maybe<Scalars['String']>;
};

export type PipWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Pip = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PipWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PipWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PipWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  balance_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  balance_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  balance_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  balance_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  balance_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  balance_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  balance_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  balance_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  balance_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  balance_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  balance_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  balance_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  balance_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  identityId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  identityId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  identityId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  identityId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  identityId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  identityId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  identityId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  identityId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  identityId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  identityId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  identityId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  identityId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  identityId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  identityId_starts_with?: Maybe<Scalars['String']>;
  maybeBlockNumber?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  maybeBlockNumber_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  maybeBlockNumber_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  maybeBlockNumber_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  maybeBlockNumber_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  maybeBlockNumber_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  maybeBlockNumber_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  maybeBlockNumber_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  maybeBlockNumber_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  maybeBlockNumber_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  maybeBlockNumber_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  maybeBlockNumber_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  maybeBlockNumber_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  maybeBlockNumber_starts_with?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  method_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  method_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  method_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  method_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  method_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  method_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  method_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  method_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  method_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  method_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  method_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  method_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  method_starts_with?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  optionDescription?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  optionDescription_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  optionDescription_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  optionDescription_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  optionDescription_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  optionDescription_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  optionDescription_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  optionDescription_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  optionDescription_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  optionDescription_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  optionDescription_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  optionDescription_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  optionDescription_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  optionDescription_starts_with?: Maybe<Scalars['String']>;
  optionUrl?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  optionUrl_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  optionUrl_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  optionUrl_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  optionUrl_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  optionUrl_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  optionUrl_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  optionUrl_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  optionUrl_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  optionUrl_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  optionUrl_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  optionUrl_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  optionUrl_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  optionUrl_starts_with?: Maybe<Scalars['String']>;
  pipArguments_every?: Maybe<PipArgumentWhereInput>;
  pipArguments_none?: Maybe<PipArgumentWhereInput>;
  pipArguments_some?: Maybe<PipArgumentWhereInput>;
  pipStatus_every?: Maybe<PipStatusWhereInput>;
  pipStatus_none?: Maybe<PipStatusWhereInput>;
  pipStatus_some?: Maybe<PipStatusWhereInput>;
  proposalHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposalHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposalHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposalHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposalHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposalHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposalHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposalHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposalHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposalHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposalHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposalHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposalHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposalHash_starts_with?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  proposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  proposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  proposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  proposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  proposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  proposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  proposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  proposedBy?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposedBy_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposedBy_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposedBy_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposedBy_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposedBy_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposedBy_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposedBy_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposedBy_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposedBy_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposedBy_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposedBy_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposedBy_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposedBy_starts_with?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  section_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  section_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  section_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  section_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  section_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  section_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  section_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  section_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  section_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  section_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  section_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  section_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  section_starts_with?: Maybe<Scalars['String']>;
};

export type PipWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  networkProposalId?: Maybe<Scalars['String']>;
};

export type Preimage = Node & {
  __typename?: 'Preimage';
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['ID'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motion?: Maybe<Motion>;
  network: Scalars['String'];
  networkHash: Scalars['String'];
  preimageArguments?: Maybe<Array<PreimageArgument>>;
  preimageStatus?: Maybe<Array<PreimageStatus>>;
  proposal?: Maybe<Proposal>;
  referendum?: Maybe<Referendum>;
  section: Scalars['String'];
};


export type PreimagePreimageArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageArgumentWhereInput>;
};


export type PreimagePreimageStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageStatusWhereInput>;
};

export type PreimageArgument = Node & {
  __typename?: 'PreimageArgument';
  id: Scalars['ID'];
  name: Scalars['String'];
  preimage: Preimage;
  value: Scalars['String'];
};

/** A connection to a list of items. */
export type PreimageArgumentConnection = {
  __typename?: 'PreimageArgumentConnection';
  aggregate: AggregatePreimageArgument;
  /** A list of edges. */
  edges: Array<Maybe<PreimageArgumentEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PreimageArgumentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  preimage: PreimageCreateOneWithoutPreimageArgumentsInput;
  value: Scalars['String'];
};

export type PreimageArgumentCreateManyWithoutPreimageInput = {
  connect?: Maybe<Array<PreimageArgumentWhereUniqueInput>>;
  create?: Maybe<Array<PreimageArgumentCreateWithoutPreimageInput>>;
};

export type PreimageArgumentCreateWithoutPreimageInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

/** An edge in a connection. */
export type PreimageArgumentEdge = {
  __typename?: 'PreimageArgumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PreimageArgument;
};

export enum PreimageArgumentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type PreimageArgumentPreviousValues = {
  __typename?: 'PreimageArgumentPreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PreimageArgumentScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageArgumentScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageArgumentScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageArgumentScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type PreimageArgumentSubscriptionPayload = {
  __typename?: 'PreimageArgumentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PreimageArgument>;
  previousValues?: Maybe<PreimageArgumentPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type PreimageArgumentSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageArgumentSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageArgumentSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageArgumentSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<PreimageArgumentWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type PreimageArgumentUpdateInput = {
  name?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneRequiredWithoutPreimageArgumentsInput>;
  value?: Maybe<Scalars['String']>;
};

export type PreimageArgumentUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PreimageArgumentUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PreimageArgumentUpdateManyWithWhereNestedInput = {
  data: PreimageArgumentUpdateManyDataInput;
  where: PreimageArgumentScalarWhereInput;
};

export type PreimageArgumentUpdateManyWithoutPreimageInput = {
  connect?: Maybe<Array<PreimageArgumentWhereUniqueInput>>;
  create?: Maybe<Array<PreimageArgumentCreateWithoutPreimageInput>>;
  delete?: Maybe<Array<PreimageArgumentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PreimageArgumentScalarWhereInput>>;
  disconnect?: Maybe<Array<PreimageArgumentWhereUniqueInput>>;
  set?: Maybe<Array<PreimageArgumentWhereUniqueInput>>;
  update?: Maybe<Array<PreimageArgumentUpdateWithWhereUniqueWithoutPreimageInput>>;
  updateMany?: Maybe<Array<PreimageArgumentUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<PreimageArgumentUpsertWithWhereUniqueWithoutPreimageInput>>;
};

export type PreimageArgumentUpdateWithWhereUniqueWithoutPreimageInput = {
  data: PreimageArgumentUpdateWithoutPreimageDataInput;
  where: PreimageArgumentWhereUniqueInput;
};

export type PreimageArgumentUpdateWithoutPreimageDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type PreimageArgumentUpsertWithWhereUniqueWithoutPreimageInput = {
  create: PreimageArgumentCreateWithoutPreimageInput;
  update: PreimageArgumentUpdateWithoutPreimageDataInput;
  where: PreimageArgumentWhereUniqueInput;
};

export type PreimageArgumentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageArgumentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageArgumentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageArgumentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type PreimageArgumentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** A connection to a list of items. */
export type PreimageConnection = {
  __typename?: 'PreimageConnection';
  aggregate: AggregatePreimage;
  /** A list of edges. */
  edges: Array<Maybe<PreimageEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PreimageCreateInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motion?: Maybe<MotionCreateOneWithoutPreimageInput>;
  network: Scalars['String'];
  networkHash: Scalars['String'];
  preimageArguments?: Maybe<PreimageArgumentCreateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusCreateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalCreateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumCreateOneWithoutPreimageInput>;
  section: Scalars['String'];
};

export type PreimageCreateOneInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateInput>;
};

export type PreimageCreateOneWithoutMotionInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutMotionInput>;
};

export type PreimageCreateOneWithoutPreimageArgumentsInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutPreimageArgumentsInput>;
};

export type PreimageCreateOneWithoutPreimageStatusInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutPreimageStatusInput>;
};

export type PreimageCreateOneWithoutProposalInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutProposalInput>;
};

export type PreimageCreateOneWithoutReferendumInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutReferendumInput>;
};

export type PreimageCreateWithoutMotionInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  network: Scalars['String'];
  networkHash: Scalars['String'];
  preimageArguments?: Maybe<PreimageArgumentCreateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusCreateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalCreateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumCreateOneWithoutPreimageInput>;
  section: Scalars['String'];
};

export type PreimageCreateWithoutPreimageArgumentsInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motion?: Maybe<MotionCreateOneWithoutPreimageInput>;
  network: Scalars['String'];
  networkHash: Scalars['String'];
  preimageStatus?: Maybe<PreimageStatusCreateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalCreateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumCreateOneWithoutPreimageInput>;
  section: Scalars['String'];
};

export type PreimageCreateWithoutPreimageStatusInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motion?: Maybe<MotionCreateOneWithoutPreimageInput>;
  network: Scalars['String'];
  networkHash: Scalars['String'];
  preimageArguments?: Maybe<PreimageArgumentCreateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalCreateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumCreateOneWithoutPreimageInput>;
  section: Scalars['String'];
};

export type PreimageCreateWithoutProposalInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motion?: Maybe<MotionCreateOneWithoutPreimageInput>;
  network: Scalars['String'];
  networkHash: Scalars['String'];
  preimageArguments?: Maybe<PreimageArgumentCreateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusCreateManyWithoutPreimageInput>;
  referendum?: Maybe<ReferendumCreateOneWithoutPreimageInput>;
  section: Scalars['String'];
};

export type PreimageCreateWithoutReferendumInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  motion?: Maybe<MotionCreateOneWithoutPreimageInput>;
  network: Scalars['String'];
  networkHash: Scalars['String'];
  preimageArguments?: Maybe<PreimageArgumentCreateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusCreateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalCreateOneWithoutPreimageInput>;
  section: Scalars['String'];
};

/** An edge in a connection. */
export type PreimageEdge = {
  __typename?: 'PreimageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Preimage;
};

export enum PreimageOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  DepositAmountAsc = 'depositAmount_ASC',
  DepositAmountDesc = 'depositAmount_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  NetworkHashAsc = 'networkHash_ASC',
  NetworkHashDesc = 'networkHash_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  SectionAsc = 'section_ASC',
  SectionDesc = 'section_DESC'
}

export type PreimagePreviousValues = {
  __typename?: 'PreimagePreviousValues';
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  hash: Scalars['String'];
  id: Scalars['ID'];
  metaDescription: Scalars['String'];
  method: Scalars['String'];
  network: Scalars['String'];
  networkHash: Scalars['String'];
  section: Scalars['String'];
};

export type PreimageStatus = Node & {
  __typename?: 'PreimageStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  network: Scalars['String'];
  preimage: Preimage;
  status: Scalars['String'];
};

/** A connection to a list of items. */
export type PreimageStatusConnection = {
  __typename?: 'PreimageStatusConnection';
  aggregate: AggregatePreimageStatus;
  /** A list of edges. */
  edges: Array<Maybe<PreimageStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PreimageStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  preimage: PreimageCreateOneWithoutPreimageStatusInput;
  status: Scalars['String'];
};

export type PreimageStatusCreateManyWithoutPreimageInput = {
  connect?: Maybe<Array<PreimageStatusWhereUniqueInput>>;
  create?: Maybe<Array<PreimageStatusCreateWithoutPreimageInput>>;
};

export type PreimageStatusCreateWithoutPreimageInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
};

/** An edge in a connection. */
export type PreimageStatusEdge = {
  __typename?: 'PreimageStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: PreimageStatus;
};

export enum PreimageStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC'
}

export type PreimageStatusPreviousValues = {
  __typename?: 'PreimageStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
};

export type PreimageStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
};

export type PreimageStatusSubscriptionPayload = {
  __typename?: 'PreimageStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<PreimageStatus>;
  previousValues?: Maybe<PreimageStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type PreimageStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<PreimageStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type PreimageStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneRequiredWithoutPreimageStatusInput>;
  status?: Maybe<Scalars['String']>;
};

export type PreimageStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PreimageStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PreimageStatusUpdateManyWithWhereNestedInput = {
  data: PreimageStatusUpdateManyDataInput;
  where: PreimageStatusScalarWhereInput;
};

export type PreimageStatusUpdateManyWithoutPreimageInput = {
  connect?: Maybe<Array<PreimageStatusWhereUniqueInput>>;
  create?: Maybe<Array<PreimageStatusCreateWithoutPreimageInput>>;
  delete?: Maybe<Array<PreimageStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<PreimageStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<PreimageStatusWhereUniqueInput>>;
  set?: Maybe<Array<PreimageStatusWhereUniqueInput>>;
  update?: Maybe<Array<PreimageStatusUpdateWithWhereUniqueWithoutPreimageInput>>;
  updateMany?: Maybe<Array<PreimageStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<PreimageStatusUpsertWithWhereUniqueWithoutPreimageInput>>;
};

export type PreimageStatusUpdateWithWhereUniqueWithoutPreimageInput = {
  data: PreimageStatusUpdateWithoutPreimageDataInput;
  where: PreimageStatusWhereUniqueInput;
};

export type PreimageStatusUpdateWithoutPreimageDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type PreimageStatusUpsertWithWhereUniqueWithoutPreimageInput = {
  create: PreimageStatusCreateWithoutPreimageInput;
  update: PreimageStatusUpdateWithoutPreimageDataInput;
  where: PreimageStatusWhereUniqueInput;
};

export type PreimageStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
};

export type PreimageStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type PreimageSubscriptionPayload = {
  __typename?: 'PreimageSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Preimage>;
  previousValues?: Maybe<PreimagePreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type PreimageSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<PreimageWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type PreimageUpdateDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutPreimageInput>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  preimageArguments?: Maybe<PreimageArgumentUpdateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusUpdateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalUpdateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumUpdateOneWithoutPreimageInput>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpdateInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutPreimageInput>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  preimageArguments?: Maybe<PreimageArgumentUpdateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusUpdateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalUpdateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumUpdateOneWithoutPreimageInput>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpdateManyMutationInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpdateOneInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PreimageUpdateDataInput>;
  upsert?: Maybe<PreimageUpsertNestedInput>;
};

export type PreimageUpdateOneRequiredWithoutPreimageArgumentsInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutPreimageArgumentsInput>;
  update?: Maybe<PreimageUpdateWithoutPreimageArgumentsDataInput>;
  upsert?: Maybe<PreimageUpsertWithoutPreimageArgumentsInput>;
};

export type PreimageUpdateOneRequiredWithoutPreimageStatusInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutPreimageStatusInput>;
  update?: Maybe<PreimageUpdateWithoutPreimageStatusDataInput>;
  upsert?: Maybe<PreimageUpsertWithoutPreimageStatusInput>;
};

export type PreimageUpdateOneWithoutMotionInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutMotionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PreimageUpdateWithoutMotionDataInput>;
  upsert?: Maybe<PreimageUpsertWithoutMotionInput>;
};

export type PreimageUpdateOneWithoutProposalInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutProposalInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PreimageUpdateWithoutProposalDataInput>;
  upsert?: Maybe<PreimageUpsertWithoutProposalInput>;
};

export type PreimageUpdateOneWithoutReferendumInput = {
  connect?: Maybe<PreimageWhereUniqueInput>;
  create?: Maybe<PreimageCreateWithoutReferendumInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<PreimageUpdateWithoutReferendumDataInput>;
  upsert?: Maybe<PreimageUpsertWithoutReferendumInput>;
};

export type PreimageUpdateWithoutMotionDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  preimageArguments?: Maybe<PreimageArgumentUpdateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusUpdateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalUpdateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumUpdateOneWithoutPreimageInput>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpdateWithoutPreimageArgumentsDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutPreimageInput>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  preimageStatus?: Maybe<PreimageStatusUpdateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalUpdateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumUpdateOneWithoutPreimageInput>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpdateWithoutPreimageStatusDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutPreimageInput>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  preimageArguments?: Maybe<PreimageArgumentUpdateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalUpdateOneWithoutPreimageInput>;
  referendum?: Maybe<ReferendumUpdateOneWithoutPreimageInput>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpdateWithoutProposalDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutPreimageInput>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  preimageArguments?: Maybe<PreimageArgumentUpdateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusUpdateManyWithoutPreimageInput>;
  referendum?: Maybe<ReferendumUpdateOneWithoutPreimageInput>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpdateWithoutReferendumDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutPreimageInput>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  preimageArguments?: Maybe<PreimageArgumentUpdateManyWithoutPreimageInput>;
  preimageStatus?: Maybe<PreimageStatusUpdateManyWithoutPreimageInput>;
  proposal?: Maybe<ProposalUpdateOneWithoutPreimageInput>;
  section?: Maybe<Scalars['String']>;
};

export type PreimageUpsertNestedInput = {
  create: PreimageCreateInput;
  update: PreimageUpdateDataInput;
};

export type PreimageUpsertWithoutMotionInput = {
  create: PreimageCreateWithoutMotionInput;
  update: PreimageUpdateWithoutMotionDataInput;
};

export type PreimageUpsertWithoutPreimageArgumentsInput = {
  create: PreimageCreateWithoutPreimageArgumentsInput;
  update: PreimageUpdateWithoutPreimageArgumentsDataInput;
};

export type PreimageUpsertWithoutPreimageStatusInput = {
  create: PreimageCreateWithoutPreimageStatusInput;
  update: PreimageUpdateWithoutPreimageStatusDataInput;
};

export type PreimageUpsertWithoutProposalInput = {
  create: PreimageCreateWithoutProposalInput;
  update: PreimageUpdateWithoutProposalDataInput;
};

export type PreimageUpsertWithoutReferendumInput = {
  create: PreimageCreateWithoutReferendumInput;
  update: PreimageUpdateWithoutReferendumDataInput;
};

export type PreimageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<PreimageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<PreimageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<PreimageWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  depositAmount_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  depositAmount_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  depositAmount_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  depositAmount_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  depositAmount_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  depositAmount_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  depositAmount_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  depositAmount_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  depositAmount_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  depositAmount_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  depositAmount_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  depositAmount_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  depositAmount_starts_with?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  hash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  hash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  hash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  hash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  hash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  hash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  hash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  hash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  hash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  hash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  hash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  hash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  hash_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  metaDescription?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  metaDescription_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  metaDescription_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  metaDescription_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  metaDescription_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  metaDescription_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  metaDescription_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  metaDescription_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  metaDescription_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  metaDescription_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  metaDescription_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  metaDescription_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  metaDescription_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  metaDescription_starts_with?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  method_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  method_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  method_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  method_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  method_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  method_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  method_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  method_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  method_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  method_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  method_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  method_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  method_starts_with?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionWhereInput>;
  network?: Maybe<Scalars['String']>;
  networkHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkHash_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimageArguments_every?: Maybe<PreimageArgumentWhereInput>;
  preimageArguments_none?: Maybe<PreimageArgumentWhereInput>;
  preimageArguments_some?: Maybe<PreimageArgumentWhereInput>;
  preimageStatus_every?: Maybe<PreimageStatusWhereInput>;
  preimageStatus_none?: Maybe<PreimageStatusWhereInput>;
  preimageStatus_some?: Maybe<PreimageStatusWhereInput>;
  proposal?: Maybe<ProposalWhereInput>;
  referendum?: Maybe<ReferendumWhereInput>;
  section?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  section_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  section_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  section_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  section_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  section_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  section_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  section_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  section_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  section_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  section_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  section_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  section_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  section_starts_with?: Maybe<Scalars['String']>;
};

export type PreimageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum PrismaDatabase {
  Default = 'default'
}

export type Profile = {
  __typename?: 'Profile';
  badges?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
};

export type Proposal = {
  __typename?: 'Proposal';
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  id: Scalars['Int'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimage?: Maybe<Preimage>;
  preimageHash: Scalars['String'];
  proposalId: Scalars['Int'];
  proposalStatus?: Maybe<Array<ProposalStatus>>;
};


export type ProposalProposalStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProposalStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ProposalStatusWhereInput>;
};

/** A connection to a list of items. */
export type ProposalConnection = {
  __typename?: 'ProposalConnection';
  aggregate: AggregateProposal;
  /** A list of edges. */
  edges: Array<Maybe<ProposalEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProposalCreateInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimage?: Maybe<PreimageCreateOneWithoutProposalInput>;
  preimageHash: Scalars['String'];
  proposalId: Scalars['Int'];
  proposalStatus?: Maybe<ProposalStatusCreateManyWithoutProposalInput>;
};

export type ProposalCreateOneWithoutPreimageInput = {
  connect?: Maybe<ProposalWhereUniqueInput>;
  create?: Maybe<ProposalCreateWithoutPreimageInput>;
};

export type ProposalCreateOneWithoutProposalStatusInput = {
  connect?: Maybe<ProposalWhereUniqueInput>;
  create?: Maybe<ProposalCreateWithoutProposalStatusInput>;
};

export type ProposalCreateWithoutPreimageInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimageHash: Scalars['String'];
  proposalId: Scalars['Int'];
  proposalStatus?: Maybe<ProposalStatusCreateManyWithoutProposalInput>;
};

export type ProposalCreateWithoutProposalStatusInput = {
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimage?: Maybe<PreimageCreateOneWithoutProposalInput>;
  preimageHash: Scalars['String'];
  proposalId: Scalars['Int'];
};

/** An edge in a connection. */
export type ProposalEdge = {
  __typename?: 'ProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Proposal;
};

export enum ProposalOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  DepositAmountAsc = 'depositAmount_ASC',
  DepositAmountDesc = 'depositAmount_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkPreimageHashAsc = 'networkPreimageHash_ASC',
  NetworkPreimageHashDesc = 'networkPreimageHash_DESC',
  NetworkProposalIdAsc = 'networkProposalId_ASC',
  NetworkProposalIdDesc = 'networkProposalId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PreimageHashAsc = 'preimageHash_ASC',
  PreimageHashDesc = 'preimageHash_DESC',
  ProposalIdAsc = 'proposalId_ASC',
  ProposalIdDesc = 'proposalId_DESC'
}

export type ProposalPreviousValues = {
  __typename?: 'ProposalPreviousValues';
  author: Scalars['String'];
  depositAmount: Scalars['String'];
  id: Scalars['Int'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimageHash: Scalars['String'];
  proposalId: Scalars['Int'];
};

export type ProposalStatus = Node & {
  __typename?: 'ProposalStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  network: Scalars['String'];
  proposal: Proposal;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type ProposalStatusConnection = {
  __typename?: 'ProposalStatusConnection';
  aggregate: AggregateProposalStatus;
  /** A list of edges. */
  edges: Array<Maybe<ProposalStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ProposalStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  proposal: ProposalCreateOneWithoutProposalStatusInput;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type ProposalStatusCreateManyWithoutProposalInput = {
  connect?: Maybe<Array<ProposalStatusWhereUniqueInput>>;
  create?: Maybe<Array<ProposalStatusCreateWithoutProposalInput>>;
};

export type ProposalStatusCreateWithoutProposalInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type ProposalStatusEdge = {
  __typename?: 'ProposalStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ProposalStatus;
};

export enum ProposalStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type ProposalStatusPreviousValues = {
  __typename?: 'ProposalStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type ProposalStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProposalStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProposalStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProposalStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type ProposalStatusSubscriptionPayload = {
  __typename?: 'ProposalStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ProposalStatus>;
  previousValues?: Maybe<ProposalStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type ProposalStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProposalStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProposalStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProposalStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<ProposalStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type ProposalStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  proposal?: Maybe<ProposalUpdateOneRequiredWithoutProposalStatusInput>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ProposalStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ProposalStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ProposalStatusUpdateManyWithWhereNestedInput = {
  data: ProposalStatusUpdateManyDataInput;
  where: ProposalStatusScalarWhereInput;
};

export type ProposalStatusUpdateManyWithoutProposalInput = {
  connect?: Maybe<Array<ProposalStatusWhereUniqueInput>>;
  create?: Maybe<Array<ProposalStatusCreateWithoutProposalInput>>;
  delete?: Maybe<Array<ProposalStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ProposalStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ProposalStatusWhereUniqueInput>>;
  set?: Maybe<Array<ProposalStatusWhereUniqueInput>>;
  update?: Maybe<Array<ProposalStatusUpdateWithWhereUniqueWithoutProposalInput>>;
  updateMany?: Maybe<Array<ProposalStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<ProposalStatusUpsertWithWhereUniqueWithoutProposalInput>>;
};

export type ProposalStatusUpdateWithWhereUniqueWithoutProposalInput = {
  data: ProposalStatusUpdateWithoutProposalDataInput;
  where: ProposalStatusWhereUniqueInput;
};

export type ProposalStatusUpdateWithoutProposalDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ProposalStatusUpsertWithWhereUniqueWithoutProposalInput = {
  create: ProposalStatusCreateWithoutProposalInput;
  update: ProposalStatusUpdateWithoutProposalDataInput;
  where: ProposalStatusWhereUniqueInput;
};

export type ProposalStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProposalStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProposalStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProposalStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  proposal?: Maybe<ProposalWhereInput>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type ProposalStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ProposalSubscriptionPayload = {
  __typename?: 'ProposalSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Proposal>;
  previousValues?: Maybe<ProposalPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type ProposalSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProposalSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProposalSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProposalSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<ProposalWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type ProposalUpdateInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutProposalInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  proposalStatus?: Maybe<ProposalStatusUpdateManyWithoutProposalInput>;
};

export type ProposalUpdateManyMutationInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
};

export type ProposalUpdateOneRequiredWithoutProposalStatusInput = {
  connect?: Maybe<ProposalWhereUniqueInput>;
  create?: Maybe<ProposalCreateWithoutProposalStatusInput>;
  update?: Maybe<ProposalUpdateWithoutProposalStatusDataInput>;
  upsert?: Maybe<ProposalUpsertWithoutProposalStatusInput>;
};

export type ProposalUpdateOneWithoutPreimageInput = {
  connect?: Maybe<ProposalWhereUniqueInput>;
  create?: Maybe<ProposalCreateWithoutPreimageInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ProposalUpdateWithoutPreimageDataInput>;
  upsert?: Maybe<ProposalUpsertWithoutPreimageInput>;
};

export type ProposalUpdateWithoutPreimageDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  proposalStatus?: Maybe<ProposalStatusUpdateManyWithoutProposalInput>;
};

export type ProposalUpdateWithoutProposalStatusDataInput = {
  author?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutProposalInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
};

export type ProposalUpsertWithoutPreimageInput = {
  create: ProposalCreateWithoutPreimageInput;
  update: ProposalUpdateWithoutPreimageDataInput;
};

export type ProposalUpsertWithoutProposalStatusInput = {
  create: ProposalCreateWithoutProposalStatusInput;
  update: ProposalUpdateWithoutProposalStatusDataInput;
};

export type ProposalWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProposalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProposalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProposalWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  depositAmount_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  depositAmount_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  depositAmount_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  depositAmount_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  depositAmount_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  depositAmount_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  depositAmount_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  depositAmount_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  depositAmount_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  depositAmount_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  depositAmount_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  depositAmount_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  depositAmount_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkPreimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkPreimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkPreimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkPreimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkPreimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkPreimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkPreimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkPreimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkPreimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkPreimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkPreimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkPreimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkPreimageHash_starts_with?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  proposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  proposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  proposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  proposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  proposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  proposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  proposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  proposalStatus_every?: Maybe<ProposalStatusWhereInput>;
  proposalStatus_none?: Maybe<ProposalStatusWhereInput>;
  proposalStatus_some?: Maybe<ProposalStatusWhereInput>;
};

export type ProposalWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Proposal = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ProposalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ProposalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ProposalWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  depositAmount?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  depositAmount_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  depositAmount_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  depositAmount_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  depositAmount_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  depositAmount_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  depositAmount_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  depositAmount_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  depositAmount_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  depositAmount_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  depositAmount_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  depositAmount_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  depositAmount_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  depositAmount_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkPreimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkPreimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkPreimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkPreimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkPreimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkPreimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkPreimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkPreimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkPreimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkPreimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkPreimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkPreimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkPreimageHash_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  proposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  proposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  proposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  proposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  proposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  proposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  proposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  proposalStatus_every?: Maybe<ProposalStatusWhereInput>;
  proposalStatus_none?: Maybe<ProposalStatusWhereInput>;
  proposalStatus_some?: Maybe<ProposalStatusWhereInput>;
};

export type ProposalWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkProposalId?: Maybe<Scalars['String']>;
};

export type PublicUser = {
  __typename?: 'PublicUser';
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  about?: Maybe<About>;
  profile?: Maybe<User>;
  subscription?: Maybe<Subscription>;
  token?: Maybe<Token>;
  user?: Maybe<User>;
  userDetails?: Maybe<Profile>;
  userWithUsername?: Maybe<Profile>;
};


export type QueryAboutArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
};


export type QueryProfileArgs = {
  username: Scalars['String'];
};


export type QuerySubscriptionArgs = {
  post_id: Scalars['Int'];
};


export type QueryUserArgs = {
  id: Scalars['Int'];
};


export type QueryUserDetailsArgs = {
  user_id: Scalars['Int'];
};


export type QueryUserWithUsernameArgs = {
  username: Scalars['String'];
};

export type Referendum = {
  __typename?: 'Referendum';
  delay: Scalars['Int'];
  end: Scalars['Int'];
  id: Scalars['Int'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkReferendumId: Scalars['String'];
  preimage?: Maybe<Preimage>;
  preimageHash: Scalars['String'];
  referendumId: Scalars['Int'];
  referendumStatus?: Maybe<Array<ReferendumStatus>>;
  voteThreshold: Scalars['String'];
};


export type ReferendumReferendumStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferendumStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ReferendumStatusWhereInput>;
};

/** A connection to a list of items. */
export type ReferendumConnection = {
  __typename?: 'ReferendumConnection';
  aggregate: AggregateReferendum;
  /** A list of edges. */
  edges: Array<Maybe<ReferendumEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ReferendumCreateInput = {
  delay: Scalars['Int'];
  end: Scalars['Int'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkReferendumId: Scalars['String'];
  preimage?: Maybe<PreimageCreateOneWithoutReferendumInput>;
  preimageHash: Scalars['String'];
  referendumId: Scalars['Int'];
  referendumStatus?: Maybe<ReferendumStatusCreateManyWithoutReferendumInput>;
  voteThreshold: Scalars['String'];
};

export type ReferendumCreateOneWithoutPreimageInput = {
  connect?: Maybe<ReferendumWhereUniqueInput>;
  create?: Maybe<ReferendumCreateWithoutPreimageInput>;
};

export type ReferendumCreateOneWithoutReferendumStatusInput = {
  connect?: Maybe<ReferendumWhereUniqueInput>;
  create?: Maybe<ReferendumCreateWithoutReferendumStatusInput>;
};

export type ReferendumCreateWithoutPreimageInput = {
  delay: Scalars['Int'];
  end: Scalars['Int'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkReferendumId: Scalars['String'];
  preimageHash: Scalars['String'];
  referendumId: Scalars['Int'];
  referendumStatus?: Maybe<ReferendumStatusCreateManyWithoutReferendumInput>;
  voteThreshold: Scalars['String'];
};

export type ReferendumCreateWithoutReferendumStatusInput = {
  delay: Scalars['Int'];
  end: Scalars['Int'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkReferendumId: Scalars['String'];
  preimage?: Maybe<PreimageCreateOneWithoutReferendumInput>;
  preimageHash: Scalars['String'];
  referendumId: Scalars['Int'];
  voteThreshold: Scalars['String'];
};

/** An edge in a connection. */
export type ReferendumEdge = {
  __typename?: 'ReferendumEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Referendum;
};

export enum ReferendumOrderByInput {
  DelayAsc = 'delay_ASC',
  DelayDesc = 'delay_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkPreimageHashAsc = 'networkPreimageHash_ASC',
  NetworkPreimageHashDesc = 'networkPreimageHash_DESC',
  NetworkReferendumIdAsc = 'networkReferendumId_ASC',
  NetworkReferendumIdDesc = 'networkReferendumId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PreimageHashAsc = 'preimageHash_ASC',
  PreimageHashDesc = 'preimageHash_DESC',
  ReferendumIdAsc = 'referendumId_ASC',
  ReferendumIdDesc = 'referendumId_DESC',
  VoteThresholdAsc = 'voteThreshold_ASC',
  VoteThresholdDesc = 'voteThreshold_DESC'
}

export type ReferendumPreviousValues = {
  __typename?: 'ReferendumPreviousValues';
  delay: Scalars['Int'];
  end: Scalars['Int'];
  id: Scalars['Int'];
  network: Scalars['String'];
  networkPreimageHash: Scalars['String'];
  networkReferendumId: Scalars['String'];
  preimageHash: Scalars['String'];
  referendumId: Scalars['Int'];
  voteThreshold: Scalars['String'];
};

export type ReferendumStatus = Node & {
  __typename?: 'ReferendumStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  network: Scalars['String'];
  referendum: Referendum;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type ReferendumStatusConnection = {
  __typename?: 'ReferendumStatusConnection';
  aggregate: AggregateReferendumStatus;
  /** A list of edges. */
  edges: Array<Maybe<ReferendumStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ReferendumStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  referendum: ReferendumCreateOneWithoutReferendumStatusInput;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type ReferendumStatusCreateManyWithoutReferendumInput = {
  connect?: Maybe<Array<ReferendumStatusWhereUniqueInput>>;
  create?: Maybe<Array<ReferendumStatusCreateWithoutReferendumInput>>;
};

export type ReferendumStatusCreateWithoutReferendumInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type ReferendumStatusEdge = {
  __typename?: 'ReferendumStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: ReferendumStatus;
};

export enum ReferendumStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type ReferendumStatusPreviousValues = {
  __typename?: 'ReferendumStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type ReferendumStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ReferendumStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ReferendumStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ReferendumStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type ReferendumStatusSubscriptionPayload = {
  __typename?: 'ReferendumStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<ReferendumStatus>;
  previousValues?: Maybe<ReferendumStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type ReferendumStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ReferendumStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ReferendumStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ReferendumStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<ReferendumStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type ReferendumStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  referendum?: Maybe<ReferendumUpdateOneRequiredWithoutReferendumStatusInput>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ReferendumStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ReferendumStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ReferendumStatusUpdateManyWithWhereNestedInput = {
  data: ReferendumStatusUpdateManyDataInput;
  where: ReferendumStatusScalarWhereInput;
};

export type ReferendumStatusUpdateManyWithoutReferendumInput = {
  connect?: Maybe<Array<ReferendumStatusWhereUniqueInput>>;
  create?: Maybe<Array<ReferendumStatusCreateWithoutReferendumInput>>;
  delete?: Maybe<Array<ReferendumStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<ReferendumStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<ReferendumStatusWhereUniqueInput>>;
  set?: Maybe<Array<ReferendumStatusWhereUniqueInput>>;
  update?: Maybe<Array<ReferendumStatusUpdateWithWhereUniqueWithoutReferendumInput>>;
  updateMany?: Maybe<Array<ReferendumStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<ReferendumStatusUpsertWithWhereUniqueWithoutReferendumInput>>;
};

export type ReferendumStatusUpdateWithWhereUniqueWithoutReferendumInput = {
  data: ReferendumStatusUpdateWithoutReferendumDataInput;
  where: ReferendumStatusWhereUniqueInput;
};

export type ReferendumStatusUpdateWithoutReferendumDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ReferendumStatusUpsertWithWhereUniqueWithoutReferendumInput = {
  create: ReferendumStatusCreateWithoutReferendumInput;
  update: ReferendumStatusUpdateWithoutReferendumDataInput;
  where: ReferendumStatusWhereUniqueInput;
};

export type ReferendumStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ReferendumStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ReferendumStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ReferendumStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  referendum?: Maybe<ReferendumWhereInput>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type ReferendumStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type ReferendumSubscriptionPayload = {
  __typename?: 'ReferendumSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Referendum>;
  previousValues?: Maybe<ReferendumPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type ReferendumSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ReferendumSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ReferendumSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ReferendumSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<ReferendumWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type ReferendumUpdateInput = {
  delay?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkReferendumId?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutReferendumInput>;
  preimageHash?: Maybe<Scalars['String']>;
  referendumId?: Maybe<Scalars['Int']>;
  referendumStatus?: Maybe<ReferendumStatusUpdateManyWithoutReferendumInput>;
  voteThreshold?: Maybe<Scalars['String']>;
};

export type ReferendumUpdateManyMutationInput = {
  delay?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkReferendumId?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  referendumId?: Maybe<Scalars['Int']>;
  voteThreshold?: Maybe<Scalars['String']>;
};

export type ReferendumUpdateOneRequiredWithoutReferendumStatusInput = {
  connect?: Maybe<ReferendumWhereUniqueInput>;
  create?: Maybe<ReferendumCreateWithoutReferendumStatusInput>;
  update?: Maybe<ReferendumUpdateWithoutReferendumStatusDataInput>;
  upsert?: Maybe<ReferendumUpsertWithoutReferendumStatusInput>;
};

export type ReferendumUpdateOneWithoutPreimageInput = {
  connect?: Maybe<ReferendumWhereUniqueInput>;
  create?: Maybe<ReferendumCreateWithoutPreimageInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ReferendumUpdateWithoutPreimageDataInput>;
  upsert?: Maybe<ReferendumUpsertWithoutPreimageInput>;
};

export type ReferendumUpdateWithoutPreimageDataInput = {
  delay?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkReferendumId?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  referendumId?: Maybe<Scalars['Int']>;
  referendumStatus?: Maybe<ReferendumStatusUpdateManyWithoutReferendumInput>;
  voteThreshold?: Maybe<Scalars['String']>;
};

export type ReferendumUpdateWithoutReferendumStatusDataInput = {
  delay?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  networkReferendumId?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneWithoutReferendumInput>;
  preimageHash?: Maybe<Scalars['String']>;
  referendumId?: Maybe<Scalars['Int']>;
  voteThreshold?: Maybe<Scalars['String']>;
};

export type ReferendumUpsertWithoutPreimageInput = {
  create: ReferendumCreateWithoutPreimageInput;
  update: ReferendumUpdateWithoutPreimageDataInput;
};

export type ReferendumUpsertWithoutReferendumStatusInput = {
  create: ReferendumCreateWithoutReferendumStatusInput;
  update: ReferendumUpdateWithoutReferendumStatusDataInput;
};

export type ReferendumWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ReferendumWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ReferendumWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ReferendumWhereInput>>;
  delay?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  delay_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  delay_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  delay_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  delay_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  delay_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  delay_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  delay_not_in?: Maybe<Array<Scalars['Int']>>;
  end?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  end_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  end_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  end_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  end_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  end_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  end_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  end_not_in?: Maybe<Array<Scalars['Int']>>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkPreimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkPreimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkPreimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkPreimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkPreimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkPreimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkPreimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkPreimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkPreimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkPreimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkPreimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkPreimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkPreimageHash_starts_with?: Maybe<Scalars['String']>;
  networkReferendumId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkReferendumId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkReferendumId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkReferendumId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkReferendumId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkReferendumId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkReferendumId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkReferendumId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkReferendumId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkReferendumId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkReferendumId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkReferendumId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkReferendumId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkReferendumId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  referendumId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  referendumId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  referendumId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  referendumId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  referendumId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  referendumId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  referendumId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  referendumId_not_in?: Maybe<Array<Scalars['Int']>>;
  referendumStatus_every?: Maybe<ReferendumStatusWhereInput>;
  referendumStatus_none?: Maybe<ReferendumStatusWhereInput>;
  referendumStatus_some?: Maybe<ReferendumStatusWhereInput>;
  voteThreshold?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  voteThreshold_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  voteThreshold_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  voteThreshold_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  voteThreshold_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  voteThreshold_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  voteThreshold_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  voteThreshold_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  voteThreshold_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  voteThreshold_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  voteThreshold_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  voteThreshold_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  voteThreshold_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  voteThreshold_starts_with?: Maybe<Scalars['String']>;
};

export type ReferendumWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Referendum = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<ReferendumWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<ReferendumWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<ReferendumWhereInput>>;
  delay?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  delay_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  delay_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  delay_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  delay_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  delay_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  delay_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  delay_not_in?: Maybe<Array<Scalars['Int']>>;
  end?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  end_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  end_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  end_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  end_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  end_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  end_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  end_not_in?: Maybe<Array<Scalars['Int']>>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  networkPreimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkPreimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkPreimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkPreimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkPreimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkPreimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkPreimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkPreimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkPreimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkPreimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkPreimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkPreimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkPreimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkPreimageHash_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkReferendumId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkReferendumId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkReferendumId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkReferendumId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkReferendumId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkReferendumId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkReferendumId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkReferendumId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkReferendumId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkReferendumId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkReferendumId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkReferendumId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkReferendumId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  referendumId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  referendumId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  referendumId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  referendumId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  referendumId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  referendumId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  referendumId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  referendumId_not_in?: Maybe<Array<Scalars['Int']>>;
  referendumStatus_every?: Maybe<ReferendumStatusWhereInput>;
  referendumStatus_none?: Maybe<ReferendumStatusWhereInput>;
  referendumStatus_some?: Maybe<ReferendumStatusWhereInput>;
  voteThreshold?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  voteThreshold_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  voteThreshold_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  voteThreshold_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  voteThreshold_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  voteThreshold_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  voteThreshold_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  voteThreshold_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  voteThreshold_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  voteThreshold_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  voteThreshold_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  voteThreshold_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  voteThreshold_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  voteThreshold_starts_with?: Maybe<Scalars['String']>;
};

export type ReferendumWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkReferendumId?: Maybe<Scalars['String']>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subscribed?: Maybe<Scalars['Boolean']>;
};

export type TechCommitteeProposal = {
  __typename?: 'TechCommitteeProposal';
  author: Scalars['String'];
  id: Scalars['Int'];
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimage?: Maybe<Preimage>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalArguments?: Maybe<Array<TechCommitteeProposalArgument>>;
  proposalHash: Scalars['String'];
  proposalId: Scalars['Int'];
  section?: Maybe<Scalars['String']>;
  status?: Maybe<Array<TechCommitteeProposalStatus>>;
};


export type TechCommitteeProposalProposalArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalArgumentWhereInput>;
};


export type TechCommitteeProposalStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalStatusWhereInput>;
};

export type TechCommitteeProposalArgument = Node & {
  __typename?: 'TechCommitteeProposalArgument';
  id: Scalars['ID'];
  name: Scalars['String'];
  proposal: TechCommitteeProposal;
  value: Scalars['String'];
};

/** A connection to a list of items. */
export type TechCommitteeProposalArgumentConnection = {
  __typename?: 'TechCommitteeProposalArgumentConnection';
  aggregate: AggregateTechCommitteeProposalArgument;
  /** A list of edges. */
  edges: Array<Maybe<TechCommitteeProposalArgumentEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TechCommitteeProposalArgumentCreateInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  proposal: TechCommitteeProposalCreateOneWithoutProposalArgumentsInput;
  value: Scalars['String'];
};

export type TechCommitteeProposalArgumentCreateManyWithoutProposalInput = {
  connect?: Maybe<Array<TechCommitteeProposalArgumentWhereUniqueInput>>;
  create?: Maybe<Array<TechCommitteeProposalArgumentCreateWithoutProposalInput>>;
};

export type TechCommitteeProposalArgumentCreateWithoutProposalInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  value: Scalars['String'];
};

/** An edge in a connection. */
export type TechCommitteeProposalArgumentEdge = {
  __typename?: 'TechCommitteeProposalArgumentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TechCommitteeProposalArgument;
};

export enum TechCommitteeProposalArgumentOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type TechCommitteeProposalArgumentPreviousValues = {
  __typename?: 'TechCommitteeProposalArgumentPreviousValues';
  id: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type TechCommitteeProposalArgumentScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalArgumentScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalArgumentScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalArgumentScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalArgumentSubscriptionPayload = {
  __typename?: 'TechCommitteeProposalArgumentSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TechCommitteeProposalArgument>;
  previousValues?: Maybe<TechCommitteeProposalArgumentPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type TechCommitteeProposalArgumentSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalArgumentSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalArgumentSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalArgumentSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<TechCommitteeProposalArgumentWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type TechCommitteeProposalArgumentUpdateInput = {
  name?: Maybe<Scalars['String']>;
  proposal?: Maybe<TechCommitteeProposalUpdateOneRequiredWithoutProposalArgumentsInput>;
  value?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalArgumentUpdateManyDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalArgumentUpdateManyMutationInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalArgumentUpdateManyWithWhereNestedInput = {
  data: TechCommitteeProposalArgumentUpdateManyDataInput;
  where: TechCommitteeProposalArgumentScalarWhereInput;
};

export type TechCommitteeProposalArgumentUpdateManyWithoutProposalInput = {
  connect?: Maybe<Array<TechCommitteeProposalArgumentWhereUniqueInput>>;
  create?: Maybe<Array<TechCommitteeProposalArgumentCreateWithoutProposalInput>>;
  delete?: Maybe<Array<TechCommitteeProposalArgumentWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TechCommitteeProposalArgumentScalarWhereInput>>;
  disconnect?: Maybe<Array<TechCommitteeProposalArgumentWhereUniqueInput>>;
  set?: Maybe<Array<TechCommitteeProposalArgumentWhereUniqueInput>>;
  update?: Maybe<Array<TechCommitteeProposalArgumentUpdateWithWhereUniqueWithoutProposalInput>>;
  updateMany?: Maybe<Array<TechCommitteeProposalArgumentUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<TechCommitteeProposalArgumentUpsertWithWhereUniqueWithoutProposalInput>>;
};

export type TechCommitteeProposalArgumentUpdateWithWhereUniqueWithoutProposalInput = {
  data: TechCommitteeProposalArgumentUpdateWithoutProposalDataInput;
  where: TechCommitteeProposalArgumentWhereUniqueInput;
};

export type TechCommitteeProposalArgumentUpdateWithoutProposalDataInput = {
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalArgumentUpsertWithWhereUniqueWithoutProposalInput = {
  create: TechCommitteeProposalArgumentCreateWithoutProposalInput;
  update: TechCommitteeProposalArgumentUpdateWithoutProposalDataInput;
  where: TechCommitteeProposalArgumentWhereUniqueInput;
};

export type TechCommitteeProposalArgumentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalArgumentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalArgumentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalArgumentWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  name_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  name_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  name_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  name_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  name_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  proposal?: Maybe<TechCommitteeProposalWhereInput>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalArgumentWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** A connection to a list of items. */
export type TechCommitteeProposalConnection = {
  __typename?: 'TechCommitteeProposalConnection';
  aggregate: AggregateTechCommitteeProposal;
  /** A list of edges. */
  edges: Array<Maybe<TechCommitteeProposalEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TechCommitteeProposalCreateInput = {
  author: Scalars['String'];
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimage?: Maybe<PreimageCreateOneInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalArguments?: Maybe<TechCommitteeProposalArgumentCreateManyWithoutProposalInput>;
  proposalHash: Scalars['String'];
  proposalId: Scalars['Int'];
  section?: Maybe<Scalars['String']>;
  status?: Maybe<TechCommitteeProposalStatusCreateManyWithoutProposalInput>;
};

export type TechCommitteeProposalCreateOneWithoutProposalArgumentsInput = {
  connect?: Maybe<TechCommitteeProposalWhereUniqueInput>;
  create?: Maybe<TechCommitteeProposalCreateWithoutProposalArgumentsInput>;
};

export type TechCommitteeProposalCreateOneWithoutStatusInput = {
  connect?: Maybe<TechCommitteeProposalWhereUniqueInput>;
  create?: Maybe<TechCommitteeProposalCreateWithoutStatusInput>;
};

export type TechCommitteeProposalCreateWithoutProposalArgumentsInput = {
  author: Scalars['String'];
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimage?: Maybe<PreimageCreateOneInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalHash: Scalars['String'];
  proposalId: Scalars['Int'];
  section?: Maybe<Scalars['String']>;
  status?: Maybe<TechCommitteeProposalStatusCreateManyWithoutProposalInput>;
};

export type TechCommitteeProposalCreateWithoutStatusInput = {
  author: Scalars['String'];
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimage?: Maybe<PreimageCreateOneInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalArguments?: Maybe<TechCommitteeProposalArgumentCreateManyWithoutProposalInput>;
  proposalHash: Scalars['String'];
  proposalId: Scalars['Int'];
  section?: Maybe<Scalars['String']>;
};

/** An edge in a connection. */
export type TechCommitteeProposalEdge = {
  __typename?: 'TechCommitteeProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TechCommitteeProposal;
};

export enum TechCommitteeProposalOrderByInput {
  AuthorAsc = 'author_ASC',
  AuthorDesc = 'author_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MemberCountAsc = 'memberCount_ASC',
  MemberCountDesc = 'memberCount_DESC',
  MetaDescriptionAsc = 'metaDescription_ASC',
  MetaDescriptionDesc = 'metaDescription_DESC',
  MethodAsc = 'method_ASC',
  MethodDesc = 'method_DESC',
  NetworkProposalIdAsc = 'networkProposalId_ASC',
  NetworkProposalIdDesc = 'networkProposalId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  PreimageHashAsc = 'preimageHash_ASC',
  PreimageHashDesc = 'preimageHash_DESC',
  ProposalHashAsc = 'proposalHash_ASC',
  ProposalHashDesc = 'proposalHash_DESC',
  ProposalIdAsc = 'proposalId_ASC',
  ProposalIdDesc = 'proposalId_DESC',
  SectionAsc = 'section_ASC',
  SectionDesc = 'section_DESC'
}

export type TechCommitteeProposalPreviousValues = {
  __typename?: 'TechCommitteeProposalPreviousValues';
  author: Scalars['String'];
  id: Scalars['Int'];
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  networkProposalId: Scalars['String'];
  preimageHash?: Maybe<Scalars['String']>;
  proposalHash: Scalars['String'];
  proposalId: Scalars['Int'];
  section?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalStatus = Node & {
  __typename?: 'TechCommitteeProposalStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  network: Scalars['String'];
  proposal: TechCommitteeProposal;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type TechCommitteeProposalStatusConnection = {
  __typename?: 'TechCommitteeProposalStatusConnection';
  aggregate: AggregateTechCommitteeProposalStatus;
  /** A list of edges. */
  edges: Array<Maybe<TechCommitteeProposalStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TechCommitteeProposalStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  proposal: TechCommitteeProposalCreateOneWithoutStatusInput;
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type TechCommitteeProposalStatusCreateManyWithoutProposalInput = {
  connect?: Maybe<Array<TechCommitteeProposalStatusWhereUniqueInput>>;
  create?: Maybe<Array<TechCommitteeProposalStatusCreateWithoutProposalInput>>;
};

export type TechCommitteeProposalStatusCreateWithoutProposalInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type TechCommitteeProposalStatusEdge = {
  __typename?: 'TechCommitteeProposalStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TechCommitteeProposalStatus;
};

export enum TechCommitteeProposalStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type TechCommitteeProposalStatusPreviousValues = {
  __typename?: 'TechCommitteeProposalStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type TechCommitteeProposalStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalStatusSubscriptionPayload = {
  __typename?: 'TechCommitteeProposalStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TechCommitteeProposalStatus>;
  previousValues?: Maybe<TechCommitteeProposalStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type TechCommitteeProposalStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<TechCommitteeProposalStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type TechCommitteeProposalStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  proposal?: Maybe<TechCommitteeProposalUpdateOneRequiredWithoutStatusInput>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalStatusUpdateManyWithWhereNestedInput = {
  data: TechCommitteeProposalStatusUpdateManyDataInput;
  where: TechCommitteeProposalStatusScalarWhereInput;
};

export type TechCommitteeProposalStatusUpdateManyWithoutProposalInput = {
  connect?: Maybe<Array<TechCommitteeProposalStatusWhereUniqueInput>>;
  create?: Maybe<Array<TechCommitteeProposalStatusCreateWithoutProposalInput>>;
  delete?: Maybe<Array<TechCommitteeProposalStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TechCommitteeProposalStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<TechCommitteeProposalStatusWhereUniqueInput>>;
  set?: Maybe<Array<TechCommitteeProposalStatusWhereUniqueInput>>;
  update?: Maybe<Array<TechCommitteeProposalStatusUpdateWithWhereUniqueWithoutProposalInput>>;
  updateMany?: Maybe<Array<TechCommitteeProposalStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<TechCommitteeProposalStatusUpsertWithWhereUniqueWithoutProposalInput>>;
};

export type TechCommitteeProposalStatusUpdateWithWhereUniqueWithoutProposalInput = {
  data: TechCommitteeProposalStatusUpdateWithoutProposalDataInput;
  where: TechCommitteeProposalStatusWhereUniqueInput;
};

export type TechCommitteeProposalStatusUpdateWithoutProposalDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalStatusUpsertWithWhereUniqueWithoutProposalInput = {
  create: TechCommitteeProposalStatusCreateWithoutProposalInput;
  update: TechCommitteeProposalStatusUpdateWithoutProposalDataInput;
  where: TechCommitteeProposalStatusWhereUniqueInput;
};

export type TechCommitteeProposalStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  proposal?: Maybe<TechCommitteeProposalWhereInput>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalSubscriptionPayload = {
  __typename?: 'TechCommitteeProposalSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TechCommitteeProposal>;
  previousValues?: Maybe<TechCommitteeProposalPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type TechCommitteeProposalSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<TechCommitteeProposalWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type TechCommitteeProposalUpdateInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalArguments?: Maybe<TechCommitteeProposalArgumentUpdateManyWithoutProposalInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
  status?: Maybe<TechCommitteeProposalStatusUpdateManyWithoutProposalInput>;
};

export type TechCommitteeProposalUpdateManyMutationInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalUpdateOneRequiredWithoutProposalArgumentsInput = {
  connect?: Maybe<TechCommitteeProposalWhereUniqueInput>;
  create?: Maybe<TechCommitteeProposalCreateWithoutProposalArgumentsInput>;
  update?: Maybe<TechCommitteeProposalUpdateWithoutProposalArgumentsDataInput>;
  upsert?: Maybe<TechCommitteeProposalUpsertWithoutProposalArgumentsInput>;
};

export type TechCommitteeProposalUpdateOneRequiredWithoutStatusInput = {
  connect?: Maybe<TechCommitteeProposalWhereUniqueInput>;
  create?: Maybe<TechCommitteeProposalCreateWithoutStatusInput>;
  update?: Maybe<TechCommitteeProposalUpdateWithoutStatusDataInput>;
  upsert?: Maybe<TechCommitteeProposalUpsertWithoutStatusInput>;
};

export type TechCommitteeProposalUpdateWithoutProposalArgumentsDataInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
  status?: Maybe<TechCommitteeProposalStatusUpdateManyWithoutProposalInput>;
};

export type TechCommitteeProposalUpdateWithoutStatusDataInput = {
  author?: Maybe<Scalars['String']>;
  memberCount?: Maybe<Scalars['Int']>;
  metaDescription?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageUpdateOneInput>;
  preimageHash?: Maybe<Scalars['String']>;
  proposalArguments?: Maybe<TechCommitteeProposalArgumentUpdateManyWithoutProposalInput>;
  proposalHash?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  section?: Maybe<Scalars['String']>;
};

export type TechCommitteeProposalUpsertWithoutProposalArgumentsInput = {
  create: TechCommitteeProposalCreateWithoutProposalArgumentsInput;
  update: TechCommitteeProposalUpdateWithoutProposalArgumentsDataInput;
};

export type TechCommitteeProposalUpsertWithoutStatusInput = {
  create: TechCommitteeProposalCreateWithoutStatusInput;
  update: TechCommitteeProposalUpdateWithoutStatusDataInput;
};

export type TechCommitteeProposalWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  memberCount?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  memberCount_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  memberCount_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  memberCount_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  memberCount_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  memberCount_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  memberCount_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  memberCount_not_in?: Maybe<Array<Scalars['Int']>>;
  metaDescription?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  metaDescription_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  metaDescription_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  metaDescription_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  metaDescription_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  metaDescription_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  metaDescription_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  metaDescription_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  metaDescription_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  metaDescription_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  metaDescription_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  metaDescription_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  metaDescription_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  metaDescription_starts_with?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  method_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  method_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  method_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  method_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  method_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  method_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  method_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  method_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  method_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  method_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  method_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  method_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  method_starts_with?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkProposalId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  proposalArguments_every?: Maybe<TechCommitteeProposalArgumentWhereInput>;
  proposalArguments_none?: Maybe<TechCommitteeProposalArgumentWhereInput>;
  proposalArguments_some?: Maybe<TechCommitteeProposalArgumentWhereInput>;
  proposalHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposalHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposalHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposalHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposalHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposalHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposalHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposalHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposalHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposalHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposalHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposalHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposalHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposalHash_starts_with?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  proposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  proposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  proposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  proposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  proposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  proposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  proposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  section?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  section_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  section_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  section_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  section_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  section_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  section_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  section_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  section_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  section_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  section_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  section_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  section_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  section_starts_with?: Maybe<Scalars['String']>;
  status_every?: Maybe<TechCommitteeProposalStatusWhereInput>;
  status_none?: Maybe<TechCommitteeProposalStatusWhereInput>;
  status_some?: Maybe<TechCommitteeProposalStatusWhereInput>;
};

export type TechCommitteeProposalWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Tech_Committee_Proposal = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TechCommitteeProposalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TechCommitteeProposalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TechCommitteeProposalWhereInput>>;
  author?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  author_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  author_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  author_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  author_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  author_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  author_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  author_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  author_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  author_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  author_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  author_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  author_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  author_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  memberCount?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  memberCount_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  memberCount_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  memberCount_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  memberCount_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  memberCount_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  memberCount_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  memberCount_not_in?: Maybe<Array<Scalars['Int']>>;
  metaDescription?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  metaDescription_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  metaDescription_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  metaDescription_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  metaDescription_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  metaDescription_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  metaDescription_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  metaDescription_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  metaDescription_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  metaDescription_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  metaDescription_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  metaDescription_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  metaDescription_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  metaDescription_starts_with?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  method_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  method_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  method_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  method_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  method_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  method_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  method_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  method_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  method_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  method_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  method_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  method_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  method_starts_with?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  preimage?: Maybe<PreimageWhereInput>;
  preimageHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  preimageHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  preimageHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  preimageHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  preimageHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  preimageHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  preimageHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  preimageHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  preimageHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  preimageHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  preimageHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  preimageHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  preimageHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  preimageHash_starts_with?: Maybe<Scalars['String']>;
  proposalArguments_every?: Maybe<TechCommitteeProposalArgumentWhereInput>;
  proposalArguments_none?: Maybe<TechCommitteeProposalArgumentWhereInput>;
  proposalArguments_some?: Maybe<TechCommitteeProposalArgumentWhereInput>;
  proposalHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposalHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposalHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposalHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposalHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposalHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposalHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposalHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposalHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposalHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposalHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposalHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposalHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposalHash_starts_with?: Maybe<Scalars['String']>;
  proposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  proposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  proposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  proposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  proposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  proposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  proposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  proposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  section?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  section_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  section_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  section_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  section_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  section_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  section_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  section_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  section_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  section_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  section_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  section_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  section_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  section_starts_with?: Maybe<Scalars['String']>;
  status_every?: Maybe<TechCommitteeProposalStatusWhereInput>;
  status_none?: Maybe<TechCommitteeProposalStatusWhereInput>;
  status_some?: Maybe<TechCommitteeProposalStatusWhereInput>;
};

export type TechCommitteeProposalWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkProposalId?: Maybe<Scalars['String']>;
};

export type Tip = {
  __typename?: 'Tip';
  closes?: Maybe<Scalars['Int']>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  id: Scalars['Int'];
  network: Scalars['String'];
  networkTipHash: Scalars['String'];
  reason: Scalars['String'];
  tipStatus?: Maybe<Array<TipStatus>>;
  who: Scalars['String'];
};


export type TipTipStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TipStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TipStatusWhereInput>;
};

/** A connection to a list of items. */
export type TipConnection = {
  __typename?: 'TipConnection';
  aggregate: AggregateTip;
  /** A list of edges. */
  edges: Array<Maybe<TipEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TipCreateInput = {
  closes?: Maybe<Scalars['Int']>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  network: Scalars['String'];
  networkTipHash: Scalars['String'];
  reason: Scalars['String'];
  tipStatus?: Maybe<TipStatusCreateManyWithoutTipInput>;
  who: Scalars['String'];
};

export type TipCreateOneWithoutTipStatusInput = {
  connect?: Maybe<TipWhereUniqueInput>;
  create?: Maybe<TipCreateWithoutTipStatusInput>;
};

export type TipCreateWithoutTipStatusInput = {
  closes?: Maybe<Scalars['Int']>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  network: Scalars['String'];
  networkTipHash: Scalars['String'];
  reason: Scalars['String'];
  who: Scalars['String'];
};

/** An edge in a connection. */
export type TipEdge = {
  __typename?: 'TipEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Tip;
};

export enum TipOrderByInput {
  ClosesAsc = 'closes_ASC',
  ClosesDesc = 'closes_DESC',
  FinderFeeAsc = 'finderFee_ASC',
  FinderFeeDesc = 'finderFee_DESC',
  FinderAsc = 'finder_ASC',
  FinderDesc = 'finder_DESC',
  HashAsc = 'hash_ASC',
  HashDesc = 'hash_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkTipHashAsc = 'networkTipHash_ASC',
  NetworkTipHashDesc = 'networkTipHash_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ReasonAsc = 'reason_ASC',
  ReasonDesc = 'reason_DESC',
  WhoAsc = 'who_ASC',
  WhoDesc = 'who_DESC'
}

export type TipPreviousValues = {
  __typename?: 'TipPreviousValues';
  closes?: Maybe<Scalars['Int']>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  hash: Scalars['String'];
  id: Scalars['Int'];
  network: Scalars['String'];
  networkTipHash: Scalars['String'];
  reason: Scalars['String'];
  who: Scalars['String'];
};

export type TipStatus = Node & {
  __typename?: 'TipStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  tip: Tip;
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type TipStatusConnection = {
  __typename?: 'TipStatusConnection';
  aggregate: AggregateTipStatus;
  /** A list of edges. */
  edges: Array<Maybe<TipStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TipStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  tip: TipCreateOneWithoutTipStatusInput;
  uniqueNetworkStatus: Scalars['String'];
};

export type TipStatusCreateManyWithoutTipInput = {
  connect?: Maybe<Array<TipStatusWhereUniqueInput>>;
  create?: Maybe<Array<TipStatusCreateWithoutTipInput>>;
};

export type TipStatusCreateWithoutTipInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type TipStatusEdge = {
  __typename?: 'TipStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TipStatus;
};

export enum TipStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type TipStatusPreviousValues = {
  __typename?: 'TipStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type TipStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TipStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TipStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TipStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type TipStatusSubscriptionPayload = {
  __typename?: 'TipStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TipStatus>;
  previousValues?: Maybe<TipStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type TipStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TipStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TipStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TipStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<TipStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type TipStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tip?: Maybe<TipUpdateOneRequiredWithoutTipStatusInput>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TipStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TipStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TipStatusUpdateManyWithWhereNestedInput = {
  data: TipStatusUpdateManyDataInput;
  where: TipStatusScalarWhereInput;
};

export type TipStatusUpdateManyWithoutTipInput = {
  connect?: Maybe<Array<TipStatusWhereUniqueInput>>;
  create?: Maybe<Array<TipStatusCreateWithoutTipInput>>;
  delete?: Maybe<Array<TipStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TipStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<TipStatusWhereUniqueInput>>;
  set?: Maybe<Array<TipStatusWhereUniqueInput>>;
  update?: Maybe<Array<TipStatusUpdateWithWhereUniqueWithoutTipInput>>;
  updateMany?: Maybe<Array<TipStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<TipStatusUpsertWithWhereUniqueWithoutTipInput>>;
};

export type TipStatusUpdateWithWhereUniqueWithoutTipInput = {
  data: TipStatusUpdateWithoutTipDataInput;
  where: TipStatusWhereUniqueInput;
};

export type TipStatusUpdateWithoutTipDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TipStatusUpsertWithWhereUniqueWithoutTipInput = {
  create: TipStatusCreateWithoutTipInput;
  update: TipStatusUpdateWithoutTipDataInput;
  where: TipStatusWhereUniqueInput;
};

export type TipStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TipStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TipStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TipStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  tip?: Maybe<TipWhereInput>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type TipStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TipSubscriptionPayload = {
  __typename?: 'TipSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<Tip>;
  previousValues?: Maybe<TipPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type TipSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TipSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TipSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TipSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<TipWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type TipUpdateInput = {
  closes?: Maybe<Scalars['Int']>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkTipHash?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  tipStatus?: Maybe<TipStatusUpdateManyWithoutTipInput>;
  who?: Maybe<Scalars['String']>;
};

export type TipUpdateManyMutationInput = {
  closes?: Maybe<Scalars['Int']>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkTipHash?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  who?: Maybe<Scalars['String']>;
};

export type TipUpdateOneRequiredWithoutTipStatusInput = {
  connect?: Maybe<TipWhereUniqueInput>;
  create?: Maybe<TipCreateWithoutTipStatusInput>;
  update?: Maybe<TipUpdateWithoutTipStatusDataInput>;
  upsert?: Maybe<TipUpsertWithoutTipStatusInput>;
};

export type TipUpdateWithoutTipStatusDataInput = {
  closes?: Maybe<Scalars['Int']>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkTipHash?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  who?: Maybe<Scalars['String']>;
};

export type TipUpsertWithoutTipStatusInput = {
  create: TipCreateWithoutTipStatusInput;
  update: TipUpdateWithoutTipStatusDataInput;
};

export type TipWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TipWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TipWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TipWhereInput>>;
  closes?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  closes_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  closes_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  closes_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  closes_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  closes_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  closes_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  closes_not_in?: Maybe<Array<Scalars['Int']>>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  finderFee_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  finderFee_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  finderFee_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  finderFee_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  finderFee_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  finderFee_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  finderFee_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  finderFee_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  finderFee_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  finderFee_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  finderFee_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  finderFee_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  finderFee_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  finder_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  finder_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  finder_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  finder_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  finder_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  finder_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  finder_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  finder_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  finder_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  finder_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  finder_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  finder_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  finder_starts_with?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  hash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  hash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  hash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  hash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  hash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  hash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  hash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  hash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  hash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  hash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  hash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  hash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  hash_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  networkTipHash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkTipHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkTipHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkTipHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkTipHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkTipHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkTipHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkTipHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkTipHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkTipHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkTipHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkTipHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkTipHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkTipHash_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  reason_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  reason_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  reason_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  reason_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  reason_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  reason_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  reason_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  reason_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  reason_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  reason_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  reason_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  reason_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  reason_starts_with?: Maybe<Scalars['String']>;
  tipStatus_every?: Maybe<TipStatusWhereInput>;
  tipStatus_none?: Maybe<TipStatusWhereInput>;
  tipStatus_some?: Maybe<TipStatusWhereInput>;
  who?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  who_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  who_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  who_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  who_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  who_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  who_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  who_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  who_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  who_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  who_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  who_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  who_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  who_starts_with?: Maybe<Scalars['String']>;
};

export type TipWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Tip = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TipWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TipWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TipWhereInput>>;
  closes?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  closes_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  closes_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  closes_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  closes_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  closes_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  closes_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  closes_not_in?: Maybe<Array<Scalars['Int']>>;
  finder?: Maybe<Scalars['String']>;
  finderFee?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  finderFee_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  finderFee_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  finderFee_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  finderFee_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  finderFee_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  finderFee_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  finderFee_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  finderFee_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  finderFee_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  finderFee_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  finderFee_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  finderFee_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  finderFee_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  finder_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  finder_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  finder_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  finder_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  finder_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  finder_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  finder_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  finder_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  finder_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  finder_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  finder_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  finder_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  finder_starts_with?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  hash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  hash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  hash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  hash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  hash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  hash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  hash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  hash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  hash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  hash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  hash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  hash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  hash_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkTipHash_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkTipHash_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkTipHash_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkTipHash_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkTipHash_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkTipHash_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkTipHash_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkTipHash_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkTipHash_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkTipHash_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkTipHash_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkTipHash_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkTipHash_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  reason_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  reason_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  reason_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  reason_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  reason_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  reason_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  reason_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  reason_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  reason_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  reason_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  reason_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  reason_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  reason_starts_with?: Maybe<Scalars['String']>;
  tipStatus_every?: Maybe<TipStatusWhereInput>;
  tipStatus_none?: Maybe<TipStatusWhereInput>;
  tipStatus_some?: Maybe<TipStatusWhereInput>;
  who?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  who_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  who_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  who_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  who_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  who_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  who_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  who_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  who_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  who_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  who_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  who_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  who_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  who_starts_with?: Maybe<Scalars['String']>;
};

export type TipWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkTipHash?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  token?: Maybe<Scalars['String']>;
};

export type TreasurySpendProposal = {
  __typename?: 'TreasurySpendProposal';
  beneficiary: Scalars['String'];
  bond: Scalars['String'];
  id: Scalars['Int'];
  motion?: Maybe<Motion>;
  network: Scalars['String'];
  networkTreasuryProposalId: Scalars['String'];
  proposer: Scalars['String'];
  treasuryProposalId: Scalars['Int'];
  treasuryStatus?: Maybe<Array<TreasuryStatus>>;
  value: Scalars['String'];
};


export type TreasurySpendProposalTreasuryStatusArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TreasuryStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TreasuryStatusWhereInput>;
};

/** A connection to a list of items. */
export type TreasurySpendProposalConnection = {
  __typename?: 'TreasurySpendProposalConnection';
  aggregate: AggregateTreasurySpendProposal;
  /** A list of edges. */
  edges: Array<Maybe<TreasurySpendProposalEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TreasurySpendProposalCreateInput = {
  beneficiary: Scalars['String'];
  bond: Scalars['String'];
  motion?: Maybe<MotionCreateOneWithoutTreasurySpendProposalInput>;
  network: Scalars['String'];
  networkTreasuryProposalId: Scalars['String'];
  proposer: Scalars['String'];
  treasuryProposalId: Scalars['Int'];
  treasuryStatus?: Maybe<TreasuryStatusCreateManyWithoutTreasurySpendProposalInput>;
  value: Scalars['String'];
};

export type TreasurySpendProposalCreateOneWithoutMotionInput = {
  connect?: Maybe<TreasurySpendProposalWhereUniqueInput>;
  create?: Maybe<TreasurySpendProposalCreateWithoutMotionInput>;
};

export type TreasurySpendProposalCreateOneWithoutTreasuryStatusInput = {
  connect?: Maybe<TreasurySpendProposalWhereUniqueInput>;
  create?: Maybe<TreasurySpendProposalCreateWithoutTreasuryStatusInput>;
};

export type TreasurySpendProposalCreateWithoutMotionInput = {
  beneficiary: Scalars['String'];
  bond: Scalars['String'];
  network: Scalars['String'];
  networkTreasuryProposalId: Scalars['String'];
  proposer: Scalars['String'];
  treasuryProposalId: Scalars['Int'];
  treasuryStatus?: Maybe<TreasuryStatusCreateManyWithoutTreasurySpendProposalInput>;
  value: Scalars['String'];
};

export type TreasurySpendProposalCreateWithoutTreasuryStatusInput = {
  beneficiary: Scalars['String'];
  bond: Scalars['String'];
  motion?: Maybe<MotionCreateOneWithoutTreasurySpendProposalInput>;
  network: Scalars['String'];
  networkTreasuryProposalId: Scalars['String'];
  proposer: Scalars['String'];
  treasuryProposalId: Scalars['Int'];
  value: Scalars['String'];
};

/** An edge in a connection. */
export type TreasurySpendProposalEdge = {
  __typename?: 'TreasurySpendProposalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TreasurySpendProposal;
};

export enum TreasurySpendProposalOrderByInput {
  BeneficiaryAsc = 'beneficiary_ASC',
  BeneficiaryDesc = 'beneficiary_DESC',
  BondAsc = 'bond_ASC',
  BondDesc = 'bond_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkTreasuryProposalIdAsc = 'networkTreasuryProposalId_ASC',
  NetworkTreasuryProposalIdDesc = 'networkTreasuryProposalId_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  ProposerAsc = 'proposer_ASC',
  ProposerDesc = 'proposer_DESC',
  TreasuryProposalIdAsc = 'treasuryProposalId_ASC',
  TreasuryProposalIdDesc = 'treasuryProposalId_DESC',
  ValueAsc = 'value_ASC',
  ValueDesc = 'value_DESC'
}

export type TreasurySpendProposalPreviousValues = {
  __typename?: 'TreasurySpendProposalPreviousValues';
  beneficiary: Scalars['String'];
  bond: Scalars['String'];
  id: Scalars['Int'];
  network: Scalars['String'];
  networkTreasuryProposalId: Scalars['String'];
  proposer: Scalars['String'];
  treasuryProposalId: Scalars['Int'];
  value: Scalars['String'];
};

export type TreasurySpendProposalSubscriptionPayload = {
  __typename?: 'TreasurySpendProposalSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TreasurySpendProposal>;
  previousValues?: Maybe<TreasurySpendProposalPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type TreasurySpendProposalSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TreasurySpendProposalSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TreasurySpendProposalSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TreasurySpendProposalSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<TreasurySpendProposalWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type TreasurySpendProposalUpdateInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutTreasurySpendProposalInput>;
  network?: Maybe<Scalars['String']>;
  networkTreasuryProposalId?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  treasuryProposalId?: Maybe<Scalars['Int']>;
  treasuryStatus?: Maybe<TreasuryStatusUpdateManyWithoutTreasurySpendProposalInput>;
  value?: Maybe<Scalars['String']>;
};

export type TreasurySpendProposalUpdateManyMutationInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkTreasuryProposalId?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  treasuryProposalId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type TreasurySpendProposalUpdateOneRequiredWithoutTreasuryStatusInput = {
  connect?: Maybe<TreasurySpendProposalWhereUniqueInput>;
  create?: Maybe<TreasurySpendProposalCreateWithoutTreasuryStatusInput>;
  update?: Maybe<TreasurySpendProposalUpdateWithoutTreasuryStatusDataInput>;
  upsert?: Maybe<TreasurySpendProposalUpsertWithoutTreasuryStatusInput>;
};

export type TreasurySpendProposalUpdateOneWithoutMotionInput = {
  connect?: Maybe<TreasurySpendProposalWhereUniqueInput>;
  create?: Maybe<TreasurySpendProposalCreateWithoutMotionInput>;
  delete?: Maybe<Scalars['Boolean']>;
  disconnect?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TreasurySpendProposalUpdateWithoutMotionDataInput>;
  upsert?: Maybe<TreasurySpendProposalUpsertWithoutMotionInput>;
};

export type TreasurySpendProposalUpdateWithoutMotionDataInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  networkTreasuryProposalId?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  treasuryProposalId?: Maybe<Scalars['Int']>;
  treasuryStatus?: Maybe<TreasuryStatusUpdateManyWithoutTreasurySpendProposalInput>;
  value?: Maybe<Scalars['String']>;
};

export type TreasurySpendProposalUpdateWithoutTreasuryStatusDataInput = {
  beneficiary?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  motion?: Maybe<MotionUpdateOneWithoutTreasurySpendProposalInput>;
  network?: Maybe<Scalars['String']>;
  networkTreasuryProposalId?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  treasuryProposalId?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type TreasurySpendProposalUpsertWithoutMotionInput = {
  create: TreasurySpendProposalCreateWithoutMotionInput;
  update: TreasurySpendProposalUpdateWithoutMotionDataInput;
};

export type TreasurySpendProposalUpsertWithoutTreasuryStatusInput = {
  create: TreasurySpendProposalCreateWithoutTreasuryStatusInput;
  update: TreasurySpendProposalUpdateWithoutTreasuryStatusDataInput;
};

export type TreasurySpendProposalWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TreasurySpendProposalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TreasurySpendProposalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TreasurySpendProposalWhereInput>>;
  beneficiary?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  beneficiary_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  beneficiary_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  beneficiary_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  beneficiary_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  beneficiary_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  beneficiary_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  beneficiary_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  beneficiary_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  beneficiary_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  beneficiary_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  beneficiary_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  beneficiary_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  beneficiary_starts_with?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  bond_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  bond_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  bond_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  bond_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bond_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  bond_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  bond_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  bond_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  bond_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  bond_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bond_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bond_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  bond_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  motion?: Maybe<MotionWhereInput>;
  network?: Maybe<Scalars['String']>;
  networkTreasuryProposalId?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkTreasuryProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkTreasuryProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkTreasuryProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkTreasuryProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkTreasuryProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkTreasuryProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkTreasuryProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkTreasuryProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkTreasuryProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkTreasuryProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkTreasuryProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkTreasuryProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkTreasuryProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposer_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposer_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposer_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposer_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposer_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposer_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposer_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposer_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposer_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposer_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposer_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposer_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposer_starts_with?: Maybe<Scalars['String']>;
  treasuryProposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  treasuryProposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  treasuryProposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  treasuryProposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  treasuryProposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  treasuryProposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  treasuryProposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  treasuryProposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  treasuryStatus_every?: Maybe<TreasuryStatusWhereInput>;
  treasuryStatus_none?: Maybe<TreasuryStatusWhereInput>;
  treasuryStatus_some?: Maybe<TreasuryStatusWhereInput>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type TreasurySpendProposalWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Treasury_Spend_Proposal = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TreasurySpendProposalWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TreasurySpendProposalWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TreasurySpendProposalWhereInput>>;
  beneficiary?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  beneficiary_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  beneficiary_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  beneficiary_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  beneficiary_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  beneficiary_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  beneficiary_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  beneficiary_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  beneficiary_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  beneficiary_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  beneficiary_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  beneficiary_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  beneficiary_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  beneficiary_starts_with?: Maybe<Scalars['String']>;
  bond?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  bond_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  bond_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  bond_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  bond_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  bond_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  bond_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  bond_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  bond_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  bond_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  bond_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  bond_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  bond_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  bond_starts_with?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['Int']>>;
  motion?: Maybe<MotionWhereInput>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  networkTreasuryProposalId_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  networkTreasuryProposalId_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  networkTreasuryProposalId_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  networkTreasuryProposalId_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  networkTreasuryProposalId_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  networkTreasuryProposalId_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  networkTreasuryProposalId_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  networkTreasuryProposalId_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  networkTreasuryProposalId_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  networkTreasuryProposalId_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  networkTreasuryProposalId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  networkTreasuryProposalId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  networkTreasuryProposalId_starts_with?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  proposer?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  proposer_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  proposer_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  proposer_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  proposer_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  proposer_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  proposer_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  proposer_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  proposer_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  proposer_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  proposer_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  proposer_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  proposer_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  proposer_starts_with?: Maybe<Scalars['String']>;
  treasuryProposalId?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  treasuryProposalId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  treasuryProposalId_gte?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  treasuryProposalId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  treasuryProposalId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  treasuryProposalId_lte?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  treasuryProposalId_not?: Maybe<Scalars['Int']>;
  /** All values that are not contained in given list. */
  treasuryProposalId_not_in?: Maybe<Array<Scalars['Int']>>;
  treasuryStatus_every?: Maybe<TreasuryStatusWhereInput>;
  treasuryStatus_none?: Maybe<TreasuryStatusWhereInput>;
  treasuryStatus_some?: Maybe<TreasuryStatusWhereInput>;
  value?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  value_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  value_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  value_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  value_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  value_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  value_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  value_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  value_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  value_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  value_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  value_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  value_starts_with?: Maybe<Scalars['String']>;
};

export type TreasurySpendProposalWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
  networkTreasuryProposalId?: Maybe<Scalars['String']>;
};

export type TreasuryStatus = Node & {
  __typename?: 'TreasuryStatus';
  blockNumber: BlockNumber;
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  treasurySpendProposal: TreasurySpendProposal;
  uniqueNetworkStatus: Scalars['String'];
};

/** A connection to a list of items. */
export type TreasuryStatusConnection = {
  __typename?: 'TreasuryStatusConnection';
  aggregate: AggregateTreasuryStatus;
  /** A list of edges. */
  edges: Array<Maybe<TreasuryStatusEdge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TreasuryStatusCreateInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  treasurySpendProposal: TreasurySpendProposalCreateOneWithoutTreasuryStatusInput;
  uniqueNetworkStatus: Scalars['String'];
};

export type TreasuryStatusCreateManyWithoutTreasurySpendProposalInput = {
  connect?: Maybe<Array<TreasuryStatusWhereUniqueInput>>;
  create?: Maybe<Array<TreasuryStatusCreateWithoutTreasurySpendProposalInput>>;
};

export type TreasuryStatusCreateWithoutTreasurySpendProposalInput = {
  blockNumber: BlockNumberCreateOneInput;
  id?: Maybe<Scalars['ID']>;
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

/** An edge in a connection. */
export type TreasuryStatusEdge = {
  __typename?: 'TreasuryStatusEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: TreasuryStatus;
};

export enum TreasuryStatusOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NetworkAsc = 'network_ASC',
  NetworkDesc = 'network_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
  UniqueNetworkStatusAsc = 'uniqueNetworkStatus_ASC',
  UniqueNetworkStatusDesc = 'uniqueNetworkStatus_DESC'
}

export type TreasuryStatusPreviousValues = {
  __typename?: 'TreasuryStatusPreviousValues';
  id: Scalars['ID'];
  network: Scalars['String'];
  status: Scalars['String'];
  uniqueNetworkStatus: Scalars['String'];
};

export type TreasuryStatusScalarWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TreasuryStatusScalarWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TreasuryStatusScalarWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TreasuryStatusScalarWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type TreasuryStatusSubscriptionPayload = {
  __typename?: 'TreasuryStatusSubscriptionPayload';
  mutation: MutationType;
  node?: Maybe<TreasuryStatus>;
  previousValues?: Maybe<TreasuryStatusPreviousValues>;
  updatedFields?: Maybe<Array<Scalars['String']>>;
};

export type TreasuryStatusSubscriptionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TreasuryStatusSubscriptionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TreasuryStatusSubscriptionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TreasuryStatusSubscriptionWhereInput>>;
  /** The subscription event gets dispatched when it's listed in mutation_in */
  mutation_in?: Maybe<Array<MutationType>>;
  node?: Maybe<TreasuryStatusWhereInput>;
  /** The subscription event gets only dispatched when one of the updated fields names is included in this list */
  updatedFields_contains?: Maybe<Scalars['String']>;
  /** The subscription event gets only dispatched when all of the field names included in this list have been updated */
  updatedFields_contains_every?: Maybe<Array<Scalars['String']>>;
  /** The subscription event gets only dispatched when some of the field names included in this list have been updated */
  updatedFields_contains_some?: Maybe<Array<Scalars['String']>>;
};

export type TreasuryStatusUpdateInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalUpdateOneRequiredWithoutTreasuryStatusInput>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TreasuryStatusUpdateManyDataInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TreasuryStatusUpdateManyMutationInput = {
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TreasuryStatusUpdateManyWithWhereNestedInput = {
  data: TreasuryStatusUpdateManyDataInput;
  where: TreasuryStatusScalarWhereInput;
};

export type TreasuryStatusUpdateManyWithoutTreasurySpendProposalInput = {
  connect?: Maybe<Array<TreasuryStatusWhereUniqueInput>>;
  create?: Maybe<Array<TreasuryStatusCreateWithoutTreasurySpendProposalInput>>;
  delete?: Maybe<Array<TreasuryStatusWhereUniqueInput>>;
  deleteMany?: Maybe<Array<TreasuryStatusScalarWhereInput>>;
  disconnect?: Maybe<Array<TreasuryStatusWhereUniqueInput>>;
  set?: Maybe<Array<TreasuryStatusWhereUniqueInput>>;
  update?: Maybe<Array<TreasuryStatusUpdateWithWhereUniqueWithoutTreasurySpendProposalInput>>;
  updateMany?: Maybe<Array<TreasuryStatusUpdateManyWithWhereNestedInput>>;
  upsert?: Maybe<Array<TreasuryStatusUpsertWithWhereUniqueWithoutTreasurySpendProposalInput>>;
};

export type TreasuryStatusUpdateWithWhereUniqueWithoutTreasurySpendProposalInput = {
  data: TreasuryStatusUpdateWithoutTreasurySpendProposalDataInput;
  where: TreasuryStatusWhereUniqueInput;
};

export type TreasuryStatusUpdateWithoutTreasurySpendProposalDataInput = {
  blockNumber?: Maybe<BlockNumberUpdateOneRequiredInput>;
  network?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type TreasuryStatusUpsertWithWhereUniqueWithoutTreasurySpendProposalInput = {
  create: TreasuryStatusCreateWithoutTreasurySpendProposalInput;
  update: TreasuryStatusUpdateWithoutTreasurySpendProposalDataInput;
  where: TreasuryStatusWhereUniqueInput;
};

export type TreasuryStatusWhereInput = {
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<TreasuryStatusWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<TreasuryStatusWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<TreasuryStatusWhereInput>>;
  blockNumber?: Maybe<BlockNumberWhereInput>;
  id?: Maybe<Scalars['ID']>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values greater than the given value. */
  id_gt?: Maybe<Scalars['ID']>;
  /** All values greater than or equal the given value. */
  id_gte?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values less than the given value. */
  id_lt?: Maybe<Scalars['ID']>;
  /** All values less than or equal the given value. */
  id_lte?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string. */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  network?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  network_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  network_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  network_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  network_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  network_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  network_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  network_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  network_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  network_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  network_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  network_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  network_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  network_starts_with?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  status_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  status_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  status_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  status_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  status_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  status_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  status_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  status_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  status_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  status_starts_with?: Maybe<Scalars['String']>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalWhereInput>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
  /** All values containing the given string. */
  uniqueNetworkStatus_contains?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  uniqueNetworkStatus_ends_with?: Maybe<Scalars['String']>;
  /** All values greater than the given value. */
  uniqueNetworkStatus_gt?: Maybe<Scalars['String']>;
  /** All values greater than or equal the given value. */
  uniqueNetworkStatus_gte?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  uniqueNetworkStatus_in?: Maybe<Array<Scalars['String']>>;
  /** All values less than the given value. */
  uniqueNetworkStatus_lt?: Maybe<Scalars['String']>;
  /** All values less than or equal the given value. */
  uniqueNetworkStatus_lte?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  uniqueNetworkStatus_not?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  uniqueNetworkStatus_not_contains?: Maybe<Scalars['String']>;
  /** All values not ending with the given string. */
  uniqueNetworkStatus_not_ends_with?: Maybe<Scalars['String']>;
  /** All values that are not contained in given list. */
  uniqueNetworkStatus_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values not starting with the given string. */
  uniqueNetworkStatus_not_starts_with?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  uniqueNetworkStatus_starts_with?: Maybe<Scalars['String']>;
};

export type TreasuryStatusWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  uniqueNetworkStatus?: Maybe<Scalars['String']>;
};

export type UndoEmailChangeResponse = {
  __typename?: 'UndoEmailChangeResponse';
  email?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};


export type User = {
  __typename?: 'User';
  default_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  username?: Maybe<Scalars['String']>;
};

/** columns and relationships of "blockchain_endpoints" */
export type Blockchain_Endpoints = {
  __typename?: 'blockchain_endpoints';
  created_at: Scalars['timestamptz'];
  display_label?: Maybe<Scalars['String']>;
  endpoint: Scalars['String'];
  id: Scalars['Int'];
  is_active: Scalars['smallint'];
  network: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "blockchain_endpoints" */
export type Blockchain_Endpoints_Aggregate = {
  __typename?: 'blockchain_endpoints_aggregate';
  aggregate?: Maybe<Blockchain_Endpoints_Aggregate_Fields>;
  nodes: Array<Blockchain_Endpoints>;
};

/** aggregate fields of "blockchain_endpoints" */
export type Blockchain_Endpoints_Aggregate_Fields = {
  __typename?: 'blockchain_endpoints_aggregate_fields';
  avg?: Maybe<Blockchain_Endpoints_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Blockchain_Endpoints_Max_Fields>;
  min?: Maybe<Blockchain_Endpoints_Min_Fields>;
  stddev?: Maybe<Blockchain_Endpoints_Stddev_Fields>;
  stddev_pop?: Maybe<Blockchain_Endpoints_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blockchain_Endpoints_Stddev_Samp_Fields>;
  sum?: Maybe<Blockchain_Endpoints_Sum_Fields>;
  var_pop?: Maybe<Blockchain_Endpoints_Var_Pop_Fields>;
  var_samp?: Maybe<Blockchain_Endpoints_Var_Samp_Fields>;
  variance?: Maybe<Blockchain_Endpoints_Variance_Fields>;
};


/** aggregate fields of "blockchain_endpoints" */
export type Blockchain_Endpoints_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blockchain_Endpoints_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Aggregate_Order_By = {
  avg?: Maybe<Blockchain_Endpoints_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Blockchain_Endpoints_Max_Order_By>;
  min?: Maybe<Blockchain_Endpoints_Min_Order_By>;
  stddev?: Maybe<Blockchain_Endpoints_Stddev_Order_By>;
  stddev_pop?: Maybe<Blockchain_Endpoints_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Blockchain_Endpoints_Stddev_Samp_Order_By>;
  sum?: Maybe<Blockchain_Endpoints_Sum_Order_By>;
  var_pop?: Maybe<Blockchain_Endpoints_Var_Pop_Order_By>;
  var_samp?: Maybe<Blockchain_Endpoints_Var_Samp_Order_By>;
  variance?: Maybe<Blockchain_Endpoints_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "blockchain_endpoints" */
export type Blockchain_Endpoints_Arr_Rel_Insert_Input = {
  data: Array<Blockchain_Endpoints_Insert_Input>;
  on_conflict?: Maybe<Blockchain_Endpoints_On_Conflict>;
};

/** aggregate avg on columns */
export type Blockchain_Endpoints_Avg_Fields = {
  __typename?: 'blockchain_endpoints_avg_fields';
  id?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Avg_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "blockchain_endpoints". All fields are combined with a logical 'AND'. */
export type Blockchain_Endpoints_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Blockchain_Endpoints_Bool_Exp>>>;
  _not?: Maybe<Blockchain_Endpoints_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Blockchain_Endpoints_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_label?: Maybe<String_Comparison_Exp>;
  endpoint?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_active?: Maybe<Smallint_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "blockchain_endpoints" */
export enum Blockchain_Endpoints_Constraint {
  /** unique or primary key constraint */
  BlockchainEndpointsPkey = 'blockchain_endpoints_pkey'
}

/** input type for incrementing integer column in table "blockchain_endpoints" */
export type Blockchain_Endpoints_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "blockchain_endpoints" */
export type Blockchain_Endpoints_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  display_label?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['smallint']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Blockchain_Endpoints_Max_Fields = {
  __typename?: 'blockchain_endpoints_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_label?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['smallint']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  display_label?: Maybe<Order_By>;
  endpoint?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Blockchain_Endpoints_Min_Fields = {
  __typename?: 'blockchain_endpoints_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  display_label?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['smallint']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  display_label?: Maybe<Order_By>;
  endpoint?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "blockchain_endpoints" */
export type Blockchain_Endpoints_Mutation_Response = {
  __typename?: 'blockchain_endpoints_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Blockchain_Endpoints>;
};

/** input type for inserting object relation for remote table "blockchain_endpoints" */
export type Blockchain_Endpoints_Obj_Rel_Insert_Input = {
  data: Blockchain_Endpoints_Insert_Input;
  on_conflict?: Maybe<Blockchain_Endpoints_On_Conflict>;
};

/** on conflict condition type for table "blockchain_endpoints" */
export type Blockchain_Endpoints_On_Conflict = {
  constraint: Blockchain_Endpoints_Constraint;
  update_columns: Array<Blockchain_Endpoints_Update_Column>;
  where?: Maybe<Blockchain_Endpoints_Bool_Exp>;
};

/** ordering options when selecting data from "blockchain_endpoints" */
export type Blockchain_Endpoints_Order_By = {
  created_at?: Maybe<Order_By>;
  display_label?: Maybe<Order_By>;
  endpoint?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "blockchain_endpoints" */
export type Blockchain_Endpoints_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blockchain_endpoints" */
export enum Blockchain_Endpoints_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayLabel = 'display_label',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Network = 'network',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "blockchain_endpoints" */
export type Blockchain_Endpoints_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  display_label?: Maybe<Scalars['String']>;
  endpoint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['smallint']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Blockchain_Endpoints_Stddev_Fields = {
  __typename?: 'blockchain_endpoints_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Blockchain_Endpoints_Stddev_Pop_Fields = {
  __typename?: 'blockchain_endpoints_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Blockchain_Endpoints_Stddev_Samp_Fields = {
  __typename?: 'blockchain_endpoints_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Blockchain_Endpoints_Sum_Fields = {
  __typename?: 'blockchain_endpoints_sum_fields';
  id?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Sum_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** update columns of table "blockchain_endpoints" */
export enum Blockchain_Endpoints_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayLabel = 'display_label',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Network = 'network',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Blockchain_Endpoints_Var_Pop_Fields = {
  __typename?: 'blockchain_endpoints_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Blockchain_Endpoints_Var_Samp_Fields = {
  __typename?: 'blockchain_endpoints_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Blockchain_Endpoints_Variance_Fields = {
  __typename?: 'blockchain_endpoints_variance_fields';
  id?: Maybe<Scalars['Float']>;
  is_active?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "blockchain_endpoints" */
export type Blockchain_Endpoints_Variance_Order_By = {
  id?: Maybe<Order_By>;
  is_active?: Maybe<Order_By>;
};

/** columns and relationships of "blockchain_socials" */
export type Blockchain_Socials = {
  __typename?: 'blockchain_socials';
  block_explorer?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  homepage?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  network: Scalars['String'];
  reddit?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** aggregated selection of "blockchain_socials" */
export type Blockchain_Socials_Aggregate = {
  __typename?: 'blockchain_socials_aggregate';
  aggregate?: Maybe<Blockchain_Socials_Aggregate_Fields>;
  nodes: Array<Blockchain_Socials>;
};

/** aggregate fields of "blockchain_socials" */
export type Blockchain_Socials_Aggregate_Fields = {
  __typename?: 'blockchain_socials_aggregate_fields';
  avg?: Maybe<Blockchain_Socials_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Blockchain_Socials_Max_Fields>;
  min?: Maybe<Blockchain_Socials_Min_Fields>;
  stddev?: Maybe<Blockchain_Socials_Stddev_Fields>;
  stddev_pop?: Maybe<Blockchain_Socials_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Blockchain_Socials_Stddev_Samp_Fields>;
  sum?: Maybe<Blockchain_Socials_Sum_Fields>;
  var_pop?: Maybe<Blockchain_Socials_Var_Pop_Fields>;
  var_samp?: Maybe<Blockchain_Socials_Var_Samp_Fields>;
  variance?: Maybe<Blockchain_Socials_Variance_Fields>;
};


/** aggregate fields of "blockchain_socials" */
export type Blockchain_Socials_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Blockchain_Socials_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blockchain_socials" */
export type Blockchain_Socials_Aggregate_Order_By = {
  avg?: Maybe<Blockchain_Socials_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Blockchain_Socials_Max_Order_By>;
  min?: Maybe<Blockchain_Socials_Min_Order_By>;
  stddev?: Maybe<Blockchain_Socials_Stddev_Order_By>;
  stddev_pop?: Maybe<Blockchain_Socials_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Blockchain_Socials_Stddev_Samp_Order_By>;
  sum?: Maybe<Blockchain_Socials_Sum_Order_By>;
  var_pop?: Maybe<Blockchain_Socials_Var_Pop_Order_By>;
  var_samp?: Maybe<Blockchain_Socials_Var_Samp_Order_By>;
  variance?: Maybe<Blockchain_Socials_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "blockchain_socials" */
export type Blockchain_Socials_Arr_Rel_Insert_Input = {
  data: Array<Blockchain_Socials_Insert_Input>;
  on_conflict?: Maybe<Blockchain_Socials_On_Conflict>;
};

/** aggregate avg on columns */
export type Blockchain_Socials_Avg_Fields = {
  __typename?: 'blockchain_socials_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "blockchain_socials". All fields are combined with a logical 'AND'. */
export type Blockchain_Socials_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Blockchain_Socials_Bool_Exp>>>;
  _not?: Maybe<Blockchain_Socials_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Blockchain_Socials_Bool_Exp>>>;
  block_explorer?: Maybe<String_Comparison_Exp>;
  discord?: Maybe<String_Comparison_Exp>;
  github?: Maybe<String_Comparison_Exp>;
  homepage?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  reddit?: Maybe<String_Comparison_Exp>;
  telegram?: Maybe<String_Comparison_Exp>;
  twitter?: Maybe<String_Comparison_Exp>;
  youtube?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "blockchain_socials" */
export enum Blockchain_Socials_Constraint {
  /** unique or primary key constraint */
  BlockchainSocialsPkey = 'blockchain_socials_pkey'
}

/** input type for incrementing integer column in table "blockchain_socials" */
export type Blockchain_Socials_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "blockchain_socials" */
export type Blockchain_Socials_Insert_Input = {
  block_explorer?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  homepage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Blockchain_Socials_Max_Fields = {
  __typename?: 'blockchain_socials_max_fields';
  block_explorer?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  homepage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Max_Order_By = {
  block_explorer?: Maybe<Order_By>;
  discord?: Maybe<Order_By>;
  github?: Maybe<Order_By>;
  homepage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  reddit?: Maybe<Order_By>;
  telegram?: Maybe<Order_By>;
  twitter?: Maybe<Order_By>;
  youtube?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Blockchain_Socials_Min_Fields = {
  __typename?: 'blockchain_socials_min_fields';
  block_explorer?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  homepage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Min_Order_By = {
  block_explorer?: Maybe<Order_By>;
  discord?: Maybe<Order_By>;
  github?: Maybe<Order_By>;
  homepage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  reddit?: Maybe<Order_By>;
  telegram?: Maybe<Order_By>;
  twitter?: Maybe<Order_By>;
  youtube?: Maybe<Order_By>;
};

/** response of any mutation on the table "blockchain_socials" */
export type Blockchain_Socials_Mutation_Response = {
  __typename?: 'blockchain_socials_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Blockchain_Socials>;
};

/** input type for inserting object relation for remote table "blockchain_socials" */
export type Blockchain_Socials_Obj_Rel_Insert_Input = {
  data: Blockchain_Socials_Insert_Input;
  on_conflict?: Maybe<Blockchain_Socials_On_Conflict>;
};

/** on conflict condition type for table "blockchain_socials" */
export type Blockchain_Socials_On_Conflict = {
  constraint: Blockchain_Socials_Constraint;
  update_columns: Array<Blockchain_Socials_Update_Column>;
  where?: Maybe<Blockchain_Socials_Bool_Exp>;
};

/** ordering options when selecting data from "blockchain_socials" */
export type Blockchain_Socials_Order_By = {
  block_explorer?: Maybe<Order_By>;
  discord?: Maybe<Order_By>;
  github?: Maybe<Order_By>;
  homepage?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  reddit?: Maybe<Order_By>;
  telegram?: Maybe<Order_By>;
  twitter?: Maybe<Order_By>;
  youtube?: Maybe<Order_By>;
};

/** primary key columns input for table: "blockchain_socials" */
export type Blockchain_Socials_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "blockchain_socials" */
export enum Blockchain_Socials_Select_Column {
  /** column name */
  BlockExplorer = 'block_explorer',
  /** column name */
  Discord = 'discord',
  /** column name */
  Github = 'github',
  /** column name */
  Homepage = 'homepage',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  Reddit = 'reddit',
  /** column name */
  Telegram = 'telegram',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  Youtube = 'youtube'
}

/** input type for updating data in table "blockchain_socials" */
export type Blockchain_Socials_Set_Input = {
  block_explorer?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  homepage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Blockchain_Socials_Stddev_Fields = {
  __typename?: 'blockchain_socials_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Blockchain_Socials_Stddev_Pop_Fields = {
  __typename?: 'blockchain_socials_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Blockchain_Socials_Stddev_Samp_Fields = {
  __typename?: 'blockchain_socials_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Blockchain_Socials_Sum_Fields = {
  __typename?: 'blockchain_socials_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "blockchain_socials" */
export enum Blockchain_Socials_Update_Column {
  /** column name */
  BlockExplorer = 'block_explorer',
  /** column name */
  Discord = 'discord',
  /** column name */
  Github = 'github',
  /** column name */
  Homepage = 'homepage',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  Reddit = 'reddit',
  /** column name */
  Telegram = 'telegram',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  Youtube = 'youtube'
}

/** aggregate var_pop on columns */
export type Blockchain_Socials_Var_Pop_Fields = {
  __typename?: 'blockchain_socials_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Blockchain_Socials_Var_Samp_Fields = {
  __typename?: 'blockchain_socials_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Blockchain_Socials_Variance_Fields = {
  __typename?: 'blockchain_socials_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "blockchain_socials" */
export type Blockchain_Socials_Variance_Order_By = {
  id?: Maybe<Order_By>;
};


/** expression to compare columns of type bpchar. All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: Maybe<Scalars['bpchar']>;
  _gt?: Maybe<Scalars['bpchar']>;
  _gte?: Maybe<Scalars['bpchar']>;
  _in?: Maybe<Array<Scalars['bpchar']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bpchar']>;
  _lte?: Maybe<Scalars['bpchar']>;
  _neq?: Maybe<Scalars['bpchar']>;
  _nin?: Maybe<Array<Scalars['bpchar']>>;
};

/** columns and relationships of "calender_events" */
export type Calender_Events = {
  __typename?: 'calender_events';
  content?: Maybe<Scalars['String']>;
  end_time: Scalars['timestamptz'];
  event_id?: Maybe<Scalars['Int']>;
  event_type?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  module?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  start_time: Scalars['timestamptz'];
  status?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "calender_events" */
export type Calender_Events_Aggregate = {
  __typename?: 'calender_events_aggregate';
  aggregate?: Maybe<Calender_Events_Aggregate_Fields>;
  nodes: Array<Calender_Events>;
};

/** aggregate fields of "calender_events" */
export type Calender_Events_Aggregate_Fields = {
  __typename?: 'calender_events_aggregate_fields';
  avg?: Maybe<Calender_Events_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Calender_Events_Max_Fields>;
  min?: Maybe<Calender_Events_Min_Fields>;
  stddev?: Maybe<Calender_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Calender_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Calender_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Calender_Events_Sum_Fields>;
  var_pop?: Maybe<Calender_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Calender_Events_Var_Samp_Fields>;
  variance?: Maybe<Calender_Events_Variance_Fields>;
};


/** aggregate fields of "calender_events" */
export type Calender_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Calender_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "calender_events" */
export type Calender_Events_Aggregate_Order_By = {
  avg?: Maybe<Calender_Events_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Calender_Events_Max_Order_By>;
  min?: Maybe<Calender_Events_Min_Order_By>;
  stddev?: Maybe<Calender_Events_Stddev_Order_By>;
  stddev_pop?: Maybe<Calender_Events_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Calender_Events_Stddev_Samp_Order_By>;
  sum?: Maybe<Calender_Events_Sum_Order_By>;
  var_pop?: Maybe<Calender_Events_Var_Pop_Order_By>;
  var_samp?: Maybe<Calender_Events_Var_Samp_Order_By>;
  variance?: Maybe<Calender_Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "calender_events" */
export type Calender_Events_Arr_Rel_Insert_Input = {
  data: Array<Calender_Events_Insert_Input>;
  on_conflict?: Maybe<Calender_Events_On_Conflict>;
};

/** aggregate avg on columns */
export type Calender_Events_Avg_Fields = {
  __typename?: 'calender_events_avg_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "calender_events" */
export type Calender_Events_Avg_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "calender_events". All fields are combined with a logical 'AND'. */
export type Calender_Events_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Calender_Events_Bool_Exp>>>;
  _not?: Maybe<Calender_Events_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Calender_Events_Bool_Exp>>>;
  content?: Maybe<String_Comparison_Exp>;
  end_time?: Maybe<Timestamptz_Comparison_Exp>;
  event_id?: Maybe<Int_Comparison_Exp>;
  event_type?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  module?: Maybe<String_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  start_time?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "calender_events" */
export enum Calender_Events_Constraint {
  /** unique or primary key constraint */
  CalenderEventsPkey = 'calender_events_pkey'
}

/** input type for incrementing integer column in table "calender_events" */
export type Calender_Events_Inc_Input = {
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "calender_events" */
export type Calender_Events_Insert_Input = {
  content?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['Int']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Calender_Events_Max_Fields = {
  __typename?: 'calender_events_max_fields';
  content?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['Int']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "calender_events" */
export type Calender_Events_Max_Order_By = {
  content?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  module?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Calender_Events_Min_Fields = {
  __typename?: 'calender_events_min_fields';
  content?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['Int']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "calender_events" */
export type Calender_Events_Min_Order_By = {
  content?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  module?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** response of any mutation on the table "calender_events" */
export type Calender_Events_Mutation_Response = {
  __typename?: 'calender_events_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Calender_Events>;
};

/** input type for inserting object relation for remote table "calender_events" */
export type Calender_Events_Obj_Rel_Insert_Input = {
  data: Calender_Events_Insert_Input;
  on_conflict?: Maybe<Calender_Events_On_Conflict>;
};

/** on conflict condition type for table "calender_events" */
export type Calender_Events_On_Conflict = {
  constraint: Calender_Events_Constraint;
  update_columns: Array<Calender_Events_Update_Column>;
  where?: Maybe<Calender_Events_Bool_Exp>;
};

/** ordering options when selecting data from "calender_events" */
export type Calender_Events_Order_By = {
  content?: Maybe<Order_By>;
  end_time?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  event_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  module?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  start_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** primary key columns input for table: "calender_events" */
export type Calender_Events_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "calender_events" */
export enum Calender_Events_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Module = 'module',
  /** column name */
  Network = 'network',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "calender_events" */
export type Calender_Events_Set_Input = {
  content?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['Int']>;
  event_type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  module?: Maybe<Scalars['String']>;
  network?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Calender_Events_Stddev_Fields = {
  __typename?: 'calender_events_stddev_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "calender_events" */
export type Calender_Events_Stddev_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Calender_Events_Stddev_Pop_Fields = {
  __typename?: 'calender_events_stddev_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "calender_events" */
export type Calender_Events_Stddev_Pop_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Calender_Events_Stddev_Samp_Fields = {
  __typename?: 'calender_events_stddev_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "calender_events" */
export type Calender_Events_Stddev_Samp_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Calender_Events_Sum_Fields = {
  __typename?: 'calender_events_sum_fields';
  event_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "calender_events" */
export type Calender_Events_Sum_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "calender_events" */
export enum Calender_Events_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  Module = 'module',
  /** column name */
  Network = 'network',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  Title = 'title',
  /** column name */
  Url = 'url'
}

/** aggregate var_pop on columns */
export type Calender_Events_Var_Pop_Fields = {
  __typename?: 'calender_events_var_pop_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "calender_events" */
export type Calender_Events_Var_Pop_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Calender_Events_Var_Samp_Fields = {
  __typename?: 'calender_events_var_samp_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "calender_events" */
export type Calender_Events_Var_Samp_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Calender_Events_Variance_Fields = {
  __typename?: 'calender_events_variance_fields';
  event_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "calender_events" */
export type Calender_Events_Variance_Order_By = {
  event_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "comment_reactions" */
export type Comment_Reactions = {
  __typename?: 'comment_reactions';
  /** An object relationship */
  comment: Comments;
  comment_id: Scalars['uuid'];
  created_at: Scalars['timestamp'];
  id: Scalars['Int'];
  /** Remote relationship field */
  reacting_user?: Maybe<User>;
  reaction: Scalars['bpchar'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['Int'];
};

/** aggregated selection of "comment_reactions" */
export type Comment_Reactions_Aggregate = {
  __typename?: 'comment_reactions_aggregate';
  aggregate?: Maybe<Comment_Reactions_Aggregate_Fields>;
  nodes: Array<Comment_Reactions>;
};

/** aggregate fields of "comment_reactions" */
export type Comment_Reactions_Aggregate_Fields = {
  __typename?: 'comment_reactions_aggregate_fields';
  avg?: Maybe<Comment_Reactions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Comment_Reactions_Max_Fields>;
  min?: Maybe<Comment_Reactions_Min_Fields>;
  stddev?: Maybe<Comment_Reactions_Stddev_Fields>;
  stddev_pop?: Maybe<Comment_Reactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comment_Reactions_Stddev_Samp_Fields>;
  sum?: Maybe<Comment_Reactions_Sum_Fields>;
  var_pop?: Maybe<Comment_Reactions_Var_Pop_Fields>;
  var_samp?: Maybe<Comment_Reactions_Var_Samp_Fields>;
  variance?: Maybe<Comment_Reactions_Variance_Fields>;
};


/** aggregate fields of "comment_reactions" */
export type Comment_Reactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Comment_Reactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "comment_reactions" */
export type Comment_Reactions_Aggregate_Order_By = {
  avg?: Maybe<Comment_Reactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Comment_Reactions_Max_Order_By>;
  min?: Maybe<Comment_Reactions_Min_Order_By>;
  stddev?: Maybe<Comment_Reactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Comment_Reactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Comment_Reactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Comment_Reactions_Sum_Order_By>;
  var_pop?: Maybe<Comment_Reactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Comment_Reactions_Var_Samp_Order_By>;
  variance?: Maybe<Comment_Reactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comment_reactions" */
export type Comment_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Comment_Reactions_Insert_Input>;
  on_conflict?: Maybe<Comment_Reactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Comment_Reactions_Avg_Fields = {
  __typename?: 'comment_reactions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "comment_reactions" */
export type Comment_Reactions_Avg_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comment_reactions". All fields are combined with a logical 'AND'. */
export type Comment_Reactions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Comment_Reactions_Bool_Exp>>>;
  _not?: Maybe<Comment_Reactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Comment_Reactions_Bool_Exp>>>;
  comment?: Maybe<Comments_Bool_Exp>;
  comment_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  reaction?: Maybe<Bpchar_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_reactions" */
export enum Comment_Reactions_Constraint {
  /** unique or primary key constraint */
  CommentReactionsCommentIdUserIdReactionKey = 'comment_reactions_comment_id_user_id_reaction_key',
  /** unique or primary key constraint */
  CommentReactionsPkey = 'comment_reactions_pkey'
}

/** input type for incrementing integer column in table "comment_reactions" */
export type Comment_Reactions_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "comment_reactions" */
export type Comment_Reactions_Insert_Input = {
  comment?: Maybe<Comments_Obj_Rel_Insert_Input>;
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Scalars['bpchar']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Comment_Reactions_Max_Fields = {
  __typename?: 'comment_reactions_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "comment_reactions" */
export type Comment_Reactions_Max_Order_By = {
  comment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Reactions_Min_Fields = {
  __typename?: 'comment_reactions_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "comment_reactions" */
export type Comment_Reactions_Min_Order_By = {
  comment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "comment_reactions" */
export type Comment_Reactions_Mutation_Response = {
  __typename?: 'comment_reactions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Comment_Reactions>;
};

/** input type for inserting object relation for remote table "comment_reactions" */
export type Comment_Reactions_Obj_Rel_Insert_Input = {
  data: Comment_Reactions_Insert_Input;
  on_conflict?: Maybe<Comment_Reactions_On_Conflict>;
};

/** on conflict condition type for table "comment_reactions" */
export type Comment_Reactions_On_Conflict = {
  constraint: Comment_Reactions_Constraint;
  update_columns: Array<Comment_Reactions_Update_Column>;
  where?: Maybe<Comment_Reactions_Bool_Exp>;
};

/** ordering options when selecting data from "comment_reactions" */
export type Comment_Reactions_Order_By = {
  comment?: Maybe<Comments_Order_By>;
  comment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  reaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "comment_reactions" */
export type Comment_Reactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "comment_reactions" */
export enum Comment_Reactions_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comment_reactions" */
export type Comment_Reactions_Set_Input = {
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Scalars['bpchar']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Comment_Reactions_Stddev_Fields = {
  __typename?: 'comment_reactions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "comment_reactions" */
export type Comment_Reactions_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comment_Reactions_Stddev_Pop_Fields = {
  __typename?: 'comment_reactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "comment_reactions" */
export type Comment_Reactions_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comment_Reactions_Stddev_Samp_Fields = {
  __typename?: 'comment_reactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "comment_reactions" */
export type Comment_Reactions_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Comment_Reactions_Sum_Fields = {
  __typename?: 'comment_reactions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "comment_reactions" */
export type Comment_Reactions_Sum_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "comment_reactions" */
export enum Comment_Reactions_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Comment_Reactions_Var_Pop_Fields = {
  __typename?: 'comment_reactions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "comment_reactions" */
export type Comment_Reactions_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comment_Reactions_Var_Samp_Fields = {
  __typename?: 'comment_reactions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "comment_reactions" */
export type Comment_Reactions_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Comment_Reactions_Variance_Fields = {
  __typename?: 'comment_reactions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "comment_reactions" */
export type Comment_Reactions_Variance_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "comments" */
export type Comments = {
  __typename?: 'comments';
  /** Remote relationship field */
  author?: Maybe<User>;
  author_id: Scalars['Int'];
  /** An array relationship */
  comment_reactions: Array<Comment_Reactions>;
  /** An aggregated array relationship */
  comment_reactions_aggregate: Comment_Reactions_Aggregate;
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  network?: Maybe<Scalars['String']>;
  /** An object relationship */
  post: Posts;
  post_id: Scalars['Int'];
  /** An array relationship */
  replies: Array<Replies>;
  /** An aggregated array relationship */
  replies_aggregate: Replies_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "comments" */
export type CommentsComment_ReactionsArgs = {
  distinct_on?: Maybe<Array<Comment_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comment_Reactions_Order_By>>;
  where?: Maybe<Comment_Reactions_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsComment_Reactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comment_Reactions_Order_By>>;
  where?: Maybe<Comment_Reactions_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsRepliesArgs = {
  distinct_on?: Maybe<Array<Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Replies_Order_By>>;
  where?: Maybe<Replies_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsReplies_AggregateArgs = {
  distinct_on?: Maybe<Array<Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Replies_Order_By>>;
  where?: Maybe<Replies_Bool_Exp>;
};

/** aggregated selection of "comments" */
export type Comments_Aggregate = {
  __typename?: 'comments_aggregate';
  aggregate?: Maybe<Comments_Aggregate_Fields>;
  nodes: Array<Comments>;
};

/** aggregate fields of "comments" */
export type Comments_Aggregate_Fields = {
  __typename?: 'comments_aggregate_fields';
  avg?: Maybe<Comments_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Comments_Max_Fields>;
  min?: Maybe<Comments_Min_Fields>;
  stddev?: Maybe<Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Comments_Sum_Fields>;
  var_pop?: Maybe<Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Comments_Var_Samp_Fields>;
  variance?: Maybe<Comments_Variance_Fields>;
};


/** aggregate fields of "comments" */
export type Comments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Comments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "comments" */
export type Comments_Aggregate_Order_By = {
  avg?: Maybe<Comments_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Comments_Max_Order_By>;
  min?: Maybe<Comments_Min_Order_By>;
  stddev?: Maybe<Comments_Stddev_Order_By>;
  stddev_pop?: Maybe<Comments_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Comments_Stddev_Samp_Order_By>;
  sum?: Maybe<Comments_Sum_Order_By>;
  var_pop?: Maybe<Comments_Var_Pop_Order_By>;
  var_samp?: Maybe<Comments_Var_Samp_Order_By>;
  variance?: Maybe<Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "comments" */
export type Comments_Arr_Rel_Insert_Input = {
  data: Array<Comments_Insert_Input>;
  on_conflict?: Maybe<Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Comments_Avg_Fields = {
  __typename?: 'comments_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "comments" */
export type Comments_Avg_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Comments_Bool_Exp>>>;
  _not?: Maybe<Comments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Comments_Bool_Exp>>>;
  author_id?: Maybe<Int_Comparison_Exp>;
  comment_reactions?: Maybe<Comment_Reactions_Bool_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  post?: Maybe<Posts_Bool_Exp>;
  post_id?: Maybe<Int_Comparison_Exp>;
  replies?: Maybe<Replies_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint */
  CommentsPkey = 'comments_pkey'
}

/** input type for incrementing integer column in table "comments" */
export type Comments_Inc_Input = {
  author_id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "comments" */
export type Comments_Insert_Input = {
  author_id?: Maybe<Scalars['Int']>;
  comment_reactions?: Maybe<Comment_Reactions_Arr_Rel_Insert_Input>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  post?: Maybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: Maybe<Scalars['Int']>;
  replies?: Maybe<Replies_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Comments_Max_Fields = {
  __typename?: 'comments_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "comments" */
export type Comments_Max_Order_By = {
  author_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Min_Fields = {
  __typename?: 'comments_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "comments" */
export type Comments_Min_Order_By = {
  author_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "comments" */
export type Comments_Mutation_Response = {
  __typename?: 'comments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Comments>;
};

/** input type for inserting object relation for remote table "comments" */
export type Comments_Obj_Rel_Insert_Input = {
  data: Comments_Insert_Input;
  on_conflict?: Maybe<Comments_On_Conflict>;
};

/** on conflict condition type for table "comments" */
export type Comments_On_Conflict = {
  constraint: Comments_Constraint;
  update_columns: Array<Comments_Update_Column>;
  where?: Maybe<Comments_Bool_Exp>;
};

/** ordering options when selecting data from "comments" */
export type Comments_Order_By = {
  author_id?: Maybe<Order_By>;
  comment_reactions_aggregate?: Maybe<Comment_Reactions_Aggregate_Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  post?: Maybe<Posts_Order_By>;
  post_id?: Maybe<Order_By>;
  replies_aggregate?: Maybe<Replies_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "comments" */
export type Comments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "comments" */
export enum Comments_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "comments" */
export type Comments_Set_Input = {
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Comments_Stddev_Fields = {
  __typename?: 'comments_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "comments" */
export type Comments_Stddev_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Comments_Stddev_Pop_Fields = {
  __typename?: 'comments_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "comments" */
export type Comments_Stddev_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Comments_Stddev_Samp_Fields = {
  __typename?: 'comments_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "comments" */
export type Comments_Stddev_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Comments_Sum_Fields = {
  __typename?: 'comments_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "comments" */
export type Comments_Sum_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** update columns of table "comments" */
export enum Comments_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Comments_Var_Pop_Fields = {
  __typename?: 'comments_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "comments" */
export type Comments_Var_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Comments_Var_Samp_Fields = {
  __typename?: 'comments_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "comments" */
export type Comments_Var_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Comments_Variance_Fields = {
  __typename?: 'comments_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "comments" */
export type Comments_Variance_Order_By = {
  author_id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  addProfile?: Maybe<Message>;
  addressLinkConfirm?: Maybe<ChangeResponse>;
  addressLinkStart?: Maybe<AddressLinkType>;
  addressLogin?: Maybe<LoginResponse>;
  addressLoginStart?: Maybe<AddressLoginType>;
  addressSignupConfirm?: Maybe<LoginResponse>;
  addressSignupStart?: Maybe<AddressLoginType>;
  addressUnlink?: Maybe<ChangeResponse>;
  changeAbout?: Maybe<Message>;
  changeEmail?: Maybe<ChangeResponse>;
  changeNotificationPreference?: Maybe<ChangeResponse>;
  changePassword?: Maybe<Message>;
  changeUsername?: Maybe<ChangeResponse>;
  createBlockIndex: BlockIndex;
  createBlockNumber: BlockNumber;
  createBounty: Bounty;
  createBountyStatus: BountyStatus;
  createChildBounty: ChildBounty;
  createChildBountyStatus: ChildBountyStatus;
  createCouncil: Council;
  createCouncilMember: CouncilMember;
  createMotion: Motion;
  createMotionProposalArgument: MotionProposalArgument;
  createMotionStatus: MotionStatus;
  createPip: Pip;
  createPipArgument: PipArgument;
  createPipStatus: PipStatus;
  createPostConfirm?: Maybe<Message>;
  createPostStart?: Maybe<AddressLoginType>;
  createPreimage: Preimage;
  createPreimageArgument: PreimageArgument;
  createPreimageStatus: PreimageStatus;
  createProposal: Proposal;
  createProposalStatus: ProposalStatus;
  createProposalTracker?: Maybe<Message>;
  createReferendum: Referendum;
  createReferendumStatus: ReferendumStatus;
  createTechCommitteeProposal: TechCommitteeProposal;
  createTechCommitteeProposalArgument: TechCommitteeProposalArgument;
  createTechCommitteeProposalStatus: TechCommitteeProposalStatus;
  createTip: Tip;
  createTipStatus: TipStatus;
  createTreasurySpendProposal: TreasurySpendProposal;
  createTreasuryStatus: TreasuryStatus;
  deleteAccount?: Maybe<Message>;
  deleteBlockIndex?: Maybe<BlockIndex>;
  deleteBlockNumber?: Maybe<BlockNumber>;
  deleteBounty?: Maybe<Bounty>;
  deleteBountyStatus?: Maybe<BountyStatus>;
  deleteChildBounty?: Maybe<ChildBounty>;
  deleteChildBountyStatus?: Maybe<ChildBountyStatus>;
  deleteCouncil?: Maybe<Council>;
  deleteCouncilMember?: Maybe<CouncilMember>;
  deleteManyBlockIndexes: BatchPayload;
  deleteManyBlockNumbers: BatchPayload;
  deleteManyBounties: BatchPayload;
  deleteManyBountyStatuses: BatchPayload;
  deleteManyChildBounties: BatchPayload;
  deleteManyChildBountyStatuses: BatchPayload;
  deleteManyCouncilMembers: BatchPayload;
  deleteManyCouncils: BatchPayload;
  deleteManyMotionProposalArguments: BatchPayload;
  deleteManyMotionStatuses: BatchPayload;
  deleteManyMotions: BatchPayload;
  deleteManyPipArguments: BatchPayload;
  deleteManyPipStatuses: BatchPayload;
  deleteManyPips: BatchPayload;
  deleteManyPreimageArguments: BatchPayload;
  deleteManyPreimageStatuses: BatchPayload;
  deleteManyPreimages: BatchPayload;
  deleteManyProposalStatuses: BatchPayload;
  deleteManyProposals: BatchPayload;
  deleteManyReferendumStatuses: BatchPayload;
  deleteManyReferendums: BatchPayload;
  deleteManyTechCommitteeProposalArguments: BatchPayload;
  deleteManyTechCommitteeProposalStatuses: BatchPayload;
  deleteManyTechCommitteeProposals: BatchPayload;
  deleteManyTipStatuses: BatchPayload;
  deleteManyTips: BatchPayload;
  deleteManyTreasurySpendProposals: BatchPayload;
  deleteManyTreasuryStatuses: BatchPayload;
  deleteMotion?: Maybe<Motion>;
  deleteMotionProposalArgument?: Maybe<MotionProposalArgument>;
  deleteMotionStatus?: Maybe<MotionStatus>;
  deletePip?: Maybe<Pip>;
  deletePipArgument?: Maybe<PipArgument>;
  deletePipStatus?: Maybe<PipStatus>;
  deletePreimage?: Maybe<Preimage>;
  deletePreimageArgument?: Maybe<PreimageArgument>;
  deletePreimageStatus?: Maybe<PreimageStatus>;
  deleteProposal?: Maybe<Proposal>;
  deleteProposalStatus?: Maybe<ProposalStatus>;
  deleteReferendum?: Maybe<Referendum>;
  deleteReferendumStatus?: Maybe<ReferendumStatus>;
  deleteTechCommitteeProposal?: Maybe<TechCommitteeProposal>;
  deleteTechCommitteeProposalArgument?: Maybe<TechCommitteeProposalArgument>;
  deleteTechCommitteeProposalStatus?: Maybe<TechCommitteeProposalStatus>;
  deleteTip?: Maybe<Tip>;
  deleteTipStatus?: Maybe<TipStatus>;
  deleteTreasurySpendProposal?: Maybe<TreasurySpendProposal>;
  deleteTreasuryStatus?: Maybe<TreasuryStatus>;
  /** delete data from the table: "blockchain_endpoints" */
  delete_blockchain_endpoints?: Maybe<Blockchain_Endpoints_Mutation_Response>;
  /** delete single row from the table: "blockchain_endpoints" */
  delete_blockchain_endpoints_by_pk?: Maybe<Blockchain_Endpoints>;
  /** delete data from the table: "blockchain_socials" */
  delete_blockchain_socials?: Maybe<Blockchain_Socials_Mutation_Response>;
  /** delete single row from the table: "blockchain_socials" */
  delete_blockchain_socials_by_pk?: Maybe<Blockchain_Socials>;
  /** delete data from the table: "calender_events" */
  delete_calender_events?: Maybe<Calender_Events_Mutation_Response>;
  /** delete single row from the table: "calender_events" */
  delete_calender_events_by_pk?: Maybe<Calender_Events>;
  /** delete data from the table: "comment_reactions" */
  delete_comment_reactions?: Maybe<Comment_Reactions_Mutation_Response>;
  /** delete single row from the table: "comment_reactions" */
  delete_comment_reactions_by_pk?: Maybe<Comment_Reactions>;
  /** delete data from the table: "comments" */
  delete_comments?: Maybe<Comments_Mutation_Response>;
  /** delete single row from the table: "comments" */
  delete_comments_by_pk?: Maybe<Comments>;
  /** delete data from the table: "onchain_links" */
  delete_onchain_links?: Maybe<Onchain_Links_Mutation_Response>;
  /** delete single row from the table: "onchain_links" */
  delete_onchain_links_by_pk?: Maybe<Onchain_Links>;
  /** delete data from the table: "onchain_post_discussion_link" */
  delete_onchain_post_discussion_link?: Maybe<Onchain_Post_Discussion_Link_Mutation_Response>;
  /** delete single row from the table: "onchain_post_discussion_link" */
  delete_onchain_post_discussion_link_by_pk?: Maybe<Onchain_Post_Discussion_Link>;
  /** delete data from the table: "option_poll" */
  delete_option_poll?: Maybe<Option_Poll_Mutation_Response>;
  /** delete single row from the table: "option_poll" */
  delete_option_poll_by_pk?: Maybe<Option_Poll>;
  /** delete data from the table: "option_poll_votes" */
  delete_option_poll_votes?: Maybe<Option_Poll_Votes_Mutation_Response>;
  /** delete single row from the table: "option_poll_votes" */
  delete_option_poll_votes_by_pk?: Maybe<Option_Poll_Votes>;
  /** delete data from the table: "polkassembly_proposals" */
  delete_polkassembly_proposals?: Maybe<Polkassembly_Proposals_Mutation_Response>;
  /** delete single row from the table: "polkassembly_proposals" */
  delete_polkassembly_proposals_by_pk?: Maybe<Polkassembly_Proposals>;
  /** delete data from the table: "poll" */
  delete_poll?: Maybe<Poll_Mutation_Response>;
  /** delete single row from the table: "poll" */
  delete_poll_by_pk?: Maybe<Poll>;
  /** delete data from the table: "poll_votes" */
  delete_poll_votes?: Maybe<Poll_Votes_Mutation_Response>;
  /** delete single row from the table: "poll_votes" */
  delete_poll_votes_by_pk?: Maybe<Poll_Votes>;
  /** delete data from the table: "post_reactions" */
  delete_post_reactions?: Maybe<Post_Reactions_Mutation_Response>;
  /** delete single row from the table: "post_reactions" */
  delete_post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** delete data from the table: "post_topics" */
  delete_post_topics?: Maybe<Post_Topics_Mutation_Response>;
  /** delete single row from the table: "post_topics" */
  delete_post_topics_by_pk?: Maybe<Post_Topics>;
  /** delete data from the table: "post_types" */
  delete_post_types?: Maybe<Post_Types_Mutation_Response>;
  /** delete single row from the table: "post_types" */
  delete_post_types_by_pk?: Maybe<Post_Types>;
  /** delete data from the table: "posts" */
  delete_posts?: Maybe<Posts_Mutation_Response>;
  /** delete single row from the table: "posts" */
  delete_posts_by_pk?: Maybe<Posts>;
  /** delete data from the table: "proposal_tracker" */
  delete_proposal_tracker?: Maybe<Proposal_Tracker_Mutation_Response>;
  /** delete single row from the table: "proposal_tracker" */
  delete_proposal_tracker_by_pk?: Maybe<Proposal_Tracker>;
  /** delete data from the table: "remark_proposal_options" */
  delete_remark_proposal_options?: Maybe<Remark_Proposal_Options_Mutation_Response>;
  /** delete single row from the table: "remark_proposal_options" */
  delete_remark_proposal_options_by_pk?: Maybe<Remark_Proposal_Options>;
  /** delete data from the table: "remark_proposal_vote" */
  delete_remark_proposal_vote?: Maybe<Remark_Proposal_Vote_Mutation_Response>;
  /** delete single row from the table: "remark_proposal_vote" */
  delete_remark_proposal_vote_by_pk?: Maybe<Remark_Proposal_Vote>;
  /** delete data from the table: "remark_proposals" */
  delete_remark_proposals?: Maybe<Remark_Proposals_Mutation_Response>;
  /** delete single row from the table: "remark_proposals" */
  delete_remark_proposals_by_pk?: Maybe<Remark_Proposals>;
  /** delete data from the table: "replies" */
  delete_replies?: Maybe<Replies_Mutation_Response>;
  /** delete single row from the table: "replies" */
  delete_replies_by_pk?: Maybe<Replies>;
  editPostConfirm?: Maybe<Message>;
  editPostStart?: Maybe<AddressLoginType>;
  executeRaw: Scalars['Json'];
  /** insert data into the table: "blockchain_endpoints" */
  insert_blockchain_endpoints?: Maybe<Blockchain_Endpoints_Mutation_Response>;
  /** insert a single row into the table: "blockchain_endpoints" */
  insert_blockchain_endpoints_one?: Maybe<Blockchain_Endpoints>;
  /** insert data into the table: "blockchain_socials" */
  insert_blockchain_socials?: Maybe<Blockchain_Socials_Mutation_Response>;
  /** insert a single row into the table: "blockchain_socials" */
  insert_blockchain_socials_one?: Maybe<Blockchain_Socials>;
  /** insert data into the table: "calender_events" */
  insert_calender_events?: Maybe<Calender_Events_Mutation_Response>;
  /** insert a single row into the table: "calender_events" */
  insert_calender_events_one?: Maybe<Calender_Events>;
  /** insert data into the table: "comment_reactions" */
  insert_comment_reactions?: Maybe<Comment_Reactions_Mutation_Response>;
  /** insert a single row into the table: "comment_reactions" */
  insert_comment_reactions_one?: Maybe<Comment_Reactions>;
  /** insert data into the table: "comments" */
  insert_comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "comments" */
  insert_comments_one?: Maybe<Comments>;
  /** insert data into the table: "onchain_links" */
  insert_onchain_links?: Maybe<Onchain_Links_Mutation_Response>;
  /** insert a single row into the table: "onchain_links" */
  insert_onchain_links_one?: Maybe<Onchain_Links>;
  /** insert data into the table: "onchain_post_discussion_link" */
  insert_onchain_post_discussion_link?: Maybe<Onchain_Post_Discussion_Link_Mutation_Response>;
  /** insert a single row into the table: "onchain_post_discussion_link" */
  insert_onchain_post_discussion_link_one?: Maybe<Onchain_Post_Discussion_Link>;
  /** insert data into the table: "option_poll" */
  insert_option_poll?: Maybe<Option_Poll_Mutation_Response>;
  /** insert a single row into the table: "option_poll" */
  insert_option_poll_one?: Maybe<Option_Poll>;
  /** insert data into the table: "option_poll_votes" */
  insert_option_poll_votes?: Maybe<Option_Poll_Votes_Mutation_Response>;
  /** insert a single row into the table: "option_poll_votes" */
  insert_option_poll_votes_one?: Maybe<Option_Poll_Votes>;
  /** insert data into the table: "polkassembly_proposals" */
  insert_polkassembly_proposals?: Maybe<Polkassembly_Proposals_Mutation_Response>;
  /** insert a single row into the table: "polkassembly_proposals" */
  insert_polkassembly_proposals_one?: Maybe<Polkassembly_Proposals>;
  /** insert data into the table: "poll" */
  insert_poll?: Maybe<Poll_Mutation_Response>;
  /** insert a single row into the table: "poll" */
  insert_poll_one?: Maybe<Poll>;
  /** insert data into the table: "poll_votes" */
  insert_poll_votes?: Maybe<Poll_Votes_Mutation_Response>;
  /** insert a single row into the table: "poll_votes" */
  insert_poll_votes_one?: Maybe<Poll_Votes>;
  /** insert data into the table: "post_reactions" */
  insert_post_reactions?: Maybe<Post_Reactions_Mutation_Response>;
  /** insert a single row into the table: "post_reactions" */
  insert_post_reactions_one?: Maybe<Post_Reactions>;
  /** insert data into the table: "post_topics" */
  insert_post_topics?: Maybe<Post_Topics_Mutation_Response>;
  /** insert a single row into the table: "post_topics" */
  insert_post_topics_one?: Maybe<Post_Topics>;
  /** insert data into the table: "post_types" */
  insert_post_types?: Maybe<Post_Types_Mutation_Response>;
  /** insert a single row into the table: "post_types" */
  insert_post_types_one?: Maybe<Post_Types>;
  /** insert data into the table: "posts" */
  insert_posts?: Maybe<Posts_Mutation_Response>;
  /** insert a single row into the table: "posts" */
  insert_posts_one?: Maybe<Posts>;
  /** insert data into the table: "proposal_tracker" */
  insert_proposal_tracker?: Maybe<Proposal_Tracker_Mutation_Response>;
  /** insert a single row into the table: "proposal_tracker" */
  insert_proposal_tracker_one?: Maybe<Proposal_Tracker>;
  /** insert data into the table: "remark_proposal_options" */
  insert_remark_proposal_options?: Maybe<Remark_Proposal_Options_Mutation_Response>;
  /** insert a single row into the table: "remark_proposal_options" */
  insert_remark_proposal_options_one?: Maybe<Remark_Proposal_Options>;
  /** insert data into the table: "remark_proposal_vote" */
  insert_remark_proposal_vote?: Maybe<Remark_Proposal_Vote_Mutation_Response>;
  /** insert a single row into the table: "remark_proposal_vote" */
  insert_remark_proposal_vote_one?: Maybe<Remark_Proposal_Vote>;
  /** insert data into the table: "remark_proposals" */
  insert_remark_proposals?: Maybe<Remark_Proposals_Mutation_Response>;
  /** insert a single row into the table: "remark_proposals" */
  insert_remark_proposals_one?: Maybe<Remark_Proposals>;
  /** insert data into the table: "replies" */
  insert_replies?: Maybe<Replies_Mutation_Response>;
  /** insert a single row into the table: "replies" */
  insert_replies_one?: Maybe<Replies>;
  login?: Maybe<LoginResponse>;
  logout?: Maybe<Message>;
  metamaskLinkConfirm?: Maybe<ChangeResponse>;
  metamaskLinkStart?: Maybe<AddressLoginType>;
  metamaskLogin?: Maybe<LoginResponse>;
  metamaskLoginStart?: Maybe<AddressLoginType>;
  metamaskSignupConfirm?: Maybe<LoginResponse>;
  metamaskSignupStart?: Maybe<AddressLoginType>;
  multisigLinkConfirm?: Maybe<ChangeResponse>;
  multisigLinkStart?: Maybe<AddressLoginType>;
  postSubscribe?: Maybe<Message>;
  postUnsubscribe?: Maybe<Message>;
  reportContent?: Maybe<Message>;
  requestResetPassword?: Maybe<Message>;
  resendVerifyEmailToken?: Maybe<Message>;
  resetPassword?: Maybe<Message>;
  setCredentialsConfirm?: Maybe<ChangeResponse>;
  setCredentialsStart?: Maybe<AddressLoginType>;
  setDefaultAddress?: Maybe<ChangeResponse>;
  signup?: Maybe<LoginResponse>;
  undoEmailChange?: Maybe<UndoEmailChangeResponse>;
  updateBlockIndex?: Maybe<BlockIndex>;
  updateBlockNumber?: Maybe<BlockNumber>;
  updateBounty?: Maybe<Bounty>;
  updateBountyStatus?: Maybe<BountyStatus>;
  updateChildBounty?: Maybe<ChildBounty>;
  updateChildBountyStatus?: Maybe<ChildBountyStatus>;
  updateCouncil?: Maybe<Council>;
  updateCouncilMember?: Maybe<CouncilMember>;
  updateManyBlockIndexes: BatchPayload;
  updateManyBlockNumbers: BatchPayload;
  updateManyBounties: BatchPayload;
  updateManyBountyStatuses: BatchPayload;
  updateManyChildBounties: BatchPayload;
  updateManyChildBountyStatuses: BatchPayload;
  updateManyCouncilMembers: BatchPayload;
  updateManyCouncils: BatchPayload;
  updateManyMotionProposalArguments: BatchPayload;
  updateManyMotionStatuses: BatchPayload;
  updateManyMotions: BatchPayload;
  updateManyPipArguments: BatchPayload;
  updateManyPipStatuses: BatchPayload;
  updateManyPips: BatchPayload;
  updateManyPreimageArguments: BatchPayload;
  updateManyPreimageStatuses: BatchPayload;
  updateManyPreimages: BatchPayload;
  updateManyProposalStatuses: BatchPayload;
  updateManyProposals: BatchPayload;
  updateManyReferendumStatuses: BatchPayload;
  updateManyReferendums: BatchPayload;
  updateManyTechCommitteeProposalArguments: BatchPayload;
  updateManyTechCommitteeProposalStatuses: BatchPayload;
  updateManyTechCommitteeProposals: BatchPayload;
  updateManyTipStatuses: BatchPayload;
  updateManyTips: BatchPayload;
  updateManyTreasurySpendProposals: BatchPayload;
  updateManyTreasuryStatuses: BatchPayload;
  updateMotion?: Maybe<Motion>;
  updateMotionProposalArgument?: Maybe<MotionProposalArgument>;
  updateMotionStatus?: Maybe<MotionStatus>;
  updatePip?: Maybe<Pip>;
  updatePipArgument?: Maybe<PipArgument>;
  updatePipStatus?: Maybe<PipStatus>;
  updatePreimage?: Maybe<Preimage>;
  updatePreimageArgument?: Maybe<PreimageArgument>;
  updatePreimageStatus?: Maybe<PreimageStatus>;
  updateProposal?: Maybe<Proposal>;
  updateProposalStatus?: Maybe<ProposalStatus>;
  updateProposalTracker?: Maybe<Message>;
  updateReferendum?: Maybe<Referendum>;
  updateReferendumStatus?: Maybe<ReferendumStatus>;
  updateTechCommitteeProposal?: Maybe<TechCommitteeProposal>;
  updateTechCommitteeProposalArgument?: Maybe<TechCommitteeProposalArgument>;
  updateTechCommitteeProposalStatus?: Maybe<TechCommitteeProposalStatus>;
  updateTip?: Maybe<Tip>;
  updateTipStatus?: Maybe<TipStatus>;
  updateTreasurySpendProposal?: Maybe<TreasurySpendProposal>;
  updateTreasuryStatus?: Maybe<TreasuryStatus>;
  /** update data of the table: "blockchain_endpoints" */
  update_blockchain_endpoints?: Maybe<Blockchain_Endpoints_Mutation_Response>;
  /** update single row of the table: "blockchain_endpoints" */
  update_blockchain_endpoints_by_pk?: Maybe<Blockchain_Endpoints>;
  /** update data of the table: "blockchain_socials" */
  update_blockchain_socials?: Maybe<Blockchain_Socials_Mutation_Response>;
  /** update single row of the table: "blockchain_socials" */
  update_blockchain_socials_by_pk?: Maybe<Blockchain_Socials>;
  /** update data of the table: "calender_events" */
  update_calender_events?: Maybe<Calender_Events_Mutation_Response>;
  /** update single row of the table: "calender_events" */
  update_calender_events_by_pk?: Maybe<Calender_Events>;
  /** update data of the table: "comment_reactions" */
  update_comment_reactions?: Maybe<Comment_Reactions_Mutation_Response>;
  /** update single row of the table: "comment_reactions" */
  update_comment_reactions_by_pk?: Maybe<Comment_Reactions>;
  /** update data of the table: "comments" */
  update_comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "comments" */
  update_comments_by_pk?: Maybe<Comments>;
  /** update data of the table: "onchain_links" */
  update_onchain_links?: Maybe<Onchain_Links_Mutation_Response>;
  /** update single row of the table: "onchain_links" */
  update_onchain_links_by_pk?: Maybe<Onchain_Links>;
  /** update data of the table: "onchain_post_discussion_link" */
  update_onchain_post_discussion_link?: Maybe<Onchain_Post_Discussion_Link_Mutation_Response>;
  /** update single row of the table: "onchain_post_discussion_link" */
  update_onchain_post_discussion_link_by_pk?: Maybe<Onchain_Post_Discussion_Link>;
  /** update data of the table: "option_poll" */
  update_option_poll?: Maybe<Option_Poll_Mutation_Response>;
  /** update single row of the table: "option_poll" */
  update_option_poll_by_pk?: Maybe<Option_Poll>;
  /** update data of the table: "option_poll_votes" */
  update_option_poll_votes?: Maybe<Option_Poll_Votes_Mutation_Response>;
  /** update single row of the table: "option_poll_votes" */
  update_option_poll_votes_by_pk?: Maybe<Option_Poll_Votes>;
  /** update data of the table: "polkassembly_proposals" */
  update_polkassembly_proposals?: Maybe<Polkassembly_Proposals_Mutation_Response>;
  /** update single row of the table: "polkassembly_proposals" */
  update_polkassembly_proposals_by_pk?: Maybe<Polkassembly_Proposals>;
  /** update data of the table: "poll" */
  update_poll?: Maybe<Poll_Mutation_Response>;
  /** update single row of the table: "poll" */
  update_poll_by_pk?: Maybe<Poll>;
  /** update data of the table: "poll_votes" */
  update_poll_votes?: Maybe<Poll_Votes_Mutation_Response>;
  /** update single row of the table: "poll_votes" */
  update_poll_votes_by_pk?: Maybe<Poll_Votes>;
  /** update data of the table: "post_reactions" */
  update_post_reactions?: Maybe<Post_Reactions_Mutation_Response>;
  /** update single row of the table: "post_reactions" */
  update_post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** update data of the table: "post_topics" */
  update_post_topics?: Maybe<Post_Topics_Mutation_Response>;
  /** update single row of the table: "post_topics" */
  update_post_topics_by_pk?: Maybe<Post_Topics>;
  /** update data of the table: "post_types" */
  update_post_types?: Maybe<Post_Types_Mutation_Response>;
  /** update single row of the table: "post_types" */
  update_post_types_by_pk?: Maybe<Post_Types>;
  /** update data of the table: "posts" */
  update_posts?: Maybe<Posts_Mutation_Response>;
  /** update single row of the table: "posts" */
  update_posts_by_pk?: Maybe<Posts>;
  /** update data of the table: "proposal_tracker" */
  update_proposal_tracker?: Maybe<Proposal_Tracker_Mutation_Response>;
  /** update single row of the table: "proposal_tracker" */
  update_proposal_tracker_by_pk?: Maybe<Proposal_Tracker>;
  /** update data of the table: "remark_proposal_options" */
  update_remark_proposal_options?: Maybe<Remark_Proposal_Options_Mutation_Response>;
  /** update single row of the table: "remark_proposal_options" */
  update_remark_proposal_options_by_pk?: Maybe<Remark_Proposal_Options>;
  /** update data of the table: "remark_proposal_vote" */
  update_remark_proposal_vote?: Maybe<Remark_Proposal_Vote_Mutation_Response>;
  /** update single row of the table: "remark_proposal_vote" */
  update_remark_proposal_vote_by_pk?: Maybe<Remark_Proposal_Vote>;
  /** update data of the table: "remark_proposals" */
  update_remark_proposals?: Maybe<Remark_Proposals_Mutation_Response>;
  /** update single row of the table: "remark_proposals" */
  update_remark_proposals_by_pk?: Maybe<Remark_Proposals>;
  /** update data of the table: "replies" */
  update_replies?: Maybe<Replies_Mutation_Response>;
  /** update single row of the table: "replies" */
  update_replies_by_pk?: Maybe<Replies>;
  upsertBlockIndex: BlockIndex;
  upsertBlockNumber: BlockNumber;
  upsertBounty: Bounty;
  upsertBountyStatus: BountyStatus;
  upsertChildBounty: ChildBounty;
  upsertChildBountyStatus: ChildBountyStatus;
  upsertCouncil: Council;
  upsertCouncilMember: CouncilMember;
  upsertMotion: Motion;
  upsertMotionProposalArgument: MotionProposalArgument;
  upsertMotionStatus: MotionStatus;
  upsertPip: Pip;
  upsertPipArgument: PipArgument;
  upsertPipStatus: PipStatus;
  upsertPreimage: Preimage;
  upsertPreimageArgument: PreimageArgument;
  upsertPreimageStatus: PreimageStatus;
  upsertProposal: Proposal;
  upsertProposalStatus: ProposalStatus;
  upsertReferendum: Referendum;
  upsertReferendumStatus: ReferendumStatus;
  upsertTechCommitteeProposal: TechCommitteeProposal;
  upsertTechCommitteeProposalArgument: TechCommitteeProposalArgument;
  upsertTechCommitteeProposalStatus: TechCommitteeProposalStatus;
  upsertTip: Tip;
  upsertTipStatus: TipStatus;
  upsertTreasurySpendProposal: TreasurySpendProposal;
  upsertTreasuryStatus: TreasuryStatus;
  verifyEmail?: Maybe<ChangeResponse>;
};


/** mutation root */
export type Mutation_RootAddProfileArgs = {
  badges?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootAddressLinkConfirmArgs = {
  address_id: Scalars['Int'];
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddressLinkStartArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddressLoginArgs = {
  address: Scalars['String'];
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddressLoginStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddressSignupConfirmArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddressSignupStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAddressUnlinkArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootChangeAboutArgs = {
  address: Scalars['String'];
  description: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  signature: Scalars['String'];
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootChangeEmailArgs = {
  email: Scalars['String'];
  network: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootChangeNotificationPreferenceArgs = {
  notificationPreferences?: Maybe<NotificationPreferencesInput>;
};


/** mutation root */
export type Mutation_RootChangePasswordArgs = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};


/** mutation root */
export type Mutation_RootChangeUsernameArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateBlockIndexArgs = {
  data: BlockIndexCreateInput;
};


/** mutation root */
export type Mutation_RootCreateBlockNumberArgs = {
  data: BlockNumberCreateInput;
};


/** mutation root */
export type Mutation_RootCreateBountyArgs = {
  data: BountyCreateInput;
};


/** mutation root */
export type Mutation_RootCreateBountyStatusArgs = {
  data: BountyStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreateChildBountyArgs = {
  data: ChildBountyCreateInput;
};


/** mutation root */
export type Mutation_RootCreateChildBountyStatusArgs = {
  data: ChildBountyStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreateCouncilArgs = {
  data: CouncilCreateInput;
};


/** mutation root */
export type Mutation_RootCreateCouncilMemberArgs = {
  data: CouncilMemberCreateInput;
};


/** mutation root */
export type Mutation_RootCreateMotionArgs = {
  data: MotionCreateInput;
};


/** mutation root */
export type Mutation_RootCreateMotionProposalArgumentArgs = {
  data: MotionProposalArgumentCreateInput;
};


/** mutation root */
export type Mutation_RootCreateMotionStatusArgs = {
  data: MotionStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreatePipArgs = {
  data: PipCreateInput;
};


/** mutation root */
export type Mutation_RootCreatePipArgumentArgs = {
  data: PipArgumentCreateInput;
};


/** mutation root */
export type Mutation_RootCreatePipStatusArgs = {
  data: PipStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreatePostConfirmArgs = {
  address: Scalars['String'];
  content: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreatePostStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreatePreimageArgs = {
  data: PreimageCreateInput;
};


/** mutation root */
export type Mutation_RootCreatePreimageArgumentArgs = {
  data: PreimageArgumentCreateInput;
};


/** mutation root */
export type Mutation_RootCreatePreimageStatusArgs = {
  data: PreimageStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreateProposalArgs = {
  data: ProposalCreateInput;
};


/** mutation root */
export type Mutation_RootCreateProposalStatusArgs = {
  data: ProposalStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreateProposalTrackerArgs = {
  deadline: Scalars['String'];
  network: Scalars['String'];
  onchain_network_proposal_id: Scalars['String'];
  start_time: Scalars['String'];
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootCreateReferendumArgs = {
  data: ReferendumCreateInput;
};


/** mutation root */
export type Mutation_RootCreateReferendumStatusArgs = {
  data: ReferendumStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreateTechCommitteeProposalArgs = {
  data: TechCommitteeProposalCreateInput;
};


/** mutation root */
export type Mutation_RootCreateTechCommitteeProposalArgumentArgs = {
  data: TechCommitteeProposalArgumentCreateInput;
};


/** mutation root */
export type Mutation_RootCreateTechCommitteeProposalStatusArgs = {
  data: TechCommitteeProposalStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreateTipArgs = {
  data: TipCreateInput;
};


/** mutation root */
export type Mutation_RootCreateTipStatusArgs = {
  data: TipStatusCreateInput;
};


/** mutation root */
export type Mutation_RootCreateTreasurySpendProposalArgs = {
  data: TreasurySpendProposalCreateInput;
};


/** mutation root */
export type Mutation_RootCreateTreasuryStatusArgs = {
  data: TreasuryStatusCreateInput;
};


/** mutation root */
export type Mutation_RootDeleteAccountArgs = {
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDeleteBlockIndexArgs = {
  where: BlockIndexWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteBlockNumberArgs = {
  where: BlockNumberWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteBountyArgs = {
  where: BountyWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteBountyStatusArgs = {
  where: BountyStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteChildBountyArgs = {
  where: ChildBountyWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteChildBountyStatusArgs = {
  where: ChildBountyStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteCouncilArgs = {
  where: CouncilWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteCouncilMemberArgs = {
  where: CouncilMemberWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteManyBlockIndexesArgs = {
  where?: Maybe<BlockIndexWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyBlockNumbersArgs = {
  where?: Maybe<BlockNumberWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyBountiesArgs = {
  where?: Maybe<BountyWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyBountyStatusesArgs = {
  where?: Maybe<BountyStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyChildBountiesArgs = {
  where?: Maybe<ChildBountyWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyChildBountyStatusesArgs = {
  where?: Maybe<ChildBountyStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyCouncilMembersArgs = {
  where?: Maybe<CouncilMemberWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyCouncilsArgs = {
  where?: Maybe<CouncilWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyMotionProposalArgumentsArgs = {
  where?: Maybe<MotionProposalArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyMotionStatusesArgs = {
  where?: Maybe<MotionStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyMotionsArgs = {
  where?: Maybe<MotionWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyPipArgumentsArgs = {
  where?: Maybe<PipArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyPipStatusesArgs = {
  where?: Maybe<PipStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyPipsArgs = {
  where?: Maybe<PipWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyPreimageArgumentsArgs = {
  where?: Maybe<PreimageArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyPreimageStatusesArgs = {
  where?: Maybe<PreimageStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyPreimagesArgs = {
  where?: Maybe<PreimageWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyProposalStatusesArgs = {
  where?: Maybe<ProposalStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyProposalsArgs = {
  where?: Maybe<ProposalWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyReferendumStatusesArgs = {
  where?: Maybe<ReferendumStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyReferendumsArgs = {
  where?: Maybe<ReferendumWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyTechCommitteeProposalArgumentsArgs = {
  where?: Maybe<TechCommitteeProposalArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyTechCommitteeProposalStatusesArgs = {
  where?: Maybe<TechCommitteeProposalStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyTechCommitteeProposalsArgs = {
  where?: Maybe<TechCommitteeProposalWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyTipStatusesArgs = {
  where?: Maybe<TipStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyTipsArgs = {
  where?: Maybe<TipWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyTreasurySpendProposalsArgs = {
  where?: Maybe<TreasurySpendProposalWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteManyTreasuryStatusesArgs = {
  where?: Maybe<TreasuryStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootDeleteMotionArgs = {
  where: MotionWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteMotionProposalArgumentArgs = {
  where: MotionProposalArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteMotionStatusArgs = {
  where: MotionStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeletePipArgs = {
  where: PipWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeletePipArgumentArgs = {
  where: PipArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeletePipStatusArgs = {
  where: PipStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeletePreimageArgs = {
  where: PreimageWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeletePreimageArgumentArgs = {
  where: PreimageArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeletePreimageStatusArgs = {
  where: PreimageStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteProposalArgs = {
  where: ProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteProposalStatusArgs = {
  where: ProposalStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteReferendumArgs = {
  where: ReferendumWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteReferendumStatusArgs = {
  where: ReferendumStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteTechCommitteeProposalArgs = {
  where: TechCommitteeProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteTechCommitteeProposalArgumentArgs = {
  where: TechCommitteeProposalArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteTechCommitteeProposalStatusArgs = {
  where: TechCommitteeProposalStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteTipArgs = {
  where: TipWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteTipStatusArgs = {
  where: TipStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteTreasurySpendProposalArgs = {
  where: TreasurySpendProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDeleteTreasuryStatusArgs = {
  where: TreasuryStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootDelete_Blockchain_EndpointsArgs = {
  where: Blockchain_Endpoints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blockchain_Endpoints_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Blockchain_SocialsArgs = {
  where: Blockchain_Socials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blockchain_Socials_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Calender_EventsArgs = {
  where: Calender_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Calender_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Comment_ReactionsArgs = {
  where: Comment_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CommentsArgs = {
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Onchain_LinksArgs = {
  where: Onchain_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Onchain_Links_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Onchain_Post_Discussion_LinkArgs = {
  where: Onchain_Post_Discussion_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Onchain_Post_Discussion_Link_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Option_PollArgs = {
  where: Option_Poll_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Option_Poll_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Option_Poll_VotesArgs = {
  where: Option_Poll_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Option_Poll_Votes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Polkassembly_ProposalsArgs = {
  where: Polkassembly_Proposals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Polkassembly_Proposals_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PollArgs = {
  where: Poll_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Poll_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Poll_VotesArgs = {
  where: Poll_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Poll_Votes_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Post_ReactionsArgs = {
  where: Post_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Post_TopicsArgs = {
  where: Post_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Topics_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Post_TypesArgs = {
  where: Post_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PostsArgs = {
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Posts_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Proposal_TrackerArgs = {
  where: Proposal_Tracker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Proposal_Tracker_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Remark_Proposal_OptionsArgs = {
  where: Remark_Proposal_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Remark_Proposal_Options_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Remark_Proposal_VoteArgs = {
  where: Remark_Proposal_Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Remark_Proposal_Vote_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Remark_ProposalsArgs = {
  where: Remark_Proposals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Remark_Proposals_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_RepliesArgs = {
  where: Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Replies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootEditPostConfirmArgs = {
  address: Scalars['String'];
  content: Scalars['String'];
  network: Scalars['String'];
  proposalId: Scalars['String'];
  proposalType: Scalars['String'];
  signature: Scalars['String'];
  title: Scalars['String'];
};


/** mutation root */
export type Mutation_RootEditPostStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootExecuteRawArgs = {
  database?: Maybe<PrismaDatabase>;
  query: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Blockchain_EndpointsArgs = {
  objects: Array<Blockchain_Endpoints_Insert_Input>;
  on_conflict?: Maybe<Blockchain_Endpoints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blockchain_Endpoints_OneArgs = {
  object: Blockchain_Endpoints_Insert_Input;
  on_conflict?: Maybe<Blockchain_Endpoints_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blockchain_SocialsArgs = {
  objects: Array<Blockchain_Socials_Insert_Input>;
  on_conflict?: Maybe<Blockchain_Socials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blockchain_Socials_OneArgs = {
  object: Blockchain_Socials_Insert_Input;
  on_conflict?: Maybe<Blockchain_Socials_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calender_EventsArgs = {
  objects: Array<Calender_Events_Insert_Input>;
  on_conflict?: Maybe<Calender_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Calender_Events_OneArgs = {
  object: Calender_Events_Insert_Input;
  on_conflict?: Maybe<Calender_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_ReactionsArgs = {
  objects: Array<Comment_Reactions_Insert_Input>;
  on_conflict?: Maybe<Comment_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Reactions_OneArgs = {
  object: Comment_Reactions_Insert_Input;
  on_conflict?: Maybe<Comment_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: Array<Comments_Insert_Input>;
  on_conflict?: Maybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: Maybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Onchain_LinksArgs = {
  objects: Array<Onchain_Links_Insert_Input>;
  on_conflict?: Maybe<Onchain_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Onchain_Links_OneArgs = {
  object: Onchain_Links_Insert_Input;
  on_conflict?: Maybe<Onchain_Links_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Onchain_Post_Discussion_LinkArgs = {
  objects: Array<Onchain_Post_Discussion_Link_Insert_Input>;
  on_conflict?: Maybe<Onchain_Post_Discussion_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Onchain_Post_Discussion_Link_OneArgs = {
  object: Onchain_Post_Discussion_Link_Insert_Input;
  on_conflict?: Maybe<Onchain_Post_Discussion_Link_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_PollArgs = {
  objects: Array<Option_Poll_Insert_Input>;
  on_conflict?: Maybe<Option_Poll_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_Poll_OneArgs = {
  object: Option_Poll_Insert_Input;
  on_conflict?: Maybe<Option_Poll_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_Poll_VotesArgs = {
  objects: Array<Option_Poll_Votes_Insert_Input>;
  on_conflict?: Maybe<Option_Poll_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Option_Poll_Votes_OneArgs = {
  object: Option_Poll_Votes_Insert_Input;
  on_conflict?: Maybe<Option_Poll_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Polkassembly_ProposalsArgs = {
  objects: Array<Polkassembly_Proposals_Insert_Input>;
  on_conflict?: Maybe<Polkassembly_Proposals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Polkassembly_Proposals_OneArgs = {
  object: Polkassembly_Proposals_Insert_Input;
  on_conflict?: Maybe<Polkassembly_Proposals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PollArgs = {
  objects: Array<Poll_Insert_Input>;
  on_conflict?: Maybe<Poll_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Poll_OneArgs = {
  object: Poll_Insert_Input;
  on_conflict?: Maybe<Poll_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Poll_VotesArgs = {
  objects: Array<Poll_Votes_Insert_Input>;
  on_conflict?: Maybe<Poll_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Poll_Votes_OneArgs = {
  object: Poll_Votes_Insert_Input;
  on_conflict?: Maybe<Poll_Votes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_ReactionsArgs = {
  objects: Array<Post_Reactions_Insert_Input>;
  on_conflict?: Maybe<Post_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Reactions_OneArgs = {
  object: Post_Reactions_Insert_Input;
  on_conflict?: Maybe<Post_Reactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_TopicsArgs = {
  objects: Array<Post_Topics_Insert_Input>;
  on_conflict?: Maybe<Post_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Topics_OneArgs = {
  object: Post_Topics_Insert_Input;
  on_conflict?: Maybe<Post_Topics_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_TypesArgs = {
  objects: Array<Post_Types_Insert_Input>;
  on_conflict?: Maybe<Post_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Types_OneArgs = {
  object: Post_Types_Insert_Input;
  on_conflict?: Maybe<Post_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PostsArgs = {
  objects: Array<Posts_Insert_Input>;
  on_conflict?: Maybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Posts_OneArgs = {
  object: Posts_Insert_Input;
  on_conflict?: Maybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Proposal_TrackerArgs = {
  objects: Array<Proposal_Tracker_Insert_Input>;
  on_conflict?: Maybe<Proposal_Tracker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Proposal_Tracker_OneArgs = {
  object: Proposal_Tracker_Insert_Input;
  on_conflict?: Maybe<Proposal_Tracker_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Remark_Proposal_OptionsArgs = {
  objects: Array<Remark_Proposal_Options_Insert_Input>;
  on_conflict?: Maybe<Remark_Proposal_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Remark_Proposal_Options_OneArgs = {
  object: Remark_Proposal_Options_Insert_Input;
  on_conflict?: Maybe<Remark_Proposal_Options_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Remark_Proposal_VoteArgs = {
  objects: Array<Remark_Proposal_Vote_Insert_Input>;
  on_conflict?: Maybe<Remark_Proposal_Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Remark_Proposal_Vote_OneArgs = {
  object: Remark_Proposal_Vote_Insert_Input;
  on_conflict?: Maybe<Remark_Proposal_Vote_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Remark_ProposalsArgs = {
  objects: Array<Remark_Proposals_Insert_Input>;
  on_conflict?: Maybe<Remark_Proposals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Remark_Proposals_OneArgs = {
  object: Remark_Proposals_Insert_Input;
  on_conflict?: Maybe<Remark_Proposals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RepliesArgs = {
  objects: Array<Replies_Insert_Input>;
  on_conflict?: Maybe<Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Replies_OneArgs = {
  object: Replies_Insert_Input;
  on_conflict?: Maybe<Replies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMetamaskLinkConfirmArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMetamaskLinkStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMetamaskLoginArgs = {
  address: Scalars['String'];
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMetamaskLoginStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMetamaskSignupConfirmArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
  signature: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMetamaskSignupStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootMultisigLinkConfirmArgs = {
  address: Scalars['String'];
  addresses: Scalars['String'];
  network: Scalars['String'];
  signatory: Scalars['String'];
  signature: Scalars['String'];
  ss58Prefix: Scalars['Int'];
  threshold: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootMultisigLinkStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPostSubscribeArgs = {
  post_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootPostUnsubscribeArgs = {
  post_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootReportContentArgs = {
  comments?: Maybe<Scalars['String']>;
  content_id: Scalars['String'];
  network: Scalars['String'];
  reason: Scalars['String'];
  type: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRequestResetPasswordArgs = {
  email: Scalars['String'];
  network: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResendVerifyEmailTokenArgs = {
  network: Scalars['String'];
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
  userId: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootSetCredentialsConfirmArgs = {
  address: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  password: Scalars['String'];
  signature: Scalars['String'];
  username: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSetCredentialsStartArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSetDefaultAddressArgs = {
  address: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSignupArgs = {
  email?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  password: Scalars['String'];
  username: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUndoEmailChangeArgs = {
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateBlockIndexArgs = {
  data: BlockIndexUpdateInput;
  where: BlockIndexWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateBlockNumberArgs = {
  data: BlockNumberUpdateInput;
  where: BlockNumberWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateBountyArgs = {
  data: BountyUpdateInput;
  where: BountyWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateBountyStatusArgs = {
  data: BountyStatusUpdateInput;
  where: BountyStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateChildBountyArgs = {
  data: ChildBountyUpdateInput;
  where: ChildBountyWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateChildBountyStatusArgs = {
  data: ChildBountyStatusUpdateInput;
  where: ChildBountyStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateCouncilArgs = {
  data: CouncilUpdateInput;
  where: CouncilWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateCouncilMemberArgs = {
  data: CouncilMemberUpdateInput;
  where: CouncilMemberWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateManyBlockIndexesArgs = {
  data: BlockIndexUpdateManyMutationInput;
  where?: Maybe<BlockIndexWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyBlockNumbersArgs = {
  data: BlockNumberUpdateManyMutationInput;
  where?: Maybe<BlockNumberWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyBountiesArgs = {
  data: BountyUpdateManyMutationInput;
  where?: Maybe<BountyWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyBountyStatusesArgs = {
  data: BountyStatusUpdateManyMutationInput;
  where?: Maybe<BountyStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyChildBountiesArgs = {
  data: ChildBountyUpdateManyMutationInput;
  where?: Maybe<ChildBountyWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyChildBountyStatusesArgs = {
  data: ChildBountyStatusUpdateManyMutationInput;
  where?: Maybe<ChildBountyStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyCouncilMembersArgs = {
  data: CouncilMemberUpdateManyMutationInput;
  where?: Maybe<CouncilMemberWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyCouncilsArgs = {
  data: CouncilUpdateManyMutationInput;
  where?: Maybe<CouncilWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyMotionProposalArgumentsArgs = {
  data: MotionProposalArgumentUpdateManyMutationInput;
  where?: Maybe<MotionProposalArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyMotionStatusesArgs = {
  data: MotionStatusUpdateManyMutationInput;
  where?: Maybe<MotionStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyMotionsArgs = {
  data: MotionUpdateManyMutationInput;
  where?: Maybe<MotionWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyPipArgumentsArgs = {
  data: PipArgumentUpdateManyMutationInput;
  where?: Maybe<PipArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyPipStatusesArgs = {
  data: PipStatusUpdateManyMutationInput;
  where?: Maybe<PipStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyPipsArgs = {
  data: PipUpdateManyMutationInput;
  where?: Maybe<PipWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyPreimageArgumentsArgs = {
  data: PreimageArgumentUpdateManyMutationInput;
  where?: Maybe<PreimageArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyPreimageStatusesArgs = {
  data: PreimageStatusUpdateManyMutationInput;
  where?: Maybe<PreimageStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyPreimagesArgs = {
  data: PreimageUpdateManyMutationInput;
  where?: Maybe<PreimageWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyProposalStatusesArgs = {
  data: ProposalStatusUpdateManyMutationInput;
  where?: Maybe<ProposalStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyProposalsArgs = {
  data: ProposalUpdateManyMutationInput;
  where?: Maybe<ProposalWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyReferendumStatusesArgs = {
  data: ReferendumStatusUpdateManyMutationInput;
  where?: Maybe<ReferendumStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyReferendumsArgs = {
  data: ReferendumUpdateManyMutationInput;
  where?: Maybe<ReferendumWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyTechCommitteeProposalArgumentsArgs = {
  data: TechCommitteeProposalArgumentUpdateManyMutationInput;
  where?: Maybe<TechCommitteeProposalArgumentWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyTechCommitteeProposalStatusesArgs = {
  data: TechCommitteeProposalStatusUpdateManyMutationInput;
  where?: Maybe<TechCommitteeProposalStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyTechCommitteeProposalsArgs = {
  data: TechCommitteeProposalUpdateManyMutationInput;
  where?: Maybe<TechCommitteeProposalWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyTipStatusesArgs = {
  data: TipStatusUpdateManyMutationInput;
  where?: Maybe<TipStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyTipsArgs = {
  data: TipUpdateManyMutationInput;
  where?: Maybe<TipWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyTreasurySpendProposalsArgs = {
  data: TreasurySpendProposalUpdateManyMutationInput;
  where?: Maybe<TreasurySpendProposalWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateManyTreasuryStatusesArgs = {
  data: TreasuryStatusUpdateManyMutationInput;
  where?: Maybe<TreasuryStatusWhereInput>;
};


/** mutation root */
export type Mutation_RootUpdateMotionArgs = {
  data: MotionUpdateInput;
  where: MotionWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateMotionProposalArgumentArgs = {
  data: MotionProposalArgumentUpdateInput;
  where: MotionProposalArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateMotionStatusArgs = {
  data: MotionStatusUpdateInput;
  where: MotionStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdatePipArgs = {
  data: PipUpdateInput;
  where: PipWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdatePipArgumentArgs = {
  data: PipArgumentUpdateInput;
  where: PipArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdatePipStatusArgs = {
  data: PipStatusUpdateInput;
  where: PipStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdatePreimageArgs = {
  data: PreimageUpdateInput;
  where: PreimageWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdatePreimageArgumentArgs = {
  data: PreimageArgumentUpdateInput;
  where: PreimageArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdatePreimageStatusArgs = {
  data: PreimageStatusUpdateInput;
  where: PreimageStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateProposalArgs = {
  data: ProposalUpdateInput;
  where: ProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateProposalStatusArgs = {
  data: ProposalStatusUpdateInput;
  where: ProposalStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateProposalTrackerArgs = {
  id: Scalars['Int'];
  network: Scalars['String'];
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateReferendumArgs = {
  data: ReferendumUpdateInput;
  where: ReferendumWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateReferendumStatusArgs = {
  data: ReferendumStatusUpdateInput;
  where: ReferendumStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateTechCommitteeProposalArgs = {
  data: TechCommitteeProposalUpdateInput;
  where: TechCommitteeProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateTechCommitteeProposalArgumentArgs = {
  data: TechCommitteeProposalArgumentUpdateInput;
  where: TechCommitteeProposalArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateTechCommitteeProposalStatusArgs = {
  data: TechCommitteeProposalStatusUpdateInput;
  where: TechCommitteeProposalStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateTipArgs = {
  data: TipUpdateInput;
  where: TipWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateTipStatusArgs = {
  data: TipStatusUpdateInput;
  where: TipStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateTreasurySpendProposalArgs = {
  data: TreasurySpendProposalUpdateInput;
  where: TreasurySpendProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdateTreasuryStatusArgs = {
  data: TreasuryStatusUpdateInput;
  where: TreasuryStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpdate_Blockchain_EndpointsArgs = {
  _inc?: Maybe<Blockchain_Endpoints_Inc_Input>;
  _set?: Maybe<Blockchain_Endpoints_Set_Input>;
  where: Blockchain_Endpoints_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blockchain_Endpoints_By_PkArgs = {
  _inc?: Maybe<Blockchain_Endpoints_Inc_Input>;
  _set?: Maybe<Blockchain_Endpoints_Set_Input>;
  pk_columns: Blockchain_Endpoints_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blockchain_SocialsArgs = {
  _inc?: Maybe<Blockchain_Socials_Inc_Input>;
  _set?: Maybe<Blockchain_Socials_Set_Input>;
  where: Blockchain_Socials_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blockchain_Socials_By_PkArgs = {
  _inc?: Maybe<Blockchain_Socials_Inc_Input>;
  _set?: Maybe<Blockchain_Socials_Set_Input>;
  pk_columns: Blockchain_Socials_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Calender_EventsArgs = {
  _inc?: Maybe<Calender_Events_Inc_Input>;
  _set?: Maybe<Calender_Events_Set_Input>;
  where: Calender_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Calender_Events_By_PkArgs = {
  _inc?: Maybe<Calender_Events_Inc_Input>;
  _set?: Maybe<Calender_Events_Set_Input>;
  pk_columns: Calender_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_ReactionsArgs = {
  _inc?: Maybe<Comment_Reactions_Inc_Input>;
  _set?: Maybe<Comment_Reactions_Set_Input>;
  where: Comment_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Reactions_By_PkArgs = {
  _inc?: Maybe<Comment_Reactions_Inc_Input>;
  _set?: Maybe<Comment_Reactions_Set_Input>;
  pk_columns: Comment_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _inc?: Maybe<Comments_Inc_Input>;
  _set?: Maybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _inc?: Maybe<Comments_Inc_Input>;
  _set?: Maybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Onchain_LinksArgs = {
  _inc?: Maybe<Onchain_Links_Inc_Input>;
  _set?: Maybe<Onchain_Links_Set_Input>;
  where: Onchain_Links_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Onchain_Links_By_PkArgs = {
  _inc?: Maybe<Onchain_Links_Inc_Input>;
  _set?: Maybe<Onchain_Links_Set_Input>;
  pk_columns: Onchain_Links_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Onchain_Post_Discussion_LinkArgs = {
  _inc?: Maybe<Onchain_Post_Discussion_Link_Inc_Input>;
  _set?: Maybe<Onchain_Post_Discussion_Link_Set_Input>;
  where: Onchain_Post_Discussion_Link_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Onchain_Post_Discussion_Link_By_PkArgs = {
  _inc?: Maybe<Onchain_Post_Discussion_Link_Inc_Input>;
  _set?: Maybe<Onchain_Post_Discussion_Link_Set_Input>;
  pk_columns: Onchain_Post_Discussion_Link_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Option_PollArgs = {
  _inc?: Maybe<Option_Poll_Inc_Input>;
  _set?: Maybe<Option_Poll_Set_Input>;
  where: Option_Poll_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Option_Poll_By_PkArgs = {
  _inc?: Maybe<Option_Poll_Inc_Input>;
  _set?: Maybe<Option_Poll_Set_Input>;
  pk_columns: Option_Poll_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Option_Poll_VotesArgs = {
  _inc?: Maybe<Option_Poll_Votes_Inc_Input>;
  _set?: Maybe<Option_Poll_Votes_Set_Input>;
  where: Option_Poll_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Option_Poll_Votes_By_PkArgs = {
  _inc?: Maybe<Option_Poll_Votes_Inc_Input>;
  _set?: Maybe<Option_Poll_Votes_Set_Input>;
  pk_columns: Option_Poll_Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Polkassembly_ProposalsArgs = {
  _inc?: Maybe<Polkassembly_Proposals_Inc_Input>;
  _set?: Maybe<Polkassembly_Proposals_Set_Input>;
  where: Polkassembly_Proposals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Polkassembly_Proposals_By_PkArgs = {
  _inc?: Maybe<Polkassembly_Proposals_Inc_Input>;
  _set?: Maybe<Polkassembly_Proposals_Set_Input>;
  pk_columns: Polkassembly_Proposals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PollArgs = {
  _inc?: Maybe<Poll_Inc_Input>;
  _set?: Maybe<Poll_Set_Input>;
  where: Poll_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Poll_By_PkArgs = {
  _inc?: Maybe<Poll_Inc_Input>;
  _set?: Maybe<Poll_Set_Input>;
  pk_columns: Poll_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Poll_VotesArgs = {
  _inc?: Maybe<Poll_Votes_Inc_Input>;
  _set?: Maybe<Poll_Votes_Set_Input>;
  where: Poll_Votes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Poll_Votes_By_PkArgs = {
  _inc?: Maybe<Poll_Votes_Inc_Input>;
  _set?: Maybe<Poll_Votes_Set_Input>;
  pk_columns: Poll_Votes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_ReactionsArgs = {
  _inc?: Maybe<Post_Reactions_Inc_Input>;
  _set?: Maybe<Post_Reactions_Set_Input>;
  where: Post_Reactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Reactions_By_PkArgs = {
  _inc?: Maybe<Post_Reactions_Inc_Input>;
  _set?: Maybe<Post_Reactions_Set_Input>;
  pk_columns: Post_Reactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_TopicsArgs = {
  _inc?: Maybe<Post_Topics_Inc_Input>;
  _set?: Maybe<Post_Topics_Set_Input>;
  where: Post_Topics_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Topics_By_PkArgs = {
  _inc?: Maybe<Post_Topics_Inc_Input>;
  _set?: Maybe<Post_Topics_Set_Input>;
  pk_columns: Post_Topics_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_TypesArgs = {
  _inc?: Maybe<Post_Types_Inc_Input>;
  _set?: Maybe<Post_Types_Set_Input>;
  where: Post_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Types_By_PkArgs = {
  _inc?: Maybe<Post_Types_Inc_Input>;
  _set?: Maybe<Post_Types_Set_Input>;
  pk_columns: Post_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PostsArgs = {
  _inc?: Maybe<Posts_Inc_Input>;
  _set?: Maybe<Posts_Set_Input>;
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_By_PkArgs = {
  _inc?: Maybe<Posts_Inc_Input>;
  _set?: Maybe<Posts_Set_Input>;
  pk_columns: Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Proposal_TrackerArgs = {
  _inc?: Maybe<Proposal_Tracker_Inc_Input>;
  _set?: Maybe<Proposal_Tracker_Set_Input>;
  where: Proposal_Tracker_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Proposal_Tracker_By_PkArgs = {
  _inc?: Maybe<Proposal_Tracker_Inc_Input>;
  _set?: Maybe<Proposal_Tracker_Set_Input>;
  pk_columns: Proposal_Tracker_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Remark_Proposal_OptionsArgs = {
  _inc?: Maybe<Remark_Proposal_Options_Inc_Input>;
  _set?: Maybe<Remark_Proposal_Options_Set_Input>;
  where: Remark_Proposal_Options_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Remark_Proposal_Options_By_PkArgs = {
  _inc?: Maybe<Remark_Proposal_Options_Inc_Input>;
  _set?: Maybe<Remark_Proposal_Options_Set_Input>;
  pk_columns: Remark_Proposal_Options_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Remark_Proposal_VoteArgs = {
  _inc?: Maybe<Remark_Proposal_Vote_Inc_Input>;
  _set?: Maybe<Remark_Proposal_Vote_Set_Input>;
  where: Remark_Proposal_Vote_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Remark_Proposal_Vote_By_PkArgs = {
  _inc?: Maybe<Remark_Proposal_Vote_Inc_Input>;
  _set?: Maybe<Remark_Proposal_Vote_Set_Input>;
  pk_columns: Remark_Proposal_Vote_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Remark_ProposalsArgs = {
  _inc?: Maybe<Remark_Proposals_Inc_Input>;
  _set?: Maybe<Remark_Proposals_Set_Input>;
  where: Remark_Proposals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Remark_Proposals_By_PkArgs = {
  _inc?: Maybe<Remark_Proposals_Inc_Input>;
  _set?: Maybe<Remark_Proposals_Set_Input>;
  pk_columns: Remark_Proposals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RepliesArgs = {
  _inc?: Maybe<Replies_Inc_Input>;
  _set?: Maybe<Replies_Set_Input>;
  where: Replies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Replies_By_PkArgs = {
  _inc?: Maybe<Replies_Inc_Input>;
  _set?: Maybe<Replies_Set_Input>;
  pk_columns: Replies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpsertBlockIndexArgs = {
  create: BlockIndexCreateInput;
  update: BlockIndexUpdateInput;
  where: BlockIndexWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertBlockNumberArgs = {
  create: BlockNumberCreateInput;
  update: BlockNumberUpdateInput;
  where: BlockNumberWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertBountyArgs = {
  create: BountyCreateInput;
  update: BountyUpdateInput;
  where: BountyWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertBountyStatusArgs = {
  create: BountyStatusCreateInput;
  update: BountyStatusUpdateInput;
  where: BountyStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertChildBountyArgs = {
  create: ChildBountyCreateInput;
  update: ChildBountyUpdateInput;
  where: ChildBountyWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertChildBountyStatusArgs = {
  create: ChildBountyStatusCreateInput;
  update: ChildBountyStatusUpdateInput;
  where: ChildBountyStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertCouncilArgs = {
  create: CouncilCreateInput;
  update: CouncilUpdateInput;
  where: CouncilWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertCouncilMemberArgs = {
  create: CouncilMemberCreateInput;
  update: CouncilMemberUpdateInput;
  where: CouncilMemberWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertMotionArgs = {
  create: MotionCreateInput;
  update: MotionUpdateInput;
  where: MotionWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertMotionProposalArgumentArgs = {
  create: MotionProposalArgumentCreateInput;
  update: MotionProposalArgumentUpdateInput;
  where: MotionProposalArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertMotionStatusArgs = {
  create: MotionStatusCreateInput;
  update: MotionStatusUpdateInput;
  where: MotionStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertPipArgs = {
  create: PipCreateInput;
  update: PipUpdateInput;
  where: PipWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertPipArgumentArgs = {
  create: PipArgumentCreateInput;
  update: PipArgumentUpdateInput;
  where: PipArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertPipStatusArgs = {
  create: PipStatusCreateInput;
  update: PipStatusUpdateInput;
  where: PipStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertPreimageArgs = {
  create: PreimageCreateInput;
  update: PreimageUpdateInput;
  where: PreimageWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertPreimageArgumentArgs = {
  create: PreimageArgumentCreateInput;
  update: PreimageArgumentUpdateInput;
  where: PreimageArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertPreimageStatusArgs = {
  create: PreimageStatusCreateInput;
  update: PreimageStatusUpdateInput;
  where: PreimageStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertProposalArgs = {
  create: ProposalCreateInput;
  update: ProposalUpdateInput;
  where: ProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertProposalStatusArgs = {
  create: ProposalStatusCreateInput;
  update: ProposalStatusUpdateInput;
  where: ProposalStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertReferendumArgs = {
  create: ReferendumCreateInput;
  update: ReferendumUpdateInput;
  where: ReferendumWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertReferendumStatusArgs = {
  create: ReferendumStatusCreateInput;
  update: ReferendumStatusUpdateInput;
  where: ReferendumStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertTechCommitteeProposalArgs = {
  create: TechCommitteeProposalCreateInput;
  update: TechCommitteeProposalUpdateInput;
  where: TechCommitteeProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertTechCommitteeProposalArgumentArgs = {
  create: TechCommitteeProposalArgumentCreateInput;
  update: TechCommitteeProposalArgumentUpdateInput;
  where: TechCommitteeProposalArgumentWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertTechCommitteeProposalStatusArgs = {
  create: TechCommitteeProposalStatusCreateInput;
  update: TechCommitteeProposalStatusUpdateInput;
  where: TechCommitteeProposalStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertTipArgs = {
  create: TipCreateInput;
  update: TipUpdateInput;
  where: TipWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertTipStatusArgs = {
  create: TipStatusCreateInput;
  update: TipStatusUpdateInput;
  where: TipStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertTreasurySpendProposalArgs = {
  create: TreasurySpendProposalCreateInput;
  update: TreasurySpendProposalUpdateInput;
  where: TreasurySpendProposalWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootUpsertTreasuryStatusArgs = {
  create: TreasuryStatusCreateInput;
  update: TreasuryStatusUpdateInput;
  where: TreasuryStatusWhereUniqueInput;
};


/** mutation root */
export type Mutation_RootVerifyEmailArgs = {
  token: Scalars['String'];
};

/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_Links = {
  __typename?: 'onchain_links';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** Remote relationship field */
  onchain_bounty: Array<Maybe<Bounty>>;
  /** Remote relationship field */
  onchain_child_bounty: Array<Maybe<ChildBounty>>;
  /** Remote relationship field */
  onchain_motion: Array<Maybe<Motion>>;
  onchain_network_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_child_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_motion_id?: Maybe<Scalars['String']>;
  onchain_network_pip_id?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_referendum_id?: Maybe<Scalars['String']>;
  onchain_network_tech_committee_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_tip_id?: Maybe<Scalars['String']>;
  onchain_network_treasury_proposal_id?: Maybe<Scalars['String']>;
  /** Remote relationship field */
  onchain_pip: Array<Maybe<Pip>>;
  onchain_pip_committee?: Maybe<Scalars['String']>;
  /** An array relationship */
  onchain_post_discussion_links: Array<Onchain_Post_Discussion_Link>;
  /** An aggregated array relationship */
  onchain_post_discussion_links_aggregate: Onchain_Post_Discussion_Link_Aggregate;
  /** Remote relationship field */
  onchain_proposal: Array<Maybe<Proposal>>;
  /** Remote relationship field */
  onchain_referendum: Array<Maybe<Referendum>>;
  /** Remote relationship field */
  onchain_tech_committee_proposal: Array<Maybe<TechCommitteeProposal>>;
  /** Remote relationship field */
  onchain_tip: Array<Maybe<Tip>>;
  /** Remote relationship field */
  onchain_treasury_spend_proposal: Array<Maybe<TreasurySpendProposal>>;
  /** An object relationship */
  post: Posts;
  post_id: Scalars['Int'];
  proposer_address: Scalars['String'];
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_BountyArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BountyWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Bounty>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_Child_BountyArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChildBountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ChildBountyWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Child_Bounty>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_MotionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Motion>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_PipArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Pip>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_Post_Discussion_LinksArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_Post_Discussion_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_ProposalArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ProposalWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Proposal>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_ReferendumArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferendumOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ReferendumWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Referendum>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_Tech_Committee_ProposalArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Tech_Committee_Proposal>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_TipArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TipWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Tip>;
};


/**
 * on chain proposal created automatically by chain-db-watcher
 *
 *
 * columns and relationships of "onchain_links"
 */
export type Onchain_LinksOnchain_Treasury_Spend_ProposalArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TreasurySpendProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TreasurySpendProposalWhereInput_Remote_Rel_Public_Onchain_Linksonchain_Treasury_Spend_Proposal>;
};

/** aggregated selection of "onchain_links" */
export type Onchain_Links_Aggregate = {
  __typename?: 'onchain_links_aggregate';
  aggregate?: Maybe<Onchain_Links_Aggregate_Fields>;
  nodes: Array<Onchain_Links>;
};

/** aggregate fields of "onchain_links" */
export type Onchain_Links_Aggregate_Fields = {
  __typename?: 'onchain_links_aggregate_fields';
  avg?: Maybe<Onchain_Links_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Onchain_Links_Max_Fields>;
  min?: Maybe<Onchain_Links_Min_Fields>;
  stddev?: Maybe<Onchain_Links_Stddev_Fields>;
  stddev_pop?: Maybe<Onchain_Links_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Onchain_Links_Stddev_Samp_Fields>;
  sum?: Maybe<Onchain_Links_Sum_Fields>;
  var_pop?: Maybe<Onchain_Links_Var_Pop_Fields>;
  var_samp?: Maybe<Onchain_Links_Var_Samp_Fields>;
  variance?: Maybe<Onchain_Links_Variance_Fields>;
};


/** aggregate fields of "onchain_links" */
export type Onchain_Links_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Onchain_Links_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "onchain_links" */
export type Onchain_Links_Aggregate_Order_By = {
  avg?: Maybe<Onchain_Links_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Onchain_Links_Max_Order_By>;
  min?: Maybe<Onchain_Links_Min_Order_By>;
  stddev?: Maybe<Onchain_Links_Stddev_Order_By>;
  stddev_pop?: Maybe<Onchain_Links_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Onchain_Links_Stddev_Samp_Order_By>;
  sum?: Maybe<Onchain_Links_Sum_Order_By>;
  var_pop?: Maybe<Onchain_Links_Var_Pop_Order_By>;
  var_samp?: Maybe<Onchain_Links_Var_Samp_Order_By>;
  variance?: Maybe<Onchain_Links_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "onchain_links" */
export type Onchain_Links_Arr_Rel_Insert_Input = {
  data: Array<Onchain_Links_Insert_Input>;
  on_conflict?: Maybe<Onchain_Links_On_Conflict>;
};

/** aggregate avg on columns */
export type Onchain_Links_Avg_Fields = {
  __typename?: 'onchain_links_avg_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "onchain_links" */
export type Onchain_Links_Avg_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "onchain_links". All fields are combined with a logical 'AND'. */
export type Onchain_Links_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Onchain_Links_Bool_Exp>>>;
  _not?: Maybe<Onchain_Links_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Onchain_Links_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  onchain_network_bounty_id?: Maybe<String_Comparison_Exp>;
  onchain_network_child_bounty_id?: Maybe<String_Comparison_Exp>;
  onchain_network_motion_id?: Maybe<String_Comparison_Exp>;
  onchain_network_pip_id?: Maybe<String_Comparison_Exp>;
  onchain_network_proposal_id?: Maybe<String_Comparison_Exp>;
  onchain_network_referendum_id?: Maybe<String_Comparison_Exp>;
  onchain_network_tech_committee_proposal_id?: Maybe<String_Comparison_Exp>;
  onchain_network_tip_id?: Maybe<String_Comparison_Exp>;
  onchain_network_treasury_proposal_id?: Maybe<String_Comparison_Exp>;
  onchain_pip_committee?: Maybe<String_Comparison_Exp>;
  onchain_post_discussion_links?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
  post?: Maybe<Posts_Bool_Exp>;
  post_id?: Maybe<Int_Comparison_Exp>;
  proposer_address?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "onchain_links" */
export enum Onchain_Links_Constraint {
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkBountyIdKey = 'onchain_links_onchain_network_bounty_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkChildBountyIdKey = 'onchain_links_onchain_network_child_bounty_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkMotionIdKey = 'onchain_links_onchain_network_motion_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkPipIdKey = 'onchain_links_onchain_network_pip_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkProposalIdKey = 'onchain_links_onchain_network_proposal_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkReferendumIdKey = 'onchain_links_onchain_network_referendum_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkTechCommitteeProposalIdKey = 'onchain_links_onchain_network_tech_committee_proposal_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkTipIdKey = 'onchain_links_onchain_network_tip_id_key',
  /** unique or primary key constraint */
  OnchainLinksOnchainNetworkTreasuryProposalIdKey = 'onchain_links_onchain_network_treasury_proposal_id_key',
  /** unique or primary key constraint */
  ProposalsPkey = 'proposals_pkey',
  /** unique or primary key constraint */
  ProposalsPostIdKey = 'proposals_post_id_key'
}

/** input type for incrementing integer column in table "onchain_links" */
export type Onchain_Links_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "onchain_links" */
export type Onchain_Links_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  onchain_network_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_child_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_motion_id?: Maybe<Scalars['String']>;
  onchain_network_pip_id?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_referendum_id?: Maybe<Scalars['String']>;
  onchain_network_tech_committee_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_tip_id?: Maybe<Scalars['String']>;
  onchain_network_treasury_proposal_id?: Maybe<Scalars['String']>;
  onchain_pip_committee?: Maybe<Scalars['String']>;
  onchain_post_discussion_links?: Maybe<Onchain_Post_Discussion_Link_Arr_Rel_Insert_Input>;
  post?: Maybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Onchain_Links_Max_Fields = {
  __typename?: 'onchain_links_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  onchain_network_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_child_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_motion_id?: Maybe<Scalars['String']>;
  onchain_network_pip_id?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_referendum_id?: Maybe<Scalars['String']>;
  onchain_network_tech_committee_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_tip_id?: Maybe<Scalars['String']>;
  onchain_network_treasury_proposal_id?: Maybe<Scalars['String']>;
  onchain_pip_committee?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "onchain_links" */
export type Onchain_Links_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_network_bounty_id?: Maybe<Order_By>;
  onchain_network_child_bounty_id?: Maybe<Order_By>;
  onchain_network_motion_id?: Maybe<Order_By>;
  onchain_network_pip_id?: Maybe<Order_By>;
  onchain_network_proposal_id?: Maybe<Order_By>;
  onchain_network_referendum_id?: Maybe<Order_By>;
  onchain_network_tech_committee_proposal_id?: Maybe<Order_By>;
  onchain_network_tip_id?: Maybe<Order_By>;
  onchain_network_treasury_proposal_id?: Maybe<Order_By>;
  onchain_pip_committee?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  proposer_address?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Onchain_Links_Min_Fields = {
  __typename?: 'onchain_links_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  onchain_network_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_child_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_motion_id?: Maybe<Scalars['String']>;
  onchain_network_pip_id?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_referendum_id?: Maybe<Scalars['String']>;
  onchain_network_tech_committee_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_tip_id?: Maybe<Scalars['String']>;
  onchain_network_treasury_proposal_id?: Maybe<Scalars['String']>;
  onchain_pip_committee?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "onchain_links" */
export type Onchain_Links_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_network_bounty_id?: Maybe<Order_By>;
  onchain_network_child_bounty_id?: Maybe<Order_By>;
  onchain_network_motion_id?: Maybe<Order_By>;
  onchain_network_pip_id?: Maybe<Order_By>;
  onchain_network_proposal_id?: Maybe<Order_By>;
  onchain_network_referendum_id?: Maybe<Order_By>;
  onchain_network_tech_committee_proposal_id?: Maybe<Order_By>;
  onchain_network_tip_id?: Maybe<Order_By>;
  onchain_network_treasury_proposal_id?: Maybe<Order_By>;
  onchain_pip_committee?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  proposer_address?: Maybe<Order_By>;
};

/** response of any mutation on the table "onchain_links" */
export type Onchain_Links_Mutation_Response = {
  __typename?: 'onchain_links_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Onchain_Links>;
};

/** input type for inserting object relation for remote table "onchain_links" */
export type Onchain_Links_Obj_Rel_Insert_Input = {
  data: Onchain_Links_Insert_Input;
  on_conflict?: Maybe<Onchain_Links_On_Conflict>;
};

/** on conflict condition type for table "onchain_links" */
export type Onchain_Links_On_Conflict = {
  constraint: Onchain_Links_Constraint;
  update_columns: Array<Onchain_Links_Update_Column>;
  where?: Maybe<Onchain_Links_Bool_Exp>;
};

/** ordering options when selecting data from "onchain_links" */
export type Onchain_Links_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_network_bounty_id?: Maybe<Order_By>;
  onchain_network_child_bounty_id?: Maybe<Order_By>;
  onchain_network_motion_id?: Maybe<Order_By>;
  onchain_network_pip_id?: Maybe<Order_By>;
  onchain_network_proposal_id?: Maybe<Order_By>;
  onchain_network_referendum_id?: Maybe<Order_By>;
  onchain_network_tech_committee_proposal_id?: Maybe<Order_By>;
  onchain_network_tip_id?: Maybe<Order_By>;
  onchain_network_treasury_proposal_id?: Maybe<Order_By>;
  onchain_pip_committee?: Maybe<Order_By>;
  onchain_post_discussion_links_aggregate?: Maybe<Onchain_Post_Discussion_Link_Aggregate_Order_By>;
  post?: Maybe<Posts_Order_By>;
  post_id?: Maybe<Order_By>;
  proposer_address?: Maybe<Order_By>;
};

/** primary key columns input for table: "onchain_links" */
export type Onchain_Links_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "onchain_links" */
export enum Onchain_Links_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnchainNetworkBountyId = 'onchain_network_bounty_id',
  /** column name */
  OnchainNetworkChildBountyId = 'onchain_network_child_bounty_id',
  /** column name */
  OnchainNetworkMotionId = 'onchain_network_motion_id',
  /** column name */
  OnchainNetworkPipId = 'onchain_network_pip_id',
  /** column name */
  OnchainNetworkProposalId = 'onchain_network_proposal_id',
  /** column name */
  OnchainNetworkReferendumId = 'onchain_network_referendum_id',
  /** column name */
  OnchainNetworkTechCommitteeProposalId = 'onchain_network_tech_committee_proposal_id',
  /** column name */
  OnchainNetworkTipId = 'onchain_network_tip_id',
  /** column name */
  OnchainNetworkTreasuryProposalId = 'onchain_network_treasury_proposal_id',
  /** column name */
  OnchainPipCommittee = 'onchain_pip_committee',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProposerAddress = 'proposer_address'
}

/** input type for updating data in table "onchain_links" */
export type Onchain_Links_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  onchain_network_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_child_bounty_id?: Maybe<Scalars['String']>;
  onchain_network_motion_id?: Maybe<Scalars['String']>;
  onchain_network_pip_id?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_referendum_id?: Maybe<Scalars['String']>;
  onchain_network_tech_committee_proposal_id?: Maybe<Scalars['String']>;
  onchain_network_tip_id?: Maybe<Scalars['String']>;
  onchain_network_treasury_proposal_id?: Maybe<Scalars['String']>;
  onchain_pip_committee?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Onchain_Links_Stddev_Fields = {
  __typename?: 'onchain_links_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "onchain_links" */
export type Onchain_Links_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Onchain_Links_Stddev_Pop_Fields = {
  __typename?: 'onchain_links_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "onchain_links" */
export type Onchain_Links_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Onchain_Links_Stddev_Samp_Fields = {
  __typename?: 'onchain_links_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "onchain_links" */
export type Onchain_Links_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Onchain_Links_Sum_Fields = {
  __typename?: 'onchain_links_sum_fields';
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "onchain_links" */
export type Onchain_Links_Sum_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** update columns of table "onchain_links" */
export enum Onchain_Links_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OnchainNetworkBountyId = 'onchain_network_bounty_id',
  /** column name */
  OnchainNetworkChildBountyId = 'onchain_network_child_bounty_id',
  /** column name */
  OnchainNetworkMotionId = 'onchain_network_motion_id',
  /** column name */
  OnchainNetworkPipId = 'onchain_network_pip_id',
  /** column name */
  OnchainNetworkProposalId = 'onchain_network_proposal_id',
  /** column name */
  OnchainNetworkReferendumId = 'onchain_network_referendum_id',
  /** column name */
  OnchainNetworkTechCommitteeProposalId = 'onchain_network_tech_committee_proposal_id',
  /** column name */
  OnchainNetworkTipId = 'onchain_network_tip_id',
  /** column name */
  OnchainNetworkTreasuryProposalId = 'onchain_network_treasury_proposal_id',
  /** column name */
  OnchainPipCommittee = 'onchain_pip_committee',
  /** column name */
  PostId = 'post_id',
  /** column name */
  ProposerAddress = 'proposer_address'
}

/** aggregate var_pop on columns */
export type Onchain_Links_Var_Pop_Fields = {
  __typename?: 'onchain_links_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "onchain_links" */
export type Onchain_Links_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Onchain_Links_Var_Samp_Fields = {
  __typename?: 'onchain_links_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "onchain_links" */
export type Onchain_Links_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Onchain_Links_Variance_Fields = {
  __typename?: 'onchain_links_variance_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "onchain_links" */
export type Onchain_Links_Variance_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** columns and relationships of "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link = {
  __typename?: 'onchain_post_discussion_link';
  author_id: Scalars['Int'];
  created_at: Scalars['timestamp'];
  discussion_post_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  onchain_link: Onchain_Links;
  onchain_link_id: Scalars['Int'];
  /** An object relationship */
  post: Posts;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Aggregate = {
  __typename?: 'onchain_post_discussion_link_aggregate';
  aggregate?: Maybe<Onchain_Post_Discussion_Link_Aggregate_Fields>;
  nodes: Array<Onchain_Post_Discussion_Link>;
};

/** aggregate fields of "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Aggregate_Fields = {
  __typename?: 'onchain_post_discussion_link_aggregate_fields';
  avg?: Maybe<Onchain_Post_Discussion_Link_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Onchain_Post_Discussion_Link_Max_Fields>;
  min?: Maybe<Onchain_Post_Discussion_Link_Min_Fields>;
  stddev?: Maybe<Onchain_Post_Discussion_Link_Stddev_Fields>;
  stddev_pop?: Maybe<Onchain_Post_Discussion_Link_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Onchain_Post_Discussion_Link_Stddev_Samp_Fields>;
  sum?: Maybe<Onchain_Post_Discussion_Link_Sum_Fields>;
  var_pop?: Maybe<Onchain_Post_Discussion_Link_Var_Pop_Fields>;
  var_samp?: Maybe<Onchain_Post_Discussion_Link_Var_Samp_Fields>;
  variance?: Maybe<Onchain_Post_Discussion_Link_Variance_Fields>;
};


/** aggregate fields of "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Aggregate_Order_By = {
  avg?: Maybe<Onchain_Post_Discussion_Link_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Onchain_Post_Discussion_Link_Max_Order_By>;
  min?: Maybe<Onchain_Post_Discussion_Link_Min_Order_By>;
  stddev?: Maybe<Onchain_Post_Discussion_Link_Stddev_Order_By>;
  stddev_pop?: Maybe<Onchain_Post_Discussion_Link_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Onchain_Post_Discussion_Link_Stddev_Samp_Order_By>;
  sum?: Maybe<Onchain_Post_Discussion_Link_Sum_Order_By>;
  var_pop?: Maybe<Onchain_Post_Discussion_Link_Var_Pop_Order_By>;
  var_samp?: Maybe<Onchain_Post_Discussion_Link_Var_Samp_Order_By>;
  variance?: Maybe<Onchain_Post_Discussion_Link_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Arr_Rel_Insert_Input = {
  data: Array<Onchain_Post_Discussion_Link_Insert_Input>;
  on_conflict?: Maybe<Onchain_Post_Discussion_Link_On_Conflict>;
};

/** aggregate avg on columns */
export type Onchain_Post_Discussion_Link_Avg_Fields = {
  __typename?: 'onchain_post_discussion_link_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  discussion_post_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onchain_link_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Avg_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "onchain_post_discussion_link". All fields are combined with a logical 'AND'. */
export type Onchain_Post_Discussion_Link_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Onchain_Post_Discussion_Link_Bool_Exp>>>;
  _not?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Onchain_Post_Discussion_Link_Bool_Exp>>>;
  author_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  discussion_post_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  onchain_link?: Maybe<Onchain_Links_Bool_Exp>;
  onchain_link_id?: Maybe<Int_Comparison_Exp>;
  post?: Maybe<Posts_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "onchain_post_discussion_link" */
export enum Onchain_Post_Discussion_Link_Constraint {
  /** unique or primary key constraint */
  OnchainPostDiscussionLinkPkey = 'onchain_post_discussion_link_pkey'
}

/** input type for incrementing integer column in table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Inc_Input = {
  author_id?: Maybe<Scalars['Int']>;
  discussion_post_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  onchain_link_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Insert_Input = {
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  discussion_post_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  onchain_link?: Maybe<Onchain_Links_Obj_Rel_Insert_Input>;
  onchain_link_id?: Maybe<Scalars['Int']>;
  post?: Maybe<Posts_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Onchain_Post_Discussion_Link_Max_Fields = {
  __typename?: 'onchain_post_discussion_link_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  discussion_post_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  onchain_link_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Max_Order_By = {
  author_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Onchain_Post_Discussion_Link_Min_Fields = {
  __typename?: 'onchain_post_discussion_link_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  discussion_post_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  onchain_link_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Min_Order_By = {
  author_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Mutation_Response = {
  __typename?: 'onchain_post_discussion_link_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Onchain_Post_Discussion_Link>;
};

/** input type for inserting object relation for remote table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Obj_Rel_Insert_Input = {
  data: Onchain_Post_Discussion_Link_Insert_Input;
  on_conflict?: Maybe<Onchain_Post_Discussion_Link_On_Conflict>;
};

/** on conflict condition type for table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_On_Conflict = {
  constraint: Onchain_Post_Discussion_Link_Constraint;
  update_columns: Array<Onchain_Post_Discussion_Link_Update_Column>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};

/** ordering options when selecting data from "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Order_By = {
  author_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link?: Maybe<Onchain_Links_Order_By>;
  onchain_link_id?: Maybe<Order_By>;
  post?: Maybe<Posts_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "onchain_post_discussion_link" */
export enum Onchain_Post_Discussion_Link_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscussionPostId = 'discussion_post_id',
  /** column name */
  Id = 'id',
  /** column name */
  OnchainLinkId = 'onchain_link_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Set_Input = {
  author_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  discussion_post_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  onchain_link_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Onchain_Post_Discussion_Link_Stddev_Fields = {
  __typename?: 'onchain_post_discussion_link_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  discussion_post_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onchain_link_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Stddev_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Onchain_Post_Discussion_Link_Stddev_Pop_Fields = {
  __typename?: 'onchain_post_discussion_link_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  discussion_post_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onchain_link_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Stddev_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Onchain_Post_Discussion_Link_Stddev_Samp_Fields = {
  __typename?: 'onchain_post_discussion_link_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  discussion_post_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onchain_link_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Stddev_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Onchain_Post_Discussion_Link_Sum_Fields = {
  __typename?: 'onchain_post_discussion_link_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  discussion_post_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  onchain_link_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Sum_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** update columns of table "onchain_post_discussion_link" */
export enum Onchain_Post_Discussion_Link_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscussionPostId = 'discussion_post_id',
  /** column name */
  Id = 'id',
  /** column name */
  OnchainLinkId = 'onchain_link_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Onchain_Post_Discussion_Link_Var_Pop_Fields = {
  __typename?: 'onchain_post_discussion_link_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  discussion_post_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onchain_link_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Var_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Onchain_Post_Discussion_Link_Var_Samp_Fields = {
  __typename?: 'onchain_post_discussion_link_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  discussion_post_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onchain_link_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Var_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Onchain_Post_Discussion_Link_Variance_Fields = {
  __typename?: 'onchain_post_discussion_link_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  discussion_post_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  onchain_link_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "onchain_post_discussion_link" */
export type Onchain_Post_Discussion_Link_Variance_Order_By = {
  author_id?: Maybe<Order_By>;
  discussion_post_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  onchain_link_id?: Maybe<Order_By>;
};

/** columns and relationships of "option_poll" */
export type Option_Poll = {
  __typename?: 'option_poll';
  created_at: Scalars['timestamptz'];
  end_at?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An object relationship */
  option_poll_post: Posts;
  /** An array relationship */
  option_poll_votes: Array<Option_Poll_Votes>;
  /** An aggregated array relationship */
  option_poll_votes_aggregate: Option_Poll_Votes_Aggregate;
  options: Scalars['String'];
  post_id: Scalars['Int'];
  question: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "option_poll" */
export type Option_PollOption_Poll_VotesArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Votes_Order_By>>;
  where?: Maybe<Option_Poll_Votes_Bool_Exp>;
};


/** columns and relationships of "option_poll" */
export type Option_PollOption_Poll_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Votes_Order_By>>;
  where?: Maybe<Option_Poll_Votes_Bool_Exp>;
};

/** aggregated selection of "option_poll" */
export type Option_Poll_Aggregate = {
  __typename?: 'option_poll_aggregate';
  aggregate?: Maybe<Option_Poll_Aggregate_Fields>;
  nodes: Array<Option_Poll>;
};

/** aggregate fields of "option_poll" */
export type Option_Poll_Aggregate_Fields = {
  __typename?: 'option_poll_aggregate_fields';
  avg?: Maybe<Option_Poll_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Option_Poll_Max_Fields>;
  min?: Maybe<Option_Poll_Min_Fields>;
  stddev?: Maybe<Option_Poll_Stddev_Fields>;
  stddev_pop?: Maybe<Option_Poll_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Option_Poll_Stddev_Samp_Fields>;
  sum?: Maybe<Option_Poll_Sum_Fields>;
  var_pop?: Maybe<Option_Poll_Var_Pop_Fields>;
  var_samp?: Maybe<Option_Poll_Var_Samp_Fields>;
  variance?: Maybe<Option_Poll_Variance_Fields>;
};


/** aggregate fields of "option_poll" */
export type Option_Poll_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Option_Poll_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "option_poll" */
export type Option_Poll_Aggregate_Order_By = {
  avg?: Maybe<Option_Poll_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Option_Poll_Max_Order_By>;
  min?: Maybe<Option_Poll_Min_Order_By>;
  stddev?: Maybe<Option_Poll_Stddev_Order_By>;
  stddev_pop?: Maybe<Option_Poll_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Option_Poll_Stddev_Samp_Order_By>;
  sum?: Maybe<Option_Poll_Sum_Order_By>;
  var_pop?: Maybe<Option_Poll_Var_Pop_Order_By>;
  var_samp?: Maybe<Option_Poll_Var_Samp_Order_By>;
  variance?: Maybe<Option_Poll_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "option_poll" */
export type Option_Poll_Arr_Rel_Insert_Input = {
  data: Array<Option_Poll_Insert_Input>;
  on_conflict?: Maybe<Option_Poll_On_Conflict>;
};

/** aggregate avg on columns */
export type Option_Poll_Avg_Fields = {
  __typename?: 'option_poll_avg_fields';
  end_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "option_poll" */
export type Option_Poll_Avg_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "option_poll". All fields are combined with a logical 'AND'. */
export type Option_Poll_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Option_Poll_Bool_Exp>>>;
  _not?: Maybe<Option_Poll_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Option_Poll_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  end_at?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  option_poll_post?: Maybe<Posts_Bool_Exp>;
  option_poll_votes?: Maybe<Option_Poll_Votes_Bool_Exp>;
  options?: Maybe<String_Comparison_Exp>;
  post_id?: Maybe<Int_Comparison_Exp>;
  question?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "option_poll" */
export enum Option_Poll_Constraint {
  /** unique or primary key constraint */
  OptionPollPkey = 'option_poll_pkey'
}

/** input type for incrementing integer column in table "option_poll" */
export type Option_Poll_Inc_Input = {
  end_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "option_poll" */
export type Option_Poll_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  option_poll_post?: Maybe<Posts_Obj_Rel_Insert_Input>;
  option_poll_votes?: Maybe<Option_Poll_Votes_Arr_Rel_Insert_Input>;
  options?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Option_Poll_Max_Fields = {
  __typename?: 'option_poll_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  options?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "option_poll" */
export type Option_Poll_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  options?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Option_Poll_Min_Fields = {
  __typename?: 'option_poll_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  options?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "option_poll" */
export type Option_Poll_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  options?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "option_poll" */
export type Option_Poll_Mutation_Response = {
  __typename?: 'option_poll_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Option_Poll>;
};

/** input type for inserting object relation for remote table "option_poll" */
export type Option_Poll_Obj_Rel_Insert_Input = {
  data: Option_Poll_Insert_Input;
  on_conflict?: Maybe<Option_Poll_On_Conflict>;
};

/** on conflict condition type for table "option_poll" */
export type Option_Poll_On_Conflict = {
  constraint: Option_Poll_Constraint;
  update_columns: Array<Option_Poll_Update_Column>;
  where?: Maybe<Option_Poll_Bool_Exp>;
};

/** ordering options when selecting data from "option_poll" */
export type Option_Poll_Order_By = {
  created_at?: Maybe<Order_By>;
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_poll_post?: Maybe<Posts_Order_By>;
  option_poll_votes_aggregate?: Maybe<Option_Poll_Votes_Aggregate_Order_By>;
  options?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  question?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "option_poll" */
export type Option_Poll_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "option_poll" */
export enum Option_Poll_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options',
  /** column name */
  PostId = 'post_id',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "option_poll" */
export type Option_Poll_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  end_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  options?: Maybe<Scalars['String']>;
  post_id?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Option_Poll_Stddev_Fields = {
  __typename?: 'option_poll_stddev_fields';
  end_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "option_poll" */
export type Option_Poll_Stddev_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Option_Poll_Stddev_Pop_Fields = {
  __typename?: 'option_poll_stddev_pop_fields';
  end_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "option_poll" */
export type Option_Poll_Stddev_Pop_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Option_Poll_Stddev_Samp_Fields = {
  __typename?: 'option_poll_stddev_samp_fields';
  end_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "option_poll" */
export type Option_Poll_Stddev_Samp_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Option_Poll_Sum_Fields = {
  __typename?: 'option_poll_sum_fields';
  end_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "option_poll" */
export type Option_Poll_Sum_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** update columns of table "option_poll" */
export enum Option_Poll_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndAt = 'end_at',
  /** column name */
  Id = 'id',
  /** column name */
  Options = 'options',
  /** column name */
  PostId = 'post_id',
  /** column name */
  Question = 'question',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Option_Poll_Var_Pop_Fields = {
  __typename?: 'option_poll_var_pop_fields';
  end_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "option_poll" */
export type Option_Poll_Var_Pop_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Option_Poll_Var_Samp_Fields = {
  __typename?: 'option_poll_var_samp_fields';
  end_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "option_poll" */
export type Option_Poll_Var_Samp_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Option_Poll_Variance_Fields = {
  __typename?: 'option_poll_variance_fields';
  end_at?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "option_poll" */
export type Option_Poll_Variance_Order_By = {
  end_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** columns and relationships of "option_poll_votes" */
export type Option_Poll_Votes = {
  __typename?: 'option_poll_votes';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  option: Scalars['String'];
  /** An object relationship */
  option_poll: Option_Poll;
  option_poll_id: Scalars['Int'];
  /** Remote relationship field */
  option_poll_voter?: Maybe<User>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['Int'];
};

/** aggregated selection of "option_poll_votes" */
export type Option_Poll_Votes_Aggregate = {
  __typename?: 'option_poll_votes_aggregate';
  aggregate?: Maybe<Option_Poll_Votes_Aggregate_Fields>;
  nodes: Array<Option_Poll_Votes>;
};

/** aggregate fields of "option_poll_votes" */
export type Option_Poll_Votes_Aggregate_Fields = {
  __typename?: 'option_poll_votes_aggregate_fields';
  avg?: Maybe<Option_Poll_Votes_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Option_Poll_Votes_Max_Fields>;
  min?: Maybe<Option_Poll_Votes_Min_Fields>;
  stddev?: Maybe<Option_Poll_Votes_Stddev_Fields>;
  stddev_pop?: Maybe<Option_Poll_Votes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Option_Poll_Votes_Stddev_Samp_Fields>;
  sum?: Maybe<Option_Poll_Votes_Sum_Fields>;
  var_pop?: Maybe<Option_Poll_Votes_Var_Pop_Fields>;
  var_samp?: Maybe<Option_Poll_Votes_Var_Samp_Fields>;
  variance?: Maybe<Option_Poll_Votes_Variance_Fields>;
};


/** aggregate fields of "option_poll_votes" */
export type Option_Poll_Votes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Option_Poll_Votes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "option_poll_votes" */
export type Option_Poll_Votes_Aggregate_Order_By = {
  avg?: Maybe<Option_Poll_Votes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Option_Poll_Votes_Max_Order_By>;
  min?: Maybe<Option_Poll_Votes_Min_Order_By>;
  stddev?: Maybe<Option_Poll_Votes_Stddev_Order_By>;
  stddev_pop?: Maybe<Option_Poll_Votes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Option_Poll_Votes_Stddev_Samp_Order_By>;
  sum?: Maybe<Option_Poll_Votes_Sum_Order_By>;
  var_pop?: Maybe<Option_Poll_Votes_Var_Pop_Order_By>;
  var_samp?: Maybe<Option_Poll_Votes_Var_Samp_Order_By>;
  variance?: Maybe<Option_Poll_Votes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "option_poll_votes" */
export type Option_Poll_Votes_Arr_Rel_Insert_Input = {
  data: Array<Option_Poll_Votes_Insert_Input>;
  on_conflict?: Maybe<Option_Poll_Votes_On_Conflict>;
};

/** aggregate avg on columns */
export type Option_Poll_Votes_Avg_Fields = {
  __typename?: 'option_poll_votes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  option_poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Avg_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "option_poll_votes". All fields are combined with a logical 'AND'. */
export type Option_Poll_Votes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Option_Poll_Votes_Bool_Exp>>>;
  _not?: Maybe<Option_Poll_Votes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Option_Poll_Votes_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  option?: Maybe<String_Comparison_Exp>;
  option_poll?: Maybe<Option_Poll_Bool_Exp>;
  option_poll_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "option_poll_votes" */
export enum Option_Poll_Votes_Constraint {
  /** unique or primary key constraint */
  OptionPollVotesPkey = 'option_poll_votes_pkey'
}

/** input type for incrementing integer column in table "option_poll_votes" */
export type Option_Poll_Votes_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  option_poll_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "option_poll_votes" */
export type Option_Poll_Votes_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_poll?: Maybe<Option_Poll_Obj_Rel_Insert_Input>;
  option_poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Option_Poll_Votes_Max_Fields = {
  __typename?: 'option_poll_votes_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Option_Poll_Votes_Min_Fields = {
  __typename?: 'option_poll_votes_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "option_poll_votes" */
export type Option_Poll_Votes_Mutation_Response = {
  __typename?: 'option_poll_votes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Option_Poll_Votes>;
};

/** input type for inserting object relation for remote table "option_poll_votes" */
export type Option_Poll_Votes_Obj_Rel_Insert_Input = {
  data: Option_Poll_Votes_Insert_Input;
  on_conflict?: Maybe<Option_Poll_Votes_On_Conflict>;
};

/** on conflict condition type for table "option_poll_votes" */
export type Option_Poll_Votes_On_Conflict = {
  constraint: Option_Poll_Votes_Constraint;
  update_columns: Array<Option_Poll_Votes_Update_Column>;
  where?: Maybe<Option_Poll_Votes_Bool_Exp>;
};

/** ordering options when selecting data from "option_poll_votes" */
export type Option_Poll_Votes_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  option_poll?: Maybe<Option_Poll_Order_By>;
  option_poll_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "option_poll_votes" */
export type Option_Poll_Votes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "option_poll_votes" */
export enum Option_Poll_Votes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  OptionPollId = 'option_poll_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "option_poll_votes" */
export type Option_Poll_Votes_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Option_Poll_Votes_Stddev_Fields = {
  __typename?: 'option_poll_votes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  option_poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Option_Poll_Votes_Stddev_Pop_Fields = {
  __typename?: 'option_poll_votes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  option_poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Option_Poll_Votes_Stddev_Samp_Fields = {
  __typename?: 'option_poll_votes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  option_poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Option_Poll_Votes_Sum_Fields = {
  __typename?: 'option_poll_votes_sum_fields';
  id?: Maybe<Scalars['Int']>;
  option_poll_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Sum_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "option_poll_votes" */
export enum Option_Poll_Votes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  OptionPollId = 'option_poll_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Option_Poll_Votes_Var_Pop_Fields = {
  __typename?: 'option_poll_votes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  option_poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Option_Poll_Votes_Var_Samp_Fields = {
  __typename?: 'option_poll_votes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  option_poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Option_Poll_Votes_Variance_Fields = {
  __typename?: 'option_poll_votes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  option_poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "option_poll_votes" */
export type Option_Poll_Votes_Variance_Order_By = {
  id?: Maybe<Order_By>;
  option_poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "polkassembly_proposals" */
export type Polkassembly_Proposals = {
  __typename?: 'polkassembly_proposals';
  /** Remote relationship field */
  author?: Maybe<User>;
  author_id: Scalars['Int'];
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  is_synced: Scalars['Boolean'];
  network: Scalars['String'];
  proposal_hash: Scalars['String'];
  proposal_id: Scalars['Int'];
  proposal_type: Scalars['Int'];
  proposer_address: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "polkassembly_proposals" */
export type Polkassembly_ProposalsAuthorArgs = {
  id: Scalars['Int'];
};

/** aggregated selection of "polkassembly_proposals" */
export type Polkassembly_Proposals_Aggregate = {
  __typename?: 'polkassembly_proposals_aggregate';
  aggregate?: Maybe<Polkassembly_Proposals_Aggregate_Fields>;
  nodes: Array<Polkassembly_Proposals>;
};

/** aggregate fields of "polkassembly_proposals" */
export type Polkassembly_Proposals_Aggregate_Fields = {
  __typename?: 'polkassembly_proposals_aggregate_fields';
  avg?: Maybe<Polkassembly_Proposals_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Polkassembly_Proposals_Max_Fields>;
  min?: Maybe<Polkassembly_Proposals_Min_Fields>;
  stddev?: Maybe<Polkassembly_Proposals_Stddev_Fields>;
  stddev_pop?: Maybe<Polkassembly_Proposals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Polkassembly_Proposals_Stddev_Samp_Fields>;
  sum?: Maybe<Polkassembly_Proposals_Sum_Fields>;
  var_pop?: Maybe<Polkassembly_Proposals_Var_Pop_Fields>;
  var_samp?: Maybe<Polkassembly_Proposals_Var_Samp_Fields>;
  variance?: Maybe<Polkassembly_Proposals_Variance_Fields>;
};


/** aggregate fields of "polkassembly_proposals" */
export type Polkassembly_Proposals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Polkassembly_Proposals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Aggregate_Order_By = {
  avg?: Maybe<Polkassembly_Proposals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Polkassembly_Proposals_Max_Order_By>;
  min?: Maybe<Polkassembly_Proposals_Min_Order_By>;
  stddev?: Maybe<Polkassembly_Proposals_Stddev_Order_By>;
  stddev_pop?: Maybe<Polkassembly_Proposals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Polkassembly_Proposals_Stddev_Samp_Order_By>;
  sum?: Maybe<Polkassembly_Proposals_Sum_Order_By>;
  var_pop?: Maybe<Polkassembly_Proposals_Var_Pop_Order_By>;
  var_samp?: Maybe<Polkassembly_Proposals_Var_Samp_Order_By>;
  variance?: Maybe<Polkassembly_Proposals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "polkassembly_proposals" */
export type Polkassembly_Proposals_Arr_Rel_Insert_Input = {
  data: Array<Polkassembly_Proposals_Insert_Input>;
  on_conflict?: Maybe<Polkassembly_Proposals_On_Conflict>;
};

/** aggregate avg on columns */
export type Polkassembly_Proposals_Avg_Fields = {
  __typename?: 'polkassembly_proposals_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  proposal_type?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Avg_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "polkassembly_proposals". All fields are combined with a logical 'AND'. */
export type Polkassembly_Proposals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Polkassembly_Proposals_Bool_Exp>>>;
  _not?: Maybe<Polkassembly_Proposals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Polkassembly_Proposals_Bool_Exp>>>;
  author_id?: Maybe<Int_Comparison_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_synced?: Maybe<Boolean_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  proposal_hash?: Maybe<String_Comparison_Exp>;
  proposal_id?: Maybe<Int_Comparison_Exp>;
  proposal_type?: Maybe<Int_Comparison_Exp>;
  proposer_address?: Maybe<String_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "polkassembly_proposals" */
export enum Polkassembly_Proposals_Constraint {
  /** unique or primary key constraint */
  PolkassemblyProposalsPkey = 'polkassembly_proposals_pkey',
  /** unique or primary key constraint */
  PolkassemblyProposalsProposalHashKey = 'polkassembly_proposals_proposal_hash_key'
}

/** input type for incrementing integer column in table "polkassembly_proposals" */
export type Polkassembly_Proposals_Inc_Input = {
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  proposal_id?: Maybe<Scalars['Int']>;
  proposal_type?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "polkassembly_proposals" */
export type Polkassembly_Proposals_Insert_Input = {
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_synced?: Maybe<Scalars['Boolean']>;
  network?: Maybe<Scalars['String']>;
  proposal_hash?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  proposal_type?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Polkassembly_Proposals_Max_Fields = {
  __typename?: 'polkassembly_proposals_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  proposal_hash?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  proposal_type?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Max_Order_By = {
  author_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  proposal_hash?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
  proposer_address?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Polkassembly_Proposals_Min_Fields = {
  __typename?: 'polkassembly_proposals_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  proposal_hash?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  proposal_type?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Min_Order_By = {
  author_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  proposal_hash?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
  proposer_address?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "polkassembly_proposals" */
export type Polkassembly_Proposals_Mutation_Response = {
  __typename?: 'polkassembly_proposals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Polkassembly_Proposals>;
};

/** input type for inserting object relation for remote table "polkassembly_proposals" */
export type Polkassembly_Proposals_Obj_Rel_Insert_Input = {
  data: Polkassembly_Proposals_Insert_Input;
  on_conflict?: Maybe<Polkassembly_Proposals_On_Conflict>;
};

/** on conflict condition type for table "polkassembly_proposals" */
export type Polkassembly_Proposals_On_Conflict = {
  constraint: Polkassembly_Proposals_Constraint;
  update_columns: Array<Polkassembly_Proposals_Update_Column>;
  where?: Maybe<Polkassembly_Proposals_Bool_Exp>;
};

/** ordering options when selecting data from "polkassembly_proposals" */
export type Polkassembly_Proposals_Order_By = {
  author_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_synced?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  proposal_hash?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
  proposer_address?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "polkassembly_proposals" */
export type Polkassembly_Proposals_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "polkassembly_proposals" */
export enum Polkassembly_Proposals_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsSynced = 'is_synced',
  /** column name */
  Network = 'network',
  /** column name */
  ProposalHash = 'proposal_hash',
  /** column name */
  ProposalId = 'proposal_id',
  /** column name */
  ProposalType = 'proposal_type',
  /** column name */
  ProposerAddress = 'proposer_address',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "polkassembly_proposals" */
export type Polkassembly_Proposals_Set_Input = {
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  is_synced?: Maybe<Scalars['Boolean']>;
  network?: Maybe<Scalars['String']>;
  proposal_hash?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  proposal_type?: Maybe<Scalars['Int']>;
  proposer_address?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Polkassembly_Proposals_Stddev_Fields = {
  __typename?: 'polkassembly_proposals_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  proposal_type?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Stddev_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Polkassembly_Proposals_Stddev_Pop_Fields = {
  __typename?: 'polkassembly_proposals_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  proposal_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Stddev_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Polkassembly_Proposals_Stddev_Samp_Fields = {
  __typename?: 'polkassembly_proposals_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  proposal_type?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Stddev_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Polkassembly_Proposals_Sum_Fields = {
  __typename?: 'polkassembly_proposals_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  proposal_id?: Maybe<Scalars['Int']>;
  proposal_type?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Sum_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** update columns of table "polkassembly_proposals" */
export enum Polkassembly_Proposals_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsSynced = 'is_synced',
  /** column name */
  Network = 'network',
  /** column name */
  ProposalHash = 'proposal_hash',
  /** column name */
  ProposalId = 'proposal_id',
  /** column name */
  ProposalType = 'proposal_type',
  /** column name */
  ProposerAddress = 'proposer_address',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Polkassembly_Proposals_Var_Pop_Fields = {
  __typename?: 'polkassembly_proposals_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  proposal_type?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Var_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Polkassembly_Proposals_Var_Samp_Fields = {
  __typename?: 'polkassembly_proposals_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  proposal_type?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Var_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Polkassembly_Proposals_Variance_Fields = {
  __typename?: 'polkassembly_proposals_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  proposal_type?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "polkassembly_proposals" */
export type Polkassembly_Proposals_Variance_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  proposal_type?: Maybe<Order_By>;
};

/** columns and relationships of "poll" */
export type Poll = {
  __typename?: 'poll';
  block_end: Scalars['Int'];
  created_at: Scalars['timestamp'];
  id: Scalars['Int'];
  /** An array relationship */
  poll_votes: Array<Poll_Votes>;
  /** An aggregated array relationship */
  poll_votes_aggregate: Poll_Votes_Aggregate;
  /** An object relationship */
  post: Posts;
  post_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "poll" */
export type PollPoll_VotesArgs = {
  distinct_on?: Maybe<Array<Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Votes_Order_By>>;
  where?: Maybe<Poll_Votes_Bool_Exp>;
};


/** columns and relationships of "poll" */
export type PollPoll_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Votes_Order_By>>;
  where?: Maybe<Poll_Votes_Bool_Exp>;
};

/** aggregated selection of "poll" */
export type Poll_Aggregate = {
  __typename?: 'poll_aggregate';
  aggregate?: Maybe<Poll_Aggregate_Fields>;
  nodes: Array<Poll>;
};

/** aggregate fields of "poll" */
export type Poll_Aggregate_Fields = {
  __typename?: 'poll_aggregate_fields';
  avg?: Maybe<Poll_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Poll_Max_Fields>;
  min?: Maybe<Poll_Min_Fields>;
  stddev?: Maybe<Poll_Stddev_Fields>;
  stddev_pop?: Maybe<Poll_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Poll_Stddev_Samp_Fields>;
  sum?: Maybe<Poll_Sum_Fields>;
  var_pop?: Maybe<Poll_Var_Pop_Fields>;
  var_samp?: Maybe<Poll_Var_Samp_Fields>;
  variance?: Maybe<Poll_Variance_Fields>;
};


/** aggregate fields of "poll" */
export type Poll_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Poll_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "poll" */
export type Poll_Aggregate_Order_By = {
  avg?: Maybe<Poll_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Poll_Max_Order_By>;
  min?: Maybe<Poll_Min_Order_By>;
  stddev?: Maybe<Poll_Stddev_Order_By>;
  stddev_pop?: Maybe<Poll_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Poll_Stddev_Samp_Order_By>;
  sum?: Maybe<Poll_Sum_Order_By>;
  var_pop?: Maybe<Poll_Var_Pop_Order_By>;
  var_samp?: Maybe<Poll_Var_Samp_Order_By>;
  variance?: Maybe<Poll_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "poll" */
export type Poll_Arr_Rel_Insert_Input = {
  data: Array<Poll_Insert_Input>;
  on_conflict?: Maybe<Poll_On_Conflict>;
};

/** aggregate avg on columns */
export type Poll_Avg_Fields = {
  __typename?: 'poll_avg_fields';
  block_end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "poll" */
export type Poll_Avg_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "poll". All fields are combined with a logical 'AND'. */
export type Poll_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Poll_Bool_Exp>>>;
  _not?: Maybe<Poll_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Poll_Bool_Exp>>>;
  block_end?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  poll_votes?: Maybe<Poll_Votes_Bool_Exp>;
  post?: Maybe<Posts_Bool_Exp>;
  post_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "poll" */
export enum Poll_Constraint {
  /** unique or primary key constraint */
  PollPkey = 'poll_pkey'
}

/** input type for incrementing integer column in table "poll" */
export type Poll_Inc_Input = {
  block_end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "poll" */
export type Poll_Insert_Input = {
  block_end?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  poll_votes?: Maybe<Poll_Votes_Arr_Rel_Insert_Input>;
  post?: Maybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Poll_Max_Fields = {
  __typename?: 'poll_max_fields';
  block_end?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "poll" */
export type Poll_Max_Order_By = {
  block_end?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Poll_Min_Fields = {
  __typename?: 'poll_min_fields';
  block_end?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "poll" */
export type Poll_Min_Order_By = {
  block_end?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "poll" */
export type Poll_Mutation_Response = {
  __typename?: 'poll_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Poll>;
};

/** input type for inserting object relation for remote table "poll" */
export type Poll_Obj_Rel_Insert_Input = {
  data: Poll_Insert_Input;
  on_conflict?: Maybe<Poll_On_Conflict>;
};

/** on conflict condition type for table "poll" */
export type Poll_On_Conflict = {
  constraint: Poll_Constraint;
  update_columns: Array<Poll_Update_Column>;
  where?: Maybe<Poll_Bool_Exp>;
};

/** ordering options when selecting data from "poll" */
export type Poll_Order_By = {
  block_end?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  poll_votes_aggregate?: Maybe<Poll_Votes_Aggregate_Order_By>;
  post?: Maybe<Posts_Order_By>;
  post_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "poll" */
export type Poll_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "poll" */
export enum Poll_Select_Column {
  /** column name */
  BlockEnd = 'block_end',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "poll" */
export type Poll_Set_Input = {
  block_end?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Poll_Stddev_Fields = {
  __typename?: 'poll_stddev_fields';
  block_end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "poll" */
export type Poll_Stddev_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Poll_Stddev_Pop_Fields = {
  __typename?: 'poll_stddev_pop_fields';
  block_end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "poll" */
export type Poll_Stddev_Pop_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Poll_Stddev_Samp_Fields = {
  __typename?: 'poll_stddev_samp_fields';
  block_end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "poll" */
export type Poll_Stddev_Samp_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Poll_Sum_Fields = {
  __typename?: 'poll_sum_fields';
  block_end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "poll" */
export type Poll_Sum_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** update columns of table "poll" */
export enum Poll_Update_Column {
  /** column name */
  BlockEnd = 'block_end',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Poll_Var_Pop_Fields = {
  __typename?: 'poll_var_pop_fields';
  block_end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "poll" */
export type Poll_Var_Pop_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Poll_Var_Samp_Fields = {
  __typename?: 'poll_var_samp_fields';
  block_end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "poll" */
export type Poll_Var_Samp_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Poll_Variance_Fields = {
  __typename?: 'poll_variance_fields';
  block_end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "poll" */
export type Poll_Variance_Order_By = {
  block_end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** columns and relationships of "poll_votes" */
export type Poll_Votes = {
  __typename?: 'poll_votes';
  created_at: Scalars['timestamp'];
  id: Scalars['Int'];
  /** An object relationship */
  poll: Poll;
  poll_id: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['Int'];
  vote: Scalars['bpchar'];
  /** Remote relationship field */
  voter?: Maybe<User>;
};

/** aggregated selection of "poll_votes" */
export type Poll_Votes_Aggregate = {
  __typename?: 'poll_votes_aggregate';
  aggregate?: Maybe<Poll_Votes_Aggregate_Fields>;
  nodes: Array<Poll_Votes>;
};

/** aggregate fields of "poll_votes" */
export type Poll_Votes_Aggregate_Fields = {
  __typename?: 'poll_votes_aggregate_fields';
  avg?: Maybe<Poll_Votes_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Poll_Votes_Max_Fields>;
  min?: Maybe<Poll_Votes_Min_Fields>;
  stddev?: Maybe<Poll_Votes_Stddev_Fields>;
  stddev_pop?: Maybe<Poll_Votes_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Poll_Votes_Stddev_Samp_Fields>;
  sum?: Maybe<Poll_Votes_Sum_Fields>;
  var_pop?: Maybe<Poll_Votes_Var_Pop_Fields>;
  var_samp?: Maybe<Poll_Votes_Var_Samp_Fields>;
  variance?: Maybe<Poll_Votes_Variance_Fields>;
};


/** aggregate fields of "poll_votes" */
export type Poll_Votes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Poll_Votes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "poll_votes" */
export type Poll_Votes_Aggregate_Order_By = {
  avg?: Maybe<Poll_Votes_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Poll_Votes_Max_Order_By>;
  min?: Maybe<Poll_Votes_Min_Order_By>;
  stddev?: Maybe<Poll_Votes_Stddev_Order_By>;
  stddev_pop?: Maybe<Poll_Votes_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Poll_Votes_Stddev_Samp_Order_By>;
  sum?: Maybe<Poll_Votes_Sum_Order_By>;
  var_pop?: Maybe<Poll_Votes_Var_Pop_Order_By>;
  var_samp?: Maybe<Poll_Votes_Var_Samp_Order_By>;
  variance?: Maybe<Poll_Votes_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "poll_votes" */
export type Poll_Votes_Arr_Rel_Insert_Input = {
  data: Array<Poll_Votes_Insert_Input>;
  on_conflict?: Maybe<Poll_Votes_On_Conflict>;
};

/** aggregate avg on columns */
export type Poll_Votes_Avg_Fields = {
  __typename?: 'poll_votes_avg_fields';
  id?: Maybe<Scalars['Float']>;
  poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "poll_votes" */
export type Poll_Votes_Avg_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "poll_votes". All fields are combined with a logical 'AND'. */
export type Poll_Votes_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Poll_Votes_Bool_Exp>>>;
  _not?: Maybe<Poll_Votes_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Poll_Votes_Bool_Exp>>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  poll?: Maybe<Poll_Bool_Exp>;
  poll_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
  vote?: Maybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "poll_votes" */
export enum Poll_Votes_Constraint {
  /** unique or primary key constraint */
  PollVotesPkey = 'poll_votes_pkey',
  /** unique or primary key constraint */
  PollVotesPollIdUserIdKey = 'poll_votes_poll_id_user_id_key'
}

/** input type for incrementing integer column in table "poll_votes" */
export type Poll_Votes_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  poll_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "poll_votes" */
export type Poll_Votes_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  poll?: Maybe<Poll_Obj_Rel_Insert_Input>;
  poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['bpchar']>;
};

/** aggregate max on columns */
export type Poll_Votes_Max_Fields = {
  __typename?: 'poll_votes_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "poll_votes" */
export type Poll_Votes_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Poll_Votes_Min_Fields = {
  __typename?: 'poll_votes_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "poll_votes" */
export type Poll_Votes_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "poll_votes" */
export type Poll_Votes_Mutation_Response = {
  __typename?: 'poll_votes_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Poll_Votes>;
};

/** input type for inserting object relation for remote table "poll_votes" */
export type Poll_Votes_Obj_Rel_Insert_Input = {
  data: Poll_Votes_Insert_Input;
  on_conflict?: Maybe<Poll_Votes_On_Conflict>;
};

/** on conflict condition type for table "poll_votes" */
export type Poll_Votes_On_Conflict = {
  constraint: Poll_Votes_Constraint;
  update_columns: Array<Poll_Votes_Update_Column>;
  where?: Maybe<Poll_Votes_Bool_Exp>;
};

/** ordering options when selecting data from "poll_votes" */
export type Poll_Votes_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  poll?: Maybe<Poll_Order_By>;
  poll_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  vote?: Maybe<Order_By>;
};

/** primary key columns input for table: "poll_votes" */
export type Poll_Votes_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "poll_votes" */
export enum Poll_Votes_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Vote = 'vote'
}

/** input type for updating data in table "poll_votes" */
export type Poll_Votes_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  poll_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
  vote?: Maybe<Scalars['bpchar']>;
};

/** aggregate stddev on columns */
export type Poll_Votes_Stddev_Fields = {
  __typename?: 'poll_votes_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "poll_votes" */
export type Poll_Votes_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Poll_Votes_Stddev_Pop_Fields = {
  __typename?: 'poll_votes_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "poll_votes" */
export type Poll_Votes_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Poll_Votes_Stddev_Samp_Fields = {
  __typename?: 'poll_votes_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "poll_votes" */
export type Poll_Votes_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Poll_Votes_Sum_Fields = {
  __typename?: 'poll_votes_sum_fields';
  id?: Maybe<Scalars['Int']>;
  poll_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "poll_votes" */
export type Poll_Votes_Sum_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "poll_votes" */
export enum Poll_Votes_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PollId = 'poll_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Vote = 'vote'
}

/** aggregate var_pop on columns */
export type Poll_Votes_Var_Pop_Fields = {
  __typename?: 'poll_votes_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "poll_votes" */
export type Poll_Votes_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Poll_Votes_Var_Samp_Fields = {
  __typename?: 'poll_votes_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "poll_votes" */
export type Poll_Votes_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Poll_Votes_Variance_Fields = {
  __typename?: 'poll_votes_variance_fields';
  id?: Maybe<Scalars['Float']>;
  poll_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "poll_votes" */
export type Poll_Votes_Variance_Order_By = {
  id?: Maybe<Order_By>;
  poll_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "post_last_update" */
export type Post_Last_Update = {
  __typename?: 'post_last_update';
  comment_id?: Maybe<Scalars['uuid']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "post_last_update" */
export type Post_Last_Update_Aggregate = {
  __typename?: 'post_last_update_aggregate';
  aggregate?: Maybe<Post_Last_Update_Aggregate_Fields>;
  nodes: Array<Post_Last_Update>;
};

/** aggregate fields of "post_last_update" */
export type Post_Last_Update_Aggregate_Fields = {
  __typename?: 'post_last_update_aggregate_fields';
  avg?: Maybe<Post_Last_Update_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Post_Last_Update_Max_Fields>;
  min?: Maybe<Post_Last_Update_Min_Fields>;
  stddev?: Maybe<Post_Last_Update_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Last_Update_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Last_Update_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Last_Update_Sum_Fields>;
  var_pop?: Maybe<Post_Last_Update_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Last_Update_Var_Samp_Fields>;
  variance?: Maybe<Post_Last_Update_Variance_Fields>;
};


/** aggregate fields of "post_last_update" */
export type Post_Last_Update_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Post_Last_Update_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_last_update" */
export type Post_Last_Update_Aggregate_Order_By = {
  avg?: Maybe<Post_Last_Update_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Post_Last_Update_Max_Order_By>;
  min?: Maybe<Post_Last_Update_Min_Order_By>;
  stddev?: Maybe<Post_Last_Update_Stddev_Order_By>;
  stddev_pop?: Maybe<Post_Last_Update_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Post_Last_Update_Stddev_Samp_Order_By>;
  sum?: Maybe<Post_Last_Update_Sum_Order_By>;
  var_pop?: Maybe<Post_Last_Update_Var_Pop_Order_By>;
  var_samp?: Maybe<Post_Last_Update_Var_Samp_Order_By>;
  variance?: Maybe<Post_Last_Update_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Post_Last_Update_Avg_Fields = {
  __typename?: 'post_last_update_avg_fields';
  post_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "post_last_update" */
export type Post_Last_Update_Avg_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_last_update". All fields are combined with a logical 'AND'. */
export type Post_Last_Update_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Post_Last_Update_Bool_Exp>>>;
  _not?: Maybe<Post_Last_Update_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Post_Last_Update_Bool_Exp>>>;
  comment_id?: Maybe<Uuid_Comparison_Exp>;
  last_update?: Maybe<Timestamptz_Comparison_Exp>;
  post_id?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Post_Last_Update_Max_Fields = {
  __typename?: 'post_last_update_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "post_last_update" */
export type Post_Last_Update_Max_Order_By = {
  comment_id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Last_Update_Min_Fields = {
  __typename?: 'post_last_update_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  last_update?: Maybe<Scalars['timestamptz']>;
  post_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "post_last_update" */
export type Post_Last_Update_Min_Order_By = {
  comment_id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "post_last_update" */
export type Post_Last_Update_Order_By = {
  comment_id?: Maybe<Order_By>;
  last_update?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
};

/** select columns of table "post_last_update" */
export enum Post_Last_Update_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  LastUpdate = 'last_update',
  /** column name */
  PostId = 'post_id'
}

/** aggregate stddev on columns */
export type Post_Last_Update_Stddev_Fields = {
  __typename?: 'post_last_update_stddev_fields';
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "post_last_update" */
export type Post_Last_Update_Stddev_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Post_Last_Update_Stddev_Pop_Fields = {
  __typename?: 'post_last_update_stddev_pop_fields';
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "post_last_update" */
export type Post_Last_Update_Stddev_Pop_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Post_Last_Update_Stddev_Samp_Fields = {
  __typename?: 'post_last_update_stddev_samp_fields';
  post_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "post_last_update" */
export type Post_Last_Update_Stddev_Samp_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Post_Last_Update_Sum_Fields = {
  __typename?: 'post_last_update_sum_fields';
  post_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "post_last_update" */
export type Post_Last_Update_Sum_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Post_Last_Update_Var_Pop_Fields = {
  __typename?: 'post_last_update_var_pop_fields';
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "post_last_update" */
export type Post_Last_Update_Var_Pop_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Post_Last_Update_Var_Samp_Fields = {
  __typename?: 'post_last_update_var_samp_fields';
  post_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "post_last_update" */
export type Post_Last_Update_Var_Samp_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Post_Last_Update_Variance_Fields = {
  __typename?: 'post_last_update_variance_fields';
  post_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "post_last_update" */
export type Post_Last_Update_Variance_Order_By = {
  post_id?: Maybe<Order_By>;
};

/** columns and relationships of "post_reactions" */
export type Post_Reactions = {
  __typename?: 'post_reactions';
  created_at: Scalars['timestamp'];
  id: Scalars['Int'];
  /** An object relationship */
  post: Posts;
  post_id: Scalars['Int'];
  /** Remote relationship field */
  reacting_user?: Maybe<User>;
  reaction: Scalars['bpchar'];
  updated_at: Scalars['timestamp'];
  user_id: Scalars['Int'];
};

/** aggregated selection of "post_reactions" */
export type Post_Reactions_Aggregate = {
  __typename?: 'post_reactions_aggregate';
  aggregate?: Maybe<Post_Reactions_Aggregate_Fields>;
  nodes: Array<Post_Reactions>;
};

/** aggregate fields of "post_reactions" */
export type Post_Reactions_Aggregate_Fields = {
  __typename?: 'post_reactions_aggregate_fields';
  avg?: Maybe<Post_Reactions_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Post_Reactions_Max_Fields>;
  min?: Maybe<Post_Reactions_Min_Fields>;
  stddev?: Maybe<Post_Reactions_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Reactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Reactions_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Reactions_Sum_Fields>;
  var_pop?: Maybe<Post_Reactions_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Reactions_Var_Samp_Fields>;
  variance?: Maybe<Post_Reactions_Variance_Fields>;
};


/** aggregate fields of "post_reactions" */
export type Post_Reactions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Post_Reactions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_reactions" */
export type Post_Reactions_Aggregate_Order_By = {
  avg?: Maybe<Post_Reactions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Post_Reactions_Max_Order_By>;
  min?: Maybe<Post_Reactions_Min_Order_By>;
  stddev?: Maybe<Post_Reactions_Stddev_Order_By>;
  stddev_pop?: Maybe<Post_Reactions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Post_Reactions_Stddev_Samp_Order_By>;
  sum?: Maybe<Post_Reactions_Sum_Order_By>;
  var_pop?: Maybe<Post_Reactions_Var_Pop_Order_By>;
  var_samp?: Maybe<Post_Reactions_Var_Samp_Order_By>;
  variance?: Maybe<Post_Reactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "post_reactions" */
export type Post_Reactions_Arr_Rel_Insert_Input = {
  data: Array<Post_Reactions_Insert_Input>;
  on_conflict?: Maybe<Post_Reactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Post_Reactions_Avg_Fields = {
  __typename?: 'post_reactions_avg_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "post_reactions" */
export type Post_Reactions_Avg_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_reactions". All fields are combined with a logical 'AND'. */
export type Post_Reactions_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Post_Reactions_Bool_Exp>>>;
  _not?: Maybe<Post_Reactions_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Post_Reactions_Bool_Exp>>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  post?: Maybe<Posts_Bool_Exp>;
  post_id?: Maybe<Int_Comparison_Exp>;
  reaction?: Maybe<Bpchar_Comparison_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_reactions" */
export enum Post_Reactions_Constraint {
  /** unique or primary key constraint */
  PostReactionsPkey = 'post_reactions_pkey',
  /** unique or primary key constraint */
  PostReactionsPostIdUserIdReactionKey = 'post_reactions_post_id_user_id_reaction_key'
}

/** input type for incrementing integer column in table "post_reactions" */
export type Post_Reactions_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "post_reactions" */
export type Post_Reactions_Insert_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  post?: Maybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Scalars['bpchar']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Post_Reactions_Max_Fields = {
  __typename?: 'post_reactions_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "post_reactions" */
export type Post_Reactions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Reactions_Min_Fields = {
  __typename?: 'post_reactions_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "post_reactions" */
export type Post_Reactions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "post_reactions" */
export type Post_Reactions_Mutation_Response = {
  __typename?: 'post_reactions_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Post_Reactions>;
};

/** input type for inserting object relation for remote table "post_reactions" */
export type Post_Reactions_Obj_Rel_Insert_Input = {
  data: Post_Reactions_Insert_Input;
  on_conflict?: Maybe<Post_Reactions_On_Conflict>;
};

/** on conflict condition type for table "post_reactions" */
export type Post_Reactions_On_Conflict = {
  constraint: Post_Reactions_Constraint;
  update_columns: Array<Post_Reactions_Update_Column>;
  where?: Maybe<Post_Reactions_Bool_Exp>;
};

/** ordering options when selecting data from "post_reactions" */
export type Post_Reactions_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  post?: Maybe<Posts_Order_By>;
  post_id?: Maybe<Order_By>;
  reaction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "post_reactions" */
export type Post_Reactions_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "post_reactions" */
export enum Post_Reactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "post_reactions" */
export type Post_Reactions_Set_Input = {
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  reaction?: Maybe<Scalars['bpchar']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Post_Reactions_Stddev_Fields = {
  __typename?: 'post_reactions_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "post_reactions" */
export type Post_Reactions_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Post_Reactions_Stddev_Pop_Fields = {
  __typename?: 'post_reactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "post_reactions" */
export type Post_Reactions_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Post_Reactions_Stddev_Samp_Fields = {
  __typename?: 'post_reactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "post_reactions" */
export type Post_Reactions_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Post_Reactions_Sum_Fields = {
  __typename?: 'post_reactions_sum_fields';
  id?: Maybe<Scalars['Int']>;
  post_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "post_reactions" */
export type Post_Reactions_Sum_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "post_reactions" */
export enum Post_Reactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  Reaction = 'reaction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Post_Reactions_Var_Pop_Fields = {
  __typename?: 'post_reactions_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "post_reactions" */
export type Post_Reactions_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Post_Reactions_Var_Samp_Fields = {
  __typename?: 'post_reactions_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "post_reactions" */
export type Post_Reactions_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Post_Reactions_Variance_Fields = {
  __typename?: 'post_reactions_variance_fields';
  id?: Maybe<Scalars['Float']>;
  post_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "post_reactions" */
export type Post_Reactions_Variance_Order_By = {
  id?: Maybe<Order_By>;
  post_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "post_topics" */
export type Post_Topics = {
  __typename?: 'post_topics';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregated array relationship */
  posts_aggregate: Posts_Aggregate;
};


/** columns and relationships of "post_topics" */
export type Post_TopicsPostsArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};


/** columns and relationships of "post_topics" */
export type Post_TopicsPosts_AggregateArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};

/** aggregated selection of "post_topics" */
export type Post_Topics_Aggregate = {
  __typename?: 'post_topics_aggregate';
  aggregate?: Maybe<Post_Topics_Aggregate_Fields>;
  nodes: Array<Post_Topics>;
};

/** aggregate fields of "post_topics" */
export type Post_Topics_Aggregate_Fields = {
  __typename?: 'post_topics_aggregate_fields';
  avg?: Maybe<Post_Topics_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Post_Topics_Max_Fields>;
  min?: Maybe<Post_Topics_Min_Fields>;
  stddev?: Maybe<Post_Topics_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Topics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Topics_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Topics_Sum_Fields>;
  var_pop?: Maybe<Post_Topics_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Topics_Var_Samp_Fields>;
  variance?: Maybe<Post_Topics_Variance_Fields>;
};


/** aggregate fields of "post_topics" */
export type Post_Topics_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Post_Topics_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_topics" */
export type Post_Topics_Aggregate_Order_By = {
  avg?: Maybe<Post_Topics_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Post_Topics_Max_Order_By>;
  min?: Maybe<Post_Topics_Min_Order_By>;
  stddev?: Maybe<Post_Topics_Stddev_Order_By>;
  stddev_pop?: Maybe<Post_Topics_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Post_Topics_Stddev_Samp_Order_By>;
  sum?: Maybe<Post_Topics_Sum_Order_By>;
  var_pop?: Maybe<Post_Topics_Var_Pop_Order_By>;
  var_samp?: Maybe<Post_Topics_Var_Samp_Order_By>;
  variance?: Maybe<Post_Topics_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "post_topics" */
export type Post_Topics_Arr_Rel_Insert_Input = {
  data: Array<Post_Topics_Insert_Input>;
  on_conflict?: Maybe<Post_Topics_On_Conflict>;
};

/** aggregate avg on columns */
export type Post_Topics_Avg_Fields = {
  __typename?: 'post_topics_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "post_topics" */
export type Post_Topics_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_topics". All fields are combined with a logical 'AND'. */
export type Post_Topics_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Post_Topics_Bool_Exp>>>;
  _not?: Maybe<Post_Topics_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Post_Topics_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  posts?: Maybe<Posts_Bool_Exp>;
};

/** unique or primary key constraints on table "post_topics" */
export enum Post_Topics_Constraint {
  /** unique or primary key constraint */
  CategoriesNameKey = 'categories_name_key',
  /** unique or primary key constraint */
  CategoriesPkey = 'categories_pkey'
}

/** input type for incrementing integer column in table "post_topics" */
export type Post_Topics_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "post_topics" */
export type Post_Topics_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  posts?: Maybe<Posts_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Post_Topics_Max_Fields = {
  __typename?: 'post_topics_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "post_topics" */
export type Post_Topics_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Topics_Min_Fields = {
  __typename?: 'post_topics_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "post_topics" */
export type Post_Topics_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "post_topics" */
export type Post_Topics_Mutation_Response = {
  __typename?: 'post_topics_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Post_Topics>;
};

/** input type for inserting object relation for remote table "post_topics" */
export type Post_Topics_Obj_Rel_Insert_Input = {
  data: Post_Topics_Insert_Input;
  on_conflict?: Maybe<Post_Topics_On_Conflict>;
};

/** on conflict condition type for table "post_topics" */
export type Post_Topics_On_Conflict = {
  constraint: Post_Topics_Constraint;
  update_columns: Array<Post_Topics_Update_Column>;
  where?: Maybe<Post_Topics_Bool_Exp>;
};

/** ordering options when selecting data from "post_topics" */
export type Post_Topics_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  posts_aggregate?: Maybe<Posts_Aggregate_Order_By>;
};

/** primary key columns input for table: "post_topics" */
export type Post_Topics_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "post_topics" */
export enum Post_Topics_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "post_topics" */
export type Post_Topics_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Post_Topics_Stddev_Fields = {
  __typename?: 'post_topics_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "post_topics" */
export type Post_Topics_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Post_Topics_Stddev_Pop_Fields = {
  __typename?: 'post_topics_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "post_topics" */
export type Post_Topics_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Post_Topics_Stddev_Samp_Fields = {
  __typename?: 'post_topics_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "post_topics" */
export type Post_Topics_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Post_Topics_Sum_Fields = {
  __typename?: 'post_topics_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "post_topics" */
export type Post_Topics_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "post_topics" */
export enum Post_Topics_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Post_Topics_Var_Pop_Fields = {
  __typename?: 'post_topics_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "post_topics" */
export type Post_Topics_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Post_Topics_Var_Samp_Fields = {
  __typename?: 'post_topics_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "post_topics" */
export type Post_Topics_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Post_Topics_Variance_Fields = {
  __typename?: 'post_topics_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "post_topics" */
export type Post_Topics_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "post_types" */
export type Post_Types = {
  __typename?: 'post_types';
  id: Scalars['Int'];
  name: Scalars['String'];
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregated array relationship */
  posts_aggregate: Posts_Aggregate;
};


/** columns and relationships of "post_types" */
export type Post_TypesPostsArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};


/** columns and relationships of "post_types" */
export type Post_TypesPosts_AggregateArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};

/** aggregated selection of "post_types" */
export type Post_Types_Aggregate = {
  __typename?: 'post_types_aggregate';
  aggregate?: Maybe<Post_Types_Aggregate_Fields>;
  nodes: Array<Post_Types>;
};

/** aggregate fields of "post_types" */
export type Post_Types_Aggregate_Fields = {
  __typename?: 'post_types_aggregate_fields';
  avg?: Maybe<Post_Types_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Post_Types_Max_Fields>;
  min?: Maybe<Post_Types_Min_Fields>;
  stddev?: Maybe<Post_Types_Stddev_Fields>;
  stddev_pop?: Maybe<Post_Types_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Post_Types_Stddev_Samp_Fields>;
  sum?: Maybe<Post_Types_Sum_Fields>;
  var_pop?: Maybe<Post_Types_Var_Pop_Fields>;
  var_samp?: Maybe<Post_Types_Var_Samp_Fields>;
  variance?: Maybe<Post_Types_Variance_Fields>;
};


/** aggregate fields of "post_types" */
export type Post_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Post_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_types" */
export type Post_Types_Aggregate_Order_By = {
  avg?: Maybe<Post_Types_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Post_Types_Max_Order_By>;
  min?: Maybe<Post_Types_Min_Order_By>;
  stddev?: Maybe<Post_Types_Stddev_Order_By>;
  stddev_pop?: Maybe<Post_Types_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Post_Types_Stddev_Samp_Order_By>;
  sum?: Maybe<Post_Types_Sum_Order_By>;
  var_pop?: Maybe<Post_Types_Var_Pop_Order_By>;
  var_samp?: Maybe<Post_Types_Var_Samp_Order_By>;
  variance?: Maybe<Post_Types_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "post_types" */
export type Post_Types_Arr_Rel_Insert_Input = {
  data: Array<Post_Types_Insert_Input>;
  on_conflict?: Maybe<Post_Types_On_Conflict>;
};

/** aggregate avg on columns */
export type Post_Types_Avg_Fields = {
  __typename?: 'post_types_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "post_types" */
export type Post_Types_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "post_types". All fields are combined with a logical 'AND'. */
export type Post_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Post_Types_Bool_Exp>>>;
  _not?: Maybe<Post_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Post_Types_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  posts?: Maybe<Posts_Bool_Exp>;
};

/** unique or primary key constraints on table "post_types" */
export enum Post_Types_Constraint {
  /** unique or primary key constraint */
  PostTypesNameKey = 'post_types_name_key',
  /** unique or primary key constraint */
  PostTypesPkey = 'post_types_pkey'
}

/** input type for incrementing integer column in table "post_types" */
export type Post_Types_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "post_types" */
export type Post_Types_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  posts?: Maybe<Posts_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Post_Types_Max_Fields = {
  __typename?: 'post_types_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "post_types" */
export type Post_Types_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Types_Min_Fields = {
  __typename?: 'post_types_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "post_types" */
export type Post_Types_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** response of any mutation on the table "post_types" */
export type Post_Types_Mutation_Response = {
  __typename?: 'post_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Post_Types>;
};

/** input type for inserting object relation for remote table "post_types" */
export type Post_Types_Obj_Rel_Insert_Input = {
  data: Post_Types_Insert_Input;
  on_conflict?: Maybe<Post_Types_On_Conflict>;
};

/** on conflict condition type for table "post_types" */
export type Post_Types_On_Conflict = {
  constraint: Post_Types_Constraint;
  update_columns: Array<Post_Types_Update_Column>;
  where?: Maybe<Post_Types_Bool_Exp>;
};

/** ordering options when selecting data from "post_types" */
export type Post_Types_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  posts_aggregate?: Maybe<Posts_Aggregate_Order_By>;
};

/** primary key columns input for table: "post_types" */
export type Post_Types_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "post_types" */
export enum Post_Types_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "post_types" */
export type Post_Types_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Post_Types_Stddev_Fields = {
  __typename?: 'post_types_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "post_types" */
export type Post_Types_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Post_Types_Stddev_Pop_Fields = {
  __typename?: 'post_types_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "post_types" */
export type Post_Types_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Post_Types_Stddev_Samp_Fields = {
  __typename?: 'post_types_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "post_types" */
export type Post_Types_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Post_Types_Sum_Fields = {
  __typename?: 'post_types_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "post_types" */
export type Post_Types_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "post_types" */
export enum Post_Types_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Post_Types_Var_Pop_Fields = {
  __typename?: 'post_types_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "post_types" */
export type Post_Types_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Post_Types_Var_Samp_Fields = {
  __typename?: 'post_types_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "post_types" */
export type Post_Types_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Post_Types_Variance_Fields = {
  __typename?: 'post_types_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "post_types" */
export type Post_Types_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "posts" */
export type Posts = {
  __typename?: 'posts';
  /** Remote relationship field */
  author?: Maybe<User>;
  author_id: Scalars['Int'];
  /** An array relationship */
  comments: Array<Comments>;
  /** An aggregated array relationship */
  comments_aggregate: Comments_Aggregate;
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  last_update?: Maybe<Post_Last_Update>;
  network: Scalars['String'];
  /** An object relationship */
  onchain_link?: Maybe<Onchain_Links>;
  /** An array relationship */
  onchain_post_discussion_links: Array<Onchain_Post_Discussion_Link>;
  /** An aggregated array relationship */
  onchain_post_discussion_links_aggregate: Onchain_Post_Discussion_Link_Aggregate;
  /** An array relationship */
  option_polls: Array<Option_Poll>;
  /** An aggregated array relationship */
  option_polls_aggregate: Option_Poll_Aggregate;
  /** An array relationship */
  polls: Array<Poll>;
  /** An aggregated array relationship */
  polls_aggregate: Poll_Aggregate;
  /** An array relationship */
  post_reactions: Array<Post_Reactions>;
  /** An aggregated array relationship */
  post_reactions_aggregate: Post_Reactions_Aggregate;
  title?: Maybe<Scalars['String']>;
  /** An object relationship */
  topic: Post_Topics;
  /** Define the main suject of the post */
  topic_id: Scalars['Int'];
  /** An object relationship */
  type: Post_Types;
  type_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "posts" */
export type PostsCommentsArgs = {
  distinct_on?: Maybe<Array<Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comments_Order_By>>;
  where?: Maybe<Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comments_Order_By>>;
  where?: Maybe<Comments_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsOnchain_Post_Discussion_LinksArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsOnchain_Post_Discussion_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsOption_PollsArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Order_By>>;
  where?: Maybe<Option_Poll_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsOption_Polls_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Order_By>>;
  where?: Maybe<Option_Poll_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPollsArgs = {
  distinct_on?: Maybe<Array<Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Order_By>>;
  where?: Maybe<Poll_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPolls_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Order_By>>;
  where?: Maybe<Poll_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPost_ReactionsArgs = {
  distinct_on?: Maybe<Array<Post_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Reactions_Order_By>>;
  where?: Maybe<Post_Reactions_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPost_Reactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Reactions_Order_By>>;
  where?: Maybe<Post_Reactions_Bool_Exp>;
};

/** aggregated selection of "posts" */
export type Posts_Aggregate = {
  __typename?: 'posts_aggregate';
  aggregate?: Maybe<Posts_Aggregate_Fields>;
  nodes: Array<Posts>;
};

/** aggregate fields of "posts" */
export type Posts_Aggregate_Fields = {
  __typename?: 'posts_aggregate_fields';
  avg?: Maybe<Posts_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Posts_Max_Fields>;
  min?: Maybe<Posts_Min_Fields>;
  stddev?: Maybe<Posts_Stddev_Fields>;
  stddev_pop?: Maybe<Posts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Posts_Stddev_Samp_Fields>;
  sum?: Maybe<Posts_Sum_Fields>;
  var_pop?: Maybe<Posts_Var_Pop_Fields>;
  var_samp?: Maybe<Posts_Var_Samp_Fields>;
  variance?: Maybe<Posts_Variance_Fields>;
};


/** aggregate fields of "posts" */
export type Posts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Posts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "posts" */
export type Posts_Aggregate_Order_By = {
  avg?: Maybe<Posts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Posts_Max_Order_By>;
  min?: Maybe<Posts_Min_Order_By>;
  stddev?: Maybe<Posts_Stddev_Order_By>;
  stddev_pop?: Maybe<Posts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Posts_Stddev_Samp_Order_By>;
  sum?: Maybe<Posts_Sum_Order_By>;
  var_pop?: Maybe<Posts_Var_Pop_Order_By>;
  var_samp?: Maybe<Posts_Var_Samp_Order_By>;
  variance?: Maybe<Posts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "posts" */
export type Posts_Arr_Rel_Insert_Input = {
  data: Array<Posts_Insert_Input>;
  on_conflict?: Maybe<Posts_On_Conflict>;
};

/** aggregate avg on columns */
export type Posts_Avg_Fields = {
  __typename?: 'posts_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "posts" */
export type Posts_Avg_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Posts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Posts_Bool_Exp>>>;
  _not?: Maybe<Posts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Posts_Bool_Exp>>>;
  author_id?: Maybe<Int_Comparison_Exp>;
  comments?: Maybe<Comments_Bool_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_update?: Maybe<Post_Last_Update_Bool_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  onchain_link?: Maybe<Onchain_Links_Bool_Exp>;
  onchain_post_discussion_links?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
  option_polls?: Maybe<Option_Poll_Bool_Exp>;
  polls?: Maybe<Poll_Bool_Exp>;
  post_reactions?: Maybe<Post_Reactions_Bool_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  topic?: Maybe<Post_Topics_Bool_Exp>;
  topic_id?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<Post_Types_Bool_Exp>;
  type_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "posts" */
export enum Posts_Constraint {
  /** unique or primary key constraint */
  MessagesPkey = 'messages_pkey'
}

/** input type for incrementing integer column in table "posts" */
export type Posts_Inc_Input = {
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "posts" */
export type Posts_Insert_Input = {
  author_id?: Maybe<Scalars['Int']>;
  comments?: Maybe<Comments_Arr_Rel_Insert_Input>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  onchain_link?: Maybe<Onchain_Links_Obj_Rel_Insert_Input>;
  onchain_post_discussion_links?: Maybe<Onchain_Post_Discussion_Link_Arr_Rel_Insert_Input>;
  option_polls?: Maybe<Option_Poll_Arr_Rel_Insert_Input>;
  polls?: Maybe<Poll_Arr_Rel_Insert_Input>;
  post_reactions?: Maybe<Post_Reactions_Arr_Rel_Insert_Input>;
  title?: Maybe<Scalars['String']>;
  topic?: Maybe<Post_Topics_Obj_Rel_Insert_Input>;
  topic_id?: Maybe<Scalars['Int']>;
  type?: Maybe<Post_Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Posts_Max_Fields = {
  __typename?: 'posts_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "posts" */
export type Posts_Max_Order_By = {
  author_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Min_Fields = {
  __typename?: 'posts_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "posts" */
export type Posts_Min_Order_By = {
  author_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "posts" */
export type Posts_Mutation_Response = {
  __typename?: 'posts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Posts>;
};

/** input type for inserting object relation for remote table "posts" */
export type Posts_Obj_Rel_Insert_Input = {
  data: Posts_Insert_Input;
  on_conflict?: Maybe<Posts_On_Conflict>;
};

/** on conflict condition type for table "posts" */
export type Posts_On_Conflict = {
  constraint: Posts_Constraint;
  update_columns: Array<Posts_Update_Column>;
  where?: Maybe<Posts_Bool_Exp>;
};

/** ordering options when selecting data from "posts" */
export type Posts_Order_By = {
  author_id?: Maybe<Order_By>;
  comments_aggregate?: Maybe<Comments_Aggregate_Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_update?: Maybe<Post_Last_Update_Order_By>;
  network?: Maybe<Order_By>;
  onchain_link?: Maybe<Onchain_Links_Order_By>;
  onchain_post_discussion_links_aggregate?: Maybe<Onchain_Post_Discussion_Link_Aggregate_Order_By>;
  option_polls_aggregate?: Maybe<Option_Poll_Aggregate_Order_By>;
  polls_aggregate?: Maybe<Poll_Aggregate_Order_By>;
  post_reactions_aggregate?: Maybe<Post_Reactions_Aggregate_Order_By>;
  title?: Maybe<Order_By>;
  topic?: Maybe<Post_Topics_Order_By>;
  topic_id?: Maybe<Order_By>;
  type?: Maybe<Post_Types_Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "posts" */
export type Posts_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "posts" */
export enum Posts_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "posts" */
export type Posts_Set_Input = {
  author_id?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  topic_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Posts_Stddev_Fields = {
  __typename?: 'posts_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "posts" */
export type Posts_Stddev_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Posts_Stddev_Pop_Fields = {
  __typename?: 'posts_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "posts" */
export type Posts_Stddev_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Posts_Stddev_Samp_Fields = {
  __typename?: 'posts_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "posts" */
export type Posts_Stddev_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Posts_Sum_Fields = {
  __typename?: 'posts_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  topic_id?: Maybe<Scalars['Int']>;
  type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "posts" */
export type Posts_Sum_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** update columns of table "posts" */
export enum Posts_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  Title = 'title',
  /** column name */
  TopicId = 'topic_id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Posts_Var_Pop_Fields = {
  __typename?: 'posts_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "posts" */
export type Posts_Var_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Posts_Var_Samp_Fields = {
  __typename?: 'posts_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "posts" */
export type Posts_Var_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Posts_Variance_Fields = {
  __typename?: 'posts_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  topic_id?: Maybe<Scalars['Float']>;
  type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "posts" */
export type Posts_Variance_Order_By = {
  author_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  topic_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
};

/** columns and relationships of "proposal_tracker" */
export type Proposal_Tracker = {
  __typename?: 'proposal_tracker';
  created_at: Scalars['timestamptz'];
  deadline: Scalars['timestamptz'];
  id: Scalars['Int'];
  network?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['Int'];
};

/** aggregated selection of "proposal_tracker" */
export type Proposal_Tracker_Aggregate = {
  __typename?: 'proposal_tracker_aggregate';
  aggregate?: Maybe<Proposal_Tracker_Aggregate_Fields>;
  nodes: Array<Proposal_Tracker>;
};

/** aggregate fields of "proposal_tracker" */
export type Proposal_Tracker_Aggregate_Fields = {
  __typename?: 'proposal_tracker_aggregate_fields';
  avg?: Maybe<Proposal_Tracker_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Proposal_Tracker_Max_Fields>;
  min?: Maybe<Proposal_Tracker_Min_Fields>;
  stddev?: Maybe<Proposal_Tracker_Stddev_Fields>;
  stddev_pop?: Maybe<Proposal_Tracker_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Proposal_Tracker_Stddev_Samp_Fields>;
  sum?: Maybe<Proposal_Tracker_Sum_Fields>;
  var_pop?: Maybe<Proposal_Tracker_Var_Pop_Fields>;
  var_samp?: Maybe<Proposal_Tracker_Var_Samp_Fields>;
  variance?: Maybe<Proposal_Tracker_Variance_Fields>;
};


/** aggregate fields of "proposal_tracker" */
export type Proposal_Tracker_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Proposal_Tracker_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "proposal_tracker" */
export type Proposal_Tracker_Aggregate_Order_By = {
  avg?: Maybe<Proposal_Tracker_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Proposal_Tracker_Max_Order_By>;
  min?: Maybe<Proposal_Tracker_Min_Order_By>;
  stddev?: Maybe<Proposal_Tracker_Stddev_Order_By>;
  stddev_pop?: Maybe<Proposal_Tracker_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Proposal_Tracker_Stddev_Samp_Order_By>;
  sum?: Maybe<Proposal_Tracker_Sum_Order_By>;
  var_pop?: Maybe<Proposal_Tracker_Var_Pop_Order_By>;
  var_samp?: Maybe<Proposal_Tracker_Var_Samp_Order_By>;
  variance?: Maybe<Proposal_Tracker_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "proposal_tracker" */
export type Proposal_Tracker_Arr_Rel_Insert_Input = {
  data: Array<Proposal_Tracker_Insert_Input>;
  on_conflict?: Maybe<Proposal_Tracker_On_Conflict>;
};

/** aggregate avg on columns */
export type Proposal_Tracker_Avg_Fields = {
  __typename?: 'proposal_tracker_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Avg_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "proposal_tracker". All fields are combined with a logical 'AND'. */
export type Proposal_Tracker_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Proposal_Tracker_Bool_Exp>>>;
  _not?: Maybe<Proposal_Tracker_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Proposal_Tracker_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deadline?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  onchain_network_proposal_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "proposal_tracker" */
export enum Proposal_Tracker_Constraint {
  /** unique or primary key constraint */
  ProposalTrackerPkey = 'proposal_tracker_pkey'
}

/** input type for incrementing integer column in table "proposal_tracker" */
export type Proposal_Tracker_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "proposal_tracker" */
export type Proposal_Tracker_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Proposal_Tracker_Max_Fields = {
  __typename?: 'proposal_tracker_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deadline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  onchain_network_proposal_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Proposal_Tracker_Min_Fields = {
  __typename?: 'proposal_tracker_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deadline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  onchain_network_proposal_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "proposal_tracker" */
export type Proposal_Tracker_Mutation_Response = {
  __typename?: 'proposal_tracker_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Proposal_Tracker>;
};

/** input type for inserting object relation for remote table "proposal_tracker" */
export type Proposal_Tracker_Obj_Rel_Insert_Input = {
  data: Proposal_Tracker_Insert_Input;
  on_conflict?: Maybe<Proposal_Tracker_On_Conflict>;
};

/** on conflict condition type for table "proposal_tracker" */
export type Proposal_Tracker_On_Conflict = {
  constraint: Proposal_Tracker_Constraint;
  update_columns: Array<Proposal_Tracker_Update_Column>;
  where?: Maybe<Proposal_Tracker_Bool_Exp>;
};

/** ordering options when selecting data from "proposal_tracker" */
export type Proposal_Tracker_Order_By = {
  created_at?: Maybe<Order_By>;
  deadline?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  onchain_network_proposal_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "proposal_tracker" */
export type Proposal_Tracker_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "proposal_tracker" */
export enum Proposal_Tracker_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  OnchainNetworkProposalId = 'onchain_network_proposal_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "proposal_tracker" */
export type Proposal_Tracker_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deadline?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  onchain_network_proposal_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Proposal_Tracker_Stddev_Fields = {
  __typename?: 'proposal_tracker_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Proposal_Tracker_Stddev_Pop_Fields = {
  __typename?: 'proposal_tracker_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Proposal_Tracker_Stddev_Samp_Fields = {
  __typename?: 'proposal_tracker_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Proposal_Tracker_Sum_Fields = {
  __typename?: 'proposal_tracker_sum_fields';
  id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Sum_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "proposal_tracker" */
export enum Proposal_Tracker_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  OnchainNetworkProposalId = 'onchain_network_proposal_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Proposal_Tracker_Var_Pop_Fields = {
  __typename?: 'proposal_tracker_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Proposal_Tracker_Var_Samp_Fields = {
  __typename?: 'proposal_tracker_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Proposal_Tracker_Variance_Fields = {
  __typename?: 'proposal_tracker_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "proposal_tracker" */
export type Proposal_Tracker_Variance_Order_By = {
  id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  about?: Maybe<About>;
  blockIndex?: Maybe<BlockIndex>;
  blockIndexes: Array<Maybe<BlockIndex>>;
  blockIndexesConnection: BlockIndexConnection;
  blockNumber?: Maybe<BlockNumber>;
  blockNumbers: Array<Maybe<BlockNumber>>;
  blockNumbersConnection: BlockNumberConnection;
  /** fetch data from the table: "blockchain_endpoints" */
  blockchain_endpoints: Array<Blockchain_Endpoints>;
  /** fetch aggregated fields from the table: "blockchain_endpoints" */
  blockchain_endpoints_aggregate: Blockchain_Endpoints_Aggregate;
  /** fetch data from the table: "blockchain_endpoints" using primary key columns */
  blockchain_endpoints_by_pk?: Maybe<Blockchain_Endpoints>;
  /** fetch data from the table: "blockchain_socials" */
  blockchain_socials: Array<Blockchain_Socials>;
  /** fetch aggregated fields from the table: "blockchain_socials" */
  blockchain_socials_aggregate: Blockchain_Socials_Aggregate;
  /** fetch data from the table: "blockchain_socials" using primary key columns */
  blockchain_socials_by_pk?: Maybe<Blockchain_Socials>;
  bounties: Array<Maybe<Bounty>>;
  bountiesConnection: BountyConnection;
  bounty?: Maybe<Bounty>;
  bountyStatus?: Maybe<BountyStatus>;
  bountyStatuses: Array<Maybe<BountyStatus>>;
  bountyStatusesConnection: BountyStatusConnection;
  /** fetch data from the table: "calender_events" */
  calender_events: Array<Calender_Events>;
  /** fetch aggregated fields from the table: "calender_events" */
  calender_events_aggregate: Calender_Events_Aggregate;
  /** fetch data from the table: "calender_events" using primary key columns */
  calender_events_by_pk?: Maybe<Calender_Events>;
  childBounties: Array<Maybe<ChildBounty>>;
  childBountiesConnection: ChildBountyConnection;
  childBounty?: Maybe<ChildBounty>;
  childBountyStatus?: Maybe<ChildBountyStatus>;
  childBountyStatuses: Array<Maybe<ChildBountyStatus>>;
  childBountyStatusesConnection: ChildBountyStatusConnection;
  /** fetch data from the table: "comment_reactions" */
  comment_reactions: Array<Comment_Reactions>;
  /** fetch aggregated fields from the table: "comment_reactions" */
  comment_reactions_aggregate: Comment_Reactions_Aggregate;
  /** fetch data from the table: "comment_reactions" using primary key columns */
  comment_reactions_by_pk?: Maybe<Comment_Reactions>;
  /** fetch data from the table: "comments" */
  comments: Array<Comments>;
  /** fetch aggregated fields from the table: "comments" */
  comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  comments_by_pk?: Maybe<Comments>;
  council?: Maybe<Council>;
  councilMember?: Maybe<CouncilMember>;
  councilMembers: Array<Maybe<CouncilMember>>;
  councilMembersConnection: CouncilMemberConnection;
  councils: Array<Maybe<Council>>;
  councilsConnection: CouncilConnection;
  motion?: Maybe<Motion>;
  motionProposalArgument?: Maybe<MotionProposalArgument>;
  motionProposalArguments: Array<Maybe<MotionProposalArgument>>;
  motionProposalArgumentsConnection: MotionProposalArgumentConnection;
  motionStatus?: Maybe<MotionStatus>;
  motionStatuses: Array<Maybe<MotionStatus>>;
  motionStatusesConnection: MotionStatusConnection;
  motions: Array<Maybe<Motion>>;
  motionsConnection: MotionConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** fetch data from the table: "onchain_links" */
  onchain_links: Array<Onchain_Links>;
  /** fetch aggregated fields from the table: "onchain_links" */
  onchain_links_aggregate: Onchain_Links_Aggregate;
  /** fetch data from the table: "onchain_links" using primary key columns */
  onchain_links_by_pk?: Maybe<Onchain_Links>;
  /** fetch data from the table: "onchain_post_discussion_link" */
  onchain_post_discussion_link: Array<Onchain_Post_Discussion_Link>;
  /** fetch aggregated fields from the table: "onchain_post_discussion_link" */
  onchain_post_discussion_link_aggregate: Onchain_Post_Discussion_Link_Aggregate;
  /** fetch data from the table: "onchain_post_discussion_link" using primary key columns */
  onchain_post_discussion_link_by_pk?: Maybe<Onchain_Post_Discussion_Link>;
  /** fetch data from the table: "option_poll" */
  option_poll: Array<Option_Poll>;
  /** fetch aggregated fields from the table: "option_poll" */
  option_poll_aggregate: Option_Poll_Aggregate;
  /** fetch data from the table: "option_poll" using primary key columns */
  option_poll_by_pk?: Maybe<Option_Poll>;
  /** fetch data from the table: "option_poll_votes" */
  option_poll_votes: Array<Option_Poll_Votes>;
  /** fetch aggregated fields from the table: "option_poll_votes" */
  option_poll_votes_aggregate: Option_Poll_Votes_Aggregate;
  /** fetch data from the table: "option_poll_votes" using primary key columns */
  option_poll_votes_by_pk?: Maybe<Option_Poll_Votes>;
  pip?: Maybe<Pip>;
  pipArgument?: Maybe<PipArgument>;
  pipArguments: Array<Maybe<PipArgument>>;
  pipArgumentsConnection: PipArgumentConnection;
  pipStatus?: Maybe<PipStatus>;
  pipStatuses: Array<Maybe<PipStatus>>;
  pipStatusesConnection: PipStatusConnection;
  pips: Array<Maybe<Pip>>;
  pipsConnection: PipConnection;
  /** fetch data from the table: "polkassembly_proposals" */
  polkassembly_proposals: Array<Polkassembly_Proposals>;
  /** fetch aggregated fields from the table: "polkassembly_proposals" */
  polkassembly_proposals_aggregate: Polkassembly_Proposals_Aggregate;
  /** fetch data from the table: "polkassembly_proposals" using primary key columns */
  polkassembly_proposals_by_pk?: Maybe<Polkassembly_Proposals>;
  /** fetch data from the table: "poll" */
  poll: Array<Poll>;
  /** fetch aggregated fields from the table: "poll" */
  poll_aggregate: Poll_Aggregate;
  /** fetch data from the table: "poll" using primary key columns */
  poll_by_pk?: Maybe<Poll>;
  /** fetch data from the table: "poll_votes" */
  poll_votes: Array<Poll_Votes>;
  /** fetch aggregated fields from the table: "poll_votes" */
  poll_votes_aggregate: Poll_Votes_Aggregate;
  /** fetch data from the table: "poll_votes" using primary key columns */
  poll_votes_by_pk?: Maybe<Poll_Votes>;
  /** fetch data from the table: "post_last_update" */
  post_last_update: Array<Post_Last_Update>;
  /** fetch aggregated fields from the table: "post_last_update" */
  post_last_update_aggregate: Post_Last_Update_Aggregate;
  /** fetch data from the table: "post_reactions" */
  post_reactions: Array<Post_Reactions>;
  /** fetch aggregated fields from the table: "post_reactions" */
  post_reactions_aggregate: Post_Reactions_Aggregate;
  /** fetch data from the table: "post_reactions" using primary key columns */
  post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** fetch data from the table: "post_topics" */
  post_topics: Array<Post_Topics>;
  /** fetch aggregated fields from the table: "post_topics" */
  post_topics_aggregate: Post_Topics_Aggregate;
  /** fetch data from the table: "post_topics" using primary key columns */
  post_topics_by_pk?: Maybe<Post_Topics>;
  /** fetch data from the table: "post_types" */
  post_types: Array<Post_Types>;
  /** fetch aggregated fields from the table: "post_types" */
  post_types_aggregate: Post_Types_Aggregate;
  /** fetch data from the table: "post_types" using primary key columns */
  post_types_by_pk?: Maybe<Post_Types>;
  /** fetch data from the table: "posts" */
  posts: Array<Posts>;
  /** fetch aggregated fields from the table: "posts" */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  preimage?: Maybe<Preimage>;
  preimageArgument?: Maybe<PreimageArgument>;
  preimageArguments: Array<Maybe<PreimageArgument>>;
  preimageArgumentsConnection: PreimageArgumentConnection;
  preimageStatus?: Maybe<PreimageStatus>;
  preimageStatuses: Array<Maybe<PreimageStatus>>;
  preimageStatusesConnection: PreimageStatusConnection;
  preimages: Array<Maybe<Preimage>>;
  preimagesConnection: PreimageConnection;
  profile?: Maybe<User>;
  proposal?: Maybe<Proposal>;
  proposalStatus?: Maybe<ProposalStatus>;
  proposalStatuses: Array<Maybe<ProposalStatus>>;
  proposalStatusesConnection: ProposalStatusConnection;
  /** fetch data from the table: "proposal_tracker" */
  proposal_tracker: Array<Proposal_Tracker>;
  /** fetch aggregated fields from the table: "proposal_tracker" */
  proposal_tracker_aggregate: Proposal_Tracker_Aggregate;
  /** fetch data from the table: "proposal_tracker" using primary key columns */
  proposal_tracker_by_pk?: Maybe<Proposal_Tracker>;
  proposals: Array<Maybe<Proposal>>;
  proposalsConnection: ProposalConnection;
  referendum?: Maybe<Referendum>;
  referendumStatus?: Maybe<ReferendumStatus>;
  referendumStatuses: Array<Maybe<ReferendumStatus>>;
  referendumStatusesConnection: ReferendumStatusConnection;
  referendums: Array<Maybe<Referendum>>;
  referendumsConnection: ReferendumConnection;
  /** fetch data from the table: "remark_proposal_options" */
  remark_proposal_options: Array<Remark_Proposal_Options>;
  /** fetch aggregated fields from the table: "remark_proposal_options" */
  remark_proposal_options_aggregate: Remark_Proposal_Options_Aggregate;
  /** fetch data from the table: "remark_proposal_options" using primary key columns */
  remark_proposal_options_by_pk?: Maybe<Remark_Proposal_Options>;
  /** fetch data from the table: "remark_proposal_vote" */
  remark_proposal_vote: Array<Remark_Proposal_Vote>;
  /** fetch aggregated fields from the table: "remark_proposal_vote" */
  remark_proposal_vote_aggregate: Remark_Proposal_Vote_Aggregate;
  /** fetch data from the table: "remark_proposal_vote" using primary key columns */
  remark_proposal_vote_by_pk?: Maybe<Remark_Proposal_Vote>;
  /** fetch data from the table: "remark_proposals" */
  remark_proposals: Array<Remark_Proposals>;
  /** fetch aggregated fields from the table: "remark_proposals" */
  remark_proposals_aggregate: Remark_Proposals_Aggregate;
  /** fetch data from the table: "remark_proposals" using primary key columns */
  remark_proposals_by_pk?: Maybe<Remark_Proposals>;
  /** fetch data from the table: "replies" */
  replies: Array<Replies>;
  /** fetch aggregated fields from the table: "replies" */
  replies_aggregate: Replies_Aggregate;
  /** fetch data from the table: "replies" using primary key columns */
  replies_by_pk?: Maybe<Replies>;
  subscription?: Maybe<Subscription>;
  techCommitteeProposal?: Maybe<TechCommitteeProposal>;
  techCommitteeProposalArgument?: Maybe<TechCommitteeProposalArgument>;
  techCommitteeProposalArguments: Array<Maybe<TechCommitteeProposalArgument>>;
  techCommitteeProposalArgumentsConnection: TechCommitteeProposalArgumentConnection;
  techCommitteeProposalStatus?: Maybe<TechCommitteeProposalStatus>;
  techCommitteeProposalStatuses: Array<Maybe<TechCommitteeProposalStatus>>;
  techCommitteeProposalStatusesConnection: TechCommitteeProposalStatusConnection;
  techCommitteeProposals: Array<Maybe<TechCommitteeProposal>>;
  techCommitteeProposalsConnection: TechCommitteeProposalConnection;
  tip?: Maybe<Tip>;
  tipStatus?: Maybe<TipStatus>;
  tipStatuses: Array<Maybe<TipStatus>>;
  tipStatusesConnection: TipStatusConnection;
  tips: Array<Maybe<Tip>>;
  tipsConnection: TipConnection;
  token?: Maybe<Token>;
  treasurySpendProposal?: Maybe<TreasurySpendProposal>;
  treasurySpendProposals: Array<Maybe<TreasurySpendProposal>>;
  treasurySpendProposalsConnection: TreasurySpendProposalConnection;
  treasuryStatus?: Maybe<TreasuryStatus>;
  treasuryStatuses: Array<Maybe<TreasuryStatus>>;
  treasuryStatusesConnection: TreasuryStatusConnection;
  user?: Maybe<User>;
  userDetails?: Maybe<Profile>;
  userWithUsername?: Maybe<Profile>;
};


/** query root */
export type Query_RootAboutArgs = {
  address: Scalars['String'];
  network: Scalars['String'];
};


/** query root */
export type Query_RootBlockIndexArgs = {
  where: BlockIndexWhereUniqueInput;
};


/** query root */
export type Query_RootBlockIndexesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BlockIndexOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockIndexWhereInput>;
};


/** query root */
export type Query_RootBlockIndexesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BlockIndexOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockIndexWhereInput>;
};


/** query root */
export type Query_RootBlockNumberArgs = {
  where: BlockNumberWhereUniqueInput;
};


/** query root */
export type Query_RootBlockNumbersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BlockNumberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockNumberWhereInput>;
};


/** query root */
export type Query_RootBlockNumbersConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BlockNumberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BlockNumberWhereInput>;
};


/** query root */
export type Query_RootBlockchain_EndpointsArgs = {
  distinct_on?: Maybe<Array<Blockchain_Endpoints_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Endpoints_Order_By>>;
  where?: Maybe<Blockchain_Endpoints_Bool_Exp>;
};


/** query root */
export type Query_RootBlockchain_Endpoints_AggregateArgs = {
  distinct_on?: Maybe<Array<Blockchain_Endpoints_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Endpoints_Order_By>>;
  where?: Maybe<Blockchain_Endpoints_Bool_Exp>;
};


/** query root */
export type Query_RootBlockchain_Endpoints_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootBlockchain_SocialsArgs = {
  distinct_on?: Maybe<Array<Blockchain_Socials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Socials_Order_By>>;
  where?: Maybe<Blockchain_Socials_Bool_Exp>;
};


/** query root */
export type Query_RootBlockchain_Socials_AggregateArgs = {
  distinct_on?: Maybe<Array<Blockchain_Socials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Socials_Order_By>>;
  where?: Maybe<Blockchain_Socials_Bool_Exp>;
};


/** query root */
export type Query_RootBlockchain_Socials_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootBountiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BountyWhereInput>;
};


/** query root */
export type Query_RootBountiesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BountyWhereInput>;
};


/** query root */
export type Query_RootBountyArgs = {
  where: BountyWhereUniqueInput;
};


/** query root */
export type Query_RootBountyStatusArgs = {
  where: BountyStatusWhereUniqueInput;
};


/** query root */
export type Query_RootBountyStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BountyStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BountyStatusWhereInput>;
};


/** query root */
export type Query_RootBountyStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<BountyStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<BountyStatusWhereInput>;
};


/** query root */
export type Query_RootCalender_EventsArgs = {
  distinct_on?: Maybe<Array<Calender_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calender_Events_Order_By>>;
  where?: Maybe<Calender_Events_Bool_Exp>;
};


/** query root */
export type Query_RootCalender_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Calender_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calender_Events_Order_By>>;
  where?: Maybe<Calender_Events_Bool_Exp>;
};


/** query root */
export type Query_RootCalender_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootChildBountiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChildBountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ChildBountyWhereInput>;
};


/** query root */
export type Query_RootChildBountiesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChildBountyOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ChildBountyWhereInput>;
};


/** query root */
export type Query_RootChildBountyArgs = {
  where: ChildBountyWhereUniqueInput;
};


/** query root */
export type Query_RootChildBountyStatusArgs = {
  where: ChildBountyStatusWhereUniqueInput;
};


/** query root */
export type Query_RootChildBountyStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChildBountyStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ChildBountyStatusWhereInput>;
};


/** query root */
export type Query_RootChildBountyStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ChildBountyStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ChildBountyStatusWhereInput>;
};


/** query root */
export type Query_RootComment_ReactionsArgs = {
  distinct_on?: Maybe<Array<Comment_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comment_Reactions_Order_By>>;
  where?: Maybe<Comment_Reactions_Bool_Exp>;
};


/** query root */
export type Query_RootComment_Reactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comment_Reactions_Order_By>>;
  where?: Maybe<Comment_Reactions_Bool_Exp>;
};


/** query root */
export type Query_RootComment_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootCommentsArgs = {
  distinct_on?: Maybe<Array<Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comments_Order_By>>;
  where?: Maybe<Comments_Bool_Exp>;
};


/** query root */
export type Query_RootComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comments_Order_By>>;
  where?: Maybe<Comments_Bool_Exp>;
};


/** query root */
export type Query_RootComments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootCouncilArgs = {
  where: CouncilWhereUniqueInput;
};


/** query root */
export type Query_RootCouncilMemberArgs = {
  where: CouncilMemberWhereUniqueInput;
};


/** query root */
export type Query_RootCouncilMembersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CouncilMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CouncilMemberWhereInput>;
};


/** query root */
export type Query_RootCouncilMembersConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CouncilMemberOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CouncilMemberWhereInput>;
};


/** query root */
export type Query_RootCouncilsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CouncilOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CouncilWhereInput>;
};


/** query root */
export type Query_RootCouncilsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<CouncilOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<CouncilWhereInput>;
};


/** query root */
export type Query_RootMotionArgs = {
  where: MotionWhereUniqueInput;
};


/** query root */
export type Query_RootMotionProposalArgumentArgs = {
  where: MotionProposalArgumentWhereUniqueInput;
};


/** query root */
export type Query_RootMotionProposalArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionProposalArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionProposalArgumentWhereInput>;
};


/** query root */
export type Query_RootMotionProposalArgumentsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionProposalArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionProposalArgumentWhereInput>;
};


/** query root */
export type Query_RootMotionStatusArgs = {
  where: MotionStatusWhereUniqueInput;
};


/** query root */
export type Query_RootMotionStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionStatusWhereInput>;
};


/** query root */
export type Query_RootMotionStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionStatusWhereInput>;
};


/** query root */
export type Query_RootMotionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionWhereInput>;
};


/** query root */
export type Query_RootMotionsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<MotionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<MotionWhereInput>;
};


/** query root */
export type Query_RootNodeArgs = {
  id: Scalars['ID'];
};


/** query root */
export type Query_RootOnchain_LinksArgs = {
  distinct_on?: Maybe<Array<Onchain_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Links_Order_By>>;
  where?: Maybe<Onchain_Links_Bool_Exp>;
};


/** query root */
export type Query_RootOnchain_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Onchain_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Links_Order_By>>;
  where?: Maybe<Onchain_Links_Bool_Exp>;
};


/** query root */
export type Query_RootOnchain_Links_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOnchain_Post_Discussion_LinkArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/** query root */
export type Query_RootOnchain_Post_Discussion_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/** query root */
export type Query_RootOnchain_Post_Discussion_Link_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOption_PollArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Order_By>>;
  where?: Maybe<Option_Poll_Bool_Exp>;
};


/** query root */
export type Query_RootOption_Poll_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Order_By>>;
  where?: Maybe<Option_Poll_Bool_Exp>;
};


/** query root */
export type Query_RootOption_Poll_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootOption_Poll_VotesArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Votes_Order_By>>;
  where?: Maybe<Option_Poll_Votes_Bool_Exp>;
};


/** query root */
export type Query_RootOption_Poll_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Votes_Order_By>>;
  where?: Maybe<Option_Poll_Votes_Bool_Exp>;
};


/** query root */
export type Query_RootOption_Poll_Votes_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPipArgs = {
  where: PipWhereUniqueInput;
};


/** query root */
export type Query_RootPipArgumentArgs = {
  where: PipArgumentWhereUniqueInput;
};


/** query root */
export type Query_RootPipArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipArgumentWhereInput>;
};


/** query root */
export type Query_RootPipArgumentsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipArgumentWhereInput>;
};


/** query root */
export type Query_RootPipStatusArgs = {
  where: PipStatusWhereUniqueInput;
};


/** query root */
export type Query_RootPipStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipStatusWhereInput>;
};


/** query root */
export type Query_RootPipStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipStatusWhereInput>;
};


/** query root */
export type Query_RootPipsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipWhereInput>;
};


/** query root */
export type Query_RootPipsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PipWhereInput>;
};


/** query root */
export type Query_RootPolkassembly_ProposalsArgs = {
  distinct_on?: Maybe<Array<Polkassembly_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Polkassembly_Proposals_Order_By>>;
  where?: Maybe<Polkassembly_Proposals_Bool_Exp>;
};


/** query root */
export type Query_RootPolkassembly_Proposals_AggregateArgs = {
  distinct_on?: Maybe<Array<Polkassembly_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Polkassembly_Proposals_Order_By>>;
  where?: Maybe<Polkassembly_Proposals_Bool_Exp>;
};


/** query root */
export type Query_RootPolkassembly_Proposals_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPollArgs = {
  distinct_on?: Maybe<Array<Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Order_By>>;
  where?: Maybe<Poll_Bool_Exp>;
};


/** query root */
export type Query_RootPoll_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Order_By>>;
  where?: Maybe<Poll_Bool_Exp>;
};


/** query root */
export type Query_RootPoll_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPoll_VotesArgs = {
  distinct_on?: Maybe<Array<Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Votes_Order_By>>;
  where?: Maybe<Poll_Votes_Bool_Exp>;
};


/** query root */
export type Query_RootPoll_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Votes_Order_By>>;
  where?: Maybe<Poll_Votes_Bool_Exp>;
};


/** query root */
export type Query_RootPoll_Votes_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPost_Last_UpdateArgs = {
  distinct_on?: Maybe<Array<Post_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Last_Update_Order_By>>;
  where?: Maybe<Post_Last_Update_Bool_Exp>;
};


/** query root */
export type Query_RootPost_Last_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Last_Update_Order_By>>;
  where?: Maybe<Post_Last_Update_Bool_Exp>;
};


/** query root */
export type Query_RootPost_ReactionsArgs = {
  distinct_on?: Maybe<Array<Post_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Reactions_Order_By>>;
  where?: Maybe<Post_Reactions_Bool_Exp>;
};


/** query root */
export type Query_RootPost_Reactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Reactions_Order_By>>;
  where?: Maybe<Post_Reactions_Bool_Exp>;
};


/** query root */
export type Query_RootPost_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPost_TopicsArgs = {
  distinct_on?: Maybe<Array<Post_Topics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Topics_Order_By>>;
  where?: Maybe<Post_Topics_Bool_Exp>;
};


/** query root */
export type Query_RootPost_Topics_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Topics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Topics_Order_By>>;
  where?: Maybe<Post_Topics_Bool_Exp>;
};


/** query root */
export type Query_RootPost_Topics_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPost_TypesArgs = {
  distinct_on?: Maybe<Array<Post_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Types_Order_By>>;
  where?: Maybe<Post_Types_Bool_Exp>;
};


/** query root */
export type Query_RootPost_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Types_Order_By>>;
  where?: Maybe<Post_Types_Bool_Exp>;
};


/** query root */
export type Query_RootPost_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPostsArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};


/** query root */
export type Query_RootPosts_AggregateArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};


/** query root */
export type Query_RootPosts_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPreimageArgs = {
  where: PreimageWhereUniqueInput;
};


/** query root */
export type Query_RootPreimageArgumentArgs = {
  where: PreimageArgumentWhereUniqueInput;
};


/** query root */
export type Query_RootPreimageArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageArgumentWhereInput>;
};


/** query root */
export type Query_RootPreimageArgumentsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageArgumentWhereInput>;
};


/** query root */
export type Query_RootPreimageStatusArgs = {
  where: PreimageStatusWhereUniqueInput;
};


/** query root */
export type Query_RootPreimageStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageStatusWhereInput>;
};


/** query root */
export type Query_RootPreimageStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageStatusWhereInput>;
};


/** query root */
export type Query_RootPreimagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageWhereInput>;
};


/** query root */
export type Query_RootPreimagesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<PreimageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<PreimageWhereInput>;
};


/** query root */
export type Query_RootProfileArgs = {
  username: Scalars['String'];
};


/** query root */
export type Query_RootProposalArgs = {
  where: ProposalWhereUniqueInput;
};


/** query root */
export type Query_RootProposalStatusArgs = {
  where: ProposalStatusWhereUniqueInput;
};


/** query root */
export type Query_RootProposalStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProposalStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ProposalStatusWhereInput>;
};


/** query root */
export type Query_RootProposalStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProposalStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ProposalStatusWhereInput>;
};


/** query root */
export type Query_RootProposal_TrackerArgs = {
  distinct_on?: Maybe<Array<Proposal_Tracker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Proposal_Tracker_Order_By>>;
  where?: Maybe<Proposal_Tracker_Bool_Exp>;
};


/** query root */
export type Query_RootProposal_Tracker_AggregateArgs = {
  distinct_on?: Maybe<Array<Proposal_Tracker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Proposal_Tracker_Order_By>>;
  where?: Maybe<Proposal_Tracker_Bool_Exp>;
};


/** query root */
export type Query_RootProposal_Tracker_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootProposalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ProposalWhereInput>;
};


/** query root */
export type Query_RootProposalsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ProposalWhereInput>;
};


/** query root */
export type Query_RootReferendumArgs = {
  where: ReferendumWhereUniqueInput;
};


/** query root */
export type Query_RootReferendumStatusArgs = {
  where: ReferendumStatusWhereUniqueInput;
};


/** query root */
export type Query_RootReferendumStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferendumStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ReferendumStatusWhereInput>;
};


/** query root */
export type Query_RootReferendumStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferendumStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ReferendumStatusWhereInput>;
};


/** query root */
export type Query_RootReferendumsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferendumOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ReferendumWhereInput>;
};


/** query root */
export type Query_RootReferendumsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<ReferendumOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<ReferendumWhereInput>;
};


/** query root */
export type Query_RootRemark_Proposal_OptionsArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Options_Order_By>>;
  where?: Maybe<Remark_Proposal_Options_Bool_Exp>;
};


/** query root */
export type Query_RootRemark_Proposal_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Options_Order_By>>;
  where?: Maybe<Remark_Proposal_Options_Bool_Exp>;
};


/** query root */
export type Query_RootRemark_Proposal_Options_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootRemark_Proposal_VoteArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};


/** query root */
export type Query_RootRemark_Proposal_Vote_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};


/** query root */
export type Query_RootRemark_Proposal_Vote_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootRemark_ProposalsArgs = {
  distinct_on?: Maybe<Array<Remark_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposals_Order_By>>;
  where?: Maybe<Remark_Proposals_Bool_Exp>;
};


/** query root */
export type Query_RootRemark_Proposals_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposals_Order_By>>;
  where?: Maybe<Remark_Proposals_Bool_Exp>;
};


/** query root */
export type Query_RootRemark_Proposals_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootRepliesArgs = {
  distinct_on?: Maybe<Array<Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Replies_Order_By>>;
  where?: Maybe<Replies_Bool_Exp>;
};


/** query root */
export type Query_RootReplies_AggregateArgs = {
  distinct_on?: Maybe<Array<Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Replies_Order_By>>;
  where?: Maybe<Replies_Bool_Exp>;
};


/** query root */
export type Query_RootReplies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootSubscriptionArgs = {
  post_id: Scalars['Int'];
};


/** query root */
export type Query_RootTechCommitteeProposalArgs = {
  where: TechCommitteeProposalWhereUniqueInput;
};


/** query root */
export type Query_RootTechCommitteeProposalArgumentArgs = {
  where: TechCommitteeProposalArgumentWhereUniqueInput;
};


/** query root */
export type Query_RootTechCommitteeProposalArgumentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalArgumentWhereInput>;
};


/** query root */
export type Query_RootTechCommitteeProposalArgumentsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalArgumentOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalArgumentWhereInput>;
};


/** query root */
export type Query_RootTechCommitteeProposalStatusArgs = {
  where: TechCommitteeProposalStatusWhereUniqueInput;
};


/** query root */
export type Query_RootTechCommitteeProposalStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalStatusWhereInput>;
};


/** query root */
export type Query_RootTechCommitteeProposalStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalStatusWhereInput>;
};


/** query root */
export type Query_RootTechCommitteeProposalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalWhereInput>;
};


/** query root */
export type Query_RootTechCommitteeProposalsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TechCommitteeProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TechCommitteeProposalWhereInput>;
};


/** query root */
export type Query_RootTipArgs = {
  where: TipWhereUniqueInput;
};


/** query root */
export type Query_RootTipStatusArgs = {
  where: TipStatusWhereUniqueInput;
};


/** query root */
export type Query_RootTipStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TipStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TipStatusWhereInput>;
};


/** query root */
export type Query_RootTipStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TipStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TipStatusWhereInput>;
};


/** query root */
export type Query_RootTipsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TipWhereInput>;
};


/** query root */
export type Query_RootTipsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TipOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TipWhereInput>;
};


/** query root */
export type Query_RootTreasurySpendProposalArgs = {
  where: TreasurySpendProposalWhereUniqueInput;
};


/** query root */
export type Query_RootTreasurySpendProposalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TreasurySpendProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TreasurySpendProposalWhereInput>;
};


/** query root */
export type Query_RootTreasurySpendProposalsConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TreasurySpendProposalOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TreasurySpendProposalWhereInput>;
};


/** query root */
export type Query_RootTreasuryStatusArgs = {
  where: TreasuryStatusWhereUniqueInput;
};


/** query root */
export type Query_RootTreasuryStatusesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TreasuryStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TreasuryStatusWhereInput>;
};


/** query root */
export type Query_RootTreasuryStatusesConnectionArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<TreasuryStatusOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  where?: Maybe<TreasuryStatusWhereInput>;
};


/** query root */
export type Query_RootUserArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUserDetailsArgs = {
  user_id: Scalars['Int'];
};


/** query root */
export type Query_RootUserWithUsernameArgs = {
  username: Scalars['String'];
};

/** columns and relationships of "remark_proposal_options" */
export type Remark_Proposal_Options = {
  __typename?: 'remark_proposal_options';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  option: Scalars['String'];
  proposal_id: Scalars['Int'];
  /** An object relationship */
  remark_proposal: Remark_Proposals;
  /** An array relationship */
  remark_proposal_votes: Array<Remark_Proposal_Vote>;
  /** An aggregated array relationship */
  remark_proposal_votes_aggregate: Remark_Proposal_Vote_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "remark_proposal_options" */
export type Remark_Proposal_OptionsRemark_Proposal_VotesArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};


/** columns and relationships of "remark_proposal_options" */
export type Remark_Proposal_OptionsRemark_Proposal_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};

/** aggregated selection of "remark_proposal_options" */
export type Remark_Proposal_Options_Aggregate = {
  __typename?: 'remark_proposal_options_aggregate';
  aggregate?: Maybe<Remark_Proposal_Options_Aggregate_Fields>;
  nodes: Array<Remark_Proposal_Options>;
};

/** aggregate fields of "remark_proposal_options" */
export type Remark_Proposal_Options_Aggregate_Fields = {
  __typename?: 'remark_proposal_options_aggregate_fields';
  avg?: Maybe<Remark_Proposal_Options_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Remark_Proposal_Options_Max_Fields>;
  min?: Maybe<Remark_Proposal_Options_Min_Fields>;
  stddev?: Maybe<Remark_Proposal_Options_Stddev_Fields>;
  stddev_pop?: Maybe<Remark_Proposal_Options_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Remark_Proposal_Options_Stddev_Samp_Fields>;
  sum?: Maybe<Remark_Proposal_Options_Sum_Fields>;
  var_pop?: Maybe<Remark_Proposal_Options_Var_Pop_Fields>;
  var_samp?: Maybe<Remark_Proposal_Options_Var_Samp_Fields>;
  variance?: Maybe<Remark_Proposal_Options_Variance_Fields>;
};


/** aggregate fields of "remark_proposal_options" */
export type Remark_Proposal_Options_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Remark_Proposal_Options_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "remark_proposal_options" */
export type Remark_Proposal_Options_Aggregate_Order_By = {
  avg?: Maybe<Remark_Proposal_Options_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Remark_Proposal_Options_Max_Order_By>;
  min?: Maybe<Remark_Proposal_Options_Min_Order_By>;
  stddev?: Maybe<Remark_Proposal_Options_Stddev_Order_By>;
  stddev_pop?: Maybe<Remark_Proposal_Options_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Remark_Proposal_Options_Stddev_Samp_Order_By>;
  sum?: Maybe<Remark_Proposal_Options_Sum_Order_By>;
  var_pop?: Maybe<Remark_Proposal_Options_Var_Pop_Order_By>;
  var_samp?: Maybe<Remark_Proposal_Options_Var_Samp_Order_By>;
  variance?: Maybe<Remark_Proposal_Options_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "remark_proposal_options" */
export type Remark_Proposal_Options_Arr_Rel_Insert_Input = {
  data: Array<Remark_Proposal_Options_Insert_Input>;
  on_conflict?: Maybe<Remark_Proposal_Options_On_Conflict>;
};

/** aggregate avg on columns */
export type Remark_Proposal_Options_Avg_Fields = {
  __typename?: 'remark_proposal_options_avg_fields';
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Avg_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "remark_proposal_options". All fields are combined with a logical 'AND'. */
export type Remark_Proposal_Options_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Remark_Proposal_Options_Bool_Exp>>>;
  _not?: Maybe<Remark_Proposal_Options_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Remark_Proposal_Options_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  option?: Maybe<String_Comparison_Exp>;
  proposal_id?: Maybe<Int_Comparison_Exp>;
  remark_proposal?: Maybe<Remark_Proposals_Bool_Exp>;
  remark_proposal_votes?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "remark_proposal_options" */
export enum Remark_Proposal_Options_Constraint {
  /** unique or primary key constraint */
  RemarkProposalOptionsPkey = 'remark_proposal_options_pkey'
}

/** input type for incrementing integer column in table "remark_proposal_options" */
export type Remark_Proposal_Options_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  proposal_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "remark_proposal_options" */
export type Remark_Proposal_Options_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  remark_proposal?: Maybe<Remark_Proposals_Obj_Rel_Insert_Input>;
  remark_proposal_votes?: Maybe<Remark_Proposal_Vote_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Remark_Proposal_Options_Max_Fields = {
  __typename?: 'remark_proposal_options_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Remark_Proposal_Options_Min_Fields = {
  __typename?: 'remark_proposal_options_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "remark_proposal_options" */
export type Remark_Proposal_Options_Mutation_Response = {
  __typename?: 'remark_proposal_options_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Remark_Proposal_Options>;
};

/** input type for inserting object relation for remote table "remark_proposal_options" */
export type Remark_Proposal_Options_Obj_Rel_Insert_Input = {
  data: Remark_Proposal_Options_Insert_Input;
  on_conflict?: Maybe<Remark_Proposal_Options_On_Conflict>;
};

/** on conflict condition type for table "remark_proposal_options" */
export type Remark_Proposal_Options_On_Conflict = {
  constraint: Remark_Proposal_Options_Constraint;
  update_columns: Array<Remark_Proposal_Options_Update_Column>;
  where?: Maybe<Remark_Proposal_Options_Bool_Exp>;
};

/** ordering options when selecting data from "remark_proposal_options" */
export type Remark_Proposal_Options_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  remark_proposal?: Maybe<Remark_Proposals_Order_By>;
  remark_proposal_votes_aggregate?: Maybe<Remark_Proposal_Vote_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "remark_proposal_options" */
export type Remark_Proposal_Options_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "remark_proposal_options" */
export enum Remark_Proposal_Options_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  ProposalId = 'proposal_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "remark_proposal_options" */
export type Remark_Proposal_Options_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Remark_Proposal_Options_Stddev_Fields = {
  __typename?: 'remark_proposal_options_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Remark_Proposal_Options_Stddev_Pop_Fields = {
  __typename?: 'remark_proposal_options_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Remark_Proposal_Options_Stddev_Samp_Fields = {
  __typename?: 'remark_proposal_options_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Remark_Proposal_Options_Sum_Fields = {
  __typename?: 'remark_proposal_options_sum_fields';
  id?: Maybe<Scalars['Int']>;
  proposal_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Sum_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** update columns of table "remark_proposal_options" */
export enum Remark_Proposal_Options_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  ProposalId = 'proposal_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Remark_Proposal_Options_Var_Pop_Fields = {
  __typename?: 'remark_proposal_options_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Remark_Proposal_Options_Var_Samp_Fields = {
  __typename?: 'remark_proposal_options_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Remark_Proposal_Options_Variance_Fields = {
  __typename?: 'remark_proposal_options_variance_fields';
  id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "remark_proposal_options" */
export type Remark_Proposal_Options_Variance_Order_By = {
  id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
};

/** columns and relationships of "remark_proposal_vote" */
export type Remark_Proposal_Vote = {
  __typename?: 'remark_proposal_vote';
  address: Scalars['String'];
  /** Remote relationship field */
  author?: Maybe<User>;
  block_number: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  option: Scalars['String'];
  option_id: Scalars['Int'];
  power: Scalars['String'];
  proposal_id: Scalars['Int'];
  /** An object relationship */
  remark_proposal: Remark_Proposals;
  /** An object relationship */
  remark_proposal_option: Remark_Proposal_Options;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['Int'];
};

/** aggregated selection of "remark_proposal_vote" */
export type Remark_Proposal_Vote_Aggregate = {
  __typename?: 'remark_proposal_vote_aggregate';
  aggregate?: Maybe<Remark_Proposal_Vote_Aggregate_Fields>;
  nodes: Array<Remark_Proposal_Vote>;
};

/** aggregate fields of "remark_proposal_vote" */
export type Remark_Proposal_Vote_Aggregate_Fields = {
  __typename?: 'remark_proposal_vote_aggregate_fields';
  avg?: Maybe<Remark_Proposal_Vote_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Remark_Proposal_Vote_Max_Fields>;
  min?: Maybe<Remark_Proposal_Vote_Min_Fields>;
  stddev?: Maybe<Remark_Proposal_Vote_Stddev_Fields>;
  stddev_pop?: Maybe<Remark_Proposal_Vote_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Remark_Proposal_Vote_Stddev_Samp_Fields>;
  sum?: Maybe<Remark_Proposal_Vote_Sum_Fields>;
  var_pop?: Maybe<Remark_Proposal_Vote_Var_Pop_Fields>;
  var_samp?: Maybe<Remark_Proposal_Vote_Var_Samp_Fields>;
  variance?: Maybe<Remark_Proposal_Vote_Variance_Fields>;
};


/** aggregate fields of "remark_proposal_vote" */
export type Remark_Proposal_Vote_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Aggregate_Order_By = {
  avg?: Maybe<Remark_Proposal_Vote_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Remark_Proposal_Vote_Max_Order_By>;
  min?: Maybe<Remark_Proposal_Vote_Min_Order_By>;
  stddev?: Maybe<Remark_Proposal_Vote_Stddev_Order_By>;
  stddev_pop?: Maybe<Remark_Proposal_Vote_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Remark_Proposal_Vote_Stddev_Samp_Order_By>;
  sum?: Maybe<Remark_Proposal_Vote_Sum_Order_By>;
  var_pop?: Maybe<Remark_Proposal_Vote_Var_Pop_Order_By>;
  var_samp?: Maybe<Remark_Proposal_Vote_Var_Samp_Order_By>;
  variance?: Maybe<Remark_Proposal_Vote_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Arr_Rel_Insert_Input = {
  data: Array<Remark_Proposal_Vote_Insert_Input>;
  on_conflict?: Maybe<Remark_Proposal_Vote_On_Conflict>;
};

/** aggregate avg on columns */
export type Remark_Proposal_Vote_Avg_Fields = {
  __typename?: 'remark_proposal_vote_avg_fields';
  block_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  option_id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Avg_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "remark_proposal_vote". All fields are combined with a logical 'AND'. */
export type Remark_Proposal_Vote_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Remark_Proposal_Vote_Bool_Exp>>>;
  _not?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Remark_Proposal_Vote_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  block_number?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  option?: Maybe<String_Comparison_Exp>;
  option_id?: Maybe<Int_Comparison_Exp>;
  power?: Maybe<String_Comparison_Exp>;
  proposal_id?: Maybe<Int_Comparison_Exp>;
  remark_proposal?: Maybe<Remark_Proposals_Bool_Exp>;
  remark_proposal_option?: Maybe<Remark_Proposal_Options_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "remark_proposal_vote" */
export enum Remark_Proposal_Vote_Constraint {
  /** unique or primary key constraint */
  RemarkProposalVotePkey = 'remark_proposal_vote_pkey'
}

/** input type for incrementing integer column in table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Inc_Input = {
  block_number?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  option_id?: Maybe<Scalars['Int']>;
  proposal_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['Int']>;
  power?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  remark_proposal?: Maybe<Remark_Proposals_Obj_Rel_Insert_Input>;
  remark_proposal_option?: Maybe<Remark_Proposal_Options_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Remark_Proposal_Vote_Max_Fields = {
  __typename?: 'remark_proposal_vote_max_fields';
  address?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['Int']>;
  power?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Max_Order_By = {
  address?: Maybe<Order_By>;
  block_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  power?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Remark_Proposal_Vote_Min_Fields = {
  __typename?: 'remark_proposal_vote_min_fields';
  address?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['Int']>;
  power?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Min_Order_By = {
  address?: Maybe<Order_By>;
  block_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  power?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Mutation_Response = {
  __typename?: 'remark_proposal_vote_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Remark_Proposal_Vote>;
};

/** input type for inserting object relation for remote table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Obj_Rel_Insert_Input = {
  data: Remark_Proposal_Vote_Insert_Input;
  on_conflict?: Maybe<Remark_Proposal_Vote_On_Conflict>;
};

/** on conflict condition type for table "remark_proposal_vote" */
export type Remark_Proposal_Vote_On_Conflict = {
  constraint: Remark_Proposal_Vote_Constraint;
  update_columns: Array<Remark_Proposal_Vote_Update_Column>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};

/** ordering options when selecting data from "remark_proposal_vote" */
export type Remark_Proposal_Vote_Order_By = {
  address?: Maybe<Order_By>;
  block_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  power?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  remark_proposal?: Maybe<Remark_Proposals_Order_By>;
  remark_proposal_option?: Maybe<Remark_Proposal_Options_Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "remark_proposal_vote" */
export type Remark_Proposal_Vote_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "remark_proposal_vote" */
export enum Remark_Proposal_Vote_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BlockNumber = 'block_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  Power = 'power',
  /** column name */
  ProposalId = 'proposal_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Set_Input = {
  address?: Maybe<Scalars['String']>;
  block_number?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  option?: Maybe<Scalars['String']>;
  option_id?: Maybe<Scalars['Int']>;
  power?: Maybe<Scalars['String']>;
  proposal_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Remark_Proposal_Vote_Stddev_Fields = {
  __typename?: 'remark_proposal_vote_stddev_fields';
  block_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  option_id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Stddev_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Remark_Proposal_Vote_Stddev_Pop_Fields = {
  __typename?: 'remark_proposal_vote_stddev_pop_fields';
  block_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  option_id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Stddev_Pop_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Remark_Proposal_Vote_Stddev_Samp_Fields = {
  __typename?: 'remark_proposal_vote_stddev_samp_fields';
  block_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  option_id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Stddev_Samp_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Remark_Proposal_Vote_Sum_Fields = {
  __typename?: 'remark_proposal_vote_sum_fields';
  block_number?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  option_id?: Maybe<Scalars['Int']>;
  proposal_id?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Sum_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "remark_proposal_vote" */
export enum Remark_Proposal_Vote_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  BlockNumber = 'block_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  OptionId = 'option_id',
  /** column name */
  Power = 'power',
  /** column name */
  ProposalId = 'proposal_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Remark_Proposal_Vote_Var_Pop_Fields = {
  __typename?: 'remark_proposal_vote_var_pop_fields';
  block_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  option_id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Var_Pop_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Remark_Proposal_Vote_Var_Samp_Fields = {
  __typename?: 'remark_proposal_vote_var_samp_fields';
  block_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  option_id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Var_Samp_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Remark_Proposal_Vote_Variance_Fields = {
  __typename?: 'remark_proposal_vote_variance_fields';
  block_number?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  option_id?: Maybe<Scalars['Float']>;
  proposal_id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "remark_proposal_vote" */
export type Remark_Proposal_Vote_Variance_Order_By = {
  block_number?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  option_id?: Maybe<Order_By>;
  proposal_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "remark_proposals" */
export type Remark_Proposals = {
  __typename?: 'remark_proposals';
  address: Scalars['String'];
  /** Remote relationship field */
  author?: Maybe<User>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  end: Scalars['Int'];
  id: Scalars['Int'];
  network: Scalars['String'];
  /** An array relationship */
  remark_proposal_options: Array<Remark_Proposal_Options>;
  /** An aggregated array relationship */
  remark_proposal_options_aggregate: Remark_Proposal_Options_Aggregate;
  /** An array relationship */
  remark_proposal_votes: Array<Remark_Proposal_Vote>;
  /** An aggregated array relationship */
  remark_proposal_votes_aggregate: Remark_Proposal_Vote_Aggregate;
  start: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['Int'];
};


/** columns and relationships of "remark_proposals" */
export type Remark_ProposalsRemark_Proposal_OptionsArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Options_Order_By>>;
  where?: Maybe<Remark_Proposal_Options_Bool_Exp>;
};


/** columns and relationships of "remark_proposals" */
export type Remark_ProposalsRemark_Proposal_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Options_Order_By>>;
  where?: Maybe<Remark_Proposal_Options_Bool_Exp>;
};


/** columns and relationships of "remark_proposals" */
export type Remark_ProposalsRemark_Proposal_VotesArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};


/** columns and relationships of "remark_proposals" */
export type Remark_ProposalsRemark_Proposal_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};

/** aggregated selection of "remark_proposals" */
export type Remark_Proposals_Aggregate = {
  __typename?: 'remark_proposals_aggregate';
  aggregate?: Maybe<Remark_Proposals_Aggregate_Fields>;
  nodes: Array<Remark_Proposals>;
};

/** aggregate fields of "remark_proposals" */
export type Remark_Proposals_Aggregate_Fields = {
  __typename?: 'remark_proposals_aggregate_fields';
  avg?: Maybe<Remark_Proposals_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Remark_Proposals_Max_Fields>;
  min?: Maybe<Remark_Proposals_Min_Fields>;
  stddev?: Maybe<Remark_Proposals_Stddev_Fields>;
  stddev_pop?: Maybe<Remark_Proposals_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Remark_Proposals_Stddev_Samp_Fields>;
  sum?: Maybe<Remark_Proposals_Sum_Fields>;
  var_pop?: Maybe<Remark_Proposals_Var_Pop_Fields>;
  var_samp?: Maybe<Remark_Proposals_Var_Samp_Fields>;
  variance?: Maybe<Remark_Proposals_Variance_Fields>;
};


/** aggregate fields of "remark_proposals" */
export type Remark_Proposals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Remark_Proposals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "remark_proposals" */
export type Remark_Proposals_Aggregate_Order_By = {
  avg?: Maybe<Remark_Proposals_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Remark_Proposals_Max_Order_By>;
  min?: Maybe<Remark_Proposals_Min_Order_By>;
  stddev?: Maybe<Remark_Proposals_Stddev_Order_By>;
  stddev_pop?: Maybe<Remark_Proposals_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Remark_Proposals_Stddev_Samp_Order_By>;
  sum?: Maybe<Remark_Proposals_Sum_Order_By>;
  var_pop?: Maybe<Remark_Proposals_Var_Pop_Order_By>;
  var_samp?: Maybe<Remark_Proposals_Var_Samp_Order_By>;
  variance?: Maybe<Remark_Proposals_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "remark_proposals" */
export type Remark_Proposals_Arr_Rel_Insert_Input = {
  data: Array<Remark_Proposals_Insert_Input>;
  on_conflict?: Maybe<Remark_Proposals_On_Conflict>;
};

/** aggregate avg on columns */
export type Remark_Proposals_Avg_Fields = {
  __typename?: 'remark_proposals_avg_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "remark_proposals" */
export type Remark_Proposals_Avg_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "remark_proposals". All fields are combined with a logical 'AND'. */
export type Remark_Proposals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Remark_Proposals_Bool_Exp>>>;
  _not?: Maybe<Remark_Proposals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Remark_Proposals_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  end?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  remark_proposal_options?: Maybe<Remark_Proposal_Options_Bool_Exp>;
  remark_proposal_votes?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
  start?: Maybe<Int_Comparison_Exp>;
  title?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "remark_proposals" */
export enum Remark_Proposals_Constraint {
  /** unique or primary key constraint */
  RemarkProposalsPkey = 'remark_proposals_pkey'
}

/** input type for incrementing integer column in table "remark_proposals" */
export type Remark_Proposals_Inc_Input = {
  end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "remark_proposals" */
export type Remark_Proposals_Insert_Input = {
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  remark_proposal_options?: Maybe<Remark_Proposal_Options_Arr_Rel_Insert_Input>;
  remark_proposal_votes?: Maybe<Remark_Proposal_Vote_Arr_Rel_Insert_Input>;
  start?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Remark_Proposals_Max_Fields = {
  __typename?: 'remark_proposals_max_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "remark_proposals" */
export type Remark_Proposals_Max_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Remark_Proposals_Min_Fields = {
  __typename?: 'remark_proposals_min_fields';
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "remark_proposals" */
export type Remark_Proposals_Min_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "remark_proposals" */
export type Remark_Proposals_Mutation_Response = {
  __typename?: 'remark_proposals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Remark_Proposals>;
};

/** input type for inserting object relation for remote table "remark_proposals" */
export type Remark_Proposals_Obj_Rel_Insert_Input = {
  data: Remark_Proposals_Insert_Input;
  on_conflict?: Maybe<Remark_Proposals_On_Conflict>;
};

/** on conflict condition type for table "remark_proposals" */
export type Remark_Proposals_On_Conflict = {
  constraint: Remark_Proposals_Constraint;
  update_columns: Array<Remark_Proposals_Update_Column>;
  where?: Maybe<Remark_Proposals_Bool_Exp>;
};

/** ordering options when selecting data from "remark_proposals" */
export type Remark_Proposals_Order_By = {
  address?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  remark_proposal_options_aggregate?: Maybe<Remark_Proposal_Options_Aggregate_Order_By>;
  remark_proposal_votes_aggregate?: Maybe<Remark_Proposal_Vote_Aggregate_Order_By>;
  start?: Maybe<Order_By>;
  title?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "remark_proposals" */
export type Remark_Proposals_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "remark_proposals" */
export enum Remark_Proposals_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  Start = 'start',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "remark_proposals" */
export type Remark_Proposals_Set_Input = {
  address?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  network?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Remark_Proposals_Stddev_Fields = {
  __typename?: 'remark_proposals_stddev_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "remark_proposals" */
export type Remark_Proposals_Stddev_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Remark_Proposals_Stddev_Pop_Fields = {
  __typename?: 'remark_proposals_stddev_pop_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "remark_proposals" */
export type Remark_Proposals_Stddev_Pop_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Remark_Proposals_Stddev_Samp_Fields = {
  __typename?: 'remark_proposals_stddev_samp_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "remark_proposals" */
export type Remark_Proposals_Stddev_Samp_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Remark_Proposals_Sum_Fields = {
  __typename?: 'remark_proposals_sum_fields';
  end?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "remark_proposals" */
export type Remark_Proposals_Sum_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** update columns of table "remark_proposals" */
export enum Remark_Proposals_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  End = 'end',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  Start = 'start',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Remark_Proposals_Var_Pop_Fields = {
  __typename?: 'remark_proposals_var_pop_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "remark_proposals" */
export type Remark_Proposals_Var_Pop_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Remark_Proposals_Var_Samp_Fields = {
  __typename?: 'remark_proposals_var_samp_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "remark_proposals" */
export type Remark_Proposals_Var_Samp_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Remark_Proposals_Variance_Fields = {
  __typename?: 'remark_proposals_variance_fields';
  end?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  start?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "remark_proposals" */
export type Remark_Proposals_Variance_Order_By = {
  end?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  start?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** columns and relationships of "replies" */
export type Replies = {
  __typename?: 'replies';
  /** Remote relationship field */
  author?: Maybe<User>;
  author_id: Scalars['Int'];
  /** An object relationship */
  comment: Comments;
  comment_id: Scalars['uuid'];
  content: Scalars['String'];
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  network?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "replies" */
export type Replies_Aggregate = {
  __typename?: 'replies_aggregate';
  aggregate?: Maybe<Replies_Aggregate_Fields>;
  nodes: Array<Replies>;
};

/** aggregate fields of "replies" */
export type Replies_Aggregate_Fields = {
  __typename?: 'replies_aggregate_fields';
  avg?: Maybe<Replies_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Replies_Max_Fields>;
  min?: Maybe<Replies_Min_Fields>;
  stddev?: Maybe<Replies_Stddev_Fields>;
  stddev_pop?: Maybe<Replies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Replies_Stddev_Samp_Fields>;
  sum?: Maybe<Replies_Sum_Fields>;
  var_pop?: Maybe<Replies_Var_Pop_Fields>;
  var_samp?: Maybe<Replies_Var_Samp_Fields>;
  variance?: Maybe<Replies_Variance_Fields>;
};


/** aggregate fields of "replies" */
export type Replies_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Replies_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "replies" */
export type Replies_Aggregate_Order_By = {
  avg?: Maybe<Replies_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Replies_Max_Order_By>;
  min?: Maybe<Replies_Min_Order_By>;
  stddev?: Maybe<Replies_Stddev_Order_By>;
  stddev_pop?: Maybe<Replies_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Replies_Stddev_Samp_Order_By>;
  sum?: Maybe<Replies_Sum_Order_By>;
  var_pop?: Maybe<Replies_Var_Pop_Order_By>;
  var_samp?: Maybe<Replies_Var_Samp_Order_By>;
  variance?: Maybe<Replies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "replies" */
export type Replies_Arr_Rel_Insert_Input = {
  data: Array<Replies_Insert_Input>;
  on_conflict?: Maybe<Replies_On_Conflict>;
};

/** aggregate avg on columns */
export type Replies_Avg_Fields = {
  __typename?: 'replies_avg_fields';
  author_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "replies" */
export type Replies_Avg_Order_By = {
  author_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "replies". All fields are combined with a logical 'AND'. */
export type Replies_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Replies_Bool_Exp>>>;
  _not?: Maybe<Replies_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Replies_Bool_Exp>>>;
  author_id?: Maybe<Int_Comparison_Exp>;
  comment?: Maybe<Comments_Bool_Exp>;
  comment_id?: Maybe<Uuid_Comparison_Exp>;
  content?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  network?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "replies" */
export enum Replies_Constraint {
  /** unique or primary key constraint */
  RepliesPkey = 'replies_pkey'
}

/** input type for incrementing integer column in table "replies" */
export type Replies_Inc_Input = {
  author_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "replies" */
export type Replies_Insert_Input = {
  author_id?: Maybe<Scalars['Int']>;
  comment?: Maybe<Comments_Obj_Rel_Insert_Input>;
  comment_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Replies_Max_Fields = {
  __typename?: 'replies_max_fields';
  author_id?: Maybe<Scalars['Int']>;
  comment_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "replies" */
export type Replies_Max_Order_By = {
  author_id?: Maybe<Order_By>;
  comment_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Replies_Min_Fields = {
  __typename?: 'replies_min_fields';
  author_id?: Maybe<Scalars['Int']>;
  comment_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "replies" */
export type Replies_Min_Order_By = {
  author_id?: Maybe<Order_By>;
  comment_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "replies" */
export type Replies_Mutation_Response = {
  __typename?: 'replies_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Replies>;
};

/** input type for inserting object relation for remote table "replies" */
export type Replies_Obj_Rel_Insert_Input = {
  data: Replies_Insert_Input;
  on_conflict?: Maybe<Replies_On_Conflict>;
};

/** on conflict condition type for table "replies" */
export type Replies_On_Conflict = {
  constraint: Replies_Constraint;
  update_columns: Array<Replies_Update_Column>;
  where?: Maybe<Replies_Bool_Exp>;
};

/** ordering options when selecting data from "replies" */
export type Replies_Order_By = {
  author_id?: Maybe<Order_By>;
  comment?: Maybe<Comments_Order_By>;
  comment_id?: Maybe<Order_By>;
  content?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  network?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "replies" */
export type Replies_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "replies" */
export enum Replies_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "replies" */
export type Replies_Set_Input = {
  author_id?: Maybe<Scalars['Int']>;
  comment_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  network?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Replies_Stddev_Fields = {
  __typename?: 'replies_stddev_fields';
  author_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "replies" */
export type Replies_Stddev_Order_By = {
  author_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Replies_Stddev_Pop_Fields = {
  __typename?: 'replies_stddev_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "replies" */
export type Replies_Stddev_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Replies_Stddev_Samp_Fields = {
  __typename?: 'replies_stddev_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "replies" */
export type Replies_Stddev_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Replies_Sum_Fields = {
  __typename?: 'replies_sum_fields';
  author_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "replies" */
export type Replies_Sum_Order_By = {
  author_id?: Maybe<Order_By>;
};

/** update columns of table "replies" */
export enum Replies_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Network = 'network',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Replies_Var_Pop_Fields = {
  __typename?: 'replies_var_pop_fields';
  author_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "replies" */
export type Replies_Var_Pop_Order_By = {
  author_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Replies_Var_Samp_Fields = {
  __typename?: 'replies_var_samp_fields';
  author_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "replies" */
export type Replies_Var_Samp_Order_By = {
  author_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Replies_Variance_Fields = {
  __typename?: 'replies_variance_fields';
  author_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "replies" */
export type Replies_Variance_Order_By = {
  author_id?: Maybe<Order_By>;
};


/** expression to compare columns of type smallint. All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  blockIndex?: Maybe<BlockIndexSubscriptionPayload>;
  blockNumber?: Maybe<BlockNumberSubscriptionPayload>;
  /** fetch data from the table: "blockchain_endpoints" */
  blockchain_endpoints: Array<Blockchain_Endpoints>;
  /** fetch aggregated fields from the table: "blockchain_endpoints" */
  blockchain_endpoints_aggregate: Blockchain_Endpoints_Aggregate;
  /** fetch data from the table: "blockchain_endpoints" using primary key columns */
  blockchain_endpoints_by_pk?: Maybe<Blockchain_Endpoints>;
  /** fetch data from the table: "blockchain_socials" */
  blockchain_socials: Array<Blockchain_Socials>;
  /** fetch aggregated fields from the table: "blockchain_socials" */
  blockchain_socials_aggregate: Blockchain_Socials_Aggregate;
  /** fetch data from the table: "blockchain_socials" using primary key columns */
  blockchain_socials_by_pk?: Maybe<Blockchain_Socials>;
  bounty?: Maybe<BountySubscriptionPayload>;
  bountyStatus?: Maybe<BountyStatusSubscriptionPayload>;
  /** fetch data from the table: "calender_events" */
  calender_events: Array<Calender_Events>;
  /** fetch aggregated fields from the table: "calender_events" */
  calender_events_aggregate: Calender_Events_Aggregate;
  /** fetch data from the table: "calender_events" using primary key columns */
  calender_events_by_pk?: Maybe<Calender_Events>;
  childBounty?: Maybe<ChildBountySubscriptionPayload>;
  childBountyStatus?: Maybe<ChildBountyStatusSubscriptionPayload>;
  /** fetch data from the table: "comment_reactions" */
  comment_reactions: Array<Comment_Reactions>;
  /** fetch aggregated fields from the table: "comment_reactions" */
  comment_reactions_aggregate: Comment_Reactions_Aggregate;
  /** fetch data from the table: "comment_reactions" using primary key columns */
  comment_reactions_by_pk?: Maybe<Comment_Reactions>;
  /** fetch data from the table: "comments" */
  comments: Array<Comments>;
  /** fetch aggregated fields from the table: "comments" */
  comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  comments_by_pk?: Maybe<Comments>;
  council?: Maybe<CouncilSubscriptionPayload>;
  councilMember?: Maybe<CouncilMemberSubscriptionPayload>;
  motion?: Maybe<MotionSubscriptionPayload>;
  motionProposalArgument?: Maybe<MotionProposalArgumentSubscriptionPayload>;
  motionStatus?: Maybe<MotionStatusSubscriptionPayload>;
  /** fetch data from the table: "onchain_links" */
  onchain_links: Array<Onchain_Links>;
  /** fetch aggregated fields from the table: "onchain_links" */
  onchain_links_aggregate: Onchain_Links_Aggregate;
  /** fetch data from the table: "onchain_links" using primary key columns */
  onchain_links_by_pk?: Maybe<Onchain_Links>;
  /** fetch data from the table: "onchain_post_discussion_link" */
  onchain_post_discussion_link: Array<Onchain_Post_Discussion_Link>;
  /** fetch aggregated fields from the table: "onchain_post_discussion_link" */
  onchain_post_discussion_link_aggregate: Onchain_Post_Discussion_Link_Aggregate;
  /** fetch data from the table: "onchain_post_discussion_link" using primary key columns */
  onchain_post_discussion_link_by_pk?: Maybe<Onchain_Post_Discussion_Link>;
  /** fetch data from the table: "option_poll" */
  option_poll: Array<Option_Poll>;
  /** fetch aggregated fields from the table: "option_poll" */
  option_poll_aggregate: Option_Poll_Aggregate;
  /** fetch data from the table: "option_poll" using primary key columns */
  option_poll_by_pk?: Maybe<Option_Poll>;
  /** fetch data from the table: "option_poll_votes" */
  option_poll_votes: Array<Option_Poll_Votes>;
  /** fetch aggregated fields from the table: "option_poll_votes" */
  option_poll_votes_aggregate: Option_Poll_Votes_Aggregate;
  /** fetch data from the table: "option_poll_votes" using primary key columns */
  option_poll_votes_by_pk?: Maybe<Option_Poll_Votes>;
  pip?: Maybe<PipSubscriptionPayload>;
  pipArgument?: Maybe<PipArgumentSubscriptionPayload>;
  pipStatus?: Maybe<PipStatusSubscriptionPayload>;
  /** fetch data from the table: "polkassembly_proposals" */
  polkassembly_proposals: Array<Polkassembly_Proposals>;
  /** fetch aggregated fields from the table: "polkassembly_proposals" */
  polkassembly_proposals_aggregate: Polkassembly_Proposals_Aggregate;
  /** fetch data from the table: "polkassembly_proposals" using primary key columns */
  polkassembly_proposals_by_pk?: Maybe<Polkassembly_Proposals>;
  /** fetch data from the table: "poll" */
  poll: Array<Poll>;
  /** fetch aggregated fields from the table: "poll" */
  poll_aggregate: Poll_Aggregate;
  /** fetch data from the table: "poll" using primary key columns */
  poll_by_pk?: Maybe<Poll>;
  /** fetch data from the table: "poll_votes" */
  poll_votes: Array<Poll_Votes>;
  /** fetch aggregated fields from the table: "poll_votes" */
  poll_votes_aggregate: Poll_Votes_Aggregate;
  /** fetch data from the table: "poll_votes" using primary key columns */
  poll_votes_by_pk?: Maybe<Poll_Votes>;
  /** fetch data from the table: "post_last_update" */
  post_last_update: Array<Post_Last_Update>;
  /** fetch aggregated fields from the table: "post_last_update" */
  post_last_update_aggregate: Post_Last_Update_Aggregate;
  /** fetch data from the table: "post_reactions" */
  post_reactions: Array<Post_Reactions>;
  /** fetch aggregated fields from the table: "post_reactions" */
  post_reactions_aggregate: Post_Reactions_Aggregate;
  /** fetch data from the table: "post_reactions" using primary key columns */
  post_reactions_by_pk?: Maybe<Post_Reactions>;
  /** fetch data from the table: "post_topics" */
  post_topics: Array<Post_Topics>;
  /** fetch aggregated fields from the table: "post_topics" */
  post_topics_aggregate: Post_Topics_Aggregate;
  /** fetch data from the table: "post_topics" using primary key columns */
  post_topics_by_pk?: Maybe<Post_Topics>;
  /** fetch data from the table: "post_types" */
  post_types: Array<Post_Types>;
  /** fetch aggregated fields from the table: "post_types" */
  post_types_aggregate: Post_Types_Aggregate;
  /** fetch data from the table: "post_types" using primary key columns */
  post_types_by_pk?: Maybe<Post_Types>;
  /** fetch data from the table: "posts" */
  posts: Array<Posts>;
  /** fetch aggregated fields from the table: "posts" */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  preimage?: Maybe<PreimageSubscriptionPayload>;
  preimageArgument?: Maybe<PreimageArgumentSubscriptionPayload>;
  preimageStatus?: Maybe<PreimageStatusSubscriptionPayload>;
  proposal?: Maybe<ProposalSubscriptionPayload>;
  proposalStatus?: Maybe<ProposalStatusSubscriptionPayload>;
  /** fetch data from the table: "proposal_tracker" */
  proposal_tracker: Array<Proposal_Tracker>;
  /** fetch aggregated fields from the table: "proposal_tracker" */
  proposal_tracker_aggregate: Proposal_Tracker_Aggregate;
  /** fetch data from the table: "proposal_tracker" using primary key columns */
  proposal_tracker_by_pk?: Maybe<Proposal_Tracker>;
  referendum?: Maybe<ReferendumSubscriptionPayload>;
  referendumStatus?: Maybe<ReferendumStatusSubscriptionPayload>;
  /** fetch data from the table: "remark_proposal_options" */
  remark_proposal_options: Array<Remark_Proposal_Options>;
  /** fetch aggregated fields from the table: "remark_proposal_options" */
  remark_proposal_options_aggregate: Remark_Proposal_Options_Aggregate;
  /** fetch data from the table: "remark_proposal_options" using primary key columns */
  remark_proposal_options_by_pk?: Maybe<Remark_Proposal_Options>;
  /** fetch data from the table: "remark_proposal_vote" */
  remark_proposal_vote: Array<Remark_Proposal_Vote>;
  /** fetch aggregated fields from the table: "remark_proposal_vote" */
  remark_proposal_vote_aggregate: Remark_Proposal_Vote_Aggregate;
  /** fetch data from the table: "remark_proposal_vote" using primary key columns */
  remark_proposal_vote_by_pk?: Maybe<Remark_Proposal_Vote>;
  /** fetch data from the table: "remark_proposals" */
  remark_proposals: Array<Remark_Proposals>;
  /** fetch aggregated fields from the table: "remark_proposals" */
  remark_proposals_aggregate: Remark_Proposals_Aggregate;
  /** fetch data from the table: "remark_proposals" using primary key columns */
  remark_proposals_by_pk?: Maybe<Remark_Proposals>;
  /** fetch data from the table: "replies" */
  replies: Array<Replies>;
  /** fetch aggregated fields from the table: "replies" */
  replies_aggregate: Replies_Aggregate;
  /** fetch data from the table: "replies" using primary key columns */
  replies_by_pk?: Maybe<Replies>;
  subscribed?: Maybe<Scalars['Boolean']>;
  techCommitteeProposal?: Maybe<TechCommitteeProposalSubscriptionPayload>;
  techCommitteeProposalArgument?: Maybe<TechCommitteeProposalArgumentSubscriptionPayload>;
  techCommitteeProposalStatus?: Maybe<TechCommitteeProposalStatusSubscriptionPayload>;
  tip?: Maybe<TipSubscriptionPayload>;
  tipStatus?: Maybe<TipStatusSubscriptionPayload>;
  treasurySpendProposal?: Maybe<TreasurySpendProposalSubscriptionPayload>;
  treasuryStatus?: Maybe<TreasuryStatusSubscriptionPayload>;
};


/** subscription root */
export type Subscription_RootBlockIndexArgs = {
  where?: Maybe<BlockIndexSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootBlockNumberArgs = {
  where?: Maybe<BlockNumberSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootBlockchain_EndpointsArgs = {
  distinct_on?: Maybe<Array<Blockchain_Endpoints_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Endpoints_Order_By>>;
  where?: Maybe<Blockchain_Endpoints_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlockchain_Endpoints_AggregateArgs = {
  distinct_on?: Maybe<Array<Blockchain_Endpoints_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Endpoints_Order_By>>;
  where?: Maybe<Blockchain_Endpoints_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlockchain_Endpoints_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootBlockchain_SocialsArgs = {
  distinct_on?: Maybe<Array<Blockchain_Socials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Socials_Order_By>>;
  where?: Maybe<Blockchain_Socials_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlockchain_Socials_AggregateArgs = {
  distinct_on?: Maybe<Array<Blockchain_Socials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Blockchain_Socials_Order_By>>;
  where?: Maybe<Blockchain_Socials_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBlockchain_Socials_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootBountyArgs = {
  where?: Maybe<BountySubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootBountyStatusArgs = {
  where?: Maybe<BountyStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootCalender_EventsArgs = {
  distinct_on?: Maybe<Array<Calender_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calender_Events_Order_By>>;
  where?: Maybe<Calender_Events_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalender_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Calender_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Calender_Events_Order_By>>;
  where?: Maybe<Calender_Events_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCalender_Events_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootChildBountyArgs = {
  where?: Maybe<ChildBountySubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootChildBountyStatusArgs = {
  where?: Maybe<ChildBountyStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootComment_ReactionsArgs = {
  distinct_on?: Maybe<Array<Comment_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comment_Reactions_Order_By>>;
  where?: Maybe<Comment_Reactions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComment_Reactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Comment_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comment_Reactions_Order_By>>;
  where?: Maybe<Comment_Reactions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComment_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootCommentsArgs = {
  distinct_on?: Maybe<Array<Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comments_Order_By>>;
  where?: Maybe<Comments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComments_AggregateArgs = {
  distinct_on?: Maybe<Array<Comments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Comments_Order_By>>;
  where?: Maybe<Comments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootCouncilArgs = {
  where?: Maybe<CouncilSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootCouncilMemberArgs = {
  where?: Maybe<CouncilMemberSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootMotionArgs = {
  where?: Maybe<MotionSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootMotionProposalArgumentArgs = {
  where?: Maybe<MotionProposalArgumentSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootMotionStatusArgs = {
  where?: Maybe<MotionStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootOnchain_LinksArgs = {
  distinct_on?: Maybe<Array<Onchain_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Links_Order_By>>;
  where?: Maybe<Onchain_Links_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOnchain_Links_AggregateArgs = {
  distinct_on?: Maybe<Array<Onchain_Links_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Links_Order_By>>;
  where?: Maybe<Onchain_Links_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOnchain_Links_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOnchain_Post_Discussion_LinkArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOnchain_Post_Discussion_Link_AggregateArgs = {
  distinct_on?: Maybe<Array<Onchain_Post_Discussion_Link_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Onchain_Post_Discussion_Link_Order_By>>;
  where?: Maybe<Onchain_Post_Discussion_Link_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOnchain_Post_Discussion_Link_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOption_PollArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Order_By>>;
  where?: Maybe<Option_Poll_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOption_Poll_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Order_By>>;
  where?: Maybe<Option_Poll_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOption_Poll_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootOption_Poll_VotesArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Votes_Order_By>>;
  where?: Maybe<Option_Poll_Votes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOption_Poll_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Option_Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Option_Poll_Votes_Order_By>>;
  where?: Maybe<Option_Poll_Votes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOption_Poll_Votes_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPipArgs = {
  where?: Maybe<PipSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootPipArgumentArgs = {
  where?: Maybe<PipArgumentSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootPipStatusArgs = {
  where?: Maybe<PipStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootPolkassembly_ProposalsArgs = {
  distinct_on?: Maybe<Array<Polkassembly_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Polkassembly_Proposals_Order_By>>;
  where?: Maybe<Polkassembly_Proposals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPolkassembly_Proposals_AggregateArgs = {
  distinct_on?: Maybe<Array<Polkassembly_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Polkassembly_Proposals_Order_By>>;
  where?: Maybe<Polkassembly_Proposals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPolkassembly_Proposals_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPollArgs = {
  distinct_on?: Maybe<Array<Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Order_By>>;
  where?: Maybe<Poll_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPoll_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Order_By>>;
  where?: Maybe<Poll_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPoll_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPoll_VotesArgs = {
  distinct_on?: Maybe<Array<Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Votes_Order_By>>;
  where?: Maybe<Poll_Votes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPoll_Votes_AggregateArgs = {
  distinct_on?: Maybe<Array<Poll_Votes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Poll_Votes_Order_By>>;
  where?: Maybe<Poll_Votes_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPoll_Votes_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPost_Last_UpdateArgs = {
  distinct_on?: Maybe<Array<Post_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Last_Update_Order_By>>;
  where?: Maybe<Post_Last_Update_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_Last_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Last_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Last_Update_Order_By>>;
  where?: Maybe<Post_Last_Update_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_ReactionsArgs = {
  distinct_on?: Maybe<Array<Post_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Reactions_Order_By>>;
  where?: Maybe<Post_Reactions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_Reactions_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Reactions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Reactions_Order_By>>;
  where?: Maybe<Post_Reactions_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_Reactions_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPost_TopicsArgs = {
  distinct_on?: Maybe<Array<Post_Topics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Topics_Order_By>>;
  where?: Maybe<Post_Topics_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_Topics_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Topics_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Topics_Order_By>>;
  where?: Maybe<Post_Topics_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_Topics_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPost_TypesArgs = {
  distinct_on?: Maybe<Array<Post_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Types_Order_By>>;
  where?: Maybe<Post_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Post_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Post_Types_Order_By>>;
  where?: Maybe<Post_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPost_Types_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPostsArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPosts_AggregateArgs = {
  distinct_on?: Maybe<Array<Posts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Posts_Order_By>>;
  where?: Maybe<Posts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPosts_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPreimageArgs = {
  where?: Maybe<PreimageSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootPreimageArgumentArgs = {
  where?: Maybe<PreimageArgumentSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootPreimageStatusArgs = {
  where?: Maybe<PreimageStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootProposalArgs = {
  where?: Maybe<ProposalSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootProposalStatusArgs = {
  where?: Maybe<ProposalStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootProposal_TrackerArgs = {
  distinct_on?: Maybe<Array<Proposal_Tracker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Proposal_Tracker_Order_By>>;
  where?: Maybe<Proposal_Tracker_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProposal_Tracker_AggregateArgs = {
  distinct_on?: Maybe<Array<Proposal_Tracker_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Proposal_Tracker_Order_By>>;
  where?: Maybe<Proposal_Tracker_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProposal_Tracker_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootReferendumArgs = {
  where?: Maybe<ReferendumSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootReferendumStatusArgs = {
  where?: Maybe<ReferendumStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootRemark_Proposal_OptionsArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Options_Order_By>>;
  where?: Maybe<Remark_Proposal_Options_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRemark_Proposal_Options_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Options_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Options_Order_By>>;
  where?: Maybe<Remark_Proposal_Options_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRemark_Proposal_Options_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRemark_Proposal_VoteArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRemark_Proposal_Vote_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposal_Vote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposal_Vote_Order_By>>;
  where?: Maybe<Remark_Proposal_Vote_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRemark_Proposal_Vote_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRemark_ProposalsArgs = {
  distinct_on?: Maybe<Array<Remark_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposals_Order_By>>;
  where?: Maybe<Remark_Proposals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRemark_Proposals_AggregateArgs = {
  distinct_on?: Maybe<Array<Remark_Proposals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Remark_Proposals_Order_By>>;
  where?: Maybe<Remark_Proposals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootRemark_Proposals_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootRepliesArgs = {
  distinct_on?: Maybe<Array<Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Replies_Order_By>>;
  where?: Maybe<Replies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReplies_AggregateArgs = {
  distinct_on?: Maybe<Array<Replies_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Replies_Order_By>>;
  where?: Maybe<Replies_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootReplies_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootTechCommitteeProposalArgs = {
  where?: Maybe<TechCommitteeProposalSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootTechCommitteeProposalArgumentArgs = {
  where?: Maybe<TechCommitteeProposalArgumentSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootTechCommitteeProposalStatusArgs = {
  where?: Maybe<TechCommitteeProposalStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootTipArgs = {
  where?: Maybe<TipSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootTipStatusArgs = {
  where?: Maybe<TipStatusSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootTreasurySpendProposalArgs = {
  where?: Maybe<TreasurySpendProposalSubscriptionWhereInput>;
};


/** subscription root */
export type Subscription_RootTreasuryStatusArgs = {
  where?: Maybe<TreasuryStatusSubscriptionWhereInput>;
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type EditCommentMutationVariables = Exact<{
  id: Scalars['uuid'];
  content: Scalars['String'];
}>;


export type EditCommentMutation = (
  { __typename?: 'mutation_root' }
  & { update_comments?: Maybe<(
    { __typename?: 'comments_mutation_response' }
    & Pick<Comments_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_comments?: Maybe<(
    { __typename?: 'comments_mutation_response' }
    & Pick<Comments_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddPolkassemblyProposalMutationVariables = Exact<{
  authorId: Scalars['Int'];
  proposalId: Scalars['Int'];
  proposalType: Scalars['Int'];
  proposalHash: Scalars['String'];
  title: Scalars['String'];
  content: Scalars['String'];
  proposerAddress: Scalars['String'];
  network: Scalars['String'];
}>;


export type AddPolkassemblyProposalMutation = (
  { __typename: 'mutation_root' }
  & { insert_polkassembly_proposals?: Maybe<(
    { __typename?: 'polkassembly_proposals_mutation_response' }
    & Pick<Polkassembly_Proposals_Mutation_Response, 'affected_rows'>
  )> }
);

export type LoginMutationVariables = Exact<{
  password: Scalars['String'];
  username: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'mutation_root' }
  & { login?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type AddressLoginStartMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type AddressLoginStartMutation = (
  { __typename?: 'mutation_root' }
  & { addressLoginStart?: Maybe<(
    { __typename?: 'AddressLoginType' }
    & Pick<AddressLoginType, 'message' | 'signMessage'>
  )> }
);

export type AddressLoginMutationVariables = Exact<{
  address: Scalars['String'];
  signature: Scalars['String'];
}>;


export type AddressLoginMutation = (
  { __typename?: 'mutation_root' }
  & { addressLogin?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type MetamaskLoginStartMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type MetamaskLoginStartMutation = (
  { __typename?: 'mutation_root' }
  & { metamaskLoginStart?: Maybe<(
    { __typename?: 'AddressLoginType' }
    & Pick<AddressLoginType, 'message' | 'signMessage'>
  )> }
);

export type MetamaskLoginMutationVariables = Exact<{
  address: Scalars['String'];
  signature: Scalars['String'];
}>;


export type MetamaskLoginMutation = (
  { __typename?: 'mutation_root' }
  & { metamaskLogin?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'mutation_root' }
  & { logout?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type FetchLatestBlockNumberQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchLatestBlockNumberQuery = (
  { __typename?: 'query_root' }
  & { blockNumbers: Array<Maybe<(
    { __typename?: 'BlockNumber' }
    & Pick<BlockNumber, 'number'>
  )>> }
);

export type CreateOptionPollMutationVariables = Exact<{
  postId: Scalars['Int'];
  question: Scalars['String'];
  options: Scalars['String'];
  endAt: Scalars['Int'];
}>;


export type CreateOptionPollMutation = (
  { __typename: 'mutation_root' }
  & { insert_option_poll?: Maybe<(
    { __typename?: 'option_poll_mutation_response' }
    & Pick<Option_Poll_Mutation_Response, 'affected_rows'>
  )> }
);

export type OptionPollFieldsFragment = (
  { __typename?: 'option_poll' }
  & Pick<Option_Poll, 'id' | 'post_id' | 'question' | 'options' | 'end_at' | 'created_at' | 'updated_at'>
);

export type OptionPollQueryVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type OptionPollQuery = (
  { __typename?: 'query_root' }
  & { option_poll: Array<(
    { __typename?: 'option_poll' }
    & OptionPollFieldsFragment
  )> }
);

export type OptionPollVotesFieldsFragment = (
  { __typename?: 'option_poll_votes' }
  & Pick<Option_Poll_Votes, 'id' | 'option' | 'created_at' | 'updated_at'>
  & { option_poll_voter?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type OptionPollVotesQueryVariables = Exact<{
  optionPollId: Scalars['Int'];
}>;


export type OptionPollVotesQuery = (
  { __typename?: 'query_root' }
  & { option_poll_votes: Array<(
    { __typename?: 'option_poll_votes' }
    & OptionPollVotesFieldsFragment
  )> }
);

export type AddOptionPollVoteMutationVariables = Exact<{
  optionPollId: Scalars['Int'];
  userId: Scalars['Int'];
  option: Scalars['String'];
}>;


export type AddOptionPollVoteMutation = (
  { __typename: 'mutation_root' }
  & { insert_option_poll_votes_one?: Maybe<(
    { __typename?: 'option_poll_votes' }
    & Pick<Option_Poll_Votes, 'id'>
  )> }
);

export type DeleteOptionPollVoteMutationVariables = Exact<{
  optionPollId: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type DeleteOptionPollVoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_option_poll_votes?: Maybe<(
    { __typename?: 'option_poll_votes_mutation_response' }
    & Pick<Option_Poll_Votes_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetCouncilMembersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCouncilMembersQuery = (
  { __typename?: 'query_root' }
  & { councils: Array<Maybe<(
    { __typename?: 'Council' }
    & { members?: Maybe<Array<(
      { __typename?: 'CouncilMember' }
      & Pick<CouncilMember, 'id' | 'address'>
    )>> }
  )>> }
);

export type PollFieldsFragment = (
  { __typename?: 'poll' }
  & Pick<Poll, 'id' | 'block_end' | 'created_at' | 'updated_at'>
);

export type PollQueryVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type PollQuery = (
  { __typename?: 'query_root' }
  & { poll: Array<(
    { __typename?: 'poll' }
    & PollFieldsFragment
  )> }
);

export type PollVotesFieldsFragment = (
  { __typename?: 'poll_votes' }
  & Pick<Poll_Votes, 'id' | 'vote' | 'created_at' | 'updated_at'>
  & { voter?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'default_address'>
  )> }
);

export type PollVotesQueryVariables = Exact<{
  pollId: Scalars['Int'];
}>;


export type PollVotesQuery = (
  { __typename?: 'query_root' }
  & { poll_votes: Array<(
    { __typename?: 'poll_votes' }
    & PollVotesFieldsFragment
  )> }
);

export type AddPollVoteMutationVariables = Exact<{
  pollId: Scalars['Int'];
  userId: Scalars['Int'];
  vote: Scalars['bpchar'];
}>;


export type AddPollVoteMutation = (
  { __typename: 'mutation_root' }
  & { insert_poll_votes_one?: Maybe<(
    { __typename?: 'poll_votes' }
    & Pick<Poll_Votes, 'id'>
  )> }
);

export type DeleteVoteMutationVariables = Exact<{
  pollId: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type DeleteVoteMutation = (
  { __typename?: 'mutation_root' }
  & { delete_poll_votes?: Maybe<(
    { __typename?: 'poll_votes_mutation_response' }
    & Pick<Poll_Votes_Mutation_Response, 'affected_rows'>
  )> }
);

export type CouncilAtBlockNumberQueryVariables = Exact<{
  blockNumber: Scalars['Int'];
}>;


export type CouncilAtBlockNumberQuery = (
  { __typename?: 'query_root' }
  & { councils: Array<Maybe<(
    { __typename?: 'Council' }
    & { members?: Maybe<Array<(
      { __typename?: 'CouncilMember' }
      & Pick<CouncilMember, 'address'>
    )>> }
  )>> }
);

export type EditPollMutationVariables = Exact<{
  id: Scalars['Int'];
  blockEnd: Scalars['Int'];
}>;


export type EditPollMutation = (
  { __typename?: 'mutation_root' }
  & { update_poll?: Maybe<(
    { __typename?: 'poll_mutation_response' }
    & Pick<Poll_Mutation_Response, 'affected_rows'>
  )> }
);

export type EditPostMutationVariables = Exact<{
  id: Scalars['Int'];
  content: Scalars['String'];
  title: Scalars['String'];
}>;


export type EditPostMutation = (
  { __typename?: 'mutation_root' }
  & { update_posts?: Maybe<(
    { __typename?: 'posts_mutation_response' }
    & Pick<Posts_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddPostCommentMutationVariables = Exact<{
  network: Scalars['String'];
  authorId: Scalars['Int'];
  content: Scalars['String'];
  postId: Scalars['Int'];
}>;


export type AddPostCommentMutation = (
  { __typename: 'mutation_root' }
  & { insert_comments?: Maybe<(
    { __typename?: 'comments_mutation_response' }
    & Pick<Comments_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddCommentReplyMutationVariables = Exact<{
  network: Scalars['String'];
  authorId: Scalars['Int'];
  content: Scalars['String'];
  commentId: Scalars['uuid'];
}>;


export type AddCommentReplyMutation = (
  { __typename: 'mutation_root' }
  & { insert_replies?: Maybe<(
    { __typename?: 'replies_mutation_response' }
    & Pick<Replies_Mutation_Response, 'affected_rows'>
  )> }
);

export type EditCommentReplyMutationVariables = Exact<{
  id: Scalars['uuid'];
  content: Scalars['String'];
}>;


export type EditCommentReplyMutation = (
  { __typename?: 'mutation_root' }
  & { update_replies?: Maybe<(
    { __typename?: 'replies_mutation_response' }
    & Pick<Replies_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteCommentReplyMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCommentReplyMutation = (
  { __typename?: 'mutation_root' }
  & { delete_replies?: Maybe<(
    { __typename?: 'replies_mutation_response' }
    & Pick<Replies_Mutation_Response, 'affected_rows'>
  )> }
);

export type PostReactionFieldsFragment = (
  { __typename?: 'post_reactions' }
  & Pick<Post_Reactions, 'id' | 'reaction' | 'created_at' | 'updated_at'>
  & { reacting_user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type PostReactionsQueryVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type PostReactionsQuery = (
  { __typename?: 'query_root' }
  & { post_reactions: Array<(
    { __typename?: 'post_reactions' }
    & PostReactionFieldsFragment
  )> }
);

export type CommentReactionFieldsFragment = (
  { __typename?: 'comment_reactions' }
  & Pick<Comment_Reactions, 'id' | 'reaction' | 'created_at' | 'updated_at'>
  & { reacting_user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type CommentReactionsQueryVariables = Exact<{
  commentId: Scalars['uuid'];
}>;


export type CommentReactionsQuery = (
  { __typename?: 'query_root' }
  & { comment_reactions: Array<(
    { __typename?: 'comment_reactions' }
    & CommentReactionFieldsFragment
  )> }
);

export type AddPostReactionMutationVariables = Exact<{
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  reaction: Scalars['bpchar'];
}>;


export type AddPostReactionMutation = (
  { __typename: 'mutation_root' }
  & { insert_post_reactions?: Maybe<(
    { __typename?: 'post_reactions_mutation_response' }
    & Pick<Post_Reactions_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddCommentReactionMutationVariables = Exact<{
  commentId: Scalars['uuid'];
  userId: Scalars['Int'];
  reaction: Scalars['bpchar'];
}>;


export type AddCommentReactionMutation = (
  { __typename: 'mutation_root' }
  & { insert_comment_reactions?: Maybe<(
    { __typename?: 'comment_reactions_mutation_response' }
    & Pick<Comment_Reactions_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeletePostReactionMutationVariables = Exact<{
  postId: Scalars['Int'];
  userId: Scalars['Int'];
  reaction: Scalars['bpchar'];
}>;


export type DeletePostReactionMutation = (
  { __typename?: 'mutation_root' }
  & { delete_post_reactions?: Maybe<(
    { __typename?: 'post_reactions_mutation_response' }
    & Pick<Post_Reactions_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteCommentReactionMutationVariables = Exact<{
  commentId: Scalars['uuid'];
  userId: Scalars['Int'];
  reaction: Scalars['bpchar'];
}>;


export type DeleteCommentReactionMutation = (
  { __typename?: 'mutation_root' }
  & { delete_comment_reactions?: Maybe<(
    { __typename?: 'comment_reactions_mutation_response' }
    & Pick<Comment_Reactions_Mutation_Response, 'affected_rows'>
  )> }
);

export type ReportContentMutationVariables = Exact<{
  network: Scalars['String'];
  type: Scalars['String'];
  content_id: Scalars['String'];
  reason: Scalars['String'];
  comments: Scalars['String'];
}>;


export type ReportContentMutation = (
  { __typename?: 'mutation_root' }
  & { reportContent?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type SignupMutationVariables = Exact<{
  network: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
}>;


export type SignupMutation = (
  { __typename?: 'mutation_root' }
  & { signup?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type AddressSignupStartMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type AddressSignupStartMutation = (
  { __typename?: 'mutation_root' }
  & { addressSignupStart?: Maybe<(
    { __typename?: 'AddressLoginType' }
    & Pick<AddressLoginType, 'message' | 'signMessage'>
  )> }
);

export type AddressSignupConfirmMutationVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
  signature: Scalars['String'];
}>;


export type AddressSignupConfirmMutation = (
  { __typename?: 'mutation_root' }
  & { addressSignupConfirm?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type MetamaskSignupStartMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type MetamaskSignupStartMutation = (
  { __typename?: 'mutation_root' }
  & { metamaskSignupStart?: Maybe<(
    { __typename?: 'AddressLoginType' }
    & Pick<AddressLoginType, 'message' | 'signMessage'>
  )> }
);

export type MetamaskSignupConfirmMutationVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
  signature: Scalars['String'];
}>;


export type MetamaskSignupConfirmMutation = (
  { __typename?: 'mutation_root' }
  & { metamaskSignupConfirm?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type MetamaskLinkStartMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type MetamaskLinkStartMutation = (
  { __typename?: 'mutation_root' }
  & { metamaskLinkStart?: Maybe<(
    { __typename?: 'AddressLoginType' }
    & Pick<AddressLoginType, 'message' | 'signMessage'>
  )> }
);

export type MetamaskLinkConfirmMutationVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
  signature: Scalars['String'];
}>;


export type MetamaskLinkConfirmMutation = (
  { __typename?: 'mutation_root' }
  & { metamaskLinkConfirm?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type PostSubscribeMutationVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type PostSubscribeMutation = (
  { __typename?: 'mutation_root' }
  & { postSubscribe?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type PostUnsubscribeMutationVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type PostUnsubscribeMutation = (
  { __typename?: 'mutation_root' }
  & { postUnsubscribe?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type SubscriptionQueryVariables = Exact<{
  postId: Scalars['Int'];
}>;


export type SubscriptionQuery = (
  { __typename?: 'query_root' }
  & { subscription?: Maybe<(
    { __typename?: 'Subscription' }
    & Pick<Subscription, 'subscribed'>
  )> }
);

export type Get_Refresh_TokenQueryVariables = Exact<{ [key: string]: never; }>;


export type Get_Refresh_TokenQuery = (
  { __typename?: 'query_root' }
  & { token?: Maybe<(
    { __typename?: 'Token' }
    & Pick<Token, 'token'>
  )> }
);

export type GetCalenderEventsQueryVariables = Exact<{
  network: Scalars['String'];
}>;


export type GetCalenderEventsQuery = (
  { __typename?: 'query_root' }
  & { calender_events: Array<(
    { __typename?: 'calender_events' }
    & Pick<Calender_Events, 'content' | 'end_time' | 'id' | 'module' | 'network' | 'start_time' | 'title' | 'url' | 'event_type' | 'event_id' | 'status'>
  )> }
);

export type EditCalenderEventMutationVariables = Exact<{
  id: Scalars['Int'];
  title: Scalars['String'];
  start_time: Scalars['timestamptz'];
  content?: Maybe<Scalars['String']>;
  end_time: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  network: Scalars['String'];
}>;


export type EditCalenderEventMutation = (
  { __typename?: 'mutation_root' }
  & { update_calender_events?: Maybe<(
    { __typename?: 'calender_events_mutation_response' }
    & Pick<Calender_Events_Mutation_Response, 'affected_rows'>
  )> }
);

export type DeleteCalenderEventMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCalenderEventMutation = (
  { __typename?: 'mutation_root' }
  & { delete_calender_events?: Maybe<(
    { __typename?: 'calender_events_mutation_response' }
    & Pick<Calender_Events_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddCalenderEventMutationVariables = Exact<{
  title: Scalars['String'];
  start_time: Scalars['timestamptz'];
  content?: Maybe<Scalars['String']>;
  end_time: Scalars['timestamptz'];
  url?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  network: Scalars['String'];
}>;


export type AddCalenderEventMutation = (
  { __typename?: 'mutation_root' }
  & { insert_calender_events?: Maybe<(
    { __typename?: 'calender_events_mutation_response' }
    & Pick<Calender_Events_Mutation_Response, 'affected_rows'>
  )> }
);

export type AddProfileMutationVariables = Exact<{
  image?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  user_id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  badges?: Maybe<Scalars['String']>;
}>;


export type AddProfileMutation = (
  { __typename?: 'mutation_root' }
  & { addProfile?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type GetUserDetailsQueryVariables = Exact<{
  user_id: Scalars['Int'];
}>;


export type GetUserDetailsQuery = (
  { __typename?: 'query_root' }
  & { userDetails?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'bio' | 'image' | 'user_id' | 'title' | 'badges'>
  )> }
);

export type LinkDiscussionToOnchainPostMutationVariables = Exact<{
  discussion_id: Scalars['Int'];
  onchain_link_id: Scalars['Int'];
  author_id: Scalars['Int'];
}>;


export type LinkDiscussionToOnchainPostMutation = (
  { __typename?: 'mutation_root' }
  & { insert_onchain_post_discussion_link?: Maybe<(
    { __typename?: 'onchain_post_discussion_link_mutation_response' }
    & Pick<Onchain_Post_Discussion_Link_Mutation_Response, 'affected_rows'>
  )> }
);

export type GetDiscussionToOnchainPostByOnchainIdQueryVariables = Exact<{
  onchain_link_id: Scalars['Int'];
}>;


export type GetDiscussionToOnchainPostByOnchainIdQuery = (
  { __typename?: 'query_root' }
  & { onchain_post_discussion_link: Array<(
    { __typename?: 'onchain_post_discussion_link' }
    & Pick<Onchain_Post_Discussion_Link, 'author_id' | 'discussion_post_id' | 'id' | 'onchain_link_id'>
  )> }
);

export type GetDiscussionToOnchainPostByDiscussionIdQueryVariables = Exact<{
  discussion_post_id: Scalars['Int'];
}>;


export type GetDiscussionToOnchainPostByDiscussionIdQuery = (
  { __typename?: 'query_root' }
  & { onchain_post_discussion_link: Array<(
    { __typename?: 'onchain_post_discussion_link' }
    & Pick<Onchain_Post_Discussion_Link, 'author_id' | 'discussion_post_id' | 'id' | 'onchain_link_id'>
  )> }
);

export type GetProposalStatusQueryVariables = Exact<{
  onchain_network_proposal_id: Scalars['String'];
}>;


export type GetProposalStatusQuery = (
  { __typename?: 'query_root' }
  & { proposal_tracker: Array<(
    { __typename?: 'proposal_tracker' }
    & Pick<Proposal_Tracker, 'deadline' | 'id' | 'onchain_network_proposal_id' | 'status'>
  )> }
);

export type CreateProposalTrackerMutationVariables = Exact<{
  deadline: Scalars['String'];
  network: Scalars['String'];
  onchain_network_proposal_id: Scalars['String'];
  status: Scalars['String'];
  start_time: Scalars['String'];
}>;


export type CreateProposalTrackerMutation = (
  { __typename?: 'mutation_root' }
  & { createProposalTracker?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type UpdateProposalTrackerMutationVariables = Exact<{
  id: Scalars['Int'];
  network: Scalars['String'];
  status: Scalars['String'];
}>;


export type UpdateProposalTrackerMutation = (
  { __typename?: 'mutation_root' }
  & { updateProposalTracker?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type GetBlockchainEndpointsQueryVariables = Exact<{
  network: Scalars['String'];
}>;


export type GetBlockchainEndpointsQuery = (
  { __typename?: 'query_root' }
  & { blockchain_endpoints: Array<(
    { __typename?: 'blockchain_endpoints' }
    & Pick<Blockchain_Endpoints, 'is_active' | 'display_label' | 'endpoint' | 'id' | 'network'>
  )> }
);

export type UserWithUsernameQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type UserWithUsernameQuery = (
  { __typename?: 'query_root' }
  & { userWithUsername?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'badges' | 'bio' | 'id' | 'image' | 'title' | 'user_id'>
  )> }
);

export type AuthorFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'default_address' | 'username'>
);

export type ReplyFieldsFragment = (
  { __typename?: 'replies' }
  & Pick<Replies, 'id' | 'comment_id' | 'content' | 'created_at' | 'updated_at'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )> }
);

export type CommentFieldsFragment = (
  { __typename?: 'comments' }
  & Pick<Comments, 'id' | 'content' | 'created_at' | 'updated_at'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, replies: Array<(
    { __typename?: 'replies' }
    & ReplyFieldsFragment
  )> }
);

export type OnchainLinkProposalPostFragment = (
  { __typename?: 'onchain_links' }
  & { onchain_proposal: Array<Maybe<(
    { __typename?: 'Proposal' }
    & Pick<Proposal, 'proposalId'>
    & { proposalStatus?: Maybe<Array<(
      { __typename?: 'ProposalStatus' }
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>> }
  )>> }
);

export type OnchainLinkBountyPostFragment = (
  { __typename?: 'onchain_links' }
  & { onchain_bounty: Array<Maybe<(
    { __typename?: 'Bounty' }
    & Pick<Bounty, 'bountyId'>
    & { bountyStatus?: Maybe<Array<(
      { __typename?: 'BountyStatus' }
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>> }
  )>> }
);

export type OnchainLinkMotionPostFragment = (
  { __typename?: 'onchain_links' }
  & { onchain_motion: Array<Maybe<(
    { __typename?: 'Motion' }
    & Pick<Motion, 'motionProposalId'>
    & { motionStatus?: Maybe<Array<(
      { __typename?: 'MotionStatus' }
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>> }
  )>> }
);

export type OnchainLinkReferendumPostFragment = (
  { __typename?: 'onchain_links' }
  & { onchain_referendum: Array<Maybe<(
    { __typename?: 'Referendum' }
    & Pick<Referendum, 'referendumId'>
    & { referendumStatus?: Maybe<Array<(
      { __typename?: 'ReferendumStatus' }
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>> }
  )>> }
);

export type OnchainLinkTechCommitteeProposalPostFragment = (
  { __typename?: 'onchain_links' }
  & { onchain_tech_committee_proposal: Array<Maybe<(
    { __typename?: 'TechCommitteeProposal' }
    & Pick<TechCommitteeProposal, 'proposalId'>
    & { status?: Maybe<Array<(
      { __typename?: 'TechCommitteeProposalStatus' }
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>> }
  )>> }
);

export type OnchainLinkTreasurySpendProposalFragment = (
  { __typename?: 'onchain_links' }
  & { onchain_treasury_spend_proposal: Array<Maybe<(
    { __typename?: 'TreasurySpendProposal' }
    & Pick<TreasurySpendProposal, 'treasuryProposalId'>
    & { treasuryStatus?: Maybe<Array<(
      { __typename?: 'TreasuryStatus' }
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>> }
  )>> }
);

export type OnchainLinkDiscussionPostFragment = (
  { __typename?: 'onchain_links' }
  & { onchain_post_discussion_links: Array<(
    { __typename?: 'onchain_post_discussion_link' }
    & Pick<Onchain_Post_Discussion_Link, 'discussion_post_id'>
  )> }
);

export type AllBountyPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type AllBountyPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_bounty_id' | 'proposer_address'>
      & { onchain_bounty: Array<Maybe<(
        { __typename?: 'Bounty' }
        & Pick<Bounty, 'id' | 'value'>
        & { bountyStatus?: Maybe<Array<(
          { __typename?: 'BountyStatus' }
          & Pick<BountyStatus, 'id' | 'status'>
        )>> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type OnchainLinkBountyFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_bounty_id'>
  & { onchain_bounty: Array<Maybe<(
    { __typename?: 'Bounty' }
    & Pick<Bounty, 'id' | 'proposer' | 'value' | 'fee' | 'curatorDeposit' | 'bond' | 'bountyId' | 'curator' | 'beneficiary'>
    & { bountyStatus?: Maybe<Array<(
      { __typename?: 'BountyStatus' }
      & Pick<BountyStatus, 'id' | 'status'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'startDateTime' | 'number'>
      ) }
    )>> }
  )>> }
);

export type BountyPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkBountyFragment
    & OnchainLinkProposalPostFragment
    & OnchainLinkDiscussionPostFragment
    & OnchainLinkMotionPostFragment
    & OnchainLinkReferendumPostFragment
    & OnchainLinkTechCommitteeProposalPostFragment
    & OnchainLinkTreasurySpendProposalFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type BountyPostAndCommentsQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type BountyPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & BountyPostFragment
  )> }
);

export type CreatePostMutationVariables = Exact<{
  network: Scalars['String'];
  userId: Scalars['Int'];
  content: Scalars['String'];
  topicId: Scalars['Int'];
  title: Scalars['String'];
}>;


export type CreatePostMutation = (
  { __typename: 'mutation_root' }
  & { insert_posts?: Maybe<(
    { __typename?: 'posts_mutation_response' }
    & Pick<Posts_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'posts' }
      & Pick<Posts, 'id'>
    )> }
  )> }
);

export type CreatePollMutationVariables = Exact<{
  postId: Scalars['Int'];
  blockEnd: Scalars['Int'];
}>;


export type CreatePollMutation = (
  { __typename: 'mutation_root' }
  & { insert_poll?: Maybe<(
    { __typename?: 'poll_mutation_response' }
    & Pick<Poll_Mutation_Response, 'affected_rows'>
  )> }
);

export type TopicFragment = (
  { __typename?: 'post_topics' }
  & Pick<Post_Topics, 'id' | 'name'>
);

export type Post_TopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type Post_TopicsQuery = (
  { __typename?: 'query_root' }
  & { post_topics: Array<(
    { __typename?: 'post_topics' }
    & TopicFragment
  )> }
);

export type OnchainLinkDiscussionFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'onchain_network_referendum_id' | 'onchain_network_motion_id' | 'onchain_network_proposal_id' | 'onchain_network_treasury_proposal_id'>
);

export type DiscussionPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkDiscussionFragment
  )>, onchain_post_discussion_links: Array<(
    { __typename?: 'onchain_post_discussion_link' }
    & Pick<Onchain_Post_Discussion_Link, 'discussion_post_id'>
    & { onchain_link: (
      { __typename?: 'onchain_links' }
      & OnchainLinkTreasurySpendProposalFragment
      & OnchainLinkProposalPostFragment
      & OnchainLinkMotionPostFragment
      & OnchainLinkBountyPostFragment
      & OnchainLinkReferendumPostFragment
      & OnchainLinkTechCommitteeProposalPostFragment
    ) }
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type DiscussionPostAndCommentsQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DiscussionPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & DiscussionPostFragment
  )> }
);

export type PostFieldsFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments_aggregate: (
    { __typename?: 'comments_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'comments_aggregate_fields' }
      & Pick<Comments_Aggregate_Fields, 'count'>
    )> }
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'name' | 'id'>
  ), last_update?: Maybe<(
    { __typename?: 'post_last_update' }
    & Pick<Post_Last_Update, 'last_update'>
  )> }
);

export type LatestDiscussionPostsQueryVariables = Exact<{
  network: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type LatestDiscussionPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & { post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
    & PostFieldsFragment
  )> }
);

export type DiscussionPostsIdDescQueryVariables = Exact<{
  network: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type DiscussionPostsIdDescQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & { post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
    & PostFieldsFragment
  )> }
);

export type DiscussionPostsIdAscQueryVariables = Exact<{
  network: Scalars['String'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type DiscussionPostsIdAscQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & { post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
    & PostFieldsFragment
  )> }
);

export type OnchainLinkMotionPreimageFragment = (
  { __typename?: 'Preimage' }
  & Pick<Preimage, 'hash' | 'id' | 'metaDescription' | 'method'>
  & { preimageArguments?: Maybe<Array<(
    { __typename?: 'PreimageArgument' }
    & Pick<PreimageArgument, 'id' | 'name' | 'value'>
  )>> }
);

export type OnchainLinkMotionTreasuryFragment = (
  { __typename?: 'TreasurySpendProposal' }
  & Pick<TreasurySpendProposal, 'beneficiary' | 'bond' | 'value'>
);

export type OnchainLinkMotionFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_referendum_id' | 'onchain_network_motion_id'>
  & { onchain_motion: Array<Maybe<(
    { __typename?: 'Motion' }
    & Pick<Motion, 'id' | 'memberCount' | 'method' | 'motionProposalHash'>
    & { motionStatus?: Maybe<Array<(
      { __typename?: 'MotionStatus' }
      & Pick<MotionStatus, 'id' | 'status'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>>, motionProposalArguments?: Maybe<Array<(
      { __typename?: 'MotionProposalArgument' }
      & Pick<MotionProposalArgument, 'name' | 'value'>
    )>>, preimage?: Maybe<(
      { __typename?: 'Preimage' }
      & OnchainLinkMotionPreimageFragment
    )>, treasurySpendProposal?: Maybe<(
      { __typename?: 'TreasurySpendProposal' }
      & OnchainLinkMotionTreasuryFragment
    )> }
  )>> }
);

export type MotionPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkMotionFragment
    & OnchainLinkBountyPostFragment
    & OnchainLinkProposalPostFragment
    & OnchainLinkDiscussionPostFragment
    & OnchainLinkReferendumPostFragment
    & OnchainLinkTechCommitteeProposalPostFragment
    & OnchainLinkTreasurySpendProposalFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type MotionPostAndCommentsQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type MotionPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & MotionPostFragment
  )> }
);

export type AllMotionPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type AllMotionPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_motion_id' | 'proposer_address'>
      & { onchain_motion: Array<Maybe<(
        { __typename?: 'Motion' }
        & Pick<Motion, 'id'>
        & { motionStatus?: Maybe<Array<(
          { __typename?: 'MotionStatus' }
          & Pick<MotionStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type ChangeNotificationPreferenceMutationVariables = Exact<{
  postParticipated?: Maybe<Scalars['Boolean']>;
  postCreated?: Maybe<Scalars['Boolean']>;
  newProposal?: Maybe<Scalars['Boolean']>;
  ownProposal?: Maybe<Scalars['Boolean']>;
}>;


export type ChangeNotificationPreferenceMutation = (
  { __typename?: 'mutation_root' }
  & { changeNotificationPreference?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type GetLatestBountyPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type GetLatestBountyPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_bounty_id' | 'proposer_address'>
      & { onchain_bounty: Array<Maybe<(
        { __typename?: 'Bounty' }
        & Pick<Bounty, 'id'>
        & { bountyStatus?: Maybe<Array<(
          { __typename?: 'BountyStatus' }
          & Pick<BountyStatus, 'id' | 'status'>
        )>> }
      )>> }
    )> }
  )> }
);

export type BountiesCountQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
}>;


export type BountiesCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetLatestMotionPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type GetLatestMotionPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_motion_id' | 'proposer_address'>
      & { onchain_motion: Array<Maybe<(
        { __typename?: 'Motion' }
        & Pick<Motion, 'id'>
        & { motionStatus?: Maybe<Array<(
          { __typename?: 'MotionStatus' }
          & Pick<MotionStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type GetLatestMotionsCountQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
}>;


export type GetLatestMotionsCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetLatestDemocracyProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type GetLatestDemocracyProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_proposal_id' | 'proposer_address'>
      & { onchain_proposal: Array<Maybe<(
        { __typename?: 'Proposal' }
        & Pick<Proposal, 'id'>
        & { proposalStatus?: Maybe<Array<(
          { __typename?: 'ProposalStatus' }
          & Pick<ProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type DemocracyProposalCountQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
}>;


export type DemocracyProposalCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetLatestReferendaPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type GetLatestReferendaPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_referendum_id' | 'proposer_address'>
      & { onchain_referendum: Array<Maybe<(
        { __typename?: 'Referendum' }
        & Pick<Referendum, 'id' | 'end'>
        & { referendumStatus?: Maybe<Array<(
          { __typename?: 'ReferendumStatus' }
          & Pick<ReferendumStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type ReferundumCountQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
}>;


export type ReferundumCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type LatestTechCommitteeProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type LatestTechCommitteeProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_tech_committee_proposal_id' | 'proposer_address'>
      & { onchain_tech_committee_proposal: Array<Maybe<(
        { __typename?: 'TechCommitteeProposal' }
        & Pick<TechCommitteeProposal, 'id'>
        & { status?: Maybe<Array<(
          { __typename?: 'TechCommitteeProposalStatus' }
          & Pick<TechCommitteeProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type TechCommitteeProposalCountQueryVariables = Exact<{
  postType: Scalars['Int'];
  network: Scalars['String'];
}>;


export type TechCommitteeProposalCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetLatestTipPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type GetLatestTipPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_tip_id' | 'proposer_address'>
      & { onchain_tip: Array<Maybe<(
        { __typename?: 'Tip' }
        & Pick<Tip, 'id' | 'reason'>
        & { tipStatus?: Maybe<Array<(
          { __typename?: 'TipStatus' }
          & Pick<TipStatus, 'id' | 'status'>
        )>> }
      )>> }
    )> }
  )> }
);

export type TipProposalCountQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
}>;


export type TipProposalCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetLatestDemocracyTreasuryProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type GetLatestDemocracyTreasuryProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_treasury_proposal_id' | 'proposer_address'>
      & { onchain_treasury_spend_proposal: Array<Maybe<(
        { __typename?: 'TreasurySpendProposal' }
        & Pick<TreasurySpendProposal, 'id'>
        & { treasuryStatus?: Maybe<Array<(
          { __typename?: 'TreasuryStatus' }
          & Pick<TreasuryStatus, 'id' | 'status'>
        )>> }
      )>> }
    )> }
  )> }
);

export type DemocracyTreasuryProposalCountQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
}>;


export type DemocracyTreasuryProposalCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetLatestPostsQueryVariables = Exact<{
  network: Scalars['String'];
  limit?: Scalars['Int'];
}>;


export type GetLatestPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_proposal_id' | 'onchain_network_bounty_id' | 'onchain_network_motion_id' | 'onchain_network_referendum_id' | 'onchain_network_tech_committee_proposal_id' | 'onchain_network_tip_id' | 'onchain_network_pip_id' | 'onchain_network_treasury_proposal_id' | 'proposer_address'>
      & { onchain_proposal: Array<Maybe<(
        { __typename?: 'Proposal' }
        & Pick<Proposal, 'id'>
        & { proposalStatus?: Maybe<Array<(
          { __typename?: 'ProposalStatus' }
          & Pick<ProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>>, onchain_bounty: Array<Maybe<(
        { __typename?: 'Bounty' }
        & Pick<Bounty, 'id'>
        & { bountyStatus?: Maybe<Array<(
          { __typename?: 'BountyStatus' }
          & Pick<BountyStatus, 'id' | 'status'>
        )>> }
      )>>, onchain_motion: Array<Maybe<(
        { __typename?: 'Motion' }
        & Pick<Motion, 'id'>
        & { motionStatus?: Maybe<Array<(
          { __typename?: 'MotionStatus' }
          & Pick<MotionStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>>, onchain_referendum: Array<Maybe<(
        { __typename?: 'Referendum' }
        & Pick<Referendum, 'id' | 'end'>
        & { referendumStatus?: Maybe<Array<(
          { __typename?: 'ReferendumStatus' }
          & Pick<ReferendumStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>>, onchain_tech_committee_proposal: Array<Maybe<(
        { __typename?: 'TechCommitteeProposal' }
        & Pick<TechCommitteeProposal, 'id'>
        & { status?: Maybe<Array<(
          { __typename?: 'TechCommitteeProposalStatus' }
          & Pick<TechCommitteeProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>>, onchain_tip: Array<Maybe<(
        { __typename?: 'Tip' }
        & Pick<Tip, 'id' | 'reason'>
        & { tipStatus?: Maybe<Array<(
          { __typename?: 'TipStatus' }
          & Pick<TipStatus, 'id' | 'status'>
        )>> }
      )>>, onchain_pip: Array<Maybe<(
        { __typename?: 'Pip' }
        & Pick<Pip, 'id' | 'method' | 'section' | 'maybeBlockNumber'>
        & { pipStatus?: Maybe<Array<(
          { __typename?: 'PipStatus' }
          & Pick<PipStatus, 'id' | 'status'>
        )>> }
      )>>, onchain_treasury_spend_proposal: Array<Maybe<(
        { __typename?: 'TreasurySpendProposal' }
        & Pick<TreasurySpendProposal, 'id'>
        & { treasuryStatus?: Maybe<Array<(
          { __typename?: 'TreasuryStatus' }
          & Pick<TreasuryStatus, 'id' | 'status'>
        )>> }
      )>> }
    )> }
  )> }
);

export type PostsCountQueryVariables = Exact<{
  network: Scalars['String'];
}>;


export type PostsCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type NetworkSocialsQueryVariables = Exact<{
  network: Scalars['String'];
}>;


export type NetworkSocialsQuery = (
  { __typename?: 'query_root' }
  & { blockchain_socials: Array<(
    { __typename?: 'blockchain_socials' }
    & Pick<Blockchain_Socials, 'block_explorer' | 'discord' | 'github' | 'homepage' | 'id' | 'network' | 'reddit' | 'telegram' | 'twitter' | 'youtube'>
  )> }
);

export type DiscussionsCountQueryVariables = Exact<{
  network: Scalars['String'];
}>;


export type DiscussionsCountQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type OnchainLinkPipFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_pip_id'>
  & { onchain_pip: Array<Maybe<(
    { __typename?: 'Pip' }
    & Pick<Pip, 'id' | 'author' | 'balance' | 'identityId' | 'maybeBlockNumber' | 'method' | 'network' | 'networkProposalId' | 'optionDescription' | 'optionUrl' | 'proposalHash' | 'proposalId' | 'proposedBy' | 'section'>
    & { pipStatus?: Maybe<Array<(
      { __typename?: 'PipStatus' }
      & Pick<PipStatus, 'id' | 'status' | 'network' | 'uniqueNetworkStatus'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'startDateTime' | 'number'>
      ) }
    )>>, pipArguments?: Maybe<Array<(
      { __typename?: 'PipArgument' }
      & Pick<PipArgument, 'id' | 'name' | 'value'>
    )>> }
  )>> }
);

export type PipPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkPipFragment
    & OnchainLinkProposalPostFragment
    & OnchainLinkMotionPostFragment
    & OnchainLinkBountyPostFragment
    & OnchainLinkDiscussionPostFragment
    & OnchainLinkTechCommitteeProposalPostFragment
    & OnchainLinkTreasurySpendProposalFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type PipPostAndCommentsQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type PipPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & PipPostFragment
  )> }
);

export type AllPipPostsQueryVariables = Exact<{
  network: Scalars['String'];
  limit?: Scalars['Int'];
}>;


export type AllPipPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_pip_id' | 'proposer_address'>
      & { onchain_pip: Array<Maybe<(
        { __typename?: 'Pip' }
        & Pick<Pip, 'id' | 'author' | 'balance' | 'identityId' | 'maybeBlockNumber' | 'method' | 'network' | 'networkProposalId' | 'optionDescription' | 'optionUrl' | 'proposalHash' | 'proposalId' | 'proposedBy' | 'section'>
        & { pipStatus?: Maybe<Array<(
          { __typename?: 'PipStatus' }
          & Pick<PipStatus, 'id' | 'status' | 'network' | 'uniqueNetworkStatus'>
        )>>, pipArguments?: Maybe<Array<(
          { __typename?: 'PipArgument' }
          & Pick<PipArgument, 'id' | 'name' | 'value'>
        )>> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type AllPipPostsByCommitteeQueryVariables = Exact<{
  network: Scalars['String'];
  limit?: Scalars['Int'];
  committee: Scalars['String'];
}>;


export type AllPipPostsByCommitteeQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_pip_id' | 'onchain_pip_committee' | 'proposer_address'>
      & { onchain_pip: Array<Maybe<(
        { __typename?: 'Pip' }
        & Pick<Pip, 'id' | 'author' | 'balance' | 'identityId' | 'maybeBlockNumber' | 'method' | 'network' | 'networkProposalId' | 'optionDescription' | 'optionUrl' | 'proposalHash' | 'proposalId' | 'proposedBy' | 'section'>
        & { pipStatus?: Maybe<Array<(
          { __typename?: 'PipStatus' }
          & Pick<PipStatus, 'id' | 'status' | 'network' | 'uniqueNetworkStatus'>
        )>>, pipArguments?: Maybe<Array<(
          { __typename?: 'PipArgument' }
          & Pick<PipArgument, 'id' | 'name' | 'value'>
        )>> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type CountCommitteePipsQueryVariables = Exact<{
  network: Scalars['String'];
  committee: Scalars['String'];
}>;


export type CountCommitteePipsQuery = (
  { __typename?: 'query_root' }
  & { posts_aggregate: (
    { __typename?: 'posts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'posts_aggregate_fields' }
      & Pick<Posts_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type AboutQueryVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
}>;


export type AboutQuery = (
  { __typename?: 'query_root' }
  & { about?: Maybe<(
    { __typename?: 'About' }
    & Pick<About, 'network' | 'address' | 'title' | 'description' | 'image'>
  )> }
);

export type ChangeAboutMutationVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  image: Scalars['String'];
  signature: Scalars['String'];
}>;


export type ChangeAboutMutation = (
  { __typename?: 'mutation_root' }
  & { changeAbout?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type OnchainLinkProposalFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_proposal_id' | 'onchain_network_referendum_id'>
  & { onchain_proposal: Array<Maybe<(
    { __typename?: 'Proposal' }
    & Pick<Proposal, 'id' | 'depositAmount'>
    & { proposalStatus?: Maybe<Array<(
      { __typename?: 'ProposalStatus' }
      & Pick<ProposalStatus, 'id' | 'status'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>>, preimage?: Maybe<(
      { __typename?: 'Preimage' }
      & Pick<Preimage, 'hash' | 'id' | 'metaDescription' | 'method'>
      & { preimageArguments?: Maybe<Array<(
        { __typename?: 'PreimageArgument' }
        & Pick<PreimageArgument, 'id' | 'name' | 'value'>
      )>> }
    )> }
  )>> }
);

export type ProposalPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkProposalFragment
    & OnchainLinkProposalFragment
    & OnchainLinkMotionPostFragment
    & OnchainLinkBountyPostFragment
    & OnchainLinkDiscussionPostFragment
    & OnchainLinkReferendumPostFragment
    & OnchainLinkTechCommitteeProposalPostFragment
    & OnchainLinkTreasurySpendProposalFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type ProposalPostAndCommentsQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type ProposalPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & ProposalPostFragment
  )> }
);

export type AllDemocracyProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type AllDemocracyProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_proposal_id' | 'proposer_address'>
      & { onchain_proposal: Array<Maybe<(
        { __typename?: 'Proposal' }
        & Pick<Proposal, 'id'>
        & { proposalStatus?: Maybe<Array<(
          { __typename?: 'ProposalStatus' }
          & Pick<ProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type AllReferendaPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type AllReferendaPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_referendum_id' | 'proposer_address'>
      & { onchain_referendum: Array<Maybe<(
        { __typename?: 'Referendum' }
        & Pick<Referendum, 'id' | 'end'>
        & { referendumStatus?: Maybe<Array<(
          { __typename?: 'ReferendumStatus' }
          & Pick<ReferendumStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type OnchainLinkReferendumFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_referendum_id'>
  & { onchain_referendum: Array<Maybe<(
    { __typename?: 'Referendum' }
    & Pick<Referendum, 'id' | 'delay' | 'end' | 'voteThreshold'>
    & { referendumStatus?: Maybe<Array<(
      { __typename?: 'ReferendumStatus' }
      & Pick<ReferendumStatus, 'status' | 'id'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'startDateTime' | 'number'>
      ) }
    )>>, preimage?: Maybe<(
      { __typename?: 'Preimage' }
      & Pick<Preimage, 'hash' | 'id' | 'metaDescription' | 'method'>
      & { preimageArguments?: Maybe<Array<(
        { __typename?: 'PreimageArgument' }
        & Pick<PreimageArgument, 'id' | 'name' | 'value'>
      )>> }
    )> }
  )>> }
);

export type ReferendumPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkReferendumFragment
    & OnchainLinkProposalPostFragment
    & OnchainLinkMotionPostFragment
    & OnchainLinkBountyPostFragment
    & OnchainLinkDiscussionPostFragment
    & OnchainLinkTechCommitteeProposalPostFragment
    & OnchainLinkTreasurySpendProposalFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type ReferendumPostAndCommentsQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type ReferendumPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & ReferendumPostFragment
  )> }
);

export type RemarkProposalsListingQueryVariables = Exact<{
  limit: Scalars['Int'];
  network: Scalars['String'];
}>;


export type RemarkProposalsListingQuery = (
  { __typename?: 'query_root' }
  & { remark_proposals: Array<(
    { __typename?: 'remark_proposals' }
    & Pick<Remark_Proposals, 'address' | 'created_at' | 'description' | 'end' | 'id' | 'network' | 'start' | 'title' | 'updated_at'>
  )> }
);

export type RemarkProposalPostQueryVariables = Exact<{
  id: Scalars['Int'];
  network: Scalars['String'];
}>;


export type RemarkProposalPostQuery = (
  { __typename?: 'query_root' }
  & { remark_proposals: Array<(
    { __typename?: 'remark_proposals' }
    & Pick<Remark_Proposals, 'address' | 'created_at' | 'description' | 'end' | 'id' | 'network' | 'start' | 'title' | 'updated_at'>
    & { remark_proposal_options: Array<(
      { __typename?: 'remark_proposal_options' }
      & Pick<Remark_Proposal_Options, 'created_at' | 'id' | 'option' | 'proposal_id' | 'updated_at'>
    )>, remark_proposal_votes: Array<(
      { __typename?: 'remark_proposal_vote' }
      & Pick<Remark_Proposal_Vote, 'address' | 'block_number' | 'created_at' | 'id' | 'option' | 'option_id' | 'power' | 'proposal_id' | 'updated_at'>
    )> }
  )> }
);

export type RemarkProposalCreateMutationMutationVariables = Exact<{
  address: Scalars['String'];
  user_id: Scalars['Int'];
  description: Scalars['String'];
  title: Scalars['String'];
  network: Scalars['String'];
  start: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type RemarkProposalCreateMutationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_remark_proposals?: Maybe<(
    { __typename?: 'remark_proposals_mutation_response' }
    & { returning: Array<(
      { __typename?: 'remark_proposals' }
      & Pick<Remark_Proposals, 'id'>
    )> }
  )> }
);

export type RemarkProposalOptionsCreateMutationMutationVariables = Exact<{
  options: Array<Remark_Proposal_Options_Insert_Input> | Remark_Proposal_Options_Insert_Input;
}>;


export type RemarkProposalOptionsCreateMutationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_remark_proposal_options?: Maybe<(
    { __typename?: 'remark_proposal_options_mutation_response' }
    & Pick<Remark_Proposal_Options_Mutation_Response, 'affected_rows'>
  )> }
);

export type CreateRemarkProposalVoteMutationMutationVariables = Exact<{
  address: Scalars['String'];
  blockNumber: Scalars['Int'];
  option: Scalars['String'];
  optionId: Scalars['Int'];
  power: Scalars['String'];
  proposalId: Scalars['Int'];
  user_id: Scalars['Int'];
}>;


export type CreateRemarkProposalVoteMutationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_remark_proposal_vote_one?: Maybe<(
    { __typename?: 'remark_proposal_vote' }
    & Pick<Remark_Proposal_Vote, 'id'>
  )> }
);

export type RequestResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  network: Scalars['String'];
}>;


export type RequestResetPasswordMutation = (
  { __typename?: 'mutation_root' }
  & { requestResetPassword?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  userId: Scalars['Int'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'mutation_root' }
  & { resetPassword?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type SearchPostFieldsFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments_aggregate: (
    { __typename?: 'comments_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'comments_aggregate_fields' }
      & Pick<Comments_Aggregate_Fields, 'count'>
    )> }
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'name' | 'id'>
  ), last_update?: Maybe<(
    { __typename?: 'post_last_update' }
    & Pick<Post_Last_Update, 'last_update'>
  )> }
);

export type SearchPostsQueryVariables = Exact<{
  term: Scalars['String'];
}>;


export type SearchPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & SearchPostFieldsFragment
  )> }
);

export type ChangeUsernameMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangeUsernameMutation = (
  { __typename?: 'mutation_root' }
  & { changeUsername?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type ChangeEmailMutationVariables = Exact<{
  network: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type ChangeEmailMutation = (
  { __typename?: 'mutation_root' }
  & { changeEmail?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'mutation_root' }
  & { changePassword?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type AddressLinkStartMutationVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
}>;


export type AddressLinkStartMutation = (
  { __typename?: 'mutation_root' }
  & { addressLinkStart?: Maybe<(
    { __typename?: 'AddressLinkType' }
    & Pick<AddressLinkType, 'sign_message' | 'message' | 'address_id'>
  )> }
);

export type AddressLinkConfirmMutationVariables = Exact<{
  address_id: Scalars['Int'];
  signature: Scalars['String'];
}>;


export type AddressLinkConfirmMutation = (
  { __typename?: 'mutation_root' }
  & { addressLinkConfirm?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type AddressUnlinkMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type AddressUnlinkMutation = (
  { __typename?: 'mutation_root' }
  & { addressUnlink?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type ResendVerifyEmailTokenMutationVariables = Exact<{
  network: Scalars['String'];
}>;


export type ResendVerifyEmailTokenMutation = (
  { __typename?: 'mutation_root' }
  & { resendVerifyEmailToken?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type SetDefaultAddressMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type SetDefaultAddressMutation = (
  { __typename?: 'mutation_root' }
  & { setDefaultAddress?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type SetCredentialsStartMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type SetCredentialsStartMutation = (
  { __typename?: 'mutation_root' }
  & { setCredentialsStart?: Maybe<(
    { __typename?: 'AddressLoginType' }
    & Pick<AddressLoginType, 'message' | 'signMessage'>
  )> }
);

export type SetCredentialsConfirmMutationVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  signature: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type SetCredentialsConfirmMutation = (
  { __typename?: 'mutation_root' }
  & { setCredentialsConfirm?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type DeleteAccountMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type DeleteAccountMutation = (
  { __typename?: 'mutation_root' }
  & { deleteAccount?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'message'>
  )> }
);

export type MultisigLinkStartMutationVariables = Exact<{
  address: Scalars['String'];
}>;


export type MultisigLinkStartMutation = (
  { __typename?: 'mutation_root' }
  & { multisigLinkStart?: Maybe<(
    { __typename?: 'AddressLoginType' }
    & Pick<AddressLoginType, 'message' | 'signMessage'>
  )> }
);

export type MultisigLinkConfirmMutationVariables = Exact<{
  network: Scalars['String'];
  address: Scalars['String'];
  addresses: Scalars['String'];
  ss58Prefix: Scalars['Int'];
  threshold: Scalars['Int'];
  signatory: Scalars['String'];
  signature: Scalars['String'];
}>;


export type MultisigLinkConfirmMutation = (
  { __typename?: 'mutation_root' }
  & { multisigLinkConfirm?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export type OnchainLinkTechCommitteeProposalPreimageFragment = (
  { __typename?: 'Preimage' }
  & Pick<Preimage, 'hash' | 'id' | 'metaDescription' | 'method'>
  & { preimageArguments?: Maybe<Array<(
    { __typename?: 'PreimageArgument' }
    & Pick<PreimageArgument, 'id' | 'name' | 'value'>
  )>> }
);

export type OnchainLinkTechCommitteeProposalFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_tech_committee_proposal_id'>
  & { onchain_tech_committee_proposal: Array<Maybe<(
    { __typename?: 'TechCommitteeProposal' }
    & Pick<TechCommitteeProposal, 'id' | 'metaDescription' | 'memberCount' | 'method' | 'proposalHash'>
    & { status?: Maybe<Array<(
      { __typename?: 'TechCommitteeProposalStatus' }
      & Pick<TechCommitteeProposalStatus, 'id' | 'status'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>>, proposalArguments?: Maybe<Array<(
      { __typename?: 'TechCommitteeProposalArgument' }
      & Pick<TechCommitteeProposalArgument, 'name' | 'value'>
    )>>, preimage?: Maybe<(
      { __typename?: 'Preimage' }
      & OnchainLinkTechCommitteeProposalPreimageFragment
    )> }
  )>> }
);

export type TechCommitteeProposalPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkTechCommitteeProposalFragment
    & OnchainLinkProposalPostFragment
    & OnchainLinkMotionPostFragment
    & OnchainLinkBountyPostFragment
    & OnchainLinkDiscussionPostFragment
    & OnchainLinkReferendumPostFragment
    & OnchainLinkTreasurySpendProposalFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type TechCommitteeProposalPostAndCommentsQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type TechCommitteeProposalPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & TechCommitteeProposalPostFragment
  )> }
);

export type AllTechCommitteeProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type AllTechCommitteeProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_tech_committee_proposal_id' | 'proposer_address'>
      & { onchain_tech_committee_proposal: Array<Maybe<(
        { __typename?: 'TechCommitteeProposal' }
        & Pick<TechCommitteeProposal, 'id' | 'method'>
        & { status?: Maybe<Array<(
          { __typename?: 'TechCommitteeProposalStatus' }
          & Pick<TechCommitteeProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type OnchainLinkTipFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_tip_id'>
  & { onchain_tip: Array<Maybe<(
    { __typename?: 'Tip' }
    & Pick<Tip, 'id' | 'hash' | 'reason' | 'who' | 'finder' | 'finderFee' | 'closes'>
    & { tipStatus?: Maybe<Array<(
      { __typename?: 'TipStatus' }
      & Pick<TipStatus, 'id' | 'status'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'startDateTime' | 'number'>
      ) }
    )>> }
  )>> }
);

export type TipPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkTipFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type TipPostAndCommentsQueryVariables = Exact<{
  networkHash: Scalars['String'];
}>;


export type TipPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & TipPostFragment
  )> }
);

export type AllTipPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  offset?: Scalars['Int'];
  postTopic: Scalars['Int'];
  limit?: Scalars['Int'];
}>;


export type AllTipPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_tip_id' | 'proposer_address'>
      & { onchain_tip: Array<Maybe<(
        { __typename?: 'Tip' }
        & Pick<Tip, 'id' | 'reason'>
        & { tipStatus?: Maybe<Array<(
          { __typename?: 'TipStatus' }
          & Pick<TipStatus, 'id' | 'status'>
        )>> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type TrackerBountyPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  onchainBountyIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrackerBountyPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_bounty_id' | 'proposer_address'>
      & { onchain_bounty: Array<Maybe<(
        { __typename?: 'Bounty' }
        & Pick<Bounty, 'id'>
        & { bountyStatus?: Maybe<Array<(
          { __typename?: 'BountyStatus' }
          & Pick<BountyStatus, 'id' | 'status'>
        )>> }
      )>> }
    )> }
  )> }
);

export type TrackerMotionPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  onchainMotionIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrackerMotionPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_motion_id' | 'proposer_address'>
      & { onchain_motion: Array<Maybe<(
        { __typename?: 'Motion' }
        & Pick<Motion, 'id'>
        & { motionStatus?: Maybe<Array<(
          { __typename?: 'MotionStatus' }
          & Pick<MotionStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type TrackerDemocracyProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  onchainProposalIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrackerDemocracyProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_proposal_id' | 'proposer_address'>
      & { onchain_proposal: Array<Maybe<(
        { __typename?: 'Proposal' }
        & Pick<Proposal, 'id'>
        & { proposalStatus?: Maybe<Array<(
          { __typename?: 'ProposalStatus' }
          & Pick<ProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type TrackerReferendaPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  onchainReferendumIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrackerReferendaPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_referendum_id' | 'proposer_address'>
      & { onchain_referendum: Array<Maybe<(
        { __typename?: 'Referendum' }
        & Pick<Referendum, 'id' | 'end'>
        & { referendumStatus?: Maybe<Array<(
          { __typename?: 'ReferendumStatus' }
          & Pick<ReferendumStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type TrackerTechCommitteeProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  onchainTechCommitteeProposalIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrackerTechCommitteeProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_tech_committee_proposal_id' | 'proposer_address'>
      & { onchain_tech_committee_proposal: Array<Maybe<(
        { __typename?: 'TechCommitteeProposal' }
        & Pick<TechCommitteeProposal, 'id'>
        & { status?: Maybe<Array<(
          { __typename?: 'TechCommitteeProposalStatus' }
          & Pick<TechCommitteeProposalStatus, 'id' | 'status'>
        )>>, preimage?: Maybe<(
          { __typename?: 'Preimage' }
          & Pick<Preimage, 'id' | 'method'>
        )> }
      )>> }
    )> }
  )> }
);

export type TrackerTipPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  onchainTipIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrackerTipPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_tip_id' | 'proposer_address'>
      & { onchain_tip: Array<Maybe<(
        { __typename?: 'Tip' }
        & Pick<Tip, 'id' | 'reason'>
        & { tipStatus?: Maybe<Array<(
          { __typename?: 'TipStatus' }
          & Pick<TipStatus, 'id' | 'status'>
        )>> }
      )>> }
    )> }
  )> }
);

export type TrackerDemocracyTreasuryProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  onchainTreasuryProposalIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type TrackerDemocracyTreasuryProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_treasury_proposal_id' | 'proposer_address'>
      & { onchain_treasury_spend_proposal: Array<Maybe<(
        { __typename?: 'TreasurySpendProposal' }
        & Pick<TreasurySpendProposal, 'id'>
        & { treasuryStatus?: Maybe<Array<(
          { __typename?: 'TreasuryStatus' }
          & Pick<TreasuryStatus, 'id' | 'status'>
        )>> }
      )>> }
    )> }
  )> }
);

export type AllDemocracyTreasuryProposalPostsQueryVariables = Exact<{
  network: Scalars['String'];
  postType: Scalars['Int'];
  postTopic: Scalars['Int'];
  limit?: Scalars['Int'];
  offset?: Scalars['Int'];
}>;


export type AllDemocracyTreasuryProposalPostsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & Pick<Posts, 'id' | 'title' | 'created_at' | 'updated_at'>
    & { author?: Maybe<(
      { __typename?: 'User' }
      & AuthorFieldsFragment
    )>, comments_aggregate: (
      { __typename?: 'comments_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'comments_aggregate_fields' }
        & Pick<Comments_Aggregate_Fields, 'count'>
      )> }
    ), type: (
      { __typename?: 'post_types' }
      & Pick<Post_Types, 'name' | 'id'>
    ), topic: (
      { __typename?: 'post_topics' }
      & Pick<Post_Topics, 'id' | 'name'>
    ), onchain_link?: Maybe<(
      { __typename?: 'onchain_links' }
      & Pick<Onchain_Links, 'id' | 'onchain_network_treasury_proposal_id' | 'proposer_address'>
      & { onchain_treasury_spend_proposal: Array<Maybe<(
        { __typename?: 'TreasurySpendProposal' }
        & Pick<TreasurySpendProposal, 'id'>
        & { treasuryStatus?: Maybe<Array<(
          { __typename?: 'TreasuryStatus' }
          & Pick<TreasuryStatus, 'id' | 'status'>
        )>> }
      )>> }
    )>, post_reactions: Array<(
      { __typename?: 'post_reactions' }
      & Pick<Post_Reactions, 'reaction'>
    )> }
  )> }
);

export type OnchainLinkTreasuryProposalFragment = (
  { __typename?: 'onchain_links' }
  & Pick<Onchain_Links, 'id' | 'proposer_address' | 'onchain_network_treasury_proposal_id' | 'onchain_network_motion_id'>
  & { onchain_treasury_spend_proposal: Array<Maybe<(
    { __typename?: 'TreasurySpendProposal' }
    & Pick<TreasurySpendProposal, 'id' | 'beneficiary' | 'value' | 'bond'>
    & { treasuryStatus?: Maybe<Array<(
      { __typename?: 'TreasuryStatus' }
      & Pick<TreasuryStatus, 'id' | 'status'>
      & { blockNumber: (
        { __typename?: 'BlockNumber' }
        & Pick<BlockNumber, 'number'>
      ) }
    )>> }
  )>> }
);

export type TreasuryProposalPostFragment = (
  { __typename?: 'posts' }
  & Pick<Posts, 'content' | 'created_at' | 'id' | 'updated_at' | 'title'>
  & { author?: Maybe<(
    { __typename?: 'User' }
    & AuthorFieldsFragment
  )>, comments: Array<(
    { __typename?: 'comments' }
    & CommentFieldsFragment
  )>, onchain_link?: Maybe<(
    { __typename?: 'onchain_links' }
    & OnchainLinkTreasuryProposalFragment
    & OnchainLinkProposalPostFragment
    & OnchainLinkMotionPostFragment
    & OnchainLinkBountyPostFragment
    & OnchainLinkDiscussionPostFragment
    & OnchainLinkReferendumPostFragment
    & OnchainLinkTechCommitteeProposalPostFragment
  )>, topic: (
    { __typename?: 'post_topics' }
    & Pick<Post_Topics, 'id' | 'name'>
  ), type: (
    { __typename?: 'post_types' }
    & Pick<Post_Types, 'id' | 'name'>
  ) }
);

export type TreasuryProposalPostAndCommentsQueryVariables = Exact<{
  networkId: Scalars['String'];
}>;


export type TreasuryProposalPostAndCommentsQuery = (
  { __typename?: 'query_root' }
  & { posts: Array<(
    { __typename?: 'posts' }
    & TreasuryProposalPostFragment
  )> }
);

export type UndoEmailChangeMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type UndoEmailChangeMutation = (
  { __typename?: 'mutation_root' }
  & { undoEmailChange?: Maybe<(
    { __typename?: 'UndoEmailChangeResponse' }
    & Pick<UndoEmailChangeResponse, 'message' | 'token' | 'email'>
  )> }
);

export type ProfileQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type ProfileQuery = (
  { __typename?: 'query_root' }
  & { profile?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'default_address'>
  )> }
);

export type VerifyEmailMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'mutation_root' }
  & { verifyEmail?: Maybe<(
    { __typename?: 'ChangeResponse' }
    & Pick<ChangeResponse, 'message' | 'token'>
  )> }
);

export const OptionPollFieldsFragmentDoc = gql`
    fragment optionPollFields on option_poll {
  id
  post_id
  question
  options
  end_at
  created_at
  updated_at
}
    `;
export const OptionPollVotesFieldsFragmentDoc = gql`
    fragment optionPollVotesFields on option_poll_votes {
  id
  option_poll_voter {
    id
    username
  }
  option
  created_at
  updated_at
}
    `;
export const PollFieldsFragmentDoc = gql`
    fragment pollFields on poll {
  id
  block_end
  created_at
  updated_at
}
    `;
export const PollVotesFieldsFragmentDoc = gql`
    fragment pollVotesFields on poll_votes {
  id
  voter {
    id
    username
    default_address
  }
  vote
  created_at
  updated_at
}
    `;
export const PostReactionFieldsFragmentDoc = gql`
    fragment postReactionFields on post_reactions {
  id
  reacting_user {
    id
    username
  }
  reaction
  created_at
  updated_at
}
    `;
export const CommentReactionFieldsFragmentDoc = gql`
    fragment commentReactionFields on comment_reactions {
  id
  reacting_user {
    id
    username
  }
  reaction
  created_at
  updated_at
}
    `;
export const AuthorFieldsFragmentDoc = gql`
    fragment authorFields on User {
  id
  default_address
  username
}
    `;
export const ReplyFieldsFragmentDoc = gql`
    fragment replyFields on replies {
  id
  author {
    ...authorFields
  }
  comment_id
  content
  created_at
  updated_at
}
    ${AuthorFieldsFragmentDoc}`;
export const CommentFieldsFragmentDoc = gql`
    fragment commentFields on comments {
  id
  author {
    ...authorFields
  }
  replies {
    ...replyFields
  }
  content
  created_at
  updated_at
}
    ${AuthorFieldsFragmentDoc}
${ReplyFieldsFragmentDoc}`;
export const OnchainLinkBountyFragmentDoc = gql`
    fragment onchainLinkBounty on onchain_links {
  id
  proposer_address
  onchain_network_bounty_id
  onchain_bounty(where: {}) {
    id
    proposer
    value
    fee
    curatorDeposit
    bond
    bountyId
    curator
    beneficiary
    bountyStatus(orderBy: id_DESC) {
      id
      status
      blockNumber {
        startDateTime
        number
      }
    }
  }
}
    `;
export const OnchainLinkProposalPostFragmentDoc = gql`
    fragment onchainLinkProposalPost on onchain_links {
  onchain_proposal {
    proposalId
    proposalStatus(first: 1) {
      blockNumber {
        number
      }
    }
  }
}
    `;
export const OnchainLinkDiscussionPostFragmentDoc = gql`
    fragment onchainLinkDiscussionPost on onchain_links {
  onchain_post_discussion_links {
    discussion_post_id
  }
}
    `;
export const OnchainLinkMotionPostFragmentDoc = gql`
    fragment onchainLinkMotionPost on onchain_links {
  onchain_motion {
    motionProposalId
    motionStatus(first: 1) {
      blockNumber {
        number
      }
    }
  }
}
    `;
export const OnchainLinkReferendumPostFragmentDoc = gql`
    fragment onchainLinkReferendumPost on onchain_links {
  onchain_referendum {
    referendumId
    referendumStatus(first: 1) {
      blockNumber {
        number
      }
    }
  }
}
    `;
export const OnchainLinkTechCommitteeProposalPostFragmentDoc = gql`
    fragment onchainLinkTechCommitteeProposalPost on onchain_links {
  onchain_tech_committee_proposal {
    proposalId
    status(first: 1) {
      blockNumber {
        number
      }
    }
  }
}
    `;
export const OnchainLinkTreasurySpendProposalFragmentDoc = gql`
    fragment onchainLinkTreasurySpendProposal on onchain_links {
  onchain_treasury_spend_proposal {
    treasuryProposalId
    treasuryStatus(first: 1) {
      blockNumber {
        number
      }
    }
  }
}
    `;
export const BountyPostFragmentDoc = gql`
    fragment bountyPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkBounty
    ...onchainLinkProposalPost
    ...onchainLinkDiscussionPost
    ...onchainLinkMotionPost
    ...onchainLinkReferendumPost
    ...onchainLinkTechCommitteeProposalPost
    ...onchainLinkTreasurySpendProposal
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkBountyFragmentDoc}
${OnchainLinkProposalPostFragmentDoc}
${OnchainLinkDiscussionPostFragmentDoc}
${OnchainLinkMotionPostFragmentDoc}
${OnchainLinkReferendumPostFragmentDoc}
${OnchainLinkTechCommitteeProposalPostFragmentDoc}
${OnchainLinkTreasurySpendProposalFragmentDoc}`;
export const TopicFragmentDoc = gql`
    fragment topic on post_topics {
  id
  name
}
    `;
export const OnchainLinkDiscussionFragmentDoc = gql`
    fragment onchainLinkDiscussion on onchain_links {
  id
  onchain_network_referendum_id
  onchain_network_motion_id
  onchain_network_proposal_id
  onchain_network_treasury_proposal_id
}
    `;
export const OnchainLinkBountyPostFragmentDoc = gql`
    fragment onchainLinkBountyPost on onchain_links {
  onchain_bounty {
    bountyId
    bountyStatus(first: 1) {
      blockNumber {
        number
      }
    }
  }
}
    `;
export const DiscussionPostFragmentDoc = gql`
    fragment discussionPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkDiscussion
  }
  onchain_post_discussion_links {
    discussion_post_id
    onchain_link {
      ...onchainLinkTreasurySpendProposal
      ...onchainLinkProposalPost
      ...onchainLinkMotionPost
      ...onchainLinkBountyPost
      ...onchainLinkReferendumPost
      ...onchainLinkTechCommitteeProposalPost
    }
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkDiscussionFragmentDoc}
${OnchainLinkTreasurySpendProposalFragmentDoc}
${OnchainLinkProposalPostFragmentDoc}
${OnchainLinkMotionPostFragmentDoc}
${OnchainLinkBountyPostFragmentDoc}
${OnchainLinkReferendumPostFragmentDoc}
${OnchainLinkTechCommitteeProposalPostFragmentDoc}`;
export const PostFieldsFragmentDoc = gql`
    fragment postFields on posts {
  id
  title
  author {
    ...authorFields
  }
  created_at
  updated_at
  comments_aggregate {
    aggregate {
      count
    }
  }
  type {
    name
    id
  }
  last_update {
    last_update
  }
}
    ${AuthorFieldsFragmentDoc}`;
export const OnchainLinkMotionPreimageFragmentDoc = gql`
    fragment onchainLinkMotionPreimage on Preimage {
  hash
  id
  metaDescription
  method
  preimageArguments {
    id
    name
    value
  }
}
    `;
export const OnchainLinkMotionTreasuryFragmentDoc = gql`
    fragment onchainLinkMotionTreasury on TreasurySpendProposal {
  beneficiary
  bond
  value
}
    `;
export const OnchainLinkMotionFragmentDoc = gql`
    fragment onchainLinkMotion on onchain_links {
  id
  proposer_address
  onchain_network_referendum_id
  onchain_network_motion_id
  onchain_motion(where: {}) {
    id
    motionStatus(orderBy: id_DESC) {
      id
      status
      blockNumber {
        number
      }
    }
    memberCount
    method
    motionProposalHash
    motionProposalArguments {
      name
      value
    }
    preimage {
      ...onchainLinkMotionPreimage
    }
    treasurySpendProposal {
      ...onchainLinkMotionTreasury
    }
  }
}
    ${OnchainLinkMotionPreimageFragmentDoc}
${OnchainLinkMotionTreasuryFragmentDoc}`;
export const MotionPostFragmentDoc = gql`
    fragment motionPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkMotion
    ...onchainLinkBountyPost
    ...onchainLinkProposalPost
    ...onchainLinkDiscussionPost
    ...onchainLinkReferendumPost
    ...onchainLinkTechCommitteeProposalPost
    ...onchainLinkTreasurySpendProposal
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkMotionFragmentDoc}
${OnchainLinkBountyPostFragmentDoc}
${OnchainLinkProposalPostFragmentDoc}
${OnchainLinkDiscussionPostFragmentDoc}
${OnchainLinkReferendumPostFragmentDoc}
${OnchainLinkTechCommitteeProposalPostFragmentDoc}
${OnchainLinkTreasurySpendProposalFragmentDoc}`;
export const OnchainLinkPipFragmentDoc = gql`
    fragment onchainLinkPip on onchain_links {
  id
  proposer_address
  onchain_network_pip_id
  onchain_pip {
    id
    pipStatus(orderBy: id_DESC) {
      id
      status
      network
      uniqueNetworkStatus
      blockNumber {
        number
      }
    }
    author
    balance
    identityId
    maybeBlockNumber
    method
    network
    networkProposalId
    optionDescription
    optionUrl
    proposalHash
    proposalId
    proposedBy
    section
    pipArguments {
      id
      name
      value
    }
  }
}
    `;
export const PipPostFragmentDoc = gql`
    fragment pipPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkPip
    ...onchainLinkProposalPost
    ...onchainLinkMotionPost
    ...onchainLinkBountyPost
    ...onchainLinkDiscussionPost
    ...onchainLinkTechCommitteeProposalPost
    ...onchainLinkTreasurySpendProposal
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkPipFragmentDoc}
${OnchainLinkProposalPostFragmentDoc}
${OnchainLinkMotionPostFragmentDoc}
${OnchainLinkBountyPostFragmentDoc}
${OnchainLinkDiscussionPostFragmentDoc}
${OnchainLinkTechCommitteeProposalPostFragmentDoc}
${OnchainLinkTreasurySpendProposalFragmentDoc}`;
export const OnchainLinkProposalFragmentDoc = gql`
    fragment onchainLinkProposal on onchain_links {
  id
  proposer_address
  onchain_network_proposal_id
  onchain_network_referendum_id
  onchain_proposal(where: {}) {
    id
    depositAmount
    proposalStatus(orderBy: id_DESC) {
      id
      status
      blockNumber {
        number
      }
    }
    preimage {
      hash
      id
      metaDescription
      method
      preimageArguments {
        id
        name
        value
      }
    }
  }
}
    `;
export const ProposalPostFragmentDoc = gql`
    fragment proposalPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkProposal
    ...onchainLinkProposal
    ...onchainLinkMotionPost
    ...onchainLinkBountyPost
    ...onchainLinkDiscussionPost
    ...onchainLinkReferendumPost
    ...onchainLinkTechCommitteeProposalPost
    ...onchainLinkTreasurySpendProposal
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkProposalFragmentDoc}
${OnchainLinkMotionPostFragmentDoc}
${OnchainLinkBountyPostFragmentDoc}
${OnchainLinkDiscussionPostFragmentDoc}
${OnchainLinkReferendumPostFragmentDoc}
${OnchainLinkTechCommitteeProposalPostFragmentDoc}
${OnchainLinkTreasurySpendProposalFragmentDoc}`;
export const OnchainLinkReferendumFragmentDoc = gql`
    fragment onchainLinkReferendum on onchain_links {
  id
  proposer_address
  onchain_network_referendum_id
  onchain_referendum(where: {}) {
    id
    delay
    end
    voteThreshold
    referendumStatus(orderBy: id_DESC) {
      blockNumber {
        startDateTime
        number
      }
      status
      id
    }
    preimage {
      hash
      id
      metaDescription
      method
      preimageArguments {
        id
        name
        value
      }
    }
  }
}
    `;
export const ReferendumPostFragmentDoc = gql`
    fragment referendumPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkReferendum
    ...onchainLinkProposalPost
    ...onchainLinkMotionPost
    ...onchainLinkBountyPost
    ...onchainLinkDiscussionPost
    ...onchainLinkTechCommitteeProposalPost
    ...onchainLinkTreasurySpendProposal
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkReferendumFragmentDoc}
${OnchainLinkProposalPostFragmentDoc}
${OnchainLinkMotionPostFragmentDoc}
${OnchainLinkBountyPostFragmentDoc}
${OnchainLinkDiscussionPostFragmentDoc}
${OnchainLinkTechCommitteeProposalPostFragmentDoc}
${OnchainLinkTreasurySpendProposalFragmentDoc}`;
export const SearchPostFieldsFragmentDoc = gql`
    fragment searchPostFields on posts {
  id
  title
  author {
    ...authorFields
  }
  created_at
  updated_at
  comments_aggregate {
    aggregate {
      count
    }
  }
  type {
    name
    id
  }
  last_update {
    last_update
  }
}
    ${AuthorFieldsFragmentDoc}`;
export const OnchainLinkTechCommitteeProposalPreimageFragmentDoc = gql`
    fragment onchainLinkTechCommitteeProposalPreimage on Preimage {
  hash
  id
  metaDescription
  method
  preimageArguments {
    id
    name
    value
  }
}
    `;
export const OnchainLinkTechCommitteeProposalFragmentDoc = gql`
    fragment onchainLinkTechCommitteeProposal on onchain_links {
  id
  proposer_address
  onchain_network_tech_committee_proposal_id
  onchain_tech_committee_proposal(where: {}) {
    id
    status(orderBy: id_DESC) {
      id
      status
      blockNumber {
        number
      }
    }
    metaDescription
    memberCount
    method
    proposalHash
    proposalArguments {
      name
      value
    }
    preimage {
      ...onchainLinkTechCommitteeProposalPreimage
    }
  }
}
    ${OnchainLinkTechCommitteeProposalPreimageFragmentDoc}`;
export const TechCommitteeProposalPostFragmentDoc = gql`
    fragment techCommitteeProposalPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkTechCommitteeProposal
    ...onchainLinkProposalPost
    ...onchainLinkMotionPost
    ...onchainLinkBountyPost
    ...onchainLinkDiscussionPost
    ...onchainLinkReferendumPost
    ...onchainLinkTreasurySpendProposal
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkTechCommitteeProposalFragmentDoc}
${OnchainLinkProposalPostFragmentDoc}
${OnchainLinkMotionPostFragmentDoc}
${OnchainLinkBountyPostFragmentDoc}
${OnchainLinkDiscussionPostFragmentDoc}
${OnchainLinkReferendumPostFragmentDoc}
${OnchainLinkTreasurySpendProposalFragmentDoc}`;
export const OnchainLinkTipFragmentDoc = gql`
    fragment onchainLinkTip on onchain_links {
  id
  proposer_address
  onchain_network_tip_id
  onchain_tip(where: {}) {
    id
    hash
    reason
    who
    finder
    finderFee
    closes
    tipStatus(orderBy: id_DESC) {
      id
      status
      blockNumber {
        startDateTime
        number
      }
    }
  }
}
    `;
export const TipPostFragmentDoc = gql`
    fragment tipPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkTip
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkTipFragmentDoc}`;
export const OnchainLinkTreasuryProposalFragmentDoc = gql`
    fragment onchainLinkTreasuryProposal on onchain_links {
  id
  proposer_address
  onchain_network_treasury_proposal_id
  onchain_network_motion_id
  onchain_treasury_spend_proposal(where: {}) {
    id
    beneficiary
    value
    bond
    treasuryStatus(orderBy: id_DESC) {
      id
      status
      blockNumber {
        number
      }
    }
  }
}
    `;
export const TreasuryProposalPostFragmentDoc = gql`
    fragment treasuryProposalPost on posts {
  author {
    ...authorFields
  }
  content
  created_at
  id
  updated_at
  comments(order_by: {created_at: asc}) {
    ...commentFields
  }
  onchain_link {
    ...onchainLinkTreasuryProposal
    ...onchainLinkProposalPost
    ...onchainLinkMotionPost
    ...onchainLinkBountyPost
    ...onchainLinkDiscussionPost
    ...onchainLinkReferendumPost
    ...onchainLinkTechCommitteeProposalPost
  }
  title
  topic {
    id
    name
  }
  type {
    id
    name
  }
}
    ${AuthorFieldsFragmentDoc}
${CommentFieldsFragmentDoc}
${OnchainLinkTreasuryProposalFragmentDoc}
${OnchainLinkProposalPostFragmentDoc}
${OnchainLinkMotionPostFragmentDoc}
${OnchainLinkBountyPostFragmentDoc}
${OnchainLinkDiscussionPostFragmentDoc}
${OnchainLinkReferendumPostFragmentDoc}
${OnchainLinkTechCommitteeProposalPostFragmentDoc}`;
export const EditCommentDocument = gql`
    mutation EditComment($id: uuid!, $content: String!) {
  update_comments(where: {id: {_eq: $id}}, _set: {content: $content}) {
    affected_rows
  }
}
    `;
export type EditCommentMutationFn = ApolloReactCommon.MutationFunction<EditCommentMutation, EditCommentMutationVariables>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCommentMutation, EditCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCommentMutation, EditCommentMutationVariables>(EditCommentDocument, baseOptions);
      }
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = ApolloReactCommon.MutationResult<EditCommentMutation>;
export type EditCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCommentMutation, EditCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($id: uuid!) {
  delete_comments(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, baseOptions);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = ApolloReactCommon.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const AddPolkassemblyProposalDocument = gql`
    mutation AddPolkassemblyProposal($authorId: Int!, $proposalId: Int!, $proposalType: Int!, $proposalHash: String!, $title: String!, $content: String!, $proposerAddress: String!, $network: String!) {
  __typename
  insert_polkassembly_proposals(
    objects: {author_id: $authorId, proposal_id: $proposalId, proposal_type: $proposalType, proposal_hash: $proposalHash, content: $content, title: $title, proposer_address: $proposerAddress, network: $network}
  ) {
    affected_rows
  }
}
    `;
export type AddPolkassemblyProposalMutationFn = ApolloReactCommon.MutationFunction<AddPolkassemblyProposalMutation, AddPolkassemblyProposalMutationVariables>;

/**
 * __useAddPolkassemblyProposalMutation__
 *
 * To run a mutation, you first call `useAddPolkassemblyProposalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPolkassemblyProposalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPolkassemblyProposalMutation, { data, loading, error }] = useAddPolkassemblyProposalMutation({
 *   variables: {
 *      authorId: // value for 'authorId'
 *      proposalId: // value for 'proposalId'
 *      proposalType: // value for 'proposalType'
 *      proposalHash: // value for 'proposalHash'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      proposerAddress: // value for 'proposerAddress'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useAddPolkassemblyProposalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPolkassemblyProposalMutation, AddPolkassemblyProposalMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPolkassemblyProposalMutation, AddPolkassemblyProposalMutationVariables>(AddPolkassemblyProposalDocument, baseOptions);
      }
export type AddPolkassemblyProposalMutationHookResult = ReturnType<typeof useAddPolkassemblyProposalMutation>;
export type AddPolkassemblyProposalMutationResult = ApolloReactCommon.MutationResult<AddPolkassemblyProposalMutation>;
export type AddPolkassemblyProposalMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPolkassemblyProposalMutation, AddPolkassemblyProposalMutationVariables>;
export const LoginDocument = gql`
    mutation LOGIN($password: String!, $username: String!) {
  login(password: $password, username: $username) {
    token
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      password: // value for 'password'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const AddressLoginStartDocument = gql`
    mutation AddressLoginStart($address: String!) {
  addressLoginStart(address: $address) {
    message
    signMessage
  }
}
    `;
export type AddressLoginStartMutationFn = ApolloReactCommon.MutationFunction<AddressLoginStartMutation, AddressLoginStartMutationVariables>;

/**
 * __useAddressLoginStartMutation__
 *
 * To run a mutation, you first call `useAddressLoginStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressLoginStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressLoginStartMutation, { data, loading, error }] = useAddressLoginStartMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddressLoginStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddressLoginStartMutation, AddressLoginStartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddressLoginStartMutation, AddressLoginStartMutationVariables>(AddressLoginStartDocument, baseOptions);
      }
export type AddressLoginStartMutationHookResult = ReturnType<typeof useAddressLoginStartMutation>;
export type AddressLoginStartMutationResult = ApolloReactCommon.MutationResult<AddressLoginStartMutation>;
export type AddressLoginStartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddressLoginStartMutation, AddressLoginStartMutationVariables>;
export const AddressLoginDocument = gql`
    mutation AddressLogin($address: String!, $signature: String!) {
  addressLogin(address: $address, signature: $signature) {
    token
  }
}
    `;
export type AddressLoginMutationFn = ApolloReactCommon.MutationFunction<AddressLoginMutation, AddressLoginMutationVariables>;

/**
 * __useAddressLoginMutation__
 *
 * To run a mutation, you first call `useAddressLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressLoginMutation, { data, loading, error }] = useAddressLoginMutation({
 *   variables: {
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useAddressLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddressLoginMutation, AddressLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<AddressLoginMutation, AddressLoginMutationVariables>(AddressLoginDocument, baseOptions);
      }
export type AddressLoginMutationHookResult = ReturnType<typeof useAddressLoginMutation>;
export type AddressLoginMutationResult = ApolloReactCommon.MutationResult<AddressLoginMutation>;
export type AddressLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<AddressLoginMutation, AddressLoginMutationVariables>;
export const MetamaskLoginStartDocument = gql`
    mutation MetamaskLoginStart($address: String!) {
  metamaskLoginStart(address: $address) {
    message
    signMessage
  }
}
    `;
export type MetamaskLoginStartMutationFn = ApolloReactCommon.MutationFunction<MetamaskLoginStartMutation, MetamaskLoginStartMutationVariables>;

/**
 * __useMetamaskLoginStartMutation__
 *
 * To run a mutation, you first call `useMetamaskLoginStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetamaskLoginStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metamaskLoginStartMutation, { data, loading, error }] = useMetamaskLoginStartMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useMetamaskLoginStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MetamaskLoginStartMutation, MetamaskLoginStartMutationVariables>) {
        return ApolloReactHooks.useMutation<MetamaskLoginStartMutation, MetamaskLoginStartMutationVariables>(MetamaskLoginStartDocument, baseOptions);
      }
export type MetamaskLoginStartMutationHookResult = ReturnType<typeof useMetamaskLoginStartMutation>;
export type MetamaskLoginStartMutationResult = ApolloReactCommon.MutationResult<MetamaskLoginStartMutation>;
export type MetamaskLoginStartMutationOptions = ApolloReactCommon.BaseMutationOptions<MetamaskLoginStartMutation, MetamaskLoginStartMutationVariables>;
export const MetamaskLoginDocument = gql`
    mutation MetamaskLogin($address: String!, $signature: String!) {
  metamaskLogin(address: $address, signature: $signature) {
    token
  }
}
    `;
export type MetamaskLoginMutationFn = ApolloReactCommon.MutationFunction<MetamaskLoginMutation, MetamaskLoginMutationVariables>;

/**
 * __useMetamaskLoginMutation__
 *
 * To run a mutation, you first call `useMetamaskLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetamaskLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metamaskLoginMutation, { data, loading, error }] = useMetamaskLoginMutation({
 *   variables: {
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useMetamaskLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MetamaskLoginMutation, MetamaskLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<MetamaskLoginMutation, MetamaskLoginMutationVariables>(MetamaskLoginDocument, baseOptions);
      }
export type MetamaskLoginMutationHookResult = ReturnType<typeof useMetamaskLoginMutation>;
export type MetamaskLoginMutationResult = ApolloReactCommon.MutationResult<MetamaskLoginMutation>;
export type MetamaskLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<MetamaskLoginMutation, MetamaskLoginMutationVariables>;
export const LogoutDocument = gql`
    mutation LOGOUT {
  logout {
    message
  }
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const FetchLatestBlockNumberDocument = gql`
    query FetchLatestBlockNumber {
  blockNumbers(last: 1) {
    number
  }
}
    `;

/**
 * __useFetchLatestBlockNumberQuery__
 *
 * To run a query within a React component, call `useFetchLatestBlockNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLatestBlockNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLatestBlockNumberQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchLatestBlockNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchLatestBlockNumberQuery, FetchLatestBlockNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchLatestBlockNumberQuery, FetchLatestBlockNumberQueryVariables>(FetchLatestBlockNumberDocument, baseOptions);
      }
export function useFetchLatestBlockNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchLatestBlockNumberQuery, FetchLatestBlockNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchLatestBlockNumberQuery, FetchLatestBlockNumberQueryVariables>(FetchLatestBlockNumberDocument, baseOptions);
        }
export type FetchLatestBlockNumberQueryHookResult = ReturnType<typeof useFetchLatestBlockNumberQuery>;
export type FetchLatestBlockNumberLazyQueryHookResult = ReturnType<typeof useFetchLatestBlockNumberLazyQuery>;
export type FetchLatestBlockNumberQueryResult = ApolloReactCommon.QueryResult<FetchLatestBlockNumberQuery, FetchLatestBlockNumberQueryVariables>;
export const CreateOptionPollDocument = gql`
    mutation createOptionPoll($postId: Int!, $question: String!, $options: String!, $endAt: Int!) {
  __typename
  insert_option_poll(
    objects: {post_id: $postId, question: $question, options: $options, end_at: $endAt}
  ) {
    affected_rows
  }
}
    `;
export type CreateOptionPollMutationFn = ApolloReactCommon.MutationFunction<CreateOptionPollMutation, CreateOptionPollMutationVariables>;

/**
 * __useCreateOptionPollMutation__
 *
 * To run a mutation, you first call `useCreateOptionPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOptionPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOptionPollMutation, { data, loading, error }] = useCreateOptionPollMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      question: // value for 'question'
 *      options: // value for 'options'
 *      endAt: // value for 'endAt'
 *   },
 * });
 */
export function useCreateOptionPollMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOptionPollMutation, CreateOptionPollMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOptionPollMutation, CreateOptionPollMutationVariables>(CreateOptionPollDocument, baseOptions);
      }
export type CreateOptionPollMutationHookResult = ReturnType<typeof useCreateOptionPollMutation>;
export type CreateOptionPollMutationResult = ApolloReactCommon.MutationResult<CreateOptionPollMutation>;
export type CreateOptionPollMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOptionPollMutation, CreateOptionPollMutationVariables>;
export const OptionPollDocument = gql`
    query OptionPoll($postId: Int!) {
  option_poll(where: {post_id: {_eq: $postId}}) {
    ...optionPollFields
  }
}
    ${OptionPollFieldsFragmentDoc}`;

/**
 * __useOptionPollQuery__
 *
 * To run a query within a React component, call `useOptionPollQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionPollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionPollQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useOptionPollQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OptionPollQuery, OptionPollQueryVariables>) {
        return ApolloReactHooks.useQuery<OptionPollQuery, OptionPollQueryVariables>(OptionPollDocument, baseOptions);
      }
export function useOptionPollLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OptionPollQuery, OptionPollQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OptionPollQuery, OptionPollQueryVariables>(OptionPollDocument, baseOptions);
        }
export type OptionPollQueryHookResult = ReturnType<typeof useOptionPollQuery>;
export type OptionPollLazyQueryHookResult = ReturnType<typeof useOptionPollLazyQuery>;
export type OptionPollQueryResult = ApolloReactCommon.QueryResult<OptionPollQuery, OptionPollQueryVariables>;
export const OptionPollVotesDocument = gql`
    query OptionPollVotes($optionPollId: Int!) {
  option_poll_votes(where: {option_poll_id: {_eq: $optionPollId}}) {
    ...optionPollVotesFields
  }
}
    ${OptionPollVotesFieldsFragmentDoc}`;

/**
 * __useOptionPollVotesQuery__
 *
 * To run a query within a React component, call `useOptionPollVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptionPollVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptionPollVotesQuery({
 *   variables: {
 *      optionPollId: // value for 'optionPollId'
 *   },
 * });
 */
export function useOptionPollVotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OptionPollVotesQuery, OptionPollVotesQueryVariables>) {
        return ApolloReactHooks.useQuery<OptionPollVotesQuery, OptionPollVotesQueryVariables>(OptionPollVotesDocument, baseOptions);
      }
export function useOptionPollVotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OptionPollVotesQuery, OptionPollVotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OptionPollVotesQuery, OptionPollVotesQueryVariables>(OptionPollVotesDocument, baseOptions);
        }
export type OptionPollVotesQueryHookResult = ReturnType<typeof useOptionPollVotesQuery>;
export type OptionPollVotesLazyQueryHookResult = ReturnType<typeof useOptionPollVotesLazyQuery>;
export type OptionPollVotesQueryResult = ApolloReactCommon.QueryResult<OptionPollVotesQuery, OptionPollVotesQueryVariables>;
export const AddOptionPollVoteDocument = gql`
    mutation AddOptionPollVote($optionPollId: Int!, $userId: Int!, $option: String!) {
  __typename
  insert_option_poll_votes_one(
    object: {option_poll_id: $optionPollId, user_id: $userId, option: $option}
  ) {
    id
  }
}
    `;
export type AddOptionPollVoteMutationFn = ApolloReactCommon.MutationFunction<AddOptionPollVoteMutation, AddOptionPollVoteMutationVariables>;

/**
 * __useAddOptionPollVoteMutation__
 *
 * To run a mutation, you first call `useAddOptionPollVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOptionPollVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOptionPollVoteMutation, { data, loading, error }] = useAddOptionPollVoteMutation({
 *   variables: {
 *      optionPollId: // value for 'optionPollId'
 *      userId: // value for 'userId'
 *      option: // value for 'option'
 *   },
 * });
 */
export function useAddOptionPollVoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddOptionPollVoteMutation, AddOptionPollVoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddOptionPollVoteMutation, AddOptionPollVoteMutationVariables>(AddOptionPollVoteDocument, baseOptions);
      }
export type AddOptionPollVoteMutationHookResult = ReturnType<typeof useAddOptionPollVoteMutation>;
export type AddOptionPollVoteMutationResult = ApolloReactCommon.MutationResult<AddOptionPollVoteMutation>;
export type AddOptionPollVoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddOptionPollVoteMutation, AddOptionPollVoteMutationVariables>;
export const DeleteOptionPollVoteDocument = gql`
    mutation DeleteOptionPollVote($optionPollId: Int!, $userId: Int!) {
  delete_option_poll_votes(
    where: {_and: [{option_poll_id: {_eq: $optionPollId}}, {user_id: {_eq: $userId}}]}
  ) {
    affected_rows
  }
}
    `;
export type DeleteOptionPollVoteMutationFn = ApolloReactCommon.MutationFunction<DeleteOptionPollVoteMutation, DeleteOptionPollVoteMutationVariables>;

/**
 * __useDeleteOptionPollVoteMutation__
 *
 * To run a mutation, you first call `useDeleteOptionPollVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionPollVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionPollVoteMutation, { data, loading, error }] = useDeleteOptionPollVoteMutation({
 *   variables: {
 *      optionPollId: // value for 'optionPollId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteOptionPollVoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOptionPollVoteMutation, DeleteOptionPollVoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOptionPollVoteMutation, DeleteOptionPollVoteMutationVariables>(DeleteOptionPollVoteDocument, baseOptions);
      }
export type DeleteOptionPollVoteMutationHookResult = ReturnType<typeof useDeleteOptionPollVoteMutation>;
export type DeleteOptionPollVoteMutationResult = ApolloReactCommon.MutationResult<DeleteOptionPollVoteMutation>;
export type DeleteOptionPollVoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOptionPollVoteMutation, DeleteOptionPollVoteMutationVariables>;
export const GetCouncilMembersDocument = gql`
    query getCouncilMembers {
  councils(last: 1) {
    members {
      id
      address
    }
  }
}
    `;

/**
 * __useGetCouncilMembersQuery__
 *
 * To run a query within a React component, call `useGetCouncilMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouncilMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouncilMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCouncilMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCouncilMembersQuery, GetCouncilMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCouncilMembersQuery, GetCouncilMembersQueryVariables>(GetCouncilMembersDocument, baseOptions);
      }
export function useGetCouncilMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCouncilMembersQuery, GetCouncilMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCouncilMembersQuery, GetCouncilMembersQueryVariables>(GetCouncilMembersDocument, baseOptions);
        }
export type GetCouncilMembersQueryHookResult = ReturnType<typeof useGetCouncilMembersQuery>;
export type GetCouncilMembersLazyQueryHookResult = ReturnType<typeof useGetCouncilMembersLazyQuery>;
export type GetCouncilMembersQueryResult = ApolloReactCommon.QueryResult<GetCouncilMembersQuery, GetCouncilMembersQueryVariables>;
export const PollDocument = gql`
    query Poll($postId: Int!) {
  poll(where: {post_id: {_eq: $postId}}) {
    ...pollFields
  }
}
    ${PollFieldsFragmentDoc}`;

/**
 * __usePollQuery__
 *
 * To run a query within a React component, call `usePollQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function usePollQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PollQuery, PollQueryVariables>) {
        return ApolloReactHooks.useQuery<PollQuery, PollQueryVariables>(PollDocument, baseOptions);
      }
export function usePollLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PollQuery, PollQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PollQuery, PollQueryVariables>(PollDocument, baseOptions);
        }
export type PollQueryHookResult = ReturnType<typeof usePollQuery>;
export type PollLazyQueryHookResult = ReturnType<typeof usePollLazyQuery>;
export type PollQueryResult = ApolloReactCommon.QueryResult<PollQuery, PollQueryVariables>;
export const PollVotesDocument = gql`
    query PollVotes($pollId: Int!) {
  poll_votes(where: {poll_id: {_eq: $pollId}}) {
    ...pollVotesFields
  }
}
    ${PollVotesFieldsFragmentDoc}`;

/**
 * __usePollVotesQuery__
 *
 * To run a query within a React component, call `usePollVotesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePollVotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePollVotesQuery({
 *   variables: {
 *      pollId: // value for 'pollId'
 *   },
 * });
 */
export function usePollVotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PollVotesQuery, PollVotesQueryVariables>) {
        return ApolloReactHooks.useQuery<PollVotesQuery, PollVotesQueryVariables>(PollVotesDocument, baseOptions);
      }
export function usePollVotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PollVotesQuery, PollVotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PollVotesQuery, PollVotesQueryVariables>(PollVotesDocument, baseOptions);
        }
export type PollVotesQueryHookResult = ReturnType<typeof usePollVotesQuery>;
export type PollVotesLazyQueryHookResult = ReturnType<typeof usePollVotesLazyQuery>;
export type PollVotesQueryResult = ApolloReactCommon.QueryResult<PollVotesQuery, PollVotesQueryVariables>;
export const AddPollVoteDocument = gql`
    mutation AddPollVote($pollId: Int!, $userId: Int!, $vote: bpchar!) {
  __typename
  insert_poll_votes_one(object: {poll_id: $pollId, user_id: $userId, vote: $vote}) {
    id
  }
}
    `;
export type AddPollVoteMutationFn = ApolloReactCommon.MutationFunction<AddPollVoteMutation, AddPollVoteMutationVariables>;

/**
 * __useAddPollVoteMutation__
 *
 * To run a mutation, you first call `useAddPollVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPollVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPollVoteMutation, { data, loading, error }] = useAddPollVoteMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      userId: // value for 'userId'
 *      vote: // value for 'vote'
 *   },
 * });
 */
export function useAddPollVoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPollVoteMutation, AddPollVoteMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPollVoteMutation, AddPollVoteMutationVariables>(AddPollVoteDocument, baseOptions);
      }
export type AddPollVoteMutationHookResult = ReturnType<typeof useAddPollVoteMutation>;
export type AddPollVoteMutationResult = ApolloReactCommon.MutationResult<AddPollVoteMutation>;
export type AddPollVoteMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPollVoteMutation, AddPollVoteMutationVariables>;
export const DeleteVoteDocument = gql`
    mutation DeleteVote($pollId: Int!, $userId: Int!) {
  delete_poll_votes(
    where: {_and: [{poll_id: {_eq: $pollId}}, {user_id: {_eq: $userId}}]}
  ) {
    affected_rows
  }
}
    `;
export type DeleteVoteMutationFn = ApolloReactCommon.MutationFunction<DeleteVoteMutation, DeleteVoteMutationVariables>;

/**
 * __useDeleteVoteMutation__
 *
 * To run a mutation, you first call `useDeleteVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVoteMutation, { data, loading, error }] = useDeleteVoteMutation({
 *   variables: {
 *      pollId: // value for 'pollId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteVoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteVoteMutation, DeleteVoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteVoteMutation, DeleteVoteMutationVariables>(DeleteVoteDocument, baseOptions);
      }
export type DeleteVoteMutationHookResult = ReturnType<typeof useDeleteVoteMutation>;
export type DeleteVoteMutationResult = ApolloReactCommon.MutationResult<DeleteVoteMutation>;
export type DeleteVoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteVoteMutation, DeleteVoteMutationVariables>;
export const CouncilAtBlockNumberDocument = gql`
    query councilAtBlockNumber($blockNumber: Int!) {
  councils(
    where: {blockNumber: {number_lte: $blockNumber}}
    orderBy: id_DESC
    first: 1
  ) {
    members {
      address
    }
  }
}
    `;

/**
 * __useCouncilAtBlockNumberQuery__
 *
 * To run a query within a React component, call `useCouncilAtBlockNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouncilAtBlockNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouncilAtBlockNumberQuery({
 *   variables: {
 *      blockNumber: // value for 'blockNumber'
 *   },
 * });
 */
export function useCouncilAtBlockNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CouncilAtBlockNumberQuery, CouncilAtBlockNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<CouncilAtBlockNumberQuery, CouncilAtBlockNumberQueryVariables>(CouncilAtBlockNumberDocument, baseOptions);
      }
export function useCouncilAtBlockNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CouncilAtBlockNumberQuery, CouncilAtBlockNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CouncilAtBlockNumberQuery, CouncilAtBlockNumberQueryVariables>(CouncilAtBlockNumberDocument, baseOptions);
        }
export type CouncilAtBlockNumberQueryHookResult = ReturnType<typeof useCouncilAtBlockNumberQuery>;
export type CouncilAtBlockNumberLazyQueryHookResult = ReturnType<typeof useCouncilAtBlockNumberLazyQuery>;
export type CouncilAtBlockNumberQueryResult = ApolloReactCommon.QueryResult<CouncilAtBlockNumberQuery, CouncilAtBlockNumberQueryVariables>;
export const EditPollDocument = gql`
    mutation EditPoll($id: Int!, $blockEnd: Int!) {
  update_poll(where: {id: {_eq: $id}}, _set: {block_end: $blockEnd}) {
    affected_rows
  }
}
    `;
export type EditPollMutationFn = ApolloReactCommon.MutationFunction<EditPollMutation, EditPollMutationVariables>;

/**
 * __useEditPollMutation__
 *
 * To run a mutation, you first call `useEditPollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPollMutation, { data, loading, error }] = useEditPollMutation({
 *   variables: {
 *      id: // value for 'id'
 *      blockEnd: // value for 'blockEnd'
 *   },
 * });
 */
export function useEditPollMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPollMutation, EditPollMutationVariables>) {
        return ApolloReactHooks.useMutation<EditPollMutation, EditPollMutationVariables>(EditPollDocument, baseOptions);
      }
export type EditPollMutationHookResult = ReturnType<typeof useEditPollMutation>;
export type EditPollMutationResult = ApolloReactCommon.MutationResult<EditPollMutation>;
export type EditPollMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPollMutation, EditPollMutationVariables>;
export const EditPostDocument = gql`
    mutation EditPost($id: Int!, $content: String!, $title: String!) {
  update_posts(where: {id: {_eq: $id}}, _set: {content: $content, title: $title}) {
    affected_rows
  }
}
    `;
export type EditPostMutationFn = ApolloReactCommon.MutationFunction<EditPostMutation, EditPostMutationVariables>;

/**
 * __useEditPostMutation__
 *
 * To run a mutation, you first call `useEditPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPostMutation, { data, loading, error }] = useEditPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useEditPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditPostMutation, EditPostMutationVariables>) {
        return ApolloReactHooks.useMutation<EditPostMutation, EditPostMutationVariables>(EditPostDocument, baseOptions);
      }
export type EditPostMutationHookResult = ReturnType<typeof useEditPostMutation>;
export type EditPostMutationResult = ApolloReactCommon.MutationResult<EditPostMutation>;
export type EditPostMutationOptions = ApolloReactCommon.BaseMutationOptions<EditPostMutation, EditPostMutationVariables>;
export const AddPostCommentDocument = gql`
    mutation AddPostComment($network: String!, $authorId: Int!, $content: String!, $postId: Int!) {
  __typename
  insert_comments(
    objects: {author_id: $authorId, content: $content, post_id: $postId, network: $network}
  ) {
    affected_rows
  }
}
    `;
export type AddPostCommentMutationFn = ApolloReactCommon.MutationFunction<AddPostCommentMutation, AddPostCommentMutationVariables>;

/**
 * __useAddPostCommentMutation__
 *
 * To run a mutation, you first call `useAddPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostCommentMutation, { data, loading, error }] = useAddPostCommentMutation({
 *   variables: {
 *      network: // value for 'network'
 *      authorId: // value for 'authorId'
 *      content: // value for 'content'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useAddPostCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPostCommentMutation, AddPostCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPostCommentMutation, AddPostCommentMutationVariables>(AddPostCommentDocument, baseOptions);
      }
export type AddPostCommentMutationHookResult = ReturnType<typeof useAddPostCommentMutation>;
export type AddPostCommentMutationResult = ApolloReactCommon.MutationResult<AddPostCommentMutation>;
export type AddPostCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPostCommentMutation, AddPostCommentMutationVariables>;
export const AddCommentReplyDocument = gql`
    mutation AddCommentReply($network: String!, $authorId: Int!, $content: String!, $commentId: uuid!) {
  __typename
  insert_replies(
    objects: {author_id: $authorId, content: $content, comment_id: $commentId, network: $network}
  ) {
    affected_rows
  }
}
    `;
export type AddCommentReplyMutationFn = ApolloReactCommon.MutationFunction<AddCommentReplyMutation, AddCommentReplyMutationVariables>;

/**
 * __useAddCommentReplyMutation__
 *
 * To run a mutation, you first call `useAddCommentReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentReplyMutation, { data, loading, error }] = useAddCommentReplyMutation({
 *   variables: {
 *      network: // value for 'network'
 *      authorId: // value for 'authorId'
 *      content: // value for 'content'
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useAddCommentReplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCommentReplyMutation, AddCommentReplyMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCommentReplyMutation, AddCommentReplyMutationVariables>(AddCommentReplyDocument, baseOptions);
      }
export type AddCommentReplyMutationHookResult = ReturnType<typeof useAddCommentReplyMutation>;
export type AddCommentReplyMutationResult = ApolloReactCommon.MutationResult<AddCommentReplyMutation>;
export type AddCommentReplyMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCommentReplyMutation, AddCommentReplyMutationVariables>;
export const EditCommentReplyDocument = gql`
    mutation EditCommentReply($id: uuid!, $content: String!) {
  update_replies(where: {id: {_eq: $id}}, _set: {content: $content}) {
    affected_rows
  }
}
    `;
export type EditCommentReplyMutationFn = ApolloReactCommon.MutationFunction<EditCommentReplyMutation, EditCommentReplyMutationVariables>;

/**
 * __useEditCommentReplyMutation__
 *
 * To run a mutation, you first call `useEditCommentReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentReplyMutation, { data, loading, error }] = useEditCommentReplyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useEditCommentReplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCommentReplyMutation, EditCommentReplyMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCommentReplyMutation, EditCommentReplyMutationVariables>(EditCommentReplyDocument, baseOptions);
      }
export type EditCommentReplyMutationHookResult = ReturnType<typeof useEditCommentReplyMutation>;
export type EditCommentReplyMutationResult = ApolloReactCommon.MutationResult<EditCommentReplyMutation>;
export type EditCommentReplyMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCommentReplyMutation, EditCommentReplyMutationVariables>;
export const DeleteCommentReplyDocument = gql`
    mutation DeleteCommentReply($id: uuid!) {
  delete_replies(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCommentReplyMutationFn = ApolloReactCommon.MutationFunction<DeleteCommentReplyMutation, DeleteCommentReplyMutationVariables>;

/**
 * __useDeleteCommentReplyMutation__
 *
 * To run a mutation, you first call `useDeleteCommentReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentReplyMutation, { data, loading, error }] = useDeleteCommentReplyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentReplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommentReplyMutation, DeleteCommentReplyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCommentReplyMutation, DeleteCommentReplyMutationVariables>(DeleteCommentReplyDocument, baseOptions);
      }
export type DeleteCommentReplyMutationHookResult = ReturnType<typeof useDeleteCommentReplyMutation>;
export type DeleteCommentReplyMutationResult = ApolloReactCommon.MutationResult<DeleteCommentReplyMutation>;
export type DeleteCommentReplyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCommentReplyMutation, DeleteCommentReplyMutationVariables>;
export const PostReactionsDocument = gql`
    query PostReactions($postId: Int!) {
  post_reactions(where: {post_id: {_eq: $postId}}) {
    ...postReactionFields
  }
}
    ${PostReactionFieldsFragmentDoc}`;

/**
 * __usePostReactionsQuery__
 *
 * To run a query within a React component, call `usePostReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostReactionsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function usePostReactionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostReactionsQuery, PostReactionsQueryVariables>) {
        return ApolloReactHooks.useQuery<PostReactionsQuery, PostReactionsQueryVariables>(PostReactionsDocument, baseOptions);
      }
export function usePostReactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostReactionsQuery, PostReactionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostReactionsQuery, PostReactionsQueryVariables>(PostReactionsDocument, baseOptions);
        }
export type PostReactionsQueryHookResult = ReturnType<typeof usePostReactionsQuery>;
export type PostReactionsLazyQueryHookResult = ReturnType<typeof usePostReactionsLazyQuery>;
export type PostReactionsQueryResult = ApolloReactCommon.QueryResult<PostReactionsQuery, PostReactionsQueryVariables>;
export const CommentReactionsDocument = gql`
    query CommentReactions($commentId: uuid!) {
  comment_reactions(where: {comment_id: {_eq: $commentId}}) {
    ...commentReactionFields
  }
}
    ${CommentReactionFieldsFragmentDoc}`;

/**
 * __useCommentReactionsQuery__
 *
 * To run a query within a React component, call `useCommentReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentReactionsQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useCommentReactionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommentReactionsQuery, CommentReactionsQueryVariables>) {
        return ApolloReactHooks.useQuery<CommentReactionsQuery, CommentReactionsQueryVariables>(CommentReactionsDocument, baseOptions);
      }
export function useCommentReactionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommentReactionsQuery, CommentReactionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommentReactionsQuery, CommentReactionsQueryVariables>(CommentReactionsDocument, baseOptions);
        }
export type CommentReactionsQueryHookResult = ReturnType<typeof useCommentReactionsQuery>;
export type CommentReactionsLazyQueryHookResult = ReturnType<typeof useCommentReactionsLazyQuery>;
export type CommentReactionsQueryResult = ApolloReactCommon.QueryResult<CommentReactionsQuery, CommentReactionsQueryVariables>;
export const AddPostReactionDocument = gql`
    mutation AddPostReaction($postId: Int!, $userId: Int!, $reaction: bpchar!) {
  __typename
  insert_post_reactions(
    objects: {post_id: $postId, user_id: $userId, reaction: $reaction}
  ) {
    affected_rows
  }
}
    `;
export type AddPostReactionMutationFn = ApolloReactCommon.MutationFunction<AddPostReactionMutation, AddPostReactionMutationVariables>;

/**
 * __useAddPostReactionMutation__
 *
 * To run a mutation, you first call `useAddPostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostReactionMutation, { data, loading, error }] = useAddPostReactionMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      userId: // value for 'userId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useAddPostReactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPostReactionMutation, AddPostReactionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPostReactionMutation, AddPostReactionMutationVariables>(AddPostReactionDocument, baseOptions);
      }
export type AddPostReactionMutationHookResult = ReturnType<typeof useAddPostReactionMutation>;
export type AddPostReactionMutationResult = ApolloReactCommon.MutationResult<AddPostReactionMutation>;
export type AddPostReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPostReactionMutation, AddPostReactionMutationVariables>;
export const AddCommentReactionDocument = gql`
    mutation AddCommentReaction($commentId: uuid!, $userId: Int!, $reaction: bpchar!) {
  __typename
  insert_comment_reactions(
    objects: {comment_id: $commentId, user_id: $userId, reaction: $reaction}
  ) {
    affected_rows
  }
}
    `;
export type AddCommentReactionMutationFn = ApolloReactCommon.MutationFunction<AddCommentReactionMutation, AddCommentReactionMutationVariables>;

/**
 * __useAddCommentReactionMutation__
 *
 * To run a mutation, you first call `useAddCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentReactionMutation, { data, loading, error }] = useAddCommentReactionMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      userId: // value for 'userId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useAddCommentReactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCommentReactionMutation, AddCommentReactionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCommentReactionMutation, AddCommentReactionMutationVariables>(AddCommentReactionDocument, baseOptions);
      }
export type AddCommentReactionMutationHookResult = ReturnType<typeof useAddCommentReactionMutation>;
export type AddCommentReactionMutationResult = ApolloReactCommon.MutationResult<AddCommentReactionMutation>;
export type AddCommentReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCommentReactionMutation, AddCommentReactionMutationVariables>;
export const DeletePostReactionDocument = gql`
    mutation DeletePostReaction($postId: Int!, $userId: Int!, $reaction: bpchar!) {
  delete_post_reactions(
    where: {_and: [{post_id: {_eq: $postId}}, {user_id: {_eq: $userId}}, {reaction: {_eq: $reaction}}]}
  ) {
    affected_rows
  }
}
    `;
export type DeletePostReactionMutationFn = ApolloReactCommon.MutationFunction<DeletePostReactionMutation, DeletePostReactionMutationVariables>;

/**
 * __useDeletePostReactionMutation__
 *
 * To run a mutation, you first call `useDeletePostReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostReactionMutation, { data, loading, error }] = useDeletePostReactionMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      userId: // value for 'userId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useDeletePostReactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePostReactionMutation, DeletePostReactionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePostReactionMutation, DeletePostReactionMutationVariables>(DeletePostReactionDocument, baseOptions);
      }
export type DeletePostReactionMutationHookResult = ReturnType<typeof useDeletePostReactionMutation>;
export type DeletePostReactionMutationResult = ApolloReactCommon.MutationResult<DeletePostReactionMutation>;
export type DeletePostReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePostReactionMutation, DeletePostReactionMutationVariables>;
export const DeleteCommentReactionDocument = gql`
    mutation DeleteCommentReaction($commentId: uuid!, $userId: Int!, $reaction: bpchar!) {
  delete_comment_reactions(
    where: {_and: [{comment_id: {_eq: $commentId}}, {user_id: {_eq: $userId}}, {reaction: {_eq: $reaction}}]}
  ) {
    affected_rows
  }
}
    `;
export type DeleteCommentReactionMutationFn = ApolloReactCommon.MutationFunction<DeleteCommentReactionMutation, DeleteCommentReactionMutationVariables>;

/**
 * __useDeleteCommentReactionMutation__
 *
 * To run a mutation, you first call `useDeleteCommentReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentReactionMutation, { data, loading, error }] = useDeleteCommentReactionMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      userId: // value for 'userId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useDeleteCommentReactionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCommentReactionMutation, DeleteCommentReactionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCommentReactionMutation, DeleteCommentReactionMutationVariables>(DeleteCommentReactionDocument, baseOptions);
      }
export type DeleteCommentReactionMutationHookResult = ReturnType<typeof useDeleteCommentReactionMutation>;
export type DeleteCommentReactionMutationResult = ApolloReactCommon.MutationResult<DeleteCommentReactionMutation>;
export type DeleteCommentReactionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCommentReactionMutation, DeleteCommentReactionMutationVariables>;
export const ReportContentDocument = gql`
    mutation ReportContent($network: String!, $type: String!, $content_id: String!, $reason: String!, $comments: String!) {
  reportContent(
    network: $network
    type: $type
    content_id: $content_id
    reason: $reason
    comments: $comments
  ) {
    message
  }
}
    `;
export type ReportContentMutationFn = ApolloReactCommon.MutationFunction<ReportContentMutation, ReportContentMutationVariables>;

/**
 * __useReportContentMutation__
 *
 * To run a mutation, you first call `useReportContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportContentMutation, { data, loading, error }] = useReportContentMutation({
 *   variables: {
 *      network: // value for 'network'
 *      type: // value for 'type'
 *      content_id: // value for 'content_id'
 *      reason: // value for 'reason'
 *      comments: // value for 'comments'
 *   },
 * });
 */
export function useReportContentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReportContentMutation, ReportContentMutationVariables>) {
        return ApolloReactHooks.useMutation<ReportContentMutation, ReportContentMutationVariables>(ReportContentDocument, baseOptions);
      }
export type ReportContentMutationHookResult = ReturnType<typeof useReportContentMutation>;
export type ReportContentMutationResult = ApolloReactCommon.MutationResult<ReportContentMutation>;
export type ReportContentMutationOptions = ApolloReactCommon.BaseMutationOptions<ReportContentMutation, ReportContentMutationVariables>;
export const SignupDocument = gql`
    mutation SIGNUP($network: String!, $email: String, $password: String!, $username: String!) {
  signup(
    network: $network
    email: $email
    password: $password
    username: $username
  ) {
    token
  }
}
    `;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      network: // value for 'network'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, baseOptions);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<SignupMutation>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const AddressSignupStartDocument = gql`
    mutation AddressSignupStart($address: String!) {
  addressSignupStart(address: $address) {
    message
    signMessage
  }
}
    `;
export type AddressSignupStartMutationFn = ApolloReactCommon.MutationFunction<AddressSignupStartMutation, AddressSignupStartMutationVariables>;

/**
 * __useAddressSignupStartMutation__
 *
 * To run a mutation, you first call `useAddressSignupStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressSignupStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressSignupStartMutation, { data, loading, error }] = useAddressSignupStartMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddressSignupStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddressSignupStartMutation, AddressSignupStartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddressSignupStartMutation, AddressSignupStartMutationVariables>(AddressSignupStartDocument, baseOptions);
      }
export type AddressSignupStartMutationHookResult = ReturnType<typeof useAddressSignupStartMutation>;
export type AddressSignupStartMutationResult = ApolloReactCommon.MutationResult<AddressSignupStartMutation>;
export type AddressSignupStartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddressSignupStartMutation, AddressSignupStartMutationVariables>;
export const AddressSignupConfirmDocument = gql`
    mutation AddressSignupConfirm($network: String!, $address: String!, $signature: String!) {
  addressSignupConfirm(
    network: $network
    address: $address
    signature: $signature
  ) {
    token
  }
}
    `;
export type AddressSignupConfirmMutationFn = ApolloReactCommon.MutationFunction<AddressSignupConfirmMutation, AddressSignupConfirmMutationVariables>;

/**
 * __useAddressSignupConfirmMutation__
 *
 * To run a mutation, you first call `useAddressSignupConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressSignupConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressSignupConfirmMutation, { data, loading, error }] = useAddressSignupConfirmMutation({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useAddressSignupConfirmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddressSignupConfirmMutation, AddressSignupConfirmMutationVariables>) {
        return ApolloReactHooks.useMutation<AddressSignupConfirmMutation, AddressSignupConfirmMutationVariables>(AddressSignupConfirmDocument, baseOptions);
      }
export type AddressSignupConfirmMutationHookResult = ReturnType<typeof useAddressSignupConfirmMutation>;
export type AddressSignupConfirmMutationResult = ApolloReactCommon.MutationResult<AddressSignupConfirmMutation>;
export type AddressSignupConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<AddressSignupConfirmMutation, AddressSignupConfirmMutationVariables>;
export const MetamaskSignupStartDocument = gql`
    mutation MetamaskSignupStart($address: String!) {
  metamaskSignupStart(address: $address) {
    message
    signMessage
  }
}
    `;
export type MetamaskSignupStartMutationFn = ApolloReactCommon.MutationFunction<MetamaskSignupStartMutation, MetamaskSignupStartMutationVariables>;

/**
 * __useMetamaskSignupStartMutation__
 *
 * To run a mutation, you first call `useMetamaskSignupStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetamaskSignupStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metamaskSignupStartMutation, { data, loading, error }] = useMetamaskSignupStartMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useMetamaskSignupStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MetamaskSignupStartMutation, MetamaskSignupStartMutationVariables>) {
        return ApolloReactHooks.useMutation<MetamaskSignupStartMutation, MetamaskSignupStartMutationVariables>(MetamaskSignupStartDocument, baseOptions);
      }
export type MetamaskSignupStartMutationHookResult = ReturnType<typeof useMetamaskSignupStartMutation>;
export type MetamaskSignupStartMutationResult = ApolloReactCommon.MutationResult<MetamaskSignupStartMutation>;
export type MetamaskSignupStartMutationOptions = ApolloReactCommon.BaseMutationOptions<MetamaskSignupStartMutation, MetamaskSignupStartMutationVariables>;
export const MetamaskSignupConfirmDocument = gql`
    mutation MetamaskSignupConfirm($network: String!, $address: String!, $signature: String!) {
  metamaskSignupConfirm(
    network: $network
    address: $address
    signature: $signature
  ) {
    token
  }
}
    `;
export type MetamaskSignupConfirmMutationFn = ApolloReactCommon.MutationFunction<MetamaskSignupConfirmMutation, MetamaskSignupConfirmMutationVariables>;

/**
 * __useMetamaskSignupConfirmMutation__
 *
 * To run a mutation, you first call `useMetamaskSignupConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetamaskSignupConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metamaskSignupConfirmMutation, { data, loading, error }] = useMetamaskSignupConfirmMutation({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useMetamaskSignupConfirmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MetamaskSignupConfirmMutation, MetamaskSignupConfirmMutationVariables>) {
        return ApolloReactHooks.useMutation<MetamaskSignupConfirmMutation, MetamaskSignupConfirmMutationVariables>(MetamaskSignupConfirmDocument, baseOptions);
      }
export type MetamaskSignupConfirmMutationHookResult = ReturnType<typeof useMetamaskSignupConfirmMutation>;
export type MetamaskSignupConfirmMutationResult = ApolloReactCommon.MutationResult<MetamaskSignupConfirmMutation>;
export type MetamaskSignupConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<MetamaskSignupConfirmMutation, MetamaskSignupConfirmMutationVariables>;
export const MetamaskLinkStartDocument = gql`
    mutation MetamaskLinkStart($address: String!) {
  metamaskLinkStart(address: $address) {
    message
    signMessage
  }
}
    `;
export type MetamaskLinkStartMutationFn = ApolloReactCommon.MutationFunction<MetamaskLinkStartMutation, MetamaskLinkStartMutationVariables>;

/**
 * __useMetamaskLinkStartMutation__
 *
 * To run a mutation, you first call `useMetamaskLinkStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetamaskLinkStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metamaskLinkStartMutation, { data, loading, error }] = useMetamaskLinkStartMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useMetamaskLinkStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MetamaskLinkStartMutation, MetamaskLinkStartMutationVariables>) {
        return ApolloReactHooks.useMutation<MetamaskLinkStartMutation, MetamaskLinkStartMutationVariables>(MetamaskLinkStartDocument, baseOptions);
      }
export type MetamaskLinkStartMutationHookResult = ReturnType<typeof useMetamaskLinkStartMutation>;
export type MetamaskLinkStartMutationResult = ApolloReactCommon.MutationResult<MetamaskLinkStartMutation>;
export type MetamaskLinkStartMutationOptions = ApolloReactCommon.BaseMutationOptions<MetamaskLinkStartMutation, MetamaskLinkStartMutationVariables>;
export const MetamaskLinkConfirmDocument = gql`
    mutation MetamaskLinkConfirm($network: String!, $address: String!, $signature: String!) {
  metamaskLinkConfirm(network: $network, address: $address, signature: $signature) {
    message
    token
  }
}
    `;
export type MetamaskLinkConfirmMutationFn = ApolloReactCommon.MutationFunction<MetamaskLinkConfirmMutation, MetamaskLinkConfirmMutationVariables>;

/**
 * __useMetamaskLinkConfirmMutation__
 *
 * To run a mutation, you first call `useMetamaskLinkConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMetamaskLinkConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [metamaskLinkConfirmMutation, { data, loading, error }] = useMetamaskLinkConfirmMutation({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useMetamaskLinkConfirmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MetamaskLinkConfirmMutation, MetamaskLinkConfirmMutationVariables>) {
        return ApolloReactHooks.useMutation<MetamaskLinkConfirmMutation, MetamaskLinkConfirmMutationVariables>(MetamaskLinkConfirmDocument, baseOptions);
      }
export type MetamaskLinkConfirmMutationHookResult = ReturnType<typeof useMetamaskLinkConfirmMutation>;
export type MetamaskLinkConfirmMutationResult = ApolloReactCommon.MutationResult<MetamaskLinkConfirmMutation>;
export type MetamaskLinkConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<MetamaskLinkConfirmMutation, MetamaskLinkConfirmMutationVariables>;
export const PostSubscribeDocument = gql`
    mutation PostSubscribe($postId: Int!) {
  postSubscribe(post_id: $postId) {
    message
  }
}
    `;
export type PostSubscribeMutationFn = ApolloReactCommon.MutationFunction<PostSubscribeMutation, PostSubscribeMutationVariables>;

/**
 * __usePostSubscribeMutation__
 *
 * To run a mutation, you first call `usePostSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postSubscribeMutation, { data, loading, error }] = usePostSubscribeMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function usePostSubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostSubscribeMutation, PostSubscribeMutationVariables>) {
        return ApolloReactHooks.useMutation<PostSubscribeMutation, PostSubscribeMutationVariables>(PostSubscribeDocument, baseOptions);
      }
export type PostSubscribeMutationHookResult = ReturnType<typeof usePostSubscribeMutation>;
export type PostSubscribeMutationResult = ApolloReactCommon.MutationResult<PostSubscribeMutation>;
export type PostSubscribeMutationOptions = ApolloReactCommon.BaseMutationOptions<PostSubscribeMutation, PostSubscribeMutationVariables>;
export const PostUnsubscribeDocument = gql`
    mutation PostUnsubscribe($postId: Int!) {
  postUnsubscribe(post_id: $postId) {
    message
  }
}
    `;
export type PostUnsubscribeMutationFn = ApolloReactCommon.MutationFunction<PostUnsubscribeMutation, PostUnsubscribeMutationVariables>;

/**
 * __usePostUnsubscribeMutation__
 *
 * To run a mutation, you first call `usePostUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postUnsubscribeMutation, { data, loading, error }] = usePostUnsubscribeMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function usePostUnsubscribeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostUnsubscribeMutation, PostUnsubscribeMutationVariables>) {
        return ApolloReactHooks.useMutation<PostUnsubscribeMutation, PostUnsubscribeMutationVariables>(PostUnsubscribeDocument, baseOptions);
      }
export type PostUnsubscribeMutationHookResult = ReturnType<typeof usePostUnsubscribeMutation>;
export type PostUnsubscribeMutationResult = ApolloReactCommon.MutationResult<PostUnsubscribeMutation>;
export type PostUnsubscribeMutationOptions = ApolloReactCommon.BaseMutationOptions<PostUnsubscribeMutation, PostUnsubscribeMutationVariables>;
export const SubscriptionDocument = gql`
    query Subscription($postId: Int!) {
  subscription(post_id: $postId) {
    subscribed
  }
}
    `;

/**
 * __useSubscriptionQuery__
 *
 * To run a query within a React component, call `useSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useSubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, baseOptions);
      }
export function useSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubscriptionQuery, SubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubscriptionQuery, SubscriptionQueryVariables>(SubscriptionDocument, baseOptions);
        }
export type SubscriptionQueryHookResult = ReturnType<typeof useSubscriptionQuery>;
export type SubscriptionLazyQueryHookResult = ReturnType<typeof useSubscriptionLazyQuery>;
export type SubscriptionQueryResult = ApolloReactCommon.QueryResult<SubscriptionQuery, SubscriptionQueryVariables>;
export const Get_Refresh_TokenDocument = gql`
    query GET_REFRESH_TOKEN {
  token {
    token
  }
}
    `;

/**
 * __useGet_Refresh_TokenQuery__
 *
 * To run a query within a React component, call `useGet_Refresh_TokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Refresh_TokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Refresh_TokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Refresh_TokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Get_Refresh_TokenQuery, Get_Refresh_TokenQueryVariables>) {
        return ApolloReactHooks.useQuery<Get_Refresh_TokenQuery, Get_Refresh_TokenQueryVariables>(Get_Refresh_TokenDocument, baseOptions);
      }
export function useGet_Refresh_TokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Get_Refresh_TokenQuery, Get_Refresh_TokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Get_Refresh_TokenQuery, Get_Refresh_TokenQueryVariables>(Get_Refresh_TokenDocument, baseOptions);
        }
export type Get_Refresh_TokenQueryHookResult = ReturnType<typeof useGet_Refresh_TokenQuery>;
export type Get_Refresh_TokenLazyQueryHookResult = ReturnType<typeof useGet_Refresh_TokenLazyQuery>;
export type Get_Refresh_TokenQueryResult = ApolloReactCommon.QueryResult<Get_Refresh_TokenQuery, Get_Refresh_TokenQueryVariables>;
export const GetCalenderEventsDocument = gql`
    query GetCalenderEvents($network: String!) {
  calender_events(where: {network: {_ilike: $network}}) {
    content
    end_time
    id
    module
    network
    start_time
    title
    url
    event_type
    event_id
    status
  }
}
    `;

/**
 * __useGetCalenderEventsQuery__
 *
 * To run a query within a React component, call `useGetCalenderEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalenderEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalenderEventsQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useGetCalenderEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCalenderEventsQuery, GetCalenderEventsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCalenderEventsQuery, GetCalenderEventsQueryVariables>(GetCalenderEventsDocument, baseOptions);
      }
export function useGetCalenderEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCalenderEventsQuery, GetCalenderEventsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCalenderEventsQuery, GetCalenderEventsQueryVariables>(GetCalenderEventsDocument, baseOptions);
        }
export type GetCalenderEventsQueryHookResult = ReturnType<typeof useGetCalenderEventsQuery>;
export type GetCalenderEventsLazyQueryHookResult = ReturnType<typeof useGetCalenderEventsLazyQuery>;
export type GetCalenderEventsQueryResult = ApolloReactCommon.QueryResult<GetCalenderEventsQuery, GetCalenderEventsQueryVariables>;
export const EditCalenderEventDocument = gql`
    mutation EditCalenderEvent($id: Int!, $title: String!, $start_time: timestamptz!, $content: String, $end_time: timestamptz!, $url: String, $module: String, $network: String!) {
  update_calender_events(
    where: {id: {_eq: $id}}
    _set: {title: $title, content: $content, start_time: $start_time, end_time: $end_time, url: $url, module: $module, network: $network}
  ) {
    affected_rows
  }
}
    `;
export type EditCalenderEventMutationFn = ApolloReactCommon.MutationFunction<EditCalenderEventMutation, EditCalenderEventMutationVariables>;

/**
 * __useEditCalenderEventMutation__
 *
 * To run a mutation, you first call `useEditCalenderEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCalenderEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCalenderEventMutation, { data, loading, error }] = useEditCalenderEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      start_time: // value for 'start_time'
 *      content: // value for 'content'
 *      end_time: // value for 'end_time'
 *      url: // value for 'url'
 *      module: // value for 'module'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useEditCalenderEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditCalenderEventMutation, EditCalenderEventMutationVariables>) {
        return ApolloReactHooks.useMutation<EditCalenderEventMutation, EditCalenderEventMutationVariables>(EditCalenderEventDocument, baseOptions);
      }
export type EditCalenderEventMutationHookResult = ReturnType<typeof useEditCalenderEventMutation>;
export type EditCalenderEventMutationResult = ApolloReactCommon.MutationResult<EditCalenderEventMutation>;
export type EditCalenderEventMutationOptions = ApolloReactCommon.BaseMutationOptions<EditCalenderEventMutation, EditCalenderEventMutationVariables>;
export const DeleteCalenderEventDocument = gql`
    mutation DeleteCalenderEvent($id: Int!) {
  delete_calender_events(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type DeleteCalenderEventMutationFn = ApolloReactCommon.MutationFunction<DeleteCalenderEventMutation, DeleteCalenderEventMutationVariables>;

/**
 * __useDeleteCalenderEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalenderEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalenderEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalenderEventMutation, { data, loading, error }] = useDeleteCalenderEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCalenderEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCalenderEventMutation, DeleteCalenderEventMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCalenderEventMutation, DeleteCalenderEventMutationVariables>(DeleteCalenderEventDocument, baseOptions);
      }
export type DeleteCalenderEventMutationHookResult = ReturnType<typeof useDeleteCalenderEventMutation>;
export type DeleteCalenderEventMutationResult = ApolloReactCommon.MutationResult<DeleteCalenderEventMutation>;
export type DeleteCalenderEventMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCalenderEventMutation, DeleteCalenderEventMutationVariables>;
export const AddCalenderEventDocument = gql`
    mutation AddCalenderEvent($title: String!, $start_time: timestamptz!, $content: String, $end_time: timestamptz!, $url: String, $module: String, $network: String!) {
  insert_calender_events(
    objects: {title: $title, start_time: $start_time, end_time: $end_time, content: $content, url: $url, module: $module, network: $network}
  ) {
    affected_rows
  }
}
    `;
export type AddCalenderEventMutationFn = ApolloReactCommon.MutationFunction<AddCalenderEventMutation, AddCalenderEventMutationVariables>;

/**
 * __useAddCalenderEventMutation__
 *
 * To run a mutation, you first call `useAddCalenderEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCalenderEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCalenderEventMutation, { data, loading, error }] = useAddCalenderEventMutation({
 *   variables: {
 *      title: // value for 'title'
 *      start_time: // value for 'start_time'
 *      content: // value for 'content'
 *      end_time: // value for 'end_time'
 *      url: // value for 'url'
 *      module: // value for 'module'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useAddCalenderEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddCalenderEventMutation, AddCalenderEventMutationVariables>) {
        return ApolloReactHooks.useMutation<AddCalenderEventMutation, AddCalenderEventMutationVariables>(AddCalenderEventDocument, baseOptions);
      }
export type AddCalenderEventMutationHookResult = ReturnType<typeof useAddCalenderEventMutation>;
export type AddCalenderEventMutationResult = ApolloReactCommon.MutationResult<AddCalenderEventMutation>;
export type AddCalenderEventMutationOptions = ApolloReactCommon.BaseMutationOptions<AddCalenderEventMutation, AddCalenderEventMutationVariables>;
export const AddProfileDocument = gql`
    mutation addProfile($image: String, $bio: String, $user_id: Int!, $title: String, $badges: String) {
  addProfile(
    image: $image
    bio: $bio
    user_id: $user_id
    title: $title
    badges: $badges
  ) {
    message
  }
}
    `;
export type AddProfileMutationFn = ApolloReactCommon.MutationFunction<AddProfileMutation, AddProfileMutationVariables>;

/**
 * __useAddProfileMutation__
 *
 * To run a mutation, you first call `useAddProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfileMutation, { data, loading, error }] = useAddProfileMutation({
 *   variables: {
 *      image: // value for 'image'
 *      bio: // value for 'bio'
 *      user_id: // value for 'user_id'
 *      title: // value for 'title'
 *      badges: // value for 'badges'
 *   },
 * });
 */
export function useAddProfileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProfileMutation, AddProfileMutationVariables>) {
        return ApolloReactHooks.useMutation<AddProfileMutation, AddProfileMutationVariables>(AddProfileDocument, baseOptions);
      }
export type AddProfileMutationHookResult = ReturnType<typeof useAddProfileMutation>;
export type AddProfileMutationResult = ApolloReactCommon.MutationResult<AddProfileMutation>;
export type AddProfileMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProfileMutation, AddProfileMutationVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($user_id: Int!) {
  userDetails(user_id: $user_id) {
    bio
    image
    user_id
    title
    badges
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, baseOptions);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, baseOptions);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = ApolloReactCommon.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const LinkDiscussionToOnchainPostDocument = gql`
    mutation linkDiscussionToOnchainPost($discussion_id: Int!, $onchain_link_id: Int!, $author_id: Int!) {
  insert_onchain_post_discussion_link(
    objects: {discussion_post_id: $discussion_id, onchain_link_id: $onchain_link_id, author_id: $author_id}
  ) {
    affected_rows
  }
}
    `;
export type LinkDiscussionToOnchainPostMutationFn = ApolloReactCommon.MutationFunction<LinkDiscussionToOnchainPostMutation, LinkDiscussionToOnchainPostMutationVariables>;

/**
 * __useLinkDiscussionToOnchainPostMutation__
 *
 * To run a mutation, you first call `useLinkDiscussionToOnchainPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkDiscussionToOnchainPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkDiscussionToOnchainPostMutation, { data, loading, error }] = useLinkDiscussionToOnchainPostMutation({
 *   variables: {
 *      discussion_id: // value for 'discussion_id'
 *      onchain_link_id: // value for 'onchain_link_id'
 *      author_id: // value for 'author_id'
 *   },
 * });
 */
export function useLinkDiscussionToOnchainPostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkDiscussionToOnchainPostMutation, LinkDiscussionToOnchainPostMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkDiscussionToOnchainPostMutation, LinkDiscussionToOnchainPostMutationVariables>(LinkDiscussionToOnchainPostDocument, baseOptions);
      }
export type LinkDiscussionToOnchainPostMutationHookResult = ReturnType<typeof useLinkDiscussionToOnchainPostMutation>;
export type LinkDiscussionToOnchainPostMutationResult = ApolloReactCommon.MutationResult<LinkDiscussionToOnchainPostMutation>;
export type LinkDiscussionToOnchainPostMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkDiscussionToOnchainPostMutation, LinkDiscussionToOnchainPostMutationVariables>;
export const GetDiscussionToOnchainPostByOnchainIdDocument = gql`
    query GetDiscussionToOnchainPostByOnchainId($onchain_link_id: Int!) {
  onchain_post_discussion_link(
    where: {onchain_link_id: {_eq: $onchain_link_id}}
    order_by: {updated_at: desc}
  ) {
    author_id
    discussion_post_id
    id
    onchain_link_id
  }
}
    `;

/**
 * __useGetDiscussionToOnchainPostByOnchainIdQuery__
 *
 * To run a query within a React component, call `useGetDiscussionToOnchainPostByOnchainIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionToOnchainPostByOnchainIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionToOnchainPostByOnchainIdQuery({
 *   variables: {
 *      onchain_link_id: // value for 'onchain_link_id'
 *   },
 * });
 */
export function useGetDiscussionToOnchainPostByOnchainIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscussionToOnchainPostByOnchainIdQuery, GetDiscussionToOnchainPostByOnchainIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscussionToOnchainPostByOnchainIdQuery, GetDiscussionToOnchainPostByOnchainIdQueryVariables>(GetDiscussionToOnchainPostByOnchainIdDocument, baseOptions);
      }
export function useGetDiscussionToOnchainPostByOnchainIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscussionToOnchainPostByOnchainIdQuery, GetDiscussionToOnchainPostByOnchainIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscussionToOnchainPostByOnchainIdQuery, GetDiscussionToOnchainPostByOnchainIdQueryVariables>(GetDiscussionToOnchainPostByOnchainIdDocument, baseOptions);
        }
export type GetDiscussionToOnchainPostByOnchainIdQueryHookResult = ReturnType<typeof useGetDiscussionToOnchainPostByOnchainIdQuery>;
export type GetDiscussionToOnchainPostByOnchainIdLazyQueryHookResult = ReturnType<typeof useGetDiscussionToOnchainPostByOnchainIdLazyQuery>;
export type GetDiscussionToOnchainPostByOnchainIdQueryResult = ApolloReactCommon.QueryResult<GetDiscussionToOnchainPostByOnchainIdQuery, GetDiscussionToOnchainPostByOnchainIdQueryVariables>;
export const GetDiscussionToOnchainPostByDiscussionIdDocument = gql`
    query GetDiscussionToOnchainPostByDiscussionId($discussion_post_id: Int!) {
  onchain_post_discussion_link(
    where: {discussion_post_id: {_eq: $discussion_post_id}}
    order_by: {updated_at: desc}
  ) {
    author_id
    discussion_post_id
    id
    onchain_link_id
  }
}
    `;

/**
 * __useGetDiscussionToOnchainPostByDiscussionIdQuery__
 *
 * To run a query within a React component, call `useGetDiscussionToOnchainPostByDiscussionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDiscussionToOnchainPostByDiscussionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDiscussionToOnchainPostByDiscussionIdQuery({
 *   variables: {
 *      discussion_post_id: // value for 'discussion_post_id'
 *   },
 * });
 */
export function useGetDiscussionToOnchainPostByDiscussionIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDiscussionToOnchainPostByDiscussionIdQuery, GetDiscussionToOnchainPostByDiscussionIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDiscussionToOnchainPostByDiscussionIdQuery, GetDiscussionToOnchainPostByDiscussionIdQueryVariables>(GetDiscussionToOnchainPostByDiscussionIdDocument, baseOptions);
      }
export function useGetDiscussionToOnchainPostByDiscussionIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDiscussionToOnchainPostByDiscussionIdQuery, GetDiscussionToOnchainPostByDiscussionIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDiscussionToOnchainPostByDiscussionIdQuery, GetDiscussionToOnchainPostByDiscussionIdQueryVariables>(GetDiscussionToOnchainPostByDiscussionIdDocument, baseOptions);
        }
export type GetDiscussionToOnchainPostByDiscussionIdQueryHookResult = ReturnType<typeof useGetDiscussionToOnchainPostByDiscussionIdQuery>;
export type GetDiscussionToOnchainPostByDiscussionIdLazyQueryHookResult = ReturnType<typeof useGetDiscussionToOnchainPostByDiscussionIdLazyQuery>;
export type GetDiscussionToOnchainPostByDiscussionIdQueryResult = ApolloReactCommon.QueryResult<GetDiscussionToOnchainPostByDiscussionIdQuery, GetDiscussionToOnchainPostByDiscussionIdQueryVariables>;
export const GetProposalStatusDocument = gql`
    query GetProposalStatus($onchain_network_proposal_id: String!) {
  proposal_tracker(
    where: {onchain_network_proposal_id: {_eq: $onchain_network_proposal_id}}
  ) {
    deadline
    id
    onchain_network_proposal_id
    status
  }
}
    `;

/**
 * __useGetProposalStatusQuery__
 *
 * To run a query within a React component, call `useGetProposalStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProposalStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProposalStatusQuery({
 *   variables: {
 *      onchain_network_proposal_id: // value for 'onchain_network_proposal_id'
 *   },
 * });
 */
export function useGetProposalStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProposalStatusQuery, GetProposalStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProposalStatusQuery, GetProposalStatusQueryVariables>(GetProposalStatusDocument, baseOptions);
      }
export function useGetProposalStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProposalStatusQuery, GetProposalStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProposalStatusQuery, GetProposalStatusQueryVariables>(GetProposalStatusDocument, baseOptions);
        }
export type GetProposalStatusQueryHookResult = ReturnType<typeof useGetProposalStatusQuery>;
export type GetProposalStatusLazyQueryHookResult = ReturnType<typeof useGetProposalStatusLazyQuery>;
export type GetProposalStatusQueryResult = ApolloReactCommon.QueryResult<GetProposalStatusQuery, GetProposalStatusQueryVariables>;
export const CreateProposalTrackerDocument = gql`
    mutation createProposalTracker($deadline: String!, $network: String!, $onchain_network_proposal_id: String!, $status: String!, $start_time: String!) {
  createProposalTracker(
    deadline: $deadline
    network: $network
    onchain_network_proposal_id: $onchain_network_proposal_id
    status: $status
    start_time: $start_time
  ) {
    message
  }
}
    `;
export type CreateProposalTrackerMutationFn = ApolloReactCommon.MutationFunction<CreateProposalTrackerMutation, CreateProposalTrackerMutationVariables>;

/**
 * __useCreateProposalTrackerMutation__
 *
 * To run a mutation, you first call `useCreateProposalTrackerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProposalTrackerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProposalTrackerMutation, { data, loading, error }] = useCreateProposalTrackerMutation({
 *   variables: {
 *      deadline: // value for 'deadline'
 *      network: // value for 'network'
 *      onchain_network_proposal_id: // value for 'onchain_network_proposal_id'
 *      status: // value for 'status'
 *      start_time: // value for 'start_time'
 *   },
 * });
 */
export function useCreateProposalTrackerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProposalTrackerMutation, CreateProposalTrackerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProposalTrackerMutation, CreateProposalTrackerMutationVariables>(CreateProposalTrackerDocument, baseOptions);
      }
export type CreateProposalTrackerMutationHookResult = ReturnType<typeof useCreateProposalTrackerMutation>;
export type CreateProposalTrackerMutationResult = ApolloReactCommon.MutationResult<CreateProposalTrackerMutation>;
export type CreateProposalTrackerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProposalTrackerMutation, CreateProposalTrackerMutationVariables>;
export const UpdateProposalTrackerDocument = gql`
    mutation updateProposalTracker($id: Int!, $network: String!, $status: String!) {
  updateProposalTracker(id: $id, network: $network, status: $status) {
    message
  }
}
    `;
export type UpdateProposalTrackerMutationFn = ApolloReactCommon.MutationFunction<UpdateProposalTrackerMutation, UpdateProposalTrackerMutationVariables>;

/**
 * __useUpdateProposalTrackerMutation__
 *
 * To run a mutation, you first call `useUpdateProposalTrackerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProposalTrackerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProposalTrackerMutation, { data, loading, error }] = useUpdateProposalTrackerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      network: // value for 'network'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateProposalTrackerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProposalTrackerMutation, UpdateProposalTrackerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProposalTrackerMutation, UpdateProposalTrackerMutationVariables>(UpdateProposalTrackerDocument, baseOptions);
      }
export type UpdateProposalTrackerMutationHookResult = ReturnType<typeof useUpdateProposalTrackerMutation>;
export type UpdateProposalTrackerMutationResult = ApolloReactCommon.MutationResult<UpdateProposalTrackerMutation>;
export type UpdateProposalTrackerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProposalTrackerMutation, UpdateProposalTrackerMutationVariables>;
export const GetBlockchainEndpointsDocument = gql`
    query GetBlockchainEndpoints($network: String!) {
  blockchain_endpoints(where: {network: {_ilike: $network}}) {
    is_active
    display_label
    endpoint
    id
    network
  }
}
    `;

/**
 * __useGetBlockchainEndpointsQuery__
 *
 * To run a query within a React component, call `useGetBlockchainEndpointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockchainEndpointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockchainEndpointsQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useGetBlockchainEndpointsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBlockchainEndpointsQuery, GetBlockchainEndpointsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBlockchainEndpointsQuery, GetBlockchainEndpointsQueryVariables>(GetBlockchainEndpointsDocument, baseOptions);
      }
export function useGetBlockchainEndpointsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBlockchainEndpointsQuery, GetBlockchainEndpointsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBlockchainEndpointsQuery, GetBlockchainEndpointsQueryVariables>(GetBlockchainEndpointsDocument, baseOptions);
        }
export type GetBlockchainEndpointsQueryHookResult = ReturnType<typeof useGetBlockchainEndpointsQuery>;
export type GetBlockchainEndpointsLazyQueryHookResult = ReturnType<typeof useGetBlockchainEndpointsLazyQuery>;
export type GetBlockchainEndpointsQueryResult = ApolloReactCommon.QueryResult<GetBlockchainEndpointsQuery, GetBlockchainEndpointsQueryVariables>;
export const UserWithUsernameDocument = gql`
    query userWithUsername($username: String!) {
  userWithUsername(username: $username) {
    badges
    bio
    id
    image
    title
    user_id
  }
}
    `;

/**
 * __useUserWithUsernameQuery__
 *
 * To run a query within a React component, call `useUserWithUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserWithUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserWithUsernameQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useUserWithUsernameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWithUsernameQuery, UserWithUsernameQueryVariables>) {
        return ApolloReactHooks.useQuery<UserWithUsernameQuery, UserWithUsernameQueryVariables>(UserWithUsernameDocument, baseOptions);
      }
export function useUserWithUsernameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWithUsernameQuery, UserWithUsernameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserWithUsernameQuery, UserWithUsernameQueryVariables>(UserWithUsernameDocument, baseOptions);
        }
export type UserWithUsernameQueryHookResult = ReturnType<typeof useUserWithUsernameQuery>;
export type UserWithUsernameLazyQueryHookResult = ReturnType<typeof useUserWithUsernameLazyQuery>;
export type UserWithUsernameQueryResult = ApolloReactCommon.QueryResult<UserWithUsernameQuery, UserWithUsernameQueryVariables>;
export const AllBountyPostsDocument = gql`
    query AllBountyPosts($network: String!, $postType: Int!, $postTopic: Int!, $limit: Int! = 5, $offset: Int! = 0) {
  posts(
    limit: $limit
    offset: $offset
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_bounty_id: {_is_null: false}}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_bounty_id
      onchain_bounty(where: {}) {
        id
        value
        bountyStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllBountyPostsQuery__
 *
 * To run a query within a React component, call `useAllBountyPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBountyPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBountyPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllBountyPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllBountyPostsQuery, AllBountyPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllBountyPostsQuery, AllBountyPostsQueryVariables>(AllBountyPostsDocument, baseOptions);
      }
export function useAllBountyPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllBountyPostsQuery, AllBountyPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllBountyPostsQuery, AllBountyPostsQueryVariables>(AllBountyPostsDocument, baseOptions);
        }
export type AllBountyPostsQueryHookResult = ReturnType<typeof useAllBountyPostsQuery>;
export type AllBountyPostsLazyQueryHookResult = ReturnType<typeof useAllBountyPostsLazyQuery>;
export type AllBountyPostsQueryResult = ApolloReactCommon.QueryResult<AllBountyPostsQuery, AllBountyPostsQueryVariables>;
export const BountyPostAndCommentsDocument = gql`
    query BountyPostAndComments($networkId: String!) {
  posts(where: {onchain_link: {onchain_network_bounty_id: {_eq: $networkId}}}) {
    ...bountyPost
  }
}
    ${BountyPostFragmentDoc}`;

/**
 * __useBountyPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useBountyPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBountyPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBountyPostAndCommentsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useBountyPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BountyPostAndCommentsQuery, BountyPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<BountyPostAndCommentsQuery, BountyPostAndCommentsQueryVariables>(BountyPostAndCommentsDocument, baseOptions);
      }
export function useBountyPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BountyPostAndCommentsQuery, BountyPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BountyPostAndCommentsQuery, BountyPostAndCommentsQueryVariables>(BountyPostAndCommentsDocument, baseOptions);
        }
export type BountyPostAndCommentsQueryHookResult = ReturnType<typeof useBountyPostAndCommentsQuery>;
export type BountyPostAndCommentsLazyQueryHookResult = ReturnType<typeof useBountyPostAndCommentsLazyQuery>;
export type BountyPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<BountyPostAndCommentsQuery, BountyPostAndCommentsQueryVariables>;
export const CreatePostDocument = gql`
    mutation createPost($network: String!, $userId: Int!, $content: String!, $topicId: Int!, $title: String!) {
  __typename
  insert_posts(
    objects: {network: $network, author_id: $userId, content: $content, title: $title, topic_id: $topicId}
  ) {
    affected_rows
    returning {
      id
    }
  }
}
    `;
export type CreatePostMutationFn = ApolloReactCommon.MutationFunction<CreatePostMutation, CreatePostMutationVariables>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      network: // value for 'network'
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *      topicId: // value for 'topicId'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreatePostMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePostMutation, CreatePostMutationVariables>(CreatePostDocument, baseOptions);
      }
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = ApolloReactCommon.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePostMutation, CreatePostMutationVariables>;
export const CreatePollDocument = gql`
    mutation createPoll($postId: Int!, $blockEnd: Int!) {
  __typename
  insert_poll(objects: {post_id: $postId, block_end: $blockEnd}) {
    affected_rows
  }
}
    `;
export type CreatePollMutationFn = ApolloReactCommon.MutationFunction<CreatePollMutation, CreatePollMutationVariables>;

/**
 * __useCreatePollMutation__
 *
 * To run a mutation, you first call `useCreatePollMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePollMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPollMutation, { data, loading, error }] = useCreatePollMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      blockEnd: // value for 'blockEnd'
 *   },
 * });
 */
export function useCreatePollMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePollMutation, CreatePollMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePollMutation, CreatePollMutationVariables>(CreatePollDocument, baseOptions);
      }
export type CreatePollMutationHookResult = ReturnType<typeof useCreatePollMutation>;
export type CreatePollMutationResult = ApolloReactCommon.MutationResult<CreatePollMutation>;
export type CreatePollMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePollMutation, CreatePollMutationVariables>;
export const Post_TopicsDocument = gql`
    query Post_topics {
  post_topics {
    ...topic
  }
}
    ${TopicFragmentDoc}`;

/**
 * __usePost_TopicsQuery__
 *
 * To run a query within a React component, call `usePost_TopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePost_TopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePost_TopicsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePost_TopicsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Post_TopicsQuery, Post_TopicsQueryVariables>) {
        return ApolloReactHooks.useQuery<Post_TopicsQuery, Post_TopicsQueryVariables>(Post_TopicsDocument, baseOptions);
      }
export function usePost_TopicsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Post_TopicsQuery, Post_TopicsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Post_TopicsQuery, Post_TopicsQueryVariables>(Post_TopicsDocument, baseOptions);
        }
export type Post_TopicsQueryHookResult = ReturnType<typeof usePost_TopicsQuery>;
export type Post_TopicsLazyQueryHookResult = ReturnType<typeof usePost_TopicsLazyQuery>;
export type Post_TopicsQueryResult = ApolloReactCommon.QueryResult<Post_TopicsQuery, Post_TopicsQueryVariables>;
export const DiscussionPostAndCommentsDocument = gql`
    query DiscussionPostAndComments($id: Int!) {
  posts(where: {id: {_eq: $id}}) {
    ...discussionPost
  }
}
    ${DiscussionPostFragmentDoc}`;

/**
 * __useDiscussionPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useDiscussionPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionPostAndCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscussionPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiscussionPostAndCommentsQuery, DiscussionPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<DiscussionPostAndCommentsQuery, DiscussionPostAndCommentsQueryVariables>(DiscussionPostAndCommentsDocument, baseOptions);
      }
export function useDiscussionPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiscussionPostAndCommentsQuery, DiscussionPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiscussionPostAndCommentsQuery, DiscussionPostAndCommentsQueryVariables>(DiscussionPostAndCommentsDocument, baseOptions);
        }
export type DiscussionPostAndCommentsQueryHookResult = ReturnType<typeof useDiscussionPostAndCommentsQuery>;
export type DiscussionPostAndCommentsLazyQueryHookResult = ReturnType<typeof useDiscussionPostAndCommentsLazyQuery>;
export type DiscussionPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<DiscussionPostAndCommentsQuery, DiscussionPostAndCommentsQueryVariables>;
export const LatestDiscussionPostsDocument = gql`
    query LatestDiscussionPosts($network: String!, $limit: Int! = 20, $offset: Int! = 0) {
  posts(
    order_by: {last_update: {last_update: desc}}
    limit: $limit
    offset: $offset
    where: {_and: [{type: {id: {_eq: 1}}}, {network: {_eq: $network}}]}
  ) {
    ...postFields
    post_reactions {
      reaction
    }
  }
}
    ${PostFieldsFragmentDoc}`;

/**
 * __useLatestDiscussionPostsQuery__
 *
 * To run a query within a React component, call `useLatestDiscussionPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestDiscussionPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestDiscussionPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLatestDiscussionPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestDiscussionPostsQuery, LatestDiscussionPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestDiscussionPostsQuery, LatestDiscussionPostsQueryVariables>(LatestDiscussionPostsDocument, baseOptions);
      }
export function useLatestDiscussionPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestDiscussionPostsQuery, LatestDiscussionPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestDiscussionPostsQuery, LatestDiscussionPostsQueryVariables>(LatestDiscussionPostsDocument, baseOptions);
        }
export type LatestDiscussionPostsQueryHookResult = ReturnType<typeof useLatestDiscussionPostsQuery>;
export type LatestDiscussionPostsLazyQueryHookResult = ReturnType<typeof useLatestDiscussionPostsLazyQuery>;
export type LatestDiscussionPostsQueryResult = ApolloReactCommon.QueryResult<LatestDiscussionPostsQuery, LatestDiscussionPostsQueryVariables>;
export const DiscussionPostsIdDescDocument = gql`
    query DiscussionPostsIdDesc($network: String!, $limit: Int! = 20, $offset: Int! = 0) {
  posts(
    order_by: {id: desc}
    limit: $limit
    offset: $offset
    where: {_and: [{type: {id: {_eq: 1}}}, {network: {_eq: $network}}]}
  ) {
    ...postFields
    post_reactions {
      reaction
    }
  }
}
    ${PostFieldsFragmentDoc}`;

/**
 * __useDiscussionPostsIdDescQuery__
 *
 * To run a query within a React component, call `useDiscussionPostsIdDescQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionPostsIdDescQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionPostsIdDescQuery({
 *   variables: {
 *      network: // value for 'network'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDiscussionPostsIdDescQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiscussionPostsIdDescQuery, DiscussionPostsIdDescQueryVariables>) {
        return ApolloReactHooks.useQuery<DiscussionPostsIdDescQuery, DiscussionPostsIdDescQueryVariables>(DiscussionPostsIdDescDocument, baseOptions);
      }
export function useDiscussionPostsIdDescLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiscussionPostsIdDescQuery, DiscussionPostsIdDescQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiscussionPostsIdDescQuery, DiscussionPostsIdDescQueryVariables>(DiscussionPostsIdDescDocument, baseOptions);
        }
export type DiscussionPostsIdDescQueryHookResult = ReturnType<typeof useDiscussionPostsIdDescQuery>;
export type DiscussionPostsIdDescLazyQueryHookResult = ReturnType<typeof useDiscussionPostsIdDescLazyQuery>;
export type DiscussionPostsIdDescQueryResult = ApolloReactCommon.QueryResult<DiscussionPostsIdDescQuery, DiscussionPostsIdDescQueryVariables>;
export const DiscussionPostsIdAscDocument = gql`
    query DiscussionPostsIdAsc($network: String!, $limit: Int! = 20, $offset: Int! = 0) {
  posts(
    order_by: {id: asc}
    limit: $limit
    offset: $offset
    where: {_and: [{type: {id: {_eq: 1}}}, {network: {_eq: $network}}]}
  ) {
    ...postFields
    post_reactions {
      reaction
    }
  }
}
    ${PostFieldsFragmentDoc}`;

/**
 * __useDiscussionPostsIdAscQuery__
 *
 * To run a query within a React component, call `useDiscussionPostsIdAscQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionPostsIdAscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionPostsIdAscQuery({
 *   variables: {
 *      network: // value for 'network'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useDiscussionPostsIdAscQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiscussionPostsIdAscQuery, DiscussionPostsIdAscQueryVariables>) {
        return ApolloReactHooks.useQuery<DiscussionPostsIdAscQuery, DiscussionPostsIdAscQueryVariables>(DiscussionPostsIdAscDocument, baseOptions);
      }
export function useDiscussionPostsIdAscLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiscussionPostsIdAscQuery, DiscussionPostsIdAscQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiscussionPostsIdAscQuery, DiscussionPostsIdAscQueryVariables>(DiscussionPostsIdAscDocument, baseOptions);
        }
export type DiscussionPostsIdAscQueryHookResult = ReturnType<typeof useDiscussionPostsIdAscQuery>;
export type DiscussionPostsIdAscLazyQueryHookResult = ReturnType<typeof useDiscussionPostsIdAscLazyQuery>;
export type DiscussionPostsIdAscQueryResult = ApolloReactCommon.QueryResult<DiscussionPostsIdAscQuery, DiscussionPostsIdAscQueryVariables>;
export const MotionPostAndCommentsDocument = gql`
    query MotionPostAndComments($networkId: String!) {
  posts(where: {onchain_link: {onchain_network_motion_id: {_eq: $networkId}}}) {
    ...motionPost
  }
}
    ${MotionPostFragmentDoc}`;

/**
 * __useMotionPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useMotionPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMotionPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMotionPostAndCommentsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useMotionPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MotionPostAndCommentsQuery, MotionPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<MotionPostAndCommentsQuery, MotionPostAndCommentsQueryVariables>(MotionPostAndCommentsDocument, baseOptions);
      }
export function useMotionPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MotionPostAndCommentsQuery, MotionPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MotionPostAndCommentsQuery, MotionPostAndCommentsQueryVariables>(MotionPostAndCommentsDocument, baseOptions);
        }
export type MotionPostAndCommentsQueryHookResult = ReturnType<typeof useMotionPostAndCommentsQuery>;
export type MotionPostAndCommentsLazyQueryHookResult = ReturnType<typeof useMotionPostAndCommentsLazyQuery>;
export type MotionPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<MotionPostAndCommentsQuery, MotionPostAndCommentsQueryVariables>;
export const AllMotionPostsDocument = gql`
    query AllMotionPosts($network: String!, $postType: Int!, $limit: Int! = 5, $offset: Int! = 0) {
  posts(
    limit: $limit
    offset: $offset
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_motion_id: {_is_null: false}}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_motion_id
      onchain_motion(where: {}) {
        id
        motionStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllMotionPostsQuery__
 *
 * To run a query within a React component, call `useAllMotionPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMotionPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMotionPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllMotionPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllMotionPostsQuery, AllMotionPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllMotionPostsQuery, AllMotionPostsQueryVariables>(AllMotionPostsDocument, baseOptions);
      }
export function useAllMotionPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllMotionPostsQuery, AllMotionPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllMotionPostsQuery, AllMotionPostsQueryVariables>(AllMotionPostsDocument, baseOptions);
        }
export type AllMotionPostsQueryHookResult = ReturnType<typeof useAllMotionPostsQuery>;
export type AllMotionPostsLazyQueryHookResult = ReturnType<typeof useAllMotionPostsLazyQuery>;
export type AllMotionPostsQueryResult = ApolloReactCommon.QueryResult<AllMotionPostsQuery, AllMotionPostsQueryVariables>;
export const ChangeNotificationPreferenceDocument = gql`
    mutation changeNotificationPreference($postParticipated: Boolean, $postCreated: Boolean, $newProposal: Boolean, $ownProposal: Boolean) {
  changeNotificationPreference(
    notificationPreferences: {postParticipated: $postParticipated, postCreated: $postCreated, newProposal: $newProposal, ownProposal: $ownProposal}
  ) {
    message
    token
  }
}
    `;
export type ChangeNotificationPreferenceMutationFn = ApolloReactCommon.MutationFunction<ChangeNotificationPreferenceMutation, ChangeNotificationPreferenceMutationVariables>;

/**
 * __useChangeNotificationPreferenceMutation__
 *
 * To run a mutation, you first call `useChangeNotificationPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeNotificationPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeNotificationPreferenceMutation, { data, loading, error }] = useChangeNotificationPreferenceMutation({
 *   variables: {
 *      postParticipated: // value for 'postParticipated'
 *      postCreated: // value for 'postCreated'
 *      newProposal: // value for 'newProposal'
 *      ownProposal: // value for 'ownProposal'
 *   },
 * });
 */
export function useChangeNotificationPreferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeNotificationPreferenceMutation, ChangeNotificationPreferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeNotificationPreferenceMutation, ChangeNotificationPreferenceMutationVariables>(ChangeNotificationPreferenceDocument, baseOptions);
      }
export type ChangeNotificationPreferenceMutationHookResult = ReturnType<typeof useChangeNotificationPreferenceMutation>;
export type ChangeNotificationPreferenceMutationResult = ApolloReactCommon.MutationResult<ChangeNotificationPreferenceMutation>;
export type ChangeNotificationPreferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeNotificationPreferenceMutation, ChangeNotificationPreferenceMutationVariables>;
export const GetLatestBountyPostsDocument = gql`
    query GetLatestBountyPosts($network: String!, $postType: Int!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_bounty_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_bounty_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_bounty_id
      onchain_bounty {
        id
        bountyStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useGetLatestBountyPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestBountyPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestBountyPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestBountyPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestBountyPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestBountyPostsQuery, GetLatestBountyPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestBountyPostsQuery, GetLatestBountyPostsQueryVariables>(GetLatestBountyPostsDocument, baseOptions);
      }
export function useGetLatestBountyPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestBountyPostsQuery, GetLatestBountyPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestBountyPostsQuery, GetLatestBountyPostsQueryVariables>(GetLatestBountyPostsDocument, baseOptions);
        }
export type GetLatestBountyPostsQueryHookResult = ReturnType<typeof useGetLatestBountyPostsQuery>;
export type GetLatestBountyPostsLazyQueryHookResult = ReturnType<typeof useGetLatestBountyPostsLazyQuery>;
export type GetLatestBountyPostsQueryResult = ApolloReactCommon.QueryResult<GetLatestBountyPostsQuery, GetLatestBountyPostsQueryVariables>;
export const BountiesCountDocument = gql`
    query BountiesCount($network: String!, $postType: Int!) {
  posts_aggregate(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_bounty_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useBountiesCountQuery__
 *
 * To run a query within a React component, call `useBountiesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBountiesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBountiesCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useBountiesCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BountiesCountQuery, BountiesCountQueryVariables>) {
        return ApolloReactHooks.useQuery<BountiesCountQuery, BountiesCountQueryVariables>(BountiesCountDocument, baseOptions);
      }
export function useBountiesCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BountiesCountQuery, BountiesCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BountiesCountQuery, BountiesCountQueryVariables>(BountiesCountDocument, baseOptions);
        }
export type BountiesCountQueryHookResult = ReturnType<typeof useBountiesCountQuery>;
export type BountiesCountLazyQueryHookResult = ReturnType<typeof useBountiesCountLazyQuery>;
export type BountiesCountQueryResult = ApolloReactCommon.QueryResult<BountiesCountQuery, BountiesCountQueryVariables>;
export const GetLatestMotionPostsDocument = gql`
    query GetLatestMotionPosts($network: String!, $postType: Int!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_motion_id: {_is_null: false}}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_motion_id
      onchain_motion {
        id
        motionStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useGetLatestMotionPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestMotionPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestMotionPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestMotionPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestMotionPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestMotionPostsQuery, GetLatestMotionPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestMotionPostsQuery, GetLatestMotionPostsQueryVariables>(GetLatestMotionPostsDocument, baseOptions);
      }
export function useGetLatestMotionPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestMotionPostsQuery, GetLatestMotionPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestMotionPostsQuery, GetLatestMotionPostsQueryVariables>(GetLatestMotionPostsDocument, baseOptions);
        }
export type GetLatestMotionPostsQueryHookResult = ReturnType<typeof useGetLatestMotionPostsQuery>;
export type GetLatestMotionPostsLazyQueryHookResult = ReturnType<typeof useGetLatestMotionPostsLazyQuery>;
export type GetLatestMotionPostsQueryResult = ApolloReactCommon.QueryResult<GetLatestMotionPostsQuery, GetLatestMotionPostsQueryVariables>;
export const GetLatestMotionsCountDocument = gql`
    query GetLatestMotionsCount($network: String!, $postType: Int!) {
  posts_aggregate(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_motion_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetLatestMotionsCountQuery__
 *
 * To run a query within a React component, call `useGetLatestMotionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestMotionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestMotionsCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useGetLatestMotionsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestMotionsCountQuery, GetLatestMotionsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestMotionsCountQuery, GetLatestMotionsCountQueryVariables>(GetLatestMotionsCountDocument, baseOptions);
      }
export function useGetLatestMotionsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestMotionsCountQuery, GetLatestMotionsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestMotionsCountQuery, GetLatestMotionsCountQueryVariables>(GetLatestMotionsCountDocument, baseOptions);
        }
export type GetLatestMotionsCountQueryHookResult = ReturnType<typeof useGetLatestMotionsCountQuery>;
export type GetLatestMotionsCountLazyQueryHookResult = ReturnType<typeof useGetLatestMotionsCountLazyQuery>;
export type GetLatestMotionsCountQueryResult = ApolloReactCommon.QueryResult<GetLatestMotionsCountQuery, GetLatestMotionsCountQueryVariables>;
export const GetLatestDemocracyProposalPostsDocument = gql`
    query GetLatestDemocracyProposalPosts($network: String!, $postType: Int!, $postTopic: Int!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_proposal_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_proposal_id
      onchain_proposal {
        id
        proposalStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useGetLatestDemocracyProposalPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestDemocracyProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDemocracyProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestDemocracyProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestDemocracyProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestDemocracyProposalPostsQuery, GetLatestDemocracyProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestDemocracyProposalPostsQuery, GetLatestDemocracyProposalPostsQueryVariables>(GetLatestDemocracyProposalPostsDocument, baseOptions);
      }
export function useGetLatestDemocracyProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestDemocracyProposalPostsQuery, GetLatestDemocracyProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestDemocracyProposalPostsQuery, GetLatestDemocracyProposalPostsQueryVariables>(GetLatestDemocracyProposalPostsDocument, baseOptions);
        }
export type GetLatestDemocracyProposalPostsQueryHookResult = ReturnType<typeof useGetLatestDemocracyProposalPostsQuery>;
export type GetLatestDemocracyProposalPostsLazyQueryHookResult = ReturnType<typeof useGetLatestDemocracyProposalPostsLazyQuery>;
export type GetLatestDemocracyProposalPostsQueryResult = ApolloReactCommon.QueryResult<GetLatestDemocracyProposalPostsQuery, GetLatestDemocracyProposalPostsQueryVariables>;
export const DemocracyProposalCountDocument = gql`
    query DemocracyProposalCount($network: String!, $postType: Int!, $postTopic: Int!) {
  posts_aggregate(
    where: {network: {_eq: $network}, type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, onchain_link: {onchain_network_proposal_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDemocracyProposalCountQuery__
 *
 * To run a query within a React component, call `useDemocracyProposalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemocracyProposalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemocracyProposalCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *   },
 * });
 */
export function useDemocracyProposalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DemocracyProposalCountQuery, DemocracyProposalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<DemocracyProposalCountQuery, DemocracyProposalCountQueryVariables>(DemocracyProposalCountDocument, baseOptions);
      }
export function useDemocracyProposalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DemocracyProposalCountQuery, DemocracyProposalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DemocracyProposalCountQuery, DemocracyProposalCountQueryVariables>(DemocracyProposalCountDocument, baseOptions);
        }
export type DemocracyProposalCountQueryHookResult = ReturnType<typeof useDemocracyProposalCountQuery>;
export type DemocracyProposalCountLazyQueryHookResult = ReturnType<typeof useDemocracyProposalCountLazyQuery>;
export type DemocracyProposalCountQueryResult = ApolloReactCommon.QueryResult<DemocracyProposalCountQuery, DemocracyProposalCountQueryVariables>;
export const GetLatestReferendaPostsDocument = gql`
    query GetLatestReferendaPosts($network: String!, $postType: Int!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_referendum_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_referendum_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_referendum_id
      onchain_referendum {
        id
        end
        referendumStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useGetLatestReferendaPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestReferendaPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestReferendaPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestReferendaPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestReferendaPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestReferendaPostsQuery, GetLatestReferendaPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestReferendaPostsQuery, GetLatestReferendaPostsQueryVariables>(GetLatestReferendaPostsDocument, baseOptions);
      }
export function useGetLatestReferendaPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestReferendaPostsQuery, GetLatestReferendaPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestReferendaPostsQuery, GetLatestReferendaPostsQueryVariables>(GetLatestReferendaPostsDocument, baseOptions);
        }
export type GetLatestReferendaPostsQueryHookResult = ReturnType<typeof useGetLatestReferendaPostsQuery>;
export type GetLatestReferendaPostsLazyQueryHookResult = ReturnType<typeof useGetLatestReferendaPostsLazyQuery>;
export type GetLatestReferendaPostsQueryResult = ApolloReactCommon.QueryResult<GetLatestReferendaPostsQuery, GetLatestReferendaPostsQueryVariables>;
export const ReferundumCountDocument = gql`
    query ReferundumCount($network: String!, $postType: Int!) {
  posts_aggregate(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_referendum_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useReferundumCountQuery__
 *
 * To run a query within a React component, call `useReferundumCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferundumCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferundumCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useReferundumCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReferundumCountQuery, ReferundumCountQueryVariables>) {
        return ApolloReactHooks.useQuery<ReferundumCountQuery, ReferundumCountQueryVariables>(ReferundumCountDocument, baseOptions);
      }
export function useReferundumCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferundumCountQuery, ReferundumCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReferundumCountQuery, ReferundumCountQueryVariables>(ReferundumCountDocument, baseOptions);
        }
export type ReferundumCountQueryHookResult = ReturnType<typeof useReferundumCountQuery>;
export type ReferundumCountLazyQueryHookResult = ReturnType<typeof useReferundumCountLazyQuery>;
export type ReferundumCountQueryResult = ApolloReactCommon.QueryResult<ReferundumCountQuery, ReferundumCountQueryVariables>;
export const LatestTechCommitteeProposalPostsDocument = gql`
    query LatestTechCommitteeProposalPosts($network: String!, $postType: Int!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_tech_committee_proposal_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_tech_committee_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_tech_committee_proposal_id
      onchain_tech_committee_proposal(
        where: {NOT: {status_some: {OR: [{status: "Closed"}, {status: "Approved"}, {status: "Executed"}, {status: "Disapproved"}]}}}
      ) {
        id
        status(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useLatestTechCommitteeProposalPostsQuery__
 *
 * To run a query within a React component, call `useLatestTechCommitteeProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestTechCommitteeProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestTechCommitteeProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useLatestTechCommitteeProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestTechCommitteeProposalPostsQuery, LatestTechCommitteeProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestTechCommitteeProposalPostsQuery, LatestTechCommitteeProposalPostsQueryVariables>(LatestTechCommitteeProposalPostsDocument, baseOptions);
      }
export function useLatestTechCommitteeProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestTechCommitteeProposalPostsQuery, LatestTechCommitteeProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestTechCommitteeProposalPostsQuery, LatestTechCommitteeProposalPostsQueryVariables>(LatestTechCommitteeProposalPostsDocument, baseOptions);
        }
export type LatestTechCommitteeProposalPostsQueryHookResult = ReturnType<typeof useLatestTechCommitteeProposalPostsQuery>;
export type LatestTechCommitteeProposalPostsLazyQueryHookResult = ReturnType<typeof useLatestTechCommitteeProposalPostsLazyQuery>;
export type LatestTechCommitteeProposalPostsQueryResult = ApolloReactCommon.QueryResult<LatestTechCommitteeProposalPostsQuery, LatestTechCommitteeProposalPostsQueryVariables>;
export const TechCommitteeProposalCountDocument = gql`
    query TechCommitteeProposalCount($postType: Int!, $network: String!) {
  posts_aggregate(
    where: {type: {id: {_eq: $postType}}, onchain_link: {onchain_network_tech_committee_proposal_id: {_is_null: false}}, network: {_eq: $network}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useTechCommitteeProposalCountQuery__
 *
 * To run a query within a React component, call `useTechCommitteeProposalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechCommitteeProposalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechCommitteeProposalCountQuery({
 *   variables: {
 *      postType: // value for 'postType'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useTechCommitteeProposalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TechCommitteeProposalCountQuery, TechCommitteeProposalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<TechCommitteeProposalCountQuery, TechCommitteeProposalCountQueryVariables>(TechCommitteeProposalCountDocument, baseOptions);
      }
export function useTechCommitteeProposalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechCommitteeProposalCountQuery, TechCommitteeProposalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TechCommitteeProposalCountQuery, TechCommitteeProposalCountQueryVariables>(TechCommitteeProposalCountDocument, baseOptions);
        }
export type TechCommitteeProposalCountQueryHookResult = ReturnType<typeof useTechCommitteeProposalCountQuery>;
export type TechCommitteeProposalCountLazyQueryHookResult = ReturnType<typeof useTechCommitteeProposalCountLazyQuery>;
export type TechCommitteeProposalCountQueryResult = ApolloReactCommon.QueryResult<TechCommitteeProposalCountQuery, TechCommitteeProposalCountQueryVariables>;
export const GetLatestTipPostsDocument = gql`
    query GetLatestTipPosts($network: String!, $postType: Int!, $postTopic: Int!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_tip_id: {_is_null: false}}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_tip_id
      onchain_tip {
        id
        reason
        tipStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useGetLatestTipPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestTipPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTipPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTipPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestTipPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestTipPostsQuery, GetLatestTipPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestTipPostsQuery, GetLatestTipPostsQueryVariables>(GetLatestTipPostsDocument, baseOptions);
      }
export function useGetLatestTipPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestTipPostsQuery, GetLatestTipPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestTipPostsQuery, GetLatestTipPostsQueryVariables>(GetLatestTipPostsDocument, baseOptions);
        }
export type GetLatestTipPostsQueryHookResult = ReturnType<typeof useGetLatestTipPostsQuery>;
export type GetLatestTipPostsLazyQueryHookResult = ReturnType<typeof useGetLatestTipPostsLazyQuery>;
export type GetLatestTipPostsQueryResult = ApolloReactCommon.QueryResult<GetLatestTipPostsQuery, GetLatestTipPostsQueryVariables>;
export const TipProposalCountDocument = gql`
    query TipProposalCount($network: String!, $postType: Int!, $postTopic: Int!) {
  posts_aggregate(
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_tip_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useTipProposalCountQuery__
 *
 * To run a query within a React component, call `useTipProposalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipProposalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipProposalCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *   },
 * });
 */
export function useTipProposalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TipProposalCountQuery, TipProposalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<TipProposalCountQuery, TipProposalCountQueryVariables>(TipProposalCountDocument, baseOptions);
      }
export function useTipProposalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TipProposalCountQuery, TipProposalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TipProposalCountQuery, TipProposalCountQueryVariables>(TipProposalCountDocument, baseOptions);
        }
export type TipProposalCountQueryHookResult = ReturnType<typeof useTipProposalCountQuery>;
export type TipProposalCountLazyQueryHookResult = ReturnType<typeof useTipProposalCountLazyQuery>;
export type TipProposalCountQueryResult = ApolloReactCommon.QueryResult<TipProposalCountQuery, TipProposalCountQueryVariables>;
export const GetLatestDemocracyTreasuryProposalPostsDocument = gql`
    query GetLatestDemocracyTreasuryProposalPosts($network: String!, $postType: Int!, $postTopic: Int!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_treasury_proposal_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_treasury_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_treasury_proposal_id
      onchain_treasury_spend_proposal {
        id
        treasuryStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useGetLatestDemocracyTreasuryProposalPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestDemocracyTreasuryProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestDemocracyTreasuryProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestDemocracyTreasuryProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestDemocracyTreasuryProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestDemocracyTreasuryProposalPostsQuery, GetLatestDemocracyTreasuryProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestDemocracyTreasuryProposalPostsQuery, GetLatestDemocracyTreasuryProposalPostsQueryVariables>(GetLatestDemocracyTreasuryProposalPostsDocument, baseOptions);
      }
export function useGetLatestDemocracyTreasuryProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestDemocracyTreasuryProposalPostsQuery, GetLatestDemocracyTreasuryProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestDemocracyTreasuryProposalPostsQuery, GetLatestDemocracyTreasuryProposalPostsQueryVariables>(GetLatestDemocracyTreasuryProposalPostsDocument, baseOptions);
        }
export type GetLatestDemocracyTreasuryProposalPostsQueryHookResult = ReturnType<typeof useGetLatestDemocracyTreasuryProposalPostsQuery>;
export type GetLatestDemocracyTreasuryProposalPostsLazyQueryHookResult = ReturnType<typeof useGetLatestDemocracyTreasuryProposalPostsLazyQuery>;
export type GetLatestDemocracyTreasuryProposalPostsQueryResult = ApolloReactCommon.QueryResult<GetLatestDemocracyTreasuryProposalPostsQuery, GetLatestDemocracyTreasuryProposalPostsQueryVariables>;
export const DemocracyTreasuryProposalCountDocument = gql`
    query DemocracyTreasuryProposalCount($network: String!, $postType: Int!, $postTopic: Int!) {
  posts_aggregate(
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_treasury_proposal_id: {_is_null: false}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDemocracyTreasuryProposalCountQuery__
 *
 * To run a query within a React component, call `useDemocracyTreasuryProposalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDemocracyTreasuryProposalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDemocracyTreasuryProposalCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *   },
 * });
 */
export function useDemocracyTreasuryProposalCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DemocracyTreasuryProposalCountQuery, DemocracyTreasuryProposalCountQueryVariables>) {
        return ApolloReactHooks.useQuery<DemocracyTreasuryProposalCountQuery, DemocracyTreasuryProposalCountQueryVariables>(DemocracyTreasuryProposalCountDocument, baseOptions);
      }
export function useDemocracyTreasuryProposalCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DemocracyTreasuryProposalCountQuery, DemocracyTreasuryProposalCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DemocracyTreasuryProposalCountQuery, DemocracyTreasuryProposalCountQueryVariables>(DemocracyTreasuryProposalCountDocument, baseOptions);
        }
export type DemocracyTreasuryProposalCountQueryHookResult = ReturnType<typeof useDemocracyTreasuryProposalCountQuery>;
export type DemocracyTreasuryProposalCountLazyQueryHookResult = ReturnType<typeof useDemocracyTreasuryProposalCountLazyQuery>;
export type DemocracyTreasuryProposalCountQueryResult = ApolloReactCommon.QueryResult<DemocracyTreasuryProposalCountQuery, DemocracyTreasuryProposalCountQueryVariables>;
export const GetLatestPostsDocument = gql`
    query GetLatestPosts($network: String!, $limit: Int! = 10) {
  posts(limit: $limit, where: {network: {_eq: $network}}, order_by: {id: desc}) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_proposal_id
      onchain_proposal {
        id
        proposalStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      onchain_network_bounty_id
      onchain_bounty {
        id
        bountyStatus(last: 1) {
          id
          status
        }
      }
      onchain_network_motion_id
      onchain_motion {
        id
        motionStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      onchain_network_referendum_id
      onchain_referendum {
        id
        end
        referendumStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      onchain_network_tech_committee_proposal_id
      onchain_tech_committee_proposal {
        id
        status(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      onchain_network_tip_id
      onchain_tip {
        id
        reason
        tipStatus(last: 1) {
          id
          status
        }
      }
      onchain_network_pip_id
      onchain_pip {
        id
        method
        section
        maybeBlockNumber
        pipStatus(last: 1) {
          id
          status
        }
      }
      onchain_network_treasury_proposal_id
      onchain_treasury_spend_proposal {
        id
        treasuryStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useGetLatestPostsQuery__
 *
 * To run a query within a React component, call `useGetLatestPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetLatestPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestPostsQuery, GetLatestPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestPostsQuery, GetLatestPostsQueryVariables>(GetLatestPostsDocument, baseOptions);
      }
export function useGetLatestPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestPostsQuery, GetLatestPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestPostsQuery, GetLatestPostsQueryVariables>(GetLatestPostsDocument, baseOptions);
        }
export type GetLatestPostsQueryHookResult = ReturnType<typeof useGetLatestPostsQuery>;
export type GetLatestPostsLazyQueryHookResult = ReturnType<typeof useGetLatestPostsLazyQuery>;
export type GetLatestPostsQueryResult = ApolloReactCommon.QueryResult<GetLatestPostsQuery, GetLatestPostsQueryVariables>;
export const PostsCountDocument = gql`
    query PostsCount($network: String!) {
  posts_aggregate(where: {network: {_eq: $network}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __usePostsCountQuery__
 *
 * To run a query within a React component, call `usePostsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function usePostsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostsCountQuery, PostsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<PostsCountQuery, PostsCountQueryVariables>(PostsCountDocument, baseOptions);
      }
export function usePostsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostsCountQuery, PostsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostsCountQuery, PostsCountQueryVariables>(PostsCountDocument, baseOptions);
        }
export type PostsCountQueryHookResult = ReturnType<typeof usePostsCountQuery>;
export type PostsCountLazyQueryHookResult = ReturnType<typeof usePostsCountLazyQuery>;
export type PostsCountQueryResult = ApolloReactCommon.QueryResult<PostsCountQuery, PostsCountQueryVariables>;
export const NetworkSocialsDocument = gql`
    query NetworkSocials($network: String!) {
  blockchain_socials(where: {network: {_ilike: $network}}) {
    block_explorer
    discord
    github
    homepage
    id
    network
    reddit
    telegram
    twitter
    youtube
  }
}
    `;

/**
 * __useNetworkSocialsQuery__
 *
 * To run a query within a React component, call `useNetworkSocialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkSocialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkSocialsQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useNetworkSocialsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NetworkSocialsQuery, NetworkSocialsQueryVariables>) {
        return ApolloReactHooks.useQuery<NetworkSocialsQuery, NetworkSocialsQueryVariables>(NetworkSocialsDocument, baseOptions);
      }
export function useNetworkSocialsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NetworkSocialsQuery, NetworkSocialsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NetworkSocialsQuery, NetworkSocialsQueryVariables>(NetworkSocialsDocument, baseOptions);
        }
export type NetworkSocialsQueryHookResult = ReturnType<typeof useNetworkSocialsQuery>;
export type NetworkSocialsLazyQueryHookResult = ReturnType<typeof useNetworkSocialsLazyQuery>;
export type NetworkSocialsQueryResult = ApolloReactCommon.QueryResult<NetworkSocialsQuery, NetworkSocialsQueryVariables>;
export const DiscussionsCountDocument = gql`
    query DiscussionsCount($network: String!) {
  posts_aggregate(where: {type: {id: {_eq: 1}}, network: {_eq: $network}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useDiscussionsCountQuery__
 *
 * To run a query within a React component, call `useDiscussionsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionsCountQuery({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useDiscussionsCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiscussionsCountQuery, DiscussionsCountQueryVariables>) {
        return ApolloReactHooks.useQuery<DiscussionsCountQuery, DiscussionsCountQueryVariables>(DiscussionsCountDocument, baseOptions);
      }
export function useDiscussionsCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiscussionsCountQuery, DiscussionsCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiscussionsCountQuery, DiscussionsCountQueryVariables>(DiscussionsCountDocument, baseOptions);
        }
export type DiscussionsCountQueryHookResult = ReturnType<typeof useDiscussionsCountQuery>;
export type DiscussionsCountLazyQueryHookResult = ReturnType<typeof useDiscussionsCountLazyQuery>;
export type DiscussionsCountQueryResult = ApolloReactCommon.QueryResult<DiscussionsCountQuery, DiscussionsCountQueryVariables>;
export const PipPostAndCommentsDocument = gql`
    query PipPostAndComments($networkId: String!) {
  posts(where: {onchain_link: {onchain_network_pip_id: {_eq: $networkId}}}) {
    ...pipPost
  }
}
    ${PipPostFragmentDoc}`;

/**
 * __usePipPostAndCommentsQuery__
 *
 * To run a query within a React component, call `usePipPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePipPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePipPostAndCommentsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function usePipPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PipPostAndCommentsQuery, PipPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<PipPostAndCommentsQuery, PipPostAndCommentsQueryVariables>(PipPostAndCommentsDocument, baseOptions);
      }
export function usePipPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PipPostAndCommentsQuery, PipPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PipPostAndCommentsQuery, PipPostAndCommentsQueryVariables>(PipPostAndCommentsDocument, baseOptions);
        }
export type PipPostAndCommentsQueryHookResult = ReturnType<typeof usePipPostAndCommentsQuery>;
export type PipPostAndCommentsLazyQueryHookResult = ReturnType<typeof usePipPostAndCommentsLazyQuery>;
export type PipPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<PipPostAndCommentsQuery, PipPostAndCommentsQueryVariables>;
export const AllPipPostsDocument = gql`
    query AllPipPosts($network: String!, $limit: Int! = 5) {
  posts(
    limit: $limit
    where: {network: {_eq: $network}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_pip_id
      onchain_pip {
        id
        pipStatus(last: 1) {
          id
          status
          network
          uniqueNetworkStatus
          blockNumber {
            number
          }
        }
        author
        balance
        identityId
        maybeBlockNumber
        method
        network
        networkProposalId
        optionDescription
        optionUrl
        proposalHash
        proposalId
        proposedBy
        section
        pipArguments {
          id
          name
          value
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllPipPostsQuery__
 *
 * To run a query within a React component, call `useAllPipPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPipPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPipPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllPipPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllPipPostsQuery, AllPipPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllPipPostsQuery, AllPipPostsQueryVariables>(AllPipPostsDocument, baseOptions);
      }
export function useAllPipPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPipPostsQuery, AllPipPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllPipPostsQuery, AllPipPostsQueryVariables>(AllPipPostsDocument, baseOptions);
        }
export type AllPipPostsQueryHookResult = ReturnType<typeof useAllPipPostsQuery>;
export type AllPipPostsLazyQueryHookResult = ReturnType<typeof useAllPipPostsLazyQuery>;
export type AllPipPostsQueryResult = ApolloReactCommon.QueryResult<AllPipPostsQuery, AllPipPostsQueryVariables>;
export const AllPipPostsByCommitteeDocument = gql`
    query AllPipPostsByCommittee($network: String!, $limit: Int! = 5, $committee: String!) {
  posts(
    limit: $limit
    where: {network: {_eq: $network}, onchain_link: {onchain_network_pip_id: {_is_null: false}, onchain_pip_committee: {_eq: $committee}}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_pip_id
      onchain_pip_committee
      onchain_pip {
        id
        pipStatus(last: 1) {
          id
          status
          network
          uniqueNetworkStatus
          blockNumber {
            number
          }
        }
        author
        balance
        identityId
        maybeBlockNumber
        method
        network
        networkProposalId
        optionDescription
        optionUrl
        proposalHash
        proposalId
        proposedBy
        section
        pipArguments {
          id
          name
          value
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllPipPostsByCommitteeQuery__
 *
 * To run a query within a React component, call `useAllPipPostsByCommitteeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPipPostsByCommitteeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPipPostsByCommitteeQuery({
 *   variables: {
 *      network: // value for 'network'
 *      limit: // value for 'limit'
 *      committee: // value for 'committee'
 *   },
 * });
 */
export function useAllPipPostsByCommitteeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllPipPostsByCommitteeQuery, AllPipPostsByCommitteeQueryVariables>) {
        return ApolloReactHooks.useQuery<AllPipPostsByCommitteeQuery, AllPipPostsByCommitteeQueryVariables>(AllPipPostsByCommitteeDocument, baseOptions);
      }
export function useAllPipPostsByCommitteeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllPipPostsByCommitteeQuery, AllPipPostsByCommitteeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllPipPostsByCommitteeQuery, AllPipPostsByCommitteeQueryVariables>(AllPipPostsByCommitteeDocument, baseOptions);
        }
export type AllPipPostsByCommitteeQueryHookResult = ReturnType<typeof useAllPipPostsByCommitteeQuery>;
export type AllPipPostsByCommitteeLazyQueryHookResult = ReturnType<typeof useAllPipPostsByCommitteeLazyQuery>;
export type AllPipPostsByCommitteeQueryResult = ApolloReactCommon.QueryResult<AllPipPostsByCommitteeQuery, AllPipPostsByCommitteeQueryVariables>;
export const CountCommitteePipsDocument = gql`
    query CountCommitteePips($network: String!, $committee: String!) {
  posts_aggregate(
    where: {network: {_eq: $network}, onchain_link: {onchain_pip_committee: {_eq: $committee}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useCountCommitteePipsQuery__
 *
 * To run a query within a React component, call `useCountCommitteePipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountCommitteePipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountCommitteePipsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      committee: // value for 'committee'
 *   },
 * });
 */
export function useCountCommitteePipsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CountCommitteePipsQuery, CountCommitteePipsQueryVariables>) {
        return ApolloReactHooks.useQuery<CountCommitteePipsQuery, CountCommitteePipsQueryVariables>(CountCommitteePipsDocument, baseOptions);
      }
export function useCountCommitteePipsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CountCommitteePipsQuery, CountCommitteePipsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CountCommitteePipsQuery, CountCommitteePipsQueryVariables>(CountCommitteePipsDocument, baseOptions);
        }
export type CountCommitteePipsQueryHookResult = ReturnType<typeof useCountCommitteePipsQuery>;
export type CountCommitteePipsLazyQueryHookResult = ReturnType<typeof useCountCommitteePipsLazyQuery>;
export type CountCommitteePipsQueryResult = ApolloReactCommon.QueryResult<CountCommitteePipsQuery, CountCommitteePipsQueryVariables>;
export const AboutDocument = gql`
    query ABOUT($network: String!, $address: String!) {
  about(network: $network, address: $address) {
    network
    address
    title
    description
    image
  }
}
    `;

/**
 * __useAboutQuery__
 *
 * To run a query within a React component, call `useAboutQuery` and pass it any options that fit your needs.
 * When your component renders, `useAboutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAboutQuery({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAboutQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AboutQuery, AboutQueryVariables>) {
        return ApolloReactHooks.useQuery<AboutQuery, AboutQueryVariables>(AboutDocument, baseOptions);
      }
export function useAboutLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AboutQuery, AboutQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AboutQuery, AboutQueryVariables>(AboutDocument, baseOptions);
        }
export type AboutQueryHookResult = ReturnType<typeof useAboutQuery>;
export type AboutLazyQueryHookResult = ReturnType<typeof useAboutLazyQuery>;
export type AboutQueryResult = ApolloReactCommon.QueryResult<AboutQuery, AboutQueryVariables>;
export const ChangeAboutDocument = gql`
    mutation changeAbout($network: String!, $address: String!, $title: String!, $description: String!, $image: String!, $signature: String!) {
  changeAbout(
    network: $network
    address: $address
    title: $title
    description: $description
    image: $image
    signature: $signature
  ) {
    message
  }
}
    `;
export type ChangeAboutMutationFn = ApolloReactCommon.MutationFunction<ChangeAboutMutation, ChangeAboutMutationVariables>;

/**
 * __useChangeAboutMutation__
 *
 * To run a mutation, you first call `useChangeAboutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAboutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAboutMutation, { data, loading, error }] = useChangeAboutMutation({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      image: // value for 'image'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useChangeAboutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeAboutMutation, ChangeAboutMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeAboutMutation, ChangeAboutMutationVariables>(ChangeAboutDocument, baseOptions);
      }
export type ChangeAboutMutationHookResult = ReturnType<typeof useChangeAboutMutation>;
export type ChangeAboutMutationResult = ApolloReactCommon.MutationResult<ChangeAboutMutation>;
export type ChangeAboutMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeAboutMutation, ChangeAboutMutationVariables>;
export const ProposalPostAndCommentsDocument = gql`
    query ProposalPostAndComments($networkId: String!) {
  posts(where: {onchain_link: {onchain_network_proposal_id: {_eq: $networkId}}}) {
    ...proposalPost
  }
}
    ${ProposalPostFragmentDoc}`;

/**
 * __useProposalPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useProposalPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProposalPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProposalPostAndCommentsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useProposalPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProposalPostAndCommentsQuery, ProposalPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProposalPostAndCommentsQuery, ProposalPostAndCommentsQueryVariables>(ProposalPostAndCommentsDocument, baseOptions);
      }
export function useProposalPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProposalPostAndCommentsQuery, ProposalPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProposalPostAndCommentsQuery, ProposalPostAndCommentsQueryVariables>(ProposalPostAndCommentsDocument, baseOptions);
        }
export type ProposalPostAndCommentsQueryHookResult = ReturnType<typeof useProposalPostAndCommentsQuery>;
export type ProposalPostAndCommentsLazyQueryHookResult = ReturnType<typeof useProposalPostAndCommentsLazyQuery>;
export type ProposalPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<ProposalPostAndCommentsQuery, ProposalPostAndCommentsQueryVariables>;
export const AllDemocracyProposalPostsDocument = gql`
    query AllDemocracyProposalPosts($network: String!, $postType: Int!, $postTopic: Int!, $limit: Int! = 5, $offset: Int! = 0) {
  posts(
    limit: $limit
    offset: $offset
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_proposal_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_proposal_id
      onchain_proposal(where: {}) {
        id
        proposalStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllDemocracyProposalPostsQuery__
 *
 * To run a query within a React component, call `useAllDemocracyProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDemocracyProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDemocracyProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllDemocracyProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllDemocracyProposalPostsQuery, AllDemocracyProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllDemocracyProposalPostsQuery, AllDemocracyProposalPostsQueryVariables>(AllDemocracyProposalPostsDocument, baseOptions);
      }
export function useAllDemocracyProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllDemocracyProposalPostsQuery, AllDemocracyProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllDemocracyProposalPostsQuery, AllDemocracyProposalPostsQueryVariables>(AllDemocracyProposalPostsDocument, baseOptions);
        }
export type AllDemocracyProposalPostsQueryHookResult = ReturnType<typeof useAllDemocracyProposalPostsQuery>;
export type AllDemocracyProposalPostsLazyQueryHookResult = ReturnType<typeof useAllDemocracyProposalPostsLazyQuery>;
export type AllDemocracyProposalPostsQueryResult = ApolloReactCommon.QueryResult<AllDemocracyProposalPostsQuery, AllDemocracyProposalPostsQueryVariables>;
export const AllReferendaPostsDocument = gql`
    query AllReferendaPosts($network: String!, $postType: Int!, $limit: Int! = 5, $offset: Int! = 0) {
  posts(
    limit: $limit
    offset: $offset
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_referendum_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_referendum_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_referendum_id
      onchain_referendum(where: {}) {
        id
        end
        referendumStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllReferendaPostsQuery__
 *
 * To run a query within a React component, call `useAllReferendaPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReferendaPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReferendaPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllReferendaPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllReferendaPostsQuery, AllReferendaPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllReferendaPostsQuery, AllReferendaPostsQueryVariables>(AllReferendaPostsDocument, baseOptions);
      }
export function useAllReferendaPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllReferendaPostsQuery, AllReferendaPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllReferendaPostsQuery, AllReferendaPostsQueryVariables>(AllReferendaPostsDocument, baseOptions);
        }
export type AllReferendaPostsQueryHookResult = ReturnType<typeof useAllReferendaPostsQuery>;
export type AllReferendaPostsLazyQueryHookResult = ReturnType<typeof useAllReferendaPostsLazyQuery>;
export type AllReferendaPostsQueryResult = ApolloReactCommon.QueryResult<AllReferendaPostsQuery, AllReferendaPostsQueryVariables>;
export const ReferendumPostAndCommentsDocument = gql`
    query ReferendumPostAndComments($networkId: String!) {
  posts(where: {onchain_link: {onchain_network_referendum_id: {_eq: $networkId}}}) {
    ...referendumPost
  }
}
    ${ReferendumPostFragmentDoc}`;

/**
 * __useReferendumPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useReferendumPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferendumPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferendumPostAndCommentsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useReferendumPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReferendumPostAndCommentsQuery, ReferendumPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReferendumPostAndCommentsQuery, ReferendumPostAndCommentsQueryVariables>(ReferendumPostAndCommentsDocument, baseOptions);
      }
export function useReferendumPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferendumPostAndCommentsQuery, ReferendumPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReferendumPostAndCommentsQuery, ReferendumPostAndCommentsQueryVariables>(ReferendumPostAndCommentsDocument, baseOptions);
        }
export type ReferendumPostAndCommentsQueryHookResult = ReturnType<typeof useReferendumPostAndCommentsQuery>;
export type ReferendumPostAndCommentsLazyQueryHookResult = ReturnType<typeof useReferendumPostAndCommentsLazyQuery>;
export type ReferendumPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<ReferendumPostAndCommentsQuery, ReferendumPostAndCommentsQueryVariables>;
export const RemarkProposalsListingDocument = gql`
    query RemarkProposalsListing($limit: Int!, $network: String!) {
  remark_proposals(
    where: {network: {_eq: $network}}
    limit: $limit
    order_by: {created_at: desc}
  ) {
    address
    created_at
    description
    end
    id
    network
    start
    title
    updated_at
  }
}
    `;

/**
 * __useRemarkProposalsListingQuery__
 *
 * To run a query within a React component, call `useRemarkProposalsListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemarkProposalsListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemarkProposalsListingQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRemarkProposalsListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RemarkProposalsListingQuery, RemarkProposalsListingQueryVariables>) {
        return ApolloReactHooks.useQuery<RemarkProposalsListingQuery, RemarkProposalsListingQueryVariables>(RemarkProposalsListingDocument, baseOptions);
      }
export function useRemarkProposalsListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RemarkProposalsListingQuery, RemarkProposalsListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RemarkProposalsListingQuery, RemarkProposalsListingQueryVariables>(RemarkProposalsListingDocument, baseOptions);
        }
export type RemarkProposalsListingQueryHookResult = ReturnType<typeof useRemarkProposalsListingQuery>;
export type RemarkProposalsListingLazyQueryHookResult = ReturnType<typeof useRemarkProposalsListingLazyQuery>;
export type RemarkProposalsListingQueryResult = ApolloReactCommon.QueryResult<RemarkProposalsListingQuery, RemarkProposalsListingQueryVariables>;
export const RemarkProposalPostDocument = gql`
    query RemarkProposalPost($id: Int!, $network: String!) {
  remark_proposals(where: {id: {_eq: $id}, network: {_eq: $network}}) {
    address
    created_at
    description
    end
    id
    network
    start
    title
    updated_at
    remark_proposal_options {
      created_at
      id
      option
      proposal_id
      updated_at
    }
    remark_proposal_votes {
      address
      block_number
      created_at
      id
      option
      option_id
      power
      proposal_id
      updated_at
    }
  }
}
    `;

/**
 * __useRemarkProposalPostQuery__
 *
 * To run a query within a React component, call `useRemarkProposalPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useRemarkProposalPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRemarkProposalPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRemarkProposalPostQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RemarkProposalPostQuery, RemarkProposalPostQueryVariables>) {
        return ApolloReactHooks.useQuery<RemarkProposalPostQuery, RemarkProposalPostQueryVariables>(RemarkProposalPostDocument, baseOptions);
      }
export function useRemarkProposalPostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RemarkProposalPostQuery, RemarkProposalPostQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RemarkProposalPostQuery, RemarkProposalPostQueryVariables>(RemarkProposalPostDocument, baseOptions);
        }
export type RemarkProposalPostQueryHookResult = ReturnType<typeof useRemarkProposalPostQuery>;
export type RemarkProposalPostLazyQueryHookResult = ReturnType<typeof useRemarkProposalPostLazyQuery>;
export type RemarkProposalPostQueryResult = ApolloReactCommon.QueryResult<RemarkProposalPostQuery, RemarkProposalPostQueryVariables>;
export const RemarkProposalCreateMutationDocument = gql`
    mutation RemarkProposalCreateMutation($address: String!, $user_id: Int!, $description: String!, $title: String!, $network: String!, $start: Int!, $end: Int!) {
  insert_remark_proposals(
    objects: {address: $address, user_id: $user_id, description: $description, end: $end, network: $network, start: $start, title: $title}
  ) {
    returning {
      id
    }
  }
}
    `;
export type RemarkProposalCreateMutationMutationFn = ApolloReactCommon.MutationFunction<RemarkProposalCreateMutationMutation, RemarkProposalCreateMutationMutationVariables>;

/**
 * __useRemarkProposalCreateMutationMutation__
 *
 * To run a mutation, you first call `useRemarkProposalCreateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemarkProposalCreateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remarkProposalCreateMutationMutation, { data, loading, error }] = useRemarkProposalCreateMutationMutation({
 *   variables: {
 *      address: // value for 'address'
 *      user_id: // value for 'user_id'
 *      description: // value for 'description'
 *      title: // value for 'title'
 *      network: // value for 'network'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useRemarkProposalCreateMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemarkProposalCreateMutationMutation, RemarkProposalCreateMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<RemarkProposalCreateMutationMutation, RemarkProposalCreateMutationMutationVariables>(RemarkProposalCreateMutationDocument, baseOptions);
      }
export type RemarkProposalCreateMutationMutationHookResult = ReturnType<typeof useRemarkProposalCreateMutationMutation>;
export type RemarkProposalCreateMutationMutationResult = ApolloReactCommon.MutationResult<RemarkProposalCreateMutationMutation>;
export type RemarkProposalCreateMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemarkProposalCreateMutationMutation, RemarkProposalCreateMutationMutationVariables>;
export const RemarkProposalOptionsCreateMutationDocument = gql`
    mutation RemarkProposalOptionsCreateMutation($options: [remark_proposal_options_insert_input!]!) {
  insert_remark_proposal_options(objects: $options) {
    affected_rows
  }
}
    `;
export type RemarkProposalOptionsCreateMutationMutationFn = ApolloReactCommon.MutationFunction<RemarkProposalOptionsCreateMutationMutation, RemarkProposalOptionsCreateMutationMutationVariables>;

/**
 * __useRemarkProposalOptionsCreateMutationMutation__
 *
 * To run a mutation, you first call `useRemarkProposalOptionsCreateMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemarkProposalOptionsCreateMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remarkProposalOptionsCreateMutationMutation, { data, loading, error }] = useRemarkProposalOptionsCreateMutationMutation({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useRemarkProposalOptionsCreateMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemarkProposalOptionsCreateMutationMutation, RemarkProposalOptionsCreateMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<RemarkProposalOptionsCreateMutationMutation, RemarkProposalOptionsCreateMutationMutationVariables>(RemarkProposalOptionsCreateMutationDocument, baseOptions);
      }
export type RemarkProposalOptionsCreateMutationMutationHookResult = ReturnType<typeof useRemarkProposalOptionsCreateMutationMutation>;
export type RemarkProposalOptionsCreateMutationMutationResult = ApolloReactCommon.MutationResult<RemarkProposalOptionsCreateMutationMutation>;
export type RemarkProposalOptionsCreateMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemarkProposalOptionsCreateMutationMutation, RemarkProposalOptionsCreateMutationMutationVariables>;
export const CreateRemarkProposalVoteMutationDocument = gql`
    mutation CreateRemarkProposalVoteMutation($address: String!, $blockNumber: Int!, $option: String!, $optionId: Int!, $power: String!, $proposalId: Int!, $user_id: Int!) {
  insert_remark_proposal_vote_one(
    object: {address: $address, block_number: $blockNumber, option: $option, option_id: $optionId, power: $power, proposal_id: $proposalId, user_id: $user_id}
  ) {
    id
  }
}
    `;
export type CreateRemarkProposalVoteMutationMutationFn = ApolloReactCommon.MutationFunction<CreateRemarkProposalVoteMutationMutation, CreateRemarkProposalVoteMutationMutationVariables>;

/**
 * __useCreateRemarkProposalVoteMutationMutation__
 *
 * To run a mutation, you first call `useCreateRemarkProposalVoteMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRemarkProposalVoteMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRemarkProposalVoteMutationMutation, { data, loading, error }] = useCreateRemarkProposalVoteMutationMutation({
 *   variables: {
 *      address: // value for 'address'
 *      blockNumber: // value for 'blockNumber'
 *      option: // value for 'option'
 *      optionId: // value for 'optionId'
 *      power: // value for 'power'
 *      proposalId: // value for 'proposalId'
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useCreateRemarkProposalVoteMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRemarkProposalVoteMutationMutation, CreateRemarkProposalVoteMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRemarkProposalVoteMutationMutation, CreateRemarkProposalVoteMutationMutationVariables>(CreateRemarkProposalVoteMutationDocument, baseOptions);
      }
export type CreateRemarkProposalVoteMutationMutationHookResult = ReturnType<typeof useCreateRemarkProposalVoteMutationMutation>;
export type CreateRemarkProposalVoteMutationMutationResult = ApolloReactCommon.MutationResult<CreateRemarkProposalVoteMutationMutation>;
export type CreateRemarkProposalVoteMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRemarkProposalVoteMutationMutation, CreateRemarkProposalVoteMutationMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($email: String!, $network: String!) {
  requestResetPassword(email: $email, network: $network) {
    message
  }
}
    `;
export type RequestResetPasswordMutationFn = ApolloReactCommon.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, baseOptions);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = ApolloReactCommon.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($newPassword: String!, $userId: Int!, $token: String!) {
  resetPassword(newPassword: $newPassword, userId: $userId, token: $token) {
    message
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      userId: // value for 'userId'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SearchPostsDocument = gql`
    query SearchPosts($term: String!) {
  posts(
    order_by: {id: desc}
    limit: 20
    where: {_or: [{title: {_ilike: $term}}, {content: {_ilike: $term}}]}
  ) {
    ...searchPostFields
  }
}
    ${SearchPostFieldsFragmentDoc}`;

/**
 * __useSearchPostsQuery__
 *
 * To run a query within a React component, call `useSearchPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPostsQuery({
 *   variables: {
 *      term: // value for 'term'
 *   },
 * });
 */
export function useSearchPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPostsQuery, SearchPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPostsQuery, SearchPostsQueryVariables>(SearchPostsDocument, baseOptions);
      }
export function useSearchPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPostsQuery, SearchPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPostsQuery, SearchPostsQueryVariables>(SearchPostsDocument, baseOptions);
        }
export type SearchPostsQueryHookResult = ReturnType<typeof useSearchPostsQuery>;
export type SearchPostsLazyQueryHookResult = ReturnType<typeof useSearchPostsLazyQuery>;
export type SearchPostsQueryResult = ApolloReactCommon.QueryResult<SearchPostsQuery, SearchPostsQueryVariables>;
export const ChangeUsernameDocument = gql`
    mutation changeUsername($username: String!, $password: String!) {
  changeUsername(username: $username, password: $password) {
    message
    token
  }
}
    `;
export type ChangeUsernameMutationFn = ApolloReactCommon.MutationFunction<ChangeUsernameMutation, ChangeUsernameMutationVariables>;

/**
 * __useChangeUsernameMutation__
 *
 * To run a mutation, you first call `useChangeUsernameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUsernameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUsernameMutation, { data, loading, error }] = useChangeUsernameMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangeUsernameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeUsernameMutation, ChangeUsernameMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeUsernameMutation, ChangeUsernameMutationVariables>(ChangeUsernameDocument, baseOptions);
      }
export type ChangeUsernameMutationHookResult = ReturnType<typeof useChangeUsernameMutation>;
export type ChangeUsernameMutationResult = ApolloReactCommon.MutationResult<ChangeUsernameMutation>;
export type ChangeUsernameMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeUsernameMutation, ChangeUsernameMutationVariables>;
export const ChangeEmailDocument = gql`
    mutation changeEmail($network: String!, $email: String!, $password: String!) {
  changeEmail(network: $network, email: $email, password: $password) {
    message
    token
  }
}
    `;
export type ChangeEmailMutationFn = ApolloReactCommon.MutationFunction<ChangeEmailMutation, ChangeEmailMutationVariables>;

/**
 * __useChangeEmailMutation__
 *
 * To run a mutation, you first call `useChangeEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEmailMutation, { data, loading, error }] = useChangeEmailMutation({
 *   variables: {
 *      network: // value for 'network'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useChangeEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeEmailMutation, ChangeEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeEmailMutation, ChangeEmailMutationVariables>(ChangeEmailDocument, baseOptions);
      }
export type ChangeEmailMutationHookResult = ReturnType<typeof useChangeEmailMutation>;
export type ChangeEmailMutationResult = ApolloReactCommon.MutationResult<ChangeEmailMutation>;
export type ChangeEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeEmailMutation, ChangeEmailMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    message
  }
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const AddressLinkStartDocument = gql`
    mutation addressLinkStart($network: String!, $address: String!) {
  addressLinkStart(network: $network, address: $address) {
    sign_message
    message
    address_id
  }
}
    `;
export type AddressLinkStartMutationFn = ApolloReactCommon.MutationFunction<AddressLinkStartMutation, AddressLinkStartMutationVariables>;

/**
 * __useAddressLinkStartMutation__
 *
 * To run a mutation, you first call `useAddressLinkStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressLinkStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressLinkStartMutation, { data, loading, error }] = useAddressLinkStartMutation({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddressLinkStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddressLinkStartMutation, AddressLinkStartMutationVariables>) {
        return ApolloReactHooks.useMutation<AddressLinkStartMutation, AddressLinkStartMutationVariables>(AddressLinkStartDocument, baseOptions);
      }
export type AddressLinkStartMutationHookResult = ReturnType<typeof useAddressLinkStartMutation>;
export type AddressLinkStartMutationResult = ApolloReactCommon.MutationResult<AddressLinkStartMutation>;
export type AddressLinkStartMutationOptions = ApolloReactCommon.BaseMutationOptions<AddressLinkStartMutation, AddressLinkStartMutationVariables>;
export const AddressLinkConfirmDocument = gql`
    mutation addressLinkConfirm($address_id: Int!, $signature: String!) {
  addressLinkConfirm(address_id: $address_id, signature: $signature) {
    message
    token
  }
}
    `;
export type AddressLinkConfirmMutationFn = ApolloReactCommon.MutationFunction<AddressLinkConfirmMutation, AddressLinkConfirmMutationVariables>;

/**
 * __useAddressLinkConfirmMutation__
 *
 * To run a mutation, you first call `useAddressLinkConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressLinkConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressLinkConfirmMutation, { data, loading, error }] = useAddressLinkConfirmMutation({
 *   variables: {
 *      address_id: // value for 'address_id'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useAddressLinkConfirmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddressLinkConfirmMutation, AddressLinkConfirmMutationVariables>) {
        return ApolloReactHooks.useMutation<AddressLinkConfirmMutation, AddressLinkConfirmMutationVariables>(AddressLinkConfirmDocument, baseOptions);
      }
export type AddressLinkConfirmMutationHookResult = ReturnType<typeof useAddressLinkConfirmMutation>;
export type AddressLinkConfirmMutationResult = ApolloReactCommon.MutationResult<AddressLinkConfirmMutation>;
export type AddressLinkConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<AddressLinkConfirmMutation, AddressLinkConfirmMutationVariables>;
export const AddressUnlinkDocument = gql`
    mutation addressUnlink($address: String!) {
  addressUnlink(address: $address) {
    message
    token
  }
}
    `;
export type AddressUnlinkMutationFn = ApolloReactCommon.MutationFunction<AddressUnlinkMutation, AddressUnlinkMutationVariables>;

/**
 * __useAddressUnlinkMutation__
 *
 * To run a mutation, you first call `useAddressUnlinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddressUnlinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addressUnlinkMutation, { data, loading, error }] = useAddressUnlinkMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useAddressUnlinkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddressUnlinkMutation, AddressUnlinkMutationVariables>) {
        return ApolloReactHooks.useMutation<AddressUnlinkMutation, AddressUnlinkMutationVariables>(AddressUnlinkDocument, baseOptions);
      }
export type AddressUnlinkMutationHookResult = ReturnType<typeof useAddressUnlinkMutation>;
export type AddressUnlinkMutationResult = ApolloReactCommon.MutationResult<AddressUnlinkMutation>;
export type AddressUnlinkMutationOptions = ApolloReactCommon.BaseMutationOptions<AddressUnlinkMutation, AddressUnlinkMutationVariables>;
export const ResendVerifyEmailTokenDocument = gql`
    mutation resendVerifyEmailToken($network: String!) {
  resendVerifyEmailToken(network: $network) {
    message
  }
}
    `;
export type ResendVerifyEmailTokenMutationFn = ApolloReactCommon.MutationFunction<ResendVerifyEmailTokenMutation, ResendVerifyEmailTokenMutationVariables>;

/**
 * __useResendVerifyEmailTokenMutation__
 *
 * To run a mutation, you first call `useResendVerifyEmailTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyEmailTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyEmailTokenMutation, { data, loading, error }] = useResendVerifyEmailTokenMutation({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useResendVerifyEmailTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendVerifyEmailTokenMutation, ResendVerifyEmailTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendVerifyEmailTokenMutation, ResendVerifyEmailTokenMutationVariables>(ResendVerifyEmailTokenDocument, baseOptions);
      }
export type ResendVerifyEmailTokenMutationHookResult = ReturnType<typeof useResendVerifyEmailTokenMutation>;
export type ResendVerifyEmailTokenMutationResult = ApolloReactCommon.MutationResult<ResendVerifyEmailTokenMutation>;
export type ResendVerifyEmailTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendVerifyEmailTokenMutation, ResendVerifyEmailTokenMutationVariables>;
export const SetDefaultAddressDocument = gql`
    mutation setDefaultAddress($address: String!) {
  setDefaultAddress(address: $address) {
    message
    token
  }
}
    `;
export type SetDefaultAddressMutationFn = ApolloReactCommon.MutationFunction<SetDefaultAddressMutation, SetDefaultAddressMutationVariables>;

/**
 * __useSetDefaultAddressMutation__
 *
 * To run a mutation, you first call `useSetDefaultAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultAddressMutation, { data, loading, error }] = useSetDefaultAddressMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSetDefaultAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDefaultAddressMutation, SetDefaultAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDefaultAddressMutation, SetDefaultAddressMutationVariables>(SetDefaultAddressDocument, baseOptions);
      }
export type SetDefaultAddressMutationHookResult = ReturnType<typeof useSetDefaultAddressMutation>;
export type SetDefaultAddressMutationResult = ApolloReactCommon.MutationResult<SetDefaultAddressMutation>;
export type SetDefaultAddressMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDefaultAddressMutation, SetDefaultAddressMutationVariables>;
export const SetCredentialsStartDocument = gql`
    mutation setCredentialsStart($address: String!) {
  setCredentialsStart(address: $address) {
    message
    signMessage
  }
}
    `;
export type SetCredentialsStartMutationFn = ApolloReactCommon.MutationFunction<SetCredentialsStartMutation, SetCredentialsStartMutationVariables>;

/**
 * __useSetCredentialsStartMutation__
 *
 * To run a mutation, you first call `useSetCredentialsStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCredentialsStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCredentialsStartMutation, { data, loading, error }] = useSetCredentialsStartMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useSetCredentialsStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCredentialsStartMutation, SetCredentialsStartMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCredentialsStartMutation, SetCredentialsStartMutationVariables>(SetCredentialsStartDocument, baseOptions);
      }
export type SetCredentialsStartMutationHookResult = ReturnType<typeof useSetCredentialsStartMutation>;
export type SetCredentialsStartMutationResult = ApolloReactCommon.MutationResult<SetCredentialsStartMutation>;
export type SetCredentialsStartMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCredentialsStartMutation, SetCredentialsStartMutationVariables>;
export const SetCredentialsConfirmDocument = gql`
    mutation setCredentialsConfirm($network: String!, $address: String!, $email: String, $signature: String!, $username: String!, $password: String!) {
  setCredentialsConfirm(
    network: $network
    address: $address
    email: $email
    signature: $signature
    username: $username
    password: $password
  ) {
    message
    token
  }
}
    `;
export type SetCredentialsConfirmMutationFn = ApolloReactCommon.MutationFunction<SetCredentialsConfirmMutation, SetCredentialsConfirmMutationVariables>;

/**
 * __useSetCredentialsConfirmMutation__
 *
 * To run a mutation, you first call `useSetCredentialsConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCredentialsConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCredentialsConfirmMutation, { data, loading, error }] = useSetCredentialsConfirmMutation({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *      email: // value for 'email'
 *      signature: // value for 'signature'
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSetCredentialsConfirmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetCredentialsConfirmMutation, SetCredentialsConfirmMutationVariables>) {
        return ApolloReactHooks.useMutation<SetCredentialsConfirmMutation, SetCredentialsConfirmMutationVariables>(SetCredentialsConfirmDocument, baseOptions);
      }
export type SetCredentialsConfirmMutationHookResult = ReturnType<typeof useSetCredentialsConfirmMutation>;
export type SetCredentialsConfirmMutationResult = ApolloReactCommon.MutationResult<SetCredentialsConfirmMutation>;
export type SetCredentialsConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<SetCredentialsConfirmMutation, SetCredentialsConfirmMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($password: String!) {
  deleteAccount(password: $password) {
    message
  }
}
    `;
export type DeleteAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, baseOptions);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = ApolloReactCommon.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const MultisigLinkStartDocument = gql`
    mutation multisigLinkStart($address: String!) {
  multisigLinkStart(address: $address) {
    message
    signMessage
  }
}
    `;
export type MultisigLinkStartMutationFn = ApolloReactCommon.MutationFunction<MultisigLinkStartMutation, MultisigLinkStartMutationVariables>;

/**
 * __useMultisigLinkStartMutation__
 *
 * To run a mutation, you first call `useMultisigLinkStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultisigLinkStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multisigLinkStartMutation, { data, loading, error }] = useMultisigLinkStartMutation({
 *   variables: {
 *      address: // value for 'address'
 *   },
 * });
 */
export function useMultisigLinkStartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MultisigLinkStartMutation, MultisigLinkStartMutationVariables>) {
        return ApolloReactHooks.useMutation<MultisigLinkStartMutation, MultisigLinkStartMutationVariables>(MultisigLinkStartDocument, baseOptions);
      }
export type MultisigLinkStartMutationHookResult = ReturnType<typeof useMultisigLinkStartMutation>;
export type MultisigLinkStartMutationResult = ApolloReactCommon.MutationResult<MultisigLinkStartMutation>;
export type MultisigLinkStartMutationOptions = ApolloReactCommon.BaseMutationOptions<MultisigLinkStartMutation, MultisigLinkStartMutationVariables>;
export const MultisigLinkConfirmDocument = gql`
    mutation multisigLinkConfirm($network: String!, $address: String!, $addresses: String!, $ss58Prefix: Int!, $threshold: Int!, $signatory: String!, $signature: String!) {
  multisigLinkConfirm(
    network: $network
    address: $address
    addresses: $addresses
    ss58Prefix: $ss58Prefix
    threshold: $threshold
    signatory: $signatory
    signature: $signature
  ) {
    message
    token
  }
}
    `;
export type MultisigLinkConfirmMutationFn = ApolloReactCommon.MutationFunction<MultisigLinkConfirmMutation, MultisigLinkConfirmMutationVariables>;

/**
 * __useMultisigLinkConfirmMutation__
 *
 * To run a mutation, you first call `useMultisigLinkConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMultisigLinkConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [multisigLinkConfirmMutation, { data, loading, error }] = useMultisigLinkConfirmMutation({
 *   variables: {
 *      network: // value for 'network'
 *      address: // value for 'address'
 *      addresses: // value for 'addresses'
 *      ss58Prefix: // value for 'ss58Prefix'
 *      threshold: // value for 'threshold'
 *      signatory: // value for 'signatory'
 *      signature: // value for 'signature'
 *   },
 * });
 */
export function useMultisigLinkConfirmMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MultisigLinkConfirmMutation, MultisigLinkConfirmMutationVariables>) {
        return ApolloReactHooks.useMutation<MultisigLinkConfirmMutation, MultisigLinkConfirmMutationVariables>(MultisigLinkConfirmDocument, baseOptions);
      }
export type MultisigLinkConfirmMutationHookResult = ReturnType<typeof useMultisigLinkConfirmMutation>;
export type MultisigLinkConfirmMutationResult = ApolloReactCommon.MutationResult<MultisigLinkConfirmMutation>;
export type MultisigLinkConfirmMutationOptions = ApolloReactCommon.BaseMutationOptions<MultisigLinkConfirmMutation, MultisigLinkConfirmMutationVariables>;
export const TechCommitteeProposalPostAndCommentsDocument = gql`
    query TechCommitteeProposalPostAndComments($networkId: String!) {
  posts(
    where: {onchain_link: {onchain_network_tech_committee_proposal_id: {_eq: $networkId}}}
  ) {
    ...techCommitteeProposalPost
  }
}
    ${TechCommitteeProposalPostFragmentDoc}`;

/**
 * __useTechCommitteeProposalPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useTechCommitteeProposalPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechCommitteeProposalPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechCommitteeProposalPostAndCommentsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useTechCommitteeProposalPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TechCommitteeProposalPostAndCommentsQuery, TechCommitteeProposalPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<TechCommitteeProposalPostAndCommentsQuery, TechCommitteeProposalPostAndCommentsQueryVariables>(TechCommitteeProposalPostAndCommentsDocument, baseOptions);
      }
export function useTechCommitteeProposalPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TechCommitteeProposalPostAndCommentsQuery, TechCommitteeProposalPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TechCommitteeProposalPostAndCommentsQuery, TechCommitteeProposalPostAndCommentsQueryVariables>(TechCommitteeProposalPostAndCommentsDocument, baseOptions);
        }
export type TechCommitteeProposalPostAndCommentsQueryHookResult = ReturnType<typeof useTechCommitteeProposalPostAndCommentsQuery>;
export type TechCommitteeProposalPostAndCommentsLazyQueryHookResult = ReturnType<typeof useTechCommitteeProposalPostAndCommentsLazyQuery>;
export type TechCommitteeProposalPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<TechCommitteeProposalPostAndCommentsQuery, TechCommitteeProposalPostAndCommentsQueryVariables>;
export const AllTechCommitteeProposalPostsDocument = gql`
    query AllTechCommitteeProposalPosts($network: String!, $postType: Int!, $limit: Int! = 5, $offset: Int! = 0) {
  posts(
    limit: $limit
    offset: $offset
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_tech_committee_proposal_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_tech_committee_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_tech_committee_proposal_id
      onchain_tech_committee_proposal(where: {}) {
        id
        method
        status(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllTechCommitteeProposalPostsQuery__
 *
 * To run a query within a React component, call `useAllTechCommitteeProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTechCommitteeProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTechCommitteeProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllTechCommitteeProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllTechCommitteeProposalPostsQuery, AllTechCommitteeProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllTechCommitteeProposalPostsQuery, AllTechCommitteeProposalPostsQueryVariables>(AllTechCommitteeProposalPostsDocument, baseOptions);
      }
export function useAllTechCommitteeProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTechCommitteeProposalPostsQuery, AllTechCommitteeProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllTechCommitteeProposalPostsQuery, AllTechCommitteeProposalPostsQueryVariables>(AllTechCommitteeProposalPostsDocument, baseOptions);
        }
export type AllTechCommitteeProposalPostsQueryHookResult = ReturnType<typeof useAllTechCommitteeProposalPostsQuery>;
export type AllTechCommitteeProposalPostsLazyQueryHookResult = ReturnType<typeof useAllTechCommitteeProposalPostsLazyQuery>;
export type AllTechCommitteeProposalPostsQueryResult = ApolloReactCommon.QueryResult<AllTechCommitteeProposalPostsQuery, AllTechCommitteeProposalPostsQueryVariables>;
export const TipPostAndCommentsDocument = gql`
    query TipPostAndComments($networkHash: String!) {
  posts(where: {onchain_link: {onchain_network_tip_id: {_eq: $networkHash}}}) {
    ...tipPost
  }
}
    ${TipPostFragmentDoc}`;

/**
 * __useTipPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useTipPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTipPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTipPostAndCommentsQuery({
 *   variables: {
 *      networkHash: // value for 'networkHash'
 *   },
 * });
 */
export function useTipPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TipPostAndCommentsQuery, TipPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<TipPostAndCommentsQuery, TipPostAndCommentsQueryVariables>(TipPostAndCommentsDocument, baseOptions);
      }
export function useTipPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TipPostAndCommentsQuery, TipPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TipPostAndCommentsQuery, TipPostAndCommentsQueryVariables>(TipPostAndCommentsDocument, baseOptions);
        }
export type TipPostAndCommentsQueryHookResult = ReturnType<typeof useTipPostAndCommentsQuery>;
export type TipPostAndCommentsLazyQueryHookResult = ReturnType<typeof useTipPostAndCommentsLazyQuery>;
export type TipPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<TipPostAndCommentsQuery, TipPostAndCommentsQueryVariables>;
export const AllTipPostsDocument = gql`
    query AllTipPosts($network: String!, $postType: Int!, $postTopic: Int!, $limit: Int! = 5, $offset: Int! = 0) {
  posts(
    limit: $limit
    offset: $offset
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_tip_id: {_is_null: false}}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_tip_id
      onchain_tip(where: {}) {
        id
        reason
        tipStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllTipPostsQuery__
 *
 * To run a query within a React component, call `useAllTipPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTipPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTipPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllTipPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllTipPostsQuery, AllTipPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllTipPostsQuery, AllTipPostsQueryVariables>(AllTipPostsDocument, baseOptions);
      }
export function useAllTipPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTipPostsQuery, AllTipPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllTipPostsQuery, AllTipPostsQueryVariables>(AllTipPostsDocument, baseOptions);
        }
export type AllTipPostsQueryHookResult = ReturnType<typeof useAllTipPostsQuery>;
export type AllTipPostsLazyQueryHookResult = ReturnType<typeof useAllTipPostsLazyQuery>;
export type AllTipPostsQueryResult = ApolloReactCommon.QueryResult<AllTipPostsQuery, AllTipPostsQueryVariables>;
export const TrackerBountyPostsDocument = gql`
    query TrackerBountyPosts($network: String!, $postType: Int!, $onchainBountyIds: [String!]!) {
  posts(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_bounty_id: {_in: $onchainBountyIds}}}
    order_by: {onchain_link: {onchain_network_bounty_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_bounty_id
      onchain_bounty(where: {}) {
        id
        bountyStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useTrackerBountyPostsQuery__
 *
 * To run a query within a React component, call `useTrackerBountyPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerBountyPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerBountyPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      onchainBountyIds: // value for 'onchainBountyIds'
 *   },
 * });
 */
export function useTrackerBountyPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackerBountyPostsQuery, TrackerBountyPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackerBountyPostsQuery, TrackerBountyPostsQueryVariables>(TrackerBountyPostsDocument, baseOptions);
      }
export function useTrackerBountyPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackerBountyPostsQuery, TrackerBountyPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackerBountyPostsQuery, TrackerBountyPostsQueryVariables>(TrackerBountyPostsDocument, baseOptions);
        }
export type TrackerBountyPostsQueryHookResult = ReturnType<typeof useTrackerBountyPostsQuery>;
export type TrackerBountyPostsLazyQueryHookResult = ReturnType<typeof useTrackerBountyPostsLazyQuery>;
export type TrackerBountyPostsQueryResult = ApolloReactCommon.QueryResult<TrackerBountyPostsQuery, TrackerBountyPostsQueryVariables>;
export const TrackerMotionPostsDocument = gql`
    query TrackerMotionPosts($network: String!, $postType: Int!, $onchainMotionIds: [String!]!) {
  posts(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_motion_id: {_in: $onchainMotionIds}}}
    order_by: {onchain_link: {onchain_network_motion_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_motion_id
      onchain_motion(where: {}) {
        id
        motionStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useTrackerMotionPostsQuery__
 *
 * To run a query within a React component, call `useTrackerMotionPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerMotionPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerMotionPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      onchainMotionIds: // value for 'onchainMotionIds'
 *   },
 * });
 */
export function useTrackerMotionPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackerMotionPostsQuery, TrackerMotionPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackerMotionPostsQuery, TrackerMotionPostsQueryVariables>(TrackerMotionPostsDocument, baseOptions);
      }
export function useTrackerMotionPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackerMotionPostsQuery, TrackerMotionPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackerMotionPostsQuery, TrackerMotionPostsQueryVariables>(TrackerMotionPostsDocument, baseOptions);
        }
export type TrackerMotionPostsQueryHookResult = ReturnType<typeof useTrackerMotionPostsQuery>;
export type TrackerMotionPostsLazyQueryHookResult = ReturnType<typeof useTrackerMotionPostsLazyQuery>;
export type TrackerMotionPostsQueryResult = ApolloReactCommon.QueryResult<TrackerMotionPostsQuery, TrackerMotionPostsQueryVariables>;
export const TrackerDemocracyProposalPostsDocument = gql`
    query TrackerDemocracyProposalPosts($network: String!, $postType: Int!, $onchainProposalIds: [String!]!) {
  posts(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_proposal_id: {_in: $onchainProposalIds}}}
    order_by: {onchain_link: {onchain_network_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_proposal_id
      onchain_proposal(where: {}) {
        id
        proposalStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useTrackerDemocracyProposalPostsQuery__
 *
 * To run a query within a React component, call `useTrackerDemocracyProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerDemocracyProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerDemocracyProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      onchainProposalIds: // value for 'onchainProposalIds'
 *   },
 * });
 */
export function useTrackerDemocracyProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackerDemocracyProposalPostsQuery, TrackerDemocracyProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackerDemocracyProposalPostsQuery, TrackerDemocracyProposalPostsQueryVariables>(TrackerDemocracyProposalPostsDocument, baseOptions);
      }
export function useTrackerDemocracyProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackerDemocracyProposalPostsQuery, TrackerDemocracyProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackerDemocracyProposalPostsQuery, TrackerDemocracyProposalPostsQueryVariables>(TrackerDemocracyProposalPostsDocument, baseOptions);
        }
export type TrackerDemocracyProposalPostsQueryHookResult = ReturnType<typeof useTrackerDemocracyProposalPostsQuery>;
export type TrackerDemocracyProposalPostsLazyQueryHookResult = ReturnType<typeof useTrackerDemocracyProposalPostsLazyQuery>;
export type TrackerDemocracyProposalPostsQueryResult = ApolloReactCommon.QueryResult<TrackerDemocracyProposalPostsQuery, TrackerDemocracyProposalPostsQueryVariables>;
export const TrackerReferendaPostsDocument = gql`
    query TrackerReferendaPosts($network: String!, $postType: Int!, $onchainReferendumIds: [String!]!) {
  posts(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_referendum_id: {_in: $onchainReferendumIds}}}
    order_by: {onchain_link: {onchain_network_referendum_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_referendum_id
      onchain_referendum(where: {}) {
        id
        end
        referendumStatus(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useTrackerReferendaPostsQuery__
 *
 * To run a query within a React component, call `useTrackerReferendaPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerReferendaPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerReferendaPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      onchainReferendumIds: // value for 'onchainReferendumIds'
 *   },
 * });
 */
export function useTrackerReferendaPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackerReferendaPostsQuery, TrackerReferendaPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackerReferendaPostsQuery, TrackerReferendaPostsQueryVariables>(TrackerReferendaPostsDocument, baseOptions);
      }
export function useTrackerReferendaPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackerReferendaPostsQuery, TrackerReferendaPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackerReferendaPostsQuery, TrackerReferendaPostsQueryVariables>(TrackerReferendaPostsDocument, baseOptions);
        }
export type TrackerReferendaPostsQueryHookResult = ReturnType<typeof useTrackerReferendaPostsQuery>;
export type TrackerReferendaPostsLazyQueryHookResult = ReturnType<typeof useTrackerReferendaPostsLazyQuery>;
export type TrackerReferendaPostsQueryResult = ApolloReactCommon.QueryResult<TrackerReferendaPostsQuery, TrackerReferendaPostsQueryVariables>;
export const TrackerTechCommitteeProposalPostsDocument = gql`
    query TrackerTechCommitteeProposalPosts($network: String!, $postType: Int!, $onchainTechCommitteeProposalIds: [String!]!) {
  posts(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_tech_committee_proposal_id: {_in: $onchainTechCommitteeProposalIds}}}
    order_by: {onchain_link: {onchain_network_tech_committee_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_tech_committee_proposal_id
      onchain_tech_committee_proposal(where: {}) {
        id
        status(last: 1) {
          id
          status
        }
        preimage {
          id
          method
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useTrackerTechCommitteeProposalPostsQuery__
 *
 * To run a query within a React component, call `useTrackerTechCommitteeProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerTechCommitteeProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerTechCommitteeProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      onchainTechCommitteeProposalIds: // value for 'onchainTechCommitteeProposalIds'
 *   },
 * });
 */
export function useTrackerTechCommitteeProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackerTechCommitteeProposalPostsQuery, TrackerTechCommitteeProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackerTechCommitteeProposalPostsQuery, TrackerTechCommitteeProposalPostsQueryVariables>(TrackerTechCommitteeProposalPostsDocument, baseOptions);
      }
export function useTrackerTechCommitteeProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackerTechCommitteeProposalPostsQuery, TrackerTechCommitteeProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackerTechCommitteeProposalPostsQuery, TrackerTechCommitteeProposalPostsQueryVariables>(TrackerTechCommitteeProposalPostsDocument, baseOptions);
        }
export type TrackerTechCommitteeProposalPostsQueryHookResult = ReturnType<typeof useTrackerTechCommitteeProposalPostsQuery>;
export type TrackerTechCommitteeProposalPostsLazyQueryHookResult = ReturnType<typeof useTrackerTechCommitteeProposalPostsLazyQuery>;
export type TrackerTechCommitteeProposalPostsQueryResult = ApolloReactCommon.QueryResult<TrackerTechCommitteeProposalPostsQuery, TrackerTechCommitteeProposalPostsQueryVariables>;
export const TrackerTipPostsDocument = gql`
    query TrackerTipPosts($network: String!, $postType: Int!, $onchainTipIds: [String!]!) {
  posts(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_tip_id: {_in: $onchainTipIds}}}
    order_by: {onchain_link: {id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_tip_id
      onchain_tip(where: {}) {
        id
        reason
        tipStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useTrackerTipPostsQuery__
 *
 * To run a query within a React component, call `useTrackerTipPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerTipPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerTipPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      onchainTipIds: // value for 'onchainTipIds'
 *   },
 * });
 */
export function useTrackerTipPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackerTipPostsQuery, TrackerTipPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackerTipPostsQuery, TrackerTipPostsQueryVariables>(TrackerTipPostsDocument, baseOptions);
      }
export function useTrackerTipPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackerTipPostsQuery, TrackerTipPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackerTipPostsQuery, TrackerTipPostsQueryVariables>(TrackerTipPostsDocument, baseOptions);
        }
export type TrackerTipPostsQueryHookResult = ReturnType<typeof useTrackerTipPostsQuery>;
export type TrackerTipPostsLazyQueryHookResult = ReturnType<typeof useTrackerTipPostsLazyQuery>;
export type TrackerTipPostsQueryResult = ApolloReactCommon.QueryResult<TrackerTipPostsQuery, TrackerTipPostsQueryVariables>;
export const TrackerDemocracyTreasuryProposalPostsDocument = gql`
    query TrackerDemocracyTreasuryProposalPosts($network: String!, $postType: Int!, $onchainTreasuryProposalIds: [String!]!) {
  posts(
    where: {type: {id: {_eq: $postType}}, network: {_eq: $network}, onchain_link: {onchain_network_treasury_proposal_id: {_in: $onchainTreasuryProposalIds}}}
    order_by: {onchain_link: {onchain_network_treasury_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_treasury_proposal_id
      onchain_treasury_spend_proposal(where: {}) {
        id
        treasuryStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useTrackerDemocracyTreasuryProposalPostsQuery__
 *
 * To run a query within a React component, call `useTrackerDemocracyTreasuryProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackerDemocracyTreasuryProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackerDemocracyTreasuryProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      onchainTreasuryProposalIds: // value for 'onchainTreasuryProposalIds'
 *   },
 * });
 */
export function useTrackerDemocracyTreasuryProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrackerDemocracyTreasuryProposalPostsQuery, TrackerDemocracyTreasuryProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrackerDemocracyTreasuryProposalPostsQuery, TrackerDemocracyTreasuryProposalPostsQueryVariables>(TrackerDemocracyTreasuryProposalPostsDocument, baseOptions);
      }
export function useTrackerDemocracyTreasuryProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrackerDemocracyTreasuryProposalPostsQuery, TrackerDemocracyTreasuryProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrackerDemocracyTreasuryProposalPostsQuery, TrackerDemocracyTreasuryProposalPostsQueryVariables>(TrackerDemocracyTreasuryProposalPostsDocument, baseOptions);
        }
export type TrackerDemocracyTreasuryProposalPostsQueryHookResult = ReturnType<typeof useTrackerDemocracyTreasuryProposalPostsQuery>;
export type TrackerDemocracyTreasuryProposalPostsLazyQueryHookResult = ReturnType<typeof useTrackerDemocracyTreasuryProposalPostsLazyQuery>;
export type TrackerDemocracyTreasuryProposalPostsQueryResult = ApolloReactCommon.QueryResult<TrackerDemocracyTreasuryProposalPostsQuery, TrackerDemocracyTreasuryProposalPostsQueryVariables>;
export const AllDemocracyTreasuryProposalPostsDocument = gql`
    query AllDemocracyTreasuryProposalPosts($network: String!, $postType: Int!, $postTopic: Int!, $limit: Int! = 5, $offset: Int! = 0) {
  posts(
    limit: $limit
    offset: $offset
    where: {type: {id: {_eq: $postType}}, topic: {id: {_eq: $postTopic}}, network: {_eq: $network}, onchain_link: {onchain_network_treasury_proposal_id: {_is_null: false}}}
    order_by: {onchain_link: {onchain_network_treasury_proposal_id: desc}}
  ) {
    id
    title
    author {
      ...authorFields
    }
    created_at
    updated_at
    comments_aggregate {
      aggregate {
        count
      }
    }
    type {
      name
      id
    }
    topic {
      id
      name
    }
    onchain_link {
      id
      onchain_network_treasury_proposal_id
      onchain_treasury_spend_proposal(where: {}) {
        id
        treasuryStatus(last: 1) {
          id
          status
        }
      }
      proposer_address
    }
    post_reactions {
      reaction
    }
  }
}
    ${AuthorFieldsFragmentDoc}`;

/**
 * __useAllDemocracyTreasuryProposalPostsQuery__
 *
 * To run a query within a React component, call `useAllDemocracyTreasuryProposalPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllDemocracyTreasuryProposalPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllDemocracyTreasuryProposalPostsQuery({
 *   variables: {
 *      network: // value for 'network'
 *      postType: // value for 'postType'
 *      postTopic: // value for 'postTopic'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAllDemocracyTreasuryProposalPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllDemocracyTreasuryProposalPostsQuery, AllDemocracyTreasuryProposalPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllDemocracyTreasuryProposalPostsQuery, AllDemocracyTreasuryProposalPostsQueryVariables>(AllDemocracyTreasuryProposalPostsDocument, baseOptions);
      }
export function useAllDemocracyTreasuryProposalPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllDemocracyTreasuryProposalPostsQuery, AllDemocracyTreasuryProposalPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllDemocracyTreasuryProposalPostsQuery, AllDemocracyTreasuryProposalPostsQueryVariables>(AllDemocracyTreasuryProposalPostsDocument, baseOptions);
        }
export type AllDemocracyTreasuryProposalPostsQueryHookResult = ReturnType<typeof useAllDemocracyTreasuryProposalPostsQuery>;
export type AllDemocracyTreasuryProposalPostsLazyQueryHookResult = ReturnType<typeof useAllDemocracyTreasuryProposalPostsLazyQuery>;
export type AllDemocracyTreasuryProposalPostsQueryResult = ApolloReactCommon.QueryResult<AllDemocracyTreasuryProposalPostsQuery, AllDemocracyTreasuryProposalPostsQueryVariables>;
export const TreasuryProposalPostAndCommentsDocument = gql`
    query TreasuryProposalPostAndComments($networkId: String!) {
  posts(
    where: {onchain_link: {onchain_network_treasury_proposal_id: {_eq: $networkId}}}
  ) {
    ...treasuryProposalPost
  }
}
    ${TreasuryProposalPostFragmentDoc}`;

/**
 * __useTreasuryProposalPostAndCommentsQuery__
 *
 * To run a query within a React component, call `useTreasuryProposalPostAndCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTreasuryProposalPostAndCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTreasuryProposalPostAndCommentsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useTreasuryProposalPostAndCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TreasuryProposalPostAndCommentsQuery, TreasuryProposalPostAndCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<TreasuryProposalPostAndCommentsQuery, TreasuryProposalPostAndCommentsQueryVariables>(TreasuryProposalPostAndCommentsDocument, baseOptions);
      }
export function useTreasuryProposalPostAndCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TreasuryProposalPostAndCommentsQuery, TreasuryProposalPostAndCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TreasuryProposalPostAndCommentsQuery, TreasuryProposalPostAndCommentsQueryVariables>(TreasuryProposalPostAndCommentsDocument, baseOptions);
        }
export type TreasuryProposalPostAndCommentsQueryHookResult = ReturnType<typeof useTreasuryProposalPostAndCommentsQuery>;
export type TreasuryProposalPostAndCommentsLazyQueryHookResult = ReturnType<typeof useTreasuryProposalPostAndCommentsLazyQuery>;
export type TreasuryProposalPostAndCommentsQueryResult = ApolloReactCommon.QueryResult<TreasuryProposalPostAndCommentsQuery, TreasuryProposalPostAndCommentsQueryVariables>;
export const UndoEmailChangeDocument = gql`
    mutation undoEmailChange($token: String!) {
  undoEmailChange(token: $token) {
    message
    token
    email
  }
}
    `;
export type UndoEmailChangeMutationFn = ApolloReactCommon.MutationFunction<UndoEmailChangeMutation, UndoEmailChangeMutationVariables>;

/**
 * __useUndoEmailChangeMutation__
 *
 * To run a mutation, you first call `useUndoEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoEmailChangeMutation, { data, loading, error }] = useUndoEmailChangeMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUndoEmailChangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UndoEmailChangeMutation, UndoEmailChangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UndoEmailChangeMutation, UndoEmailChangeMutationVariables>(UndoEmailChangeDocument, baseOptions);
      }
export type UndoEmailChangeMutationHookResult = ReturnType<typeof useUndoEmailChangeMutation>;
export type UndoEmailChangeMutationResult = ApolloReactCommon.MutationResult<UndoEmailChangeMutation>;
export type UndoEmailChangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UndoEmailChangeMutation, UndoEmailChangeMutationVariables>;
export const ProfileDocument = gql`
    query PROFILE($username: String!) {
  profile(username: $username) {
    id
    username
    default_address
  }
}
    `;

/**
 * __useProfileQuery__
 *
 * To run a query within a React component, call `useProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
      }
export function useProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileQuery, ProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileQuery, ProfileQueryVariables>(ProfileDocument, baseOptions);
        }
export type ProfileQueryHookResult = ReturnType<typeof useProfileQuery>;
export type ProfileLazyQueryHookResult = ReturnType<typeof useProfileLazyQuery>;
export type ProfileQueryResult = ApolloReactCommon.QueryResult<ProfileQuery, ProfileQueryVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($token: String!) {
  verifyEmail(token: $token) {
    message
    token
  }
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
