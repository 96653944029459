// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React from 'react';
import { useParams } from 'react-router-dom';
import { PostCategory } from 'src/global/post_categories';
import BackToListingView from 'src/ui-components/BackToListingView';
import { ErrorState } from 'src/ui-components/UIStates';

import Post from '../../../components/Post/Post';
import { usePipPostAndCommentsQuery } from '../../../generated/graphql';
import Loader from '../../../ui-components/Loader';
import getNetwork from '../../../util/getNetwork';

const network = getNetwork();

const CommitteePost = ({ pipType }:any) => {
	const { id } = useParams();
	const { data, error, refetch } = usePipPostAndCommentsQuery({
		variables: {
			networkId: `${network}_${id}`
		}
	});

	if (error?.message) return <ErrorState errorMessage={error.message} />;

	if (data) return (
		<>
			<BackToListingView postCategory={pipType.isTechnical? PostCategory.PIP_TECHNICAL: pipType.isCommunity? PostCategory.PIP_COMMUNITY: PostCategory.PIP_UPGRADE} />
			<div className='mt-6'>
				<Post data={data} { ...pipType } refetch={refetch} />
			</div>
		</>
	);

	return <Loader/>;
};

export default CommitteePost;
