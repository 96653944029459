// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { DownOutlined } from '@ant-design/icons';
import { MenuProps, Space } from 'antd';
import { Dropdown } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from 'src/context/ApiContext';
import { useGetBlockchainEndpointsLazyQuery } from 'src/generated/graphql';
import getNetwork from 'src/util/getNetwork';

interface Props {
	className?: string
	setSidebarHiddenFunc?: () => void
}

const NETWORK = getNetwork();

const RPCDropdown = ({ className }: Props) => {
	const { wsProvider, setWsProvider } = useContext(ApiContext);
	const [endpoint, setEndpoint] = useState<string>(wsProvider);
	const [RPCOptions, setRPCOptions] = useState<MenuProps['items']>([]);

	const [refetch, { data, error }] = useGetBlockchainEndpointsLazyQuery({
		variables:{
			network: NETWORK
		}
	});

	useEffect(() => {
		let cancel = false;
		if(cancel) return;

		refetch();

		const items: MenuProps['items'] = [];

		data?.blockchain_endpoints.forEach((endpointData) => {
			const optionObj = {
				key: endpointData.endpoint,
				label: endpointData.display_label
			};

			items.push(optionObj);
		});

		setRPCOptions(items);

		return () => {
			cancel = true;
		};

	}, [data?.blockchain_endpoints, refetch]);

	const dropdownLabel = () => {
		let label = '';

		data?.blockchain_endpoints.some((endpointData) => {
			if(endpointData.endpoint == endpoint){
				label = `${endpointData.display_label?.substring(4, endpointData.display_label.length)}`;
				return true;
			}
		});

		return <span className='min-w-[75px]'>{label}</span>;
	};

	const handleEndpointChange: MenuProps['onClick'] = ({ key }) => {
		if(endpoint == `${key}`) return;

		setEndpoint(`${key}`);
		setWsProvider(`${key}`);
	};

	return (
		!error && data && data.blockchain_endpoints.length > 0 ? <Dropdown
			trigger={['click']}
			menu={{ items: RPCOptions, onClick: handleEndpointChange }}
			className={className}
		><Space className='text-navBlue hover:text-pink_primary font-medium cursor-pointer'>{dropdownLabel()}<DownOutlined className='align-middle' /></Space></Dropdown> : <></>
	);
};

export default RPCDropdown;
