// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import styled from '@xstyled/styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { PostEmptyState } from 'src/ui-components/UIStates';

import RemarkProposalCard from '../../RemarkProposalCard';

interface Props {
  className?: string
  data: any
}

const RemarkProposalListing = ({ className, data }: Props) => {

	const noPost = !data.remark_proposals || !data.remark_proposals.length;
	// const atLeastOneCurrentBounty = data.remark_proposals.some((post: any) => {
	// 	if (post.onchain_link?.onchain_bounty.length){
	// 		// this breaks the loop as soon as
	// 		// we find a post that has a bounty.
	// 		return true;
	// 	}
	// 	return false;
	// });

	if (noPost) return <div className={className}><PostEmptyState /></div>;

	return (
		<ul className={`${className} bounties__list`}>
			{data.remark_proposals.map(
				(post: any) => {
					// const onchainId = post.onchain_link?.onchain_network_bounty_id?.split('_')[1];
					const id = post.id;

					return <li key={post.id} className='bounties__item'>
						{<Link to={`/remark-proposal/${id}`}>
							<RemarkProposalCard
								defaultAddress={post.address}
								// comments={post.comments_aggregate.aggregate?.count
								// 	? post.comments_aggregate.aggregate.count.toString()
								// 	: 'no'}
								created_at={post.created_at}
								last_update={post.updated_at}
								title={post.title || 'No title'}
								id={id}
								// username={post.author.username}
							/>
						</Link>}
					</li>
					;
				}
			)}
		</ul>
	);
};

export default styled(RemarkProposalListing)`
	margin-block-start: 0;
	margin-block-end: 0;

	li {
		list-style-type: none;
	}

	.bounties__item {
		margin: 0 0 1rem 0;
		a:hover {
			text-decoration: none;
		}
	}
`;
