// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { ShareAltOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Dropdown } from 'antd';
import React from 'react';
import copyToClipboard from 'src/util/copyToClipboard';
import getNetwork from 'src/util/getNetwork';

const NETWORK = getNetwork();

const ShareButton = function ({ title }: {title?: string | null}) {

	const share = () => {
		const twitterParameters = [];

		twitterParameters.push(`url=${encodeURI(global.window.location.href)}`);

		if (title) {
			twitterParameters.push(`text=${encodeURI(`[${NETWORK}] ${title}`)}`);
		}

		twitterParameters.push('via=' + encodeURI('polkassembly'));

		const url = 'https://twitter.com/intent/tweet?' + twitterParameters.join('&');

		global.window.open(url);
	};

	const items: MenuProps['items'] = [
		{
			key: 1,
			label: (
				<div
					onClick={share}
				>
					Twitter
				</div>
			)
		},
		{
			key: 2,
			label: (
				<div
					onClick={() => copyToClipboard(global.window.location.href)}
				>
					Copy link
				</div>
			)
		}
	];

	return (
		<>
			<Dropdown
				menu={{ items }}
				trigger={['click']}
			>
				<Button
					className={'text-pink_primary flex items-center border-none shadow-none px-1 md:px-2'}
				>
					<ShareAltOutlined /> {' Share'}
				</Button>
			</Dropdown>
		</>
	);
};

export default ShareButton;
