// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React, { useEffect } from 'react';
import RemarkProposalListing from 'src/components/Listing/RemarkProposal/RemarkProposalListing';
import { LoadingState } from 'src/ui-components/UIStates';

import { useRemarkProposalsListingQuery } from '../../../generated/graphql';
import FilteredError from '../../../ui-components/FilteredError';
import getNetwork from '../../../util/getNetwork';

const NETWORK = getNetwork();

interface Props {
	className?: string
	limit: number
}

const RemarkProposalContainer = ({ className, limit }:Props) => {

	const { data, error, refetch } = useRemarkProposalsListingQuery({ variables: {
		limit,
		network: NETWORK
	} });

	useEffect(() => {
		refetch();
	}, [refetch]);

	if (error?.message) return <FilteredError text={error.message}/>;

	if (data) return (
		<>
			<div className={`${className} shadow-md bg-white p-3 md:p-8 rounded-md`}>
				<div className='flex items-center justify-between'>
					<h1 className='dashboard-heading'>Remark Proposals</h1>
				</div>

				<RemarkProposalListing className='mt-6' data={data} />
			</div>
		</>
	);

	return <LoadingState/>;
};

export default RemarkProposalContainer;
