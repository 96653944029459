// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React from 'react';
import { noTitle } from 'src/global/noTitle';
import CreationLabel from 'src/ui-components/CreationLabel';
import StatusTag from 'src/ui-components/StatusTag';
import UpdateLabel from 'src/ui-components/UpdateLabel';
import getIdFromOnchainId from 'src/util/getIdFromOnchainId';
import getNetwork from 'src/util/getNetwork';

import { DiscussionPostFragment, MotionPostFragment,OnchainLinkPipFragment,ProposalPostFragment, ReferendumPostFragment, TreasuryProposalPostFragment } from '../../generated/graphql';
import { convertHexToStr } from './Tabs/PostOnChainInfo/PostPipInfo';

interface Props {
	className?: string,
	isTipProposal: boolean,
	onchainId?: string | number | null
	post: DiscussionPostFragment | ProposalPostFragment | ReferendumPostFragment| TreasuryProposalPostFragment| MotionPostFragment
	postStatus?: string
}
const NETWORK = getNetwork();
const PostHeading = ({ className, isTipProposal, onchainId, post, postStatus }:Props) => {
	const { author, created_at, content, updated_at } = post;

	let title = post.title;

	if (!author || !author.username || !content) return <div>Post not available</div>;

	const defaultAddress = (post as any).onchain_link?.proposer_address || author.default_address;

	if (!title && ['polymesh','polymesh-test'].includes(NETWORK)) {
		if (post?.onchain_link) {
			const onchainPip = (post?.onchain_link as OnchainLinkPipFragment)?.onchain_pip;
			if (onchainPip && Array.isArray(onchainPip) && onchainPip.length > 0) {
				const desc = onchainPip?.[0]?.optionDescription;
				let metaDescription;
				if (desc?.startsWith('0x')) {
					metaDescription = convertHexToStr(desc);
				} else {
					metaDescription = desc;
				}
				if (metaDescription) {
					title = metaDescription;
				} else {
					title = onchainPip?.[0]?.method;
				}
			}
		}
	}

	return (
		<div className={className}>
			{postStatus && <StatusTag className='mb-3' status={postStatus}/>}
			<h2 className='text-lg font-medium mb-1'>{(onchainId || onchainId === 0) && !isTipProposal && `#${getIdFromOnchainId(onchainId)}`} {title || noTitle}</h2>
			<div className='mb-8'>
				<>
					<CreationLabel
						className='md'
						created_at={created_at}
						defaultAddress={defaultAddress}
						username={author.username}
						topic={post.topic.name}
					>
						<UpdateLabel
							className='md'
							created_at={created_at}
							updated_at={updated_at}
						/>
					</CreationLabel>
				</>
			</div>
		</div>
	);
};

export default PostHeading;
