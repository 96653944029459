// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { Signer } from '@polkadot/api/types';
import { isWeb3Injected, web3Enable } from '@polkadot/extension-dapp';
import { Injected, InjectedAccount, InjectedWindow } from '@polkadot/extension-inject/types';
import styled from '@xstyled/styled-components';
import { Alert, Button, Form, Input } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PostCategory } from 'src/global/post_categories';
import BackToListingView from 'src/ui-components/BackToListingView';
import ErrorAlert from 'src/ui-components/ErrorAlert';
import queueNotification from 'src/ui-components/QueueNotification';
import getEncodedAddress from 'src/util/getEncodedAddress';

import ContentForm from '../../components/ContentForm';
// import TitleForm from '../../components/TitleForm';
import { ApiContext } from '../../context/ApiContext';
import { UserDetailsContext } from '../../context/UserDetailsContext';
import { useRemarkProposalCreateMutationMutation, useRemarkProposalOptionsCreateMutationMutation } from '../../generated/graphql';
import { APPNAME } from '../../global/appName';
import { NotificationStatus, Wallet } from '../../types';
import AccountSelectionForm from '../../ui-components/AccountSelectionForm';
import getNetwork from '../../util/getNetwork';

const network = getNetwork();

interface Props {
	className?: string
}

const CreateRemarkProposal = ({ className }:Props): JSX.Element => {
	// const [title, setTitle] = useState('');
	// const [content, setContent] = useState('');
	const [optionsArray, setOptionsArray] = useState(['']);
	const currentUser = useContext(UserDetailsContext);
	// const { control, errors, handleSubmit } = useForm();
	const [address, setAddress] = useState<string>(currentUser.defaultAddress || '');
	const [accounts, setAccounts] = useState<InjectedAccount[]>([]);
	const [accountsMap, setAccountsMap] = useState<{[key:string]:string}>({});
	const [signersMap, setSignersMap] = useState<{[key:string]: Signer}>({});
	const { api, apiReady } = useContext(ApiContext);

	const [createRemarkProposalPost , { loading, error }] = useRemarkProposalCreateMutationMutation();
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [createOptionsMutation] = useRemarkProposalOptionsCreateMutationMutation();
	const [isSending, setIsSending] = useState<boolean>(false);
	const [formDisabled, setFormDisabled] = useState<boolean>(false);
	const navigate = useNavigate();

	const [form] = Form.useForm();

	useEffect(() => {
		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		const signer: Signer = signersMap[accountsMap[address]];
		api?.setSigner(signer);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [address]);

	const getWalletAccounts = async (chosenWallet: Wallet): Promise<InjectedAccount[] | undefined> => {
		const injectedWindow = window as Window & InjectedWindow;

		let wallet = isWeb3Injected
			? injectedWindow.injectedWeb3[chosenWallet]
			: null;

		if (!wallet) {
			wallet = Object.values(injectedWindow.injectedWeb3)[0];
		}

		if (!wallet) {
			return;
		}

		let injected: Injected | undefined;

		try {
			injected = await new Promise((resolve, reject) => {
				const timeoutId = setTimeout(() => {
					reject(new Error('Wallet Timeout'));
				}, 60000); // wait 60 sec

				wallet?.enable?.(APPNAME).then(value => {
					clearTimeout(timeoutId);
					resolve(value);
				}).catch(error => {
					reject(error);
				});
			});
		} catch (err) {
			console.log('Error fetching wallet accounts : ', err);
		}

		if(!injected) {
			return;
		}

		const accounts = await injected.accounts.get();

		if (accounts.length === 0) return;

		accounts.forEach((account) => {
			account.address = getEncodedAddress(account.address) || account.address;
		});

		return accounts;
	};

	const getAccounts = useCallback(async (): Promise<undefined> => {
		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		const extensions = await web3Enable(APPNAME);

		let accounts: InjectedAccount[] = [];
		let polakadotJSAccounts : InjectedAccount[] | undefined;
		let subwalletAccounts: InjectedAccount[] | undefined;
		let talismanAccounts: InjectedAccount[] | undefined;

		const signersMapLocal = signersMap as {[key:string]: Signer};
		const accountsMapLocal = accountsMap as {[key:string]: string};

		for (const extObj of extensions) {
			if(extObj.name == 'polkadot-js') {
				signersMapLocal['polkadot-js'] = extObj.signer;
				polakadotJSAccounts = await getWalletAccounts(Wallet.POLKADOT);
			} else if(extObj.name == 'subwallet-js') {
				signersMapLocal['subwallet-js'] = extObj.signer;
				subwalletAccounts = await getWalletAccounts(Wallet.SUBWALLET);
			} else if(extObj.name == 'talisman') {
				signersMapLocal['talisman'] = extObj.signer;
				talismanAccounts = await getWalletAccounts(Wallet.TALISMAN);
			}
		}

		if(polakadotJSAccounts) {
			accounts = accounts.concat(polakadotJSAccounts);
			polakadotJSAccounts.forEach((acc: InjectedAccount) => {
				accountsMapLocal[acc.address] = 'polkadot-js';
			});
		}

		if(subwalletAccounts) {
			accounts = accounts.concat(subwalletAccounts);
			subwalletAccounts.forEach((acc: InjectedAccount) => {
				accountsMapLocal[acc.address] = 'subwallet-js';
			});
		}

		if(talismanAccounts) {
			accounts = accounts.concat(talismanAccounts);
			talismanAccounts.forEach((acc: InjectedAccount) => {
				accountsMapLocal[acc.address] = 'talisman';
			});
		}

		if (accounts.length === 0) {
			return;
		} else {
			setAccountsMap(accountsMapLocal);
			setSignersMap(signersMapLocal);
		}

		setAccounts(accounts);
		if (accounts.length > 0) {
			const signer: Signer = signersMapLocal[accountsMapLocal[accounts[0].address]];
			api.setSigner(signer);
		}

		return;
	}, [accountsMap, api, apiReady, signersMap]);

	useEffect(() => {
		getAccounts();
	}, [getAccounts]);

	const handleSend = async () => {

		if (!api || !apiReady || !currentUser.id) return;

		try{
			form.validateFields();

			const title = form.getFieldValue('title');
			const content = form.getFieldValue('content');
			const start = form.getFieldValue('start');
			const end = form.getFieldValue('end');
			if(!title || !content) return;

			setFormDisabled(true);
			const payload = JSON.stringify({
				address,
				content,
				end,
				id: currentUser.id,
				start,
				title
			});

			console.log('payload', payload);

			setIsSending(true);

			if (currentUser.id && title && content){
				api.tx.system.remarkWithEvent(`Tanganika::Proposal::${payload}`).signAndSend(address, ({ status }) => {
					if(!currentUser.id){
						return;
					}
					if(status.isInBlock){
						queueNotification({
							header: 'Thanks for sharing!',
							message: 'Post created successfully.',
							status: NotificationStatus.SUCCESS
						});
						createRemarkProposalPost({ variables: {
							address,
							description: content,
							end,
							network,
							start,
							title,
							user_id: currentUser.id
						} }).then(({ data }) => {
							if (data?.insert_remark_proposals && data?.insert_remark_proposals?.returning?.length && data?.insert_remark_proposals?.returning?.[0]?.id) {
								const postId = data?.insert_remark_proposals?.returning?.[0]?.id;
								console.log(data);
								createOptionsMutation({ variables: {
									options: optionsArray.map((option) => ({
										option,
										proposal_id: postId
									}))
								} }).then(({ data }) => console.log(data)).catch(e => console.log(e));
								setIsSending(false);
								navigate(`/remark-proposal/${postId}`);
								queueNotification({
									header: 'Thanks for sharing!',
									message: 'Post created successfully.',
									status: NotificationStatus.SUCCESS
								});
							} else {
								throw Error('Error in post creation');
							}
						}).catch( e => {
							setIsSending(false);
							queueNotification({
								header: 'Error in post creation',
								status: NotificationStatus.ERROR
							});
							console.error(e);
						});
					}
					else{
						if(status.isBroadcast){
							setIsSending(true);
						}
					}
				}).catch((error) => {
					setIsSending(false);
					queueNotification({
						header: 'Error in post creation',
						message: error.message,
						status: NotificationStatus.ERROR
					});
				});
			} else {
				setIsSending(false);
				console.error('Current userid, title, content or selected topic missing',currentUser.id, title, content);
			}
		} catch{
			//do nothing, await form.validateFields(); will automatically highlight the error ridden fields
		} finally{
			setFormDisabled(false);
		}

	};

	// const onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {setTitle(event.target.value); return event.target.value;};
	// const onContentChange = (data: string) => {setContent(data); return data.length ? data : null;};

	const onOptionChange = (event: any, i: number) => {
		setOptionsArray((prevState) => {
			const copyArray = [...prevState];
			copyArray[i] = event.target.value;
			return copyArray;
		});
		console.log(optionsArray);
	};

	const onAddOption = () => {
		setOptionsArray((prevState) => {
			const copyOptionsArray = [...prevState];
			copyOptionsArray.push('');
			return copyOptionsArray;
		});
	};

	const onRemoveOption = (i: number) => {
		const copyOptionsArray = [...optionsArray];
		copyOptionsArray.splice(i, 1);
		setOptionsArray(copyOptionsArray);
	};

	const onAccountChange = (address: string) => {
		setAddress(address);
	};

	return (
		<>
			<div className={className}>
				<BackToListingView postCategory={PostCategory.REMARK_PROPOSAL} />

				{!currentUser.id ? <Alert className='mt-4' type='warning' message='Please login to Create a Remark Proposal Post.' showIcon /> :
					<div className="flex flex-col mt-6 bg-white p-4 md:p-8 rounded-md w-full shadow-md mb-4">
						<h2 className="dashboard-heading mb-8">New Post</h2>
						{error && <ErrorAlert errorMsg={error.message} className='mb-4' />}

						<Form
							form={form}
							name="create-post-form"
							onFinish={handleSend}
							layout="vertical"
							disabled={formDisabled || loading}
							validateMessages= {
								{ required: "Please add the '${name}'" }
							}
						>
							<Form.Item name="title" label='Title' rules={[{ required: true }]}>
								<Input
									name='title'
									placeholder='Title'
									autoFocus
								/>
							</Form.Item>

							<ContentForm />

							<Form.Item name="start" label='Start Block Number' rules={[{ required: true }]}>
								<Input
									name={'start'}
									placeholder='123456'
								/>
							</Form.Item>
							<Form.Item name="end" label='End Block Number' rules={[{ required: true }]}>
								<Input
									name={'end'}
									placeholder='123456'
								/>
							</Form.Item>

							<Form.Item label='Add Options'>
								{optionsArray.map((option, i) => {
									return (
										<>
											<div className='flex items-center gap-x-4 mb-4'>
												<Input
													onChange={(e) => onOptionChange(e, i)}
													type='text'
													value={option}
												/>
												{i !== 0 && <Button onClick={() => onRemoveOption(i)}>-</Button>}

											</div>
										</>
									);
								})}
								<div className='flex w-full justify-end'>
									<Button className='bg-pink_primary text-white border-white hover:bg-pink_secondary flex items-center justify-center rounded-md ' onClick={onAddOption}>+</Button>
								</div>
							</Form.Item>

							<Form.Item>
								<AccountSelectionForm withBalance onAccountChange={onAccountChange} title='Select Account' address={address} accounts={accounts} />
							</Form.Item>

							<Form.Item>
								<Button htmlType="submit" disabled={!currentUser.id || formDisabled || loading || isSending} className='mt-16 bg-pink_primary text-white border-white hover:bg-pink_secondary flex items-center justify-center rounded-md text-lg h-[50px] w-[215px]'>
							Create Post
								</Button>
							</Form.Item>
						</Form>
					</div>
				}

			</div>
		</>
	);
};

export default styled(CreateRemarkProposal)`
	.mainButtonContainer{
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: 3rem;
	}

	input.error {
		border-color: red_secondary !important;
	}

	.errorText {
		color: red_secondary;
	}

	.option_input{
		display: flex;
		justify-content: space-between;
		align-items: baseline;

		.button{
			margin-left: 10px;
		}
	}
`;
