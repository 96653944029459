// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { RightOutlined } from '@ant-design/icons';
import styled from '@xstyled/styled-components';
import React from 'react';
import { Link } from 'react-router-dom';

import BountyContainer from './Bounties';
import MotionContainer from './Motions';
import ProposalContainer from './Proposals';
import ReferendaContainer from './Referenda';
import TechCommitteeProposalsContainer from './TechCommitteeProposals';
import TipContainer from './Tips';
import TreasuryContainer from './Treasury';

const OnchainPostsContainer = ({ className } : {className?: string}) => {

	const limit = 10;

	return (
		<div className={className}>
			<h1 className='dashboard-heading mb-4 md:mb-6'>Latest on-chain activity</h1>

			{/* Intro and Create Post Button */}
			<div className="flex flex-col md:flex-row">
				<p className="text-sidebarBlue text-sm md:text-base font-medium bg-white p-4 md:p-8 rounded-md w-full shadow-md mb-4">
				This is the place to discuss on-chain proposals, referenda, motions, tips, bounties, treasury proposals and technical committee proposals.
						On-chain posts are automatically generated as soon as they are created on the chain.
						Only the proposer is able to edit them.
				</p>
			</div>

			<h3>Referenda</h3>
			<ReferendaContainer className='referendaContainer' limit={limit} />
			<Link className='full-listing-link' to='referenda'><RightOutlined />See all referenda</Link>
			<h3>Proposals</h3>
			<ProposalContainer className='proposalContainer' limit={limit} />
			<Link className='full-listing-link' to='proposals'><RightOutlined />See all proposals</Link>
			<h3>Motions</h3>
			<MotionContainer className='motionContainer' limit={limit} />
			<Link className='full-listing-link' to='motions'><RightOutlined />See all motions</Link>
			<h3>Treasury proposals</h3>
			<TreasuryContainer className='treasuryContainer' limit={limit} />
			<Link className='full-listing-link' to='treasury-proposals'><RightOutlined />See all treasury proposals</Link>
			<h3>Technical Committee Proposals</h3>
			<TechCommitteeProposalsContainer className='techCommitteeProposalsContainer' limit={limit} />
			<Link className='full-listing-link' to='tech-comm-proposals'><RightOutlined />See all technical committee proposals proposals</Link>
			<h3>Tips</h3>
			<TipContainer className='tipContainer' limit={limit} />
			<Link className='full-listing-link' to='tips'><RightOutlined />See all tips</Link>
			<h3>Bounties</h3>
			<BountyContainer className='bountyContainer' limit={limit} />
			<Link className='full-listing-link' to='bounties'><RightOutlined />See all bounties</Link>
		</div>
	);

};

export default styled(OnchainPostsContainer)`

	.referendaContainer, .proposalContainer, .motionContainer, .treasuryContainer, .tipContainer, .bountyContainer, .techCommitteeProposalsContainer {
		margin-bottom: 2rem;
	}

	h1 {
		@media only screen and (max-width: 576px) {
			margin: 3rem 1rem 1rem 1rem;
		}

		@media only screen and (max-width: 768px) and (min-width: 576px) {
			margin-left: 1rem;
		}

		@media only screen and (max-width: 991px) and (min-width: 768px) {
			margin-left: 1rem;
		}
	}

	@media only screen and (max-width: 991px) and (min-width: 768px) {
		.ui[class*="tablet reversed"].grid {
			flex-direction: column-reverse;
		}
	}

	.full-listing-link {
		display: flex;
		color: black_text;
		font-weight: 500;
		margin-bottom: 4rem;
	}
`;
