// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CalendarView from 'src/screens/CalendarView';
import Committee from 'src/screens/Committee';
import CreatePost from 'src/screens/CreatePost';
import Home from 'src/screens/Home';
import { PrivacyPolicy, TermsAndConditions, TermsOfWebsite } from 'src/screens/LegalDocuments';
import Bounties from 'src/screens/Listing/Bounties';
import Discussions from 'src/screens/Listing/Discussions';
import Council from 'src/screens/Listing/Members';
import Motions from 'src/screens/Listing/Motions';
import Proposals from 'src/screens/Listing/Proposals';
import Referenda from 'src/screens/Listing/Referenda';
import TechCommitteeProposals from 'src/screens/Listing/TechCommProposals';
import Tips from 'src/screens/Listing/Tips';
import Treasury from 'src/screens/Listing/Treasury';
import LoginForm from 'src/screens/LoginForm';
import NewsView from 'src/screens/NewsView';
import NotFound from 'src/screens/NotFound';
import NotificationSettings from 'src/screens/NotificationSettings';
import OnChain from 'src/screens/OnChain';
import PostCommittee from 'src/screens/PipPost/CommitteePost';
import PostBounty from 'src/screens/Posts/BountyPost';
import PostDiscussion from 'src/screens/Posts/DiscussionPost';
import PostMotion from 'src/screens/Posts/MotionPost';
import PostProposal from 'src/screens/Posts/ProposalPost';
import PostReferendum from 'src/screens/Posts/ReferendumPost';
import PostTechCommitteeProposal from 'src/screens/Posts/TechCommProposalPost';
import PostTip from 'src/screens/Posts/TipPost';
import PostTreasury from 'src/screens/Posts/TreasuryPost';
import Profile from 'src/screens/Profile';
import RemarkProposal from 'src/screens/RemarkProposal';
import RemarkProposalPost from 'src/screens/RemarkProposalPost';
import CreateRemarkProposal from 'src/screens/RemarkProposalPost/Create';
import RequestResetPassword from 'src/screens/RequestResetPassword';
import ResetPassword from 'src/screens/ResetPassword';
import Search from 'src/screens/Search';
import Settings from 'src/screens/Settings';
import SignupForm from 'src/screens/SignupForm';
import Tracker from 'src/screens/Tracker';
import UndoEmailChange from 'src/screens/UndoEmailChange';
import UserProfile from 'src/screens/UserProfile';
import VerifyEmail from 'src/screens/VerifyEmail';
import getNetwork from 'src/util/getNetwork';

const network = getNetwork();

function SwitchRoutes() {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/discussions" element={<Discussions />} />
			<Route path="/login" element={<LoginForm />} />
			<Route path="/post/create" element={<CreatePost />} />
			<Route path="/bounty/:id" element={<PostBounty />} />
			<Route path="/motion/:id" element={<PostMotion />} />
			<Route path="/proposal/:id" element={<PostProposal />} />
			<Route path="/referendum/:id" element={<PostReferendum />} />
			<Route path="/treasury/:id" element={<PostTreasury />} />
			<Route path="/tech/:id" element={<PostTechCommitteeProposal />} />
			<Route path="/tip/:hash" element={<PostTip />} />
			<Route path="/post/:id" element={<PostDiscussion />} />
			<Route path="/onchain" element={<OnChain />} />
			<Route path="/bounties" element={<Bounties />} />
			<Route path="/referenda" element={<Referenda />} />
			<Route path="/proposals" element={<Proposals />} />
			<Route path="/motions" element={<Motions />} />
			<Route path="/treasury-proposals" element={<Treasury />} />
			<Route path="/tech-comm-proposals" element={<TechCommitteeProposals />} />
			<Route path="/tips" element={<Tips />} />
			<Route path="/request-reset-password" element={<RequestResetPassword />} />
			<Route path="/reset-password" element={<ResetPassword />} />
			<Route path="/signup" element={<SignupForm />} />
			<Route path="/verify-email/:token" element={<VerifyEmail />} />
			<Route path="/undo-email-change/:token" element={<UndoEmailChange />} />
			<Route path="/settings" element={<Settings />} />
			<Route path="/notification-settings" element={<NotificationSettings />} />
			<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
			<Route path="/terms-of-website" element={<TermsOfWebsite />} />
			<Route path="/privacy" element={<PrivacyPolicy />} />
			<Route path="/user/:username" element={<UserProfile />} />
			<Route path="/search" element={<Search />} />
			<Route path="/tracker" element={<Tracker />} />
			<Route path="/council" element={<Council />} />
			<Route path="/profile/:address" element={<Profile />} />
			<Route path="/calendar" element={<CalendarView />} />
			<Route path="/news" element={<NewsView />} />
			{['tanganika'].includes(network) && <>
				<Route path="/remark-proposals" element={<RemarkProposal />} />
				<Route path="/remark-proposal/:id" element={<RemarkProposalPost />} />
				<Route path="/remark-proposal/create" element={<CreateRemarkProposal />} />
			</>}
			{['polymesh', 'polymesh-test'].includes(network) && <>
				<Route element={<PostCommittee pipType={{ 'isCommunity': true }} />}  path="/community/:id" />
				<Route element={<PostCommittee pipType={{ 'isTechnical': true }} />} path="/technical/:id" />
				<Route element={<PostCommittee pipType={{ 'isUpgrade': true }} />} path="/upgrade/:id" />
				<Route element={<Committee committeeType='Upgrade' />} path="/upgrade" />
				<Route element={<Committee committeeType='Technical' />} path="/technical" />
				<Route element={<Committee committeeType='Community' />} path="/community" />
				<Route element={<PostDiscussion/>} path="/post/:id" />
			</>
			}
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default SwitchRoutes;

