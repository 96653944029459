// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { Col, Row } from 'antd';
import React from 'react';
import SearchBar from 'src/ui-components/SearchBar';

interface Props {
  className?: string
}

const SearchScreen = ({ className }: Props) => {
	return (
		<div className={className}>
			<Row>
				<Col span={24}>
					<h3 className='dashboard-heading'>Search</h3>
					<SearchBar />
				</Col>
			</Row>
		</div>
	);
};

export default SearchScreen;
