// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import * as React from 'react';
import ExternalLinks from 'src/components/ExternalLinks';
import { OnchainLinkPipFragment } from 'src/generated/graphql';
import { chainProperties } from 'src/global/networkConstants';
import AddressComponent from 'src/ui-components/Address';
import getNetwork from 'src/util/getNetwork';

import OnchainInfoWrapper from './OnchainInfoWrapper';

interface Props{
	onchainLink: OnchainLinkPipFragment
}

const currentNetwork = getNetwork();

export const convertHexToStr = (hex:any) => {
	let str = '';
	if (!hex?.startsWith('0x')) {
		return hex;
	}

	for (let i = 0; i < hex.length; i += 2) {
		const charCode = parseInt(hex.substr(i, 2), 16);
		str += String.fromCharCode(charCode);
	}
	return str;
};

const PostPipInfo = ({ onchainLink }: Props) => {
	if (!onchainLink) return null;

	const {
		onchain_pip: onchainPip,
		proposer_address: proposerAddress
	} = onchainLink;

	const desc = onchainPip?.[0]?.optionDescription;
	let metaDescription;
	if (desc?.startsWith('0x')) {
		metaDescription = convertHexToStr(desc);
	} else {
		metaDescription = desc;
	}
	const id = onchainPip?.[0]?.identityId;

	let identityId;
	if (!id?.startsWith('0x')) {
		identityId = convertHexToStr(id);
	} else {
		identityId = id;
	}

	const method = onchainPip?.[0]?.method;
	const balance = onchainPip?.[0]?.balance;
	const blockNumber = onchainPip?.[0]?.maybeBlockNumber;
	const pipArguments = onchainPip?.[0]?.pipArguments;
	const section = onchainPip?.[0]?.section;
	const proposedBy = onchainPip?.[0]?.proposedBy;
	const proposalId = onchainPip?.[0]?.proposalId;
	const proposalHash = onchainPip?.[0]?.proposalHash;
	const optionUrl = onchainPip?.[0]?.optionUrl;

	let url = '';
	if (optionUrl?.startsWith('0x')) {
		url = convertHexToStr(optionUrl);
	} else {
		url = optionUrl || '';
	}

	return (
		<OnchainInfoWrapper>
			<h5 className='mb-5 font-bold text-base'>Metadata</h5>
			<ul className='list-none flex flex-col gap-y-2'>
				<li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Proposer</h6>
					<article className='flex gap-x-2 col-span-4 md:col-span-6 overflow-hidden'>
						<AddressComponent displayInline={true} address={proposerAddress}/>
					</article>
				</li>
				{balance && currentNetwork && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Deposit</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{parseInt(balance) / Math.pow(10, chainProperties[currentNetwork].tokenDecimals) + ' ' + chainProperties[currentNetwork].tokenSymbol}
					</div>
				</li>}
				{method && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Method</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{method}
					</div>
				</li>}
				{method && url && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>URL</h6>
					<a href={url} target='_blank' className='text-pink_primary col-span-4 md:col-span-6' rel="noreferrer">
						{url}
					</a>
				</li>}
				{method && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Identity ID</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{identityId}
					</div>
				</li>}
				{pipArguments && pipArguments.length &&
				<>
					{
						pipArguments.map((el, i) => {
						// const isAccountArgument = el.name === 'account';
							return (
								<>
									<li key={`name${i}`} className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
										<h6 className='col-span-2'>Name</h6>
										<div className='text-navBlue col-span-4 md:col-span-6'>
											{el.name}
										</div>
									</li>
									<li key={`value${i}`} className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
										<h6 className='col-span-2'>Value</h6>
										<div className='text-navBlue col-span-4 md:col-span-6'>
											{el.value}
										</div>
									</li>
								</>
							);
						})
					}
				</>
				}
				{blockNumber && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Block Number</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{blockNumber}
					</div>
				</li>}
				{proposedBy && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Proposed By</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{proposedBy}
					</div>
				</li>}
				{section && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Section</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{section}
					</div>
				</li>}
				{proposalHash && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Proposal Hash</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{proposalHash}
					</div>
				</li>}
				{proposalId && <li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
					<h6 className='col-span-2'>Proposal Id</h6>
					<div className='text-navBlue col-span-4 md:col-span-6'>
						{proposalId}
					</div>
				</li>}
			</ul>
			{metaDescription && metaDescription.length > 2 &&
				<div className='grid grid-cols-6 md:grid-cols-8 gap-x-5 mt-5'>
					<h6 className='col-span-6 md:col-span-2'>Description</h6>
					<p className='text-navBlue leading-6 col-span-6'>{metaDescription}</p>
				</div>}
			<ExternalLinks className='mt-5' isPip onchainId={onchainPip?.[0]?.pipStatus?.at(-1)?.blockNumber.number} />
		</OnchainInfoWrapper>
	);
};

export default PostPipInfo;
