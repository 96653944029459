// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.
import { Signer } from '@polkadot/api/types';
import { InjectedAccount } from '@polkadot/extension-inject/types';
import { Alert, Button, Form } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApiContext } from 'src/context/ApiContext';
import { UserDetailsContext } from 'src/context/UserDetailsContext';
import { useCreateRemarkProposalVoteMutationMutation } from 'src/generated/graphql';
import { useCurrentBlock } from 'src/hooks';
import { NotificationStatus } from 'src/types';
import AccountSelectionForm from 'src/ui-components/AccountSelectionForm';
import GovSidebarCard from 'src/ui-components/GovSidebarCard';
import queueNotification from 'src/ui-components/QueueNotification';
import { LoadingState } from 'src/ui-components/UIStates';

interface Props {
	className?: string
	address: string
	accounts: InjectedAccount[]
	onAccountChange: (address: string) => void
	getAccounts: () => Promise<undefined>
    post: any
	accountsMap: {[key:string]:string}
	signerMap: {[key:string]: Signer}
    votesRefetch: any
}

const VoteRemarkProposal = ({ address, accounts, accountsMap, signerMap, onAccountChange, getAccounts, className, post, votesRefetch }: Props) => {
	const [loading, setLoading] = useState<boolean>(false);
	const [hasVoted, setHasVoted] = useState<boolean>(false);
	const [balance, setBalance] = useState<string>('0');

	const { id } = useContext(UserDetailsContext);
	const currentBlock = useCurrentBlock()?.toNumber() || 0;

	const { api, apiReady } = useContext(ApiContext);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [createRemarkProposalVote, { error, loading: createLoading }] = useCreateRemarkProposalVoteMutationMutation();

	const { remark_proposal_options, remark_proposal_votes, start } = post;

	useEffect(() => {
		setHasVoted(remark_proposal_votes.some((vote: any) => vote.address === address));
	}, [address, remark_proposal_votes]);

	useEffect(() => {
		getAccounts();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, apiReady, accountsMap, signerMap]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const getPower = useCallback(async () => {
		if(!api){
			return;
		}
		if(!apiReady){
			return;
		}
		const blockHash = await api.rpc.chain.getBlockHash(start);

		const apiAt = await api.at(blockHash);

		apiAt.query.system.account(address)
			.then((result: any) => {
				if ( result.data && result.data.free && result.data?.free?.toBigInt()){
					setBalance((result.data?.free?.toBigInt() || '0'));
				}
				else{
					setBalance('0');
				}
			})
			.catch(e => console.error(e));
	}, [address, api, apiReady, start]);

	useEffect(() => {
		getPower();
	}, [getPower]);

	const votePoll = useCallback(async (options: any) => {
		setLoading(true);
		if (!api) {
			setLoading(false);
			return;
		}

		if (!apiReady) {
			setLoading(false);
			return;
		}

		if(!id){
			setLoading(false);
			return;
		}

		getPower();

		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const payload = JSON.stringify({
			address: address,
			blockNumber: currentBlock,
			option: options.option,
			optionId: options.id,
			power: balance,
			proposalId: options.proposal_id,
			user_id: id
		});

		try {
			api.tx.system.remarkWithEvent(`Tanganika::Vote::${payload}`).signAndSend(address, ({ status }: { status: any }) => {
				if (!id || !address) {
					setLoading(false);
					return;
				}
				if(status.isInBlock){
					setLoading(false);
					queueNotification({
						header: 'Success!',
						message: 'Voting successful.',
						status: NotificationStatus.SUCCESS
					});
					createRemarkProposalVote({
						variables: {
							address,
							blockNumber: currentBlock,
							option: options.option,
							optionId: options.id,
							power: balance,
							proposalId: options.proposal_id,
							user_id: id
						}
					}).then(() => {
						queueNotification({
							header: 'Success!',
							message: 'Voting successful.',
							status: NotificationStatus.SUCCESS
						});
					}).catch(console.error);
				}else {
					if (status.isBroadcast){
						setLoading(true);
					}
					console.log(`Current status: ${status.type}`);
				}
			}).catch((error) => {
				queueNotification({
					header: 'Error in Voting!',
					message: error.message,
					status: NotificationStatus.ERROR
				});
				console.log(error);
				setLoading(false);
			});

			votesRefetch();
		} catch (error) {
			setLoading(false);
			console.log(error);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [api, apiReady, id, address, start, currentBlock, balance, createRemarkProposalVote, votesRefetch]);

	return (
		<div className={className}>
			<GovSidebarCard className='mb-5'>
				<h2 className='dashboard-heading mb-4'>Vote</h2>
				{!id ? <Alert message='Please login to Vote.' showIcon type='warning' /> :
					<>
						{loading ? <LoadingState /> :
							<Form>
								<Form.Item>
									<AccountSelectionForm
										title='Vote with Account'
										accounts={accounts}
										address={address}
										withBalance
										onAccountChange={onAccountChange}
									/>

									<div className='text-md font-medium text-sidebarBlue my-2'>Power : {balance}</div>

								</Form.Item>
								{hasVoted && <div className='dashboard-heading text-center' >Thank You for Voting!</div>}
								{remark_proposal_options?.map((remarkOption: any, i: number) => {
									const optionId = remarkOption.id;
									let votes = 0;
									votes = remark_proposal_votes.reduce((sum: number, vote: any) => {
										if(vote.option_id === optionId){
											sum++;
										}
										return sum;
									}, 0);
									let votedOption: number | undefined | null;
									remark_proposal_votes.forEach((vote: any) => {
										if(vote.address === address){
											votedOption = vote.option_id;
										}
									});
									return (
										<Form.Item key={`${remarkOption.option}${i}`}>
											<div className='flex items-center gap-x-2'>
												<Button disabled={hasVoted || balance === '0'} className={`${votedOption === remarkOption.id ? 'bg-pink_primary text-white': ''} w-full rounded-md p-2 flex justify-center items-center ${!hasVoted ? 'hover:bg-pink_primary border-pink_primary border-[1px] border-solid text-pink_primary hover:text-white transition-colors duration-300': ''} `} onClick={() => votePoll(remarkOption)}>
													{remarkOption.option} {votedOption === remarkOption.id && <span className='text-small'> (Voted)</span>}
												</Button>
												<div className='rounded-md px-4 py-2 flex justify-center items-center text-sidebarBlue bg-grey_light'>{votes}</div>
											</div>
										</Form.Item>
									);})}
							</Form>}
					</>
				}
			</GovSidebarCard>
		</div>
	);
};

export default VoteRemarkProposal;
