// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

/* eslint-disable sort-keys */
import styled from '@xstyled/styled-components';
import { Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MetaContext } from 'src/context/MetaContext';
import { UserDetailsContext } from 'src/context/UserDetailsContext';
import { BountyPostAndCommentsQueryHookResult, BountyPostFragment, DiscussionPostAndCommentsQueryHookResult, DiscussionPostFragment, MotionPostAndCommentsQueryHookResult, MotionPostFragment, OnchainLinkBountyFragment, OnchainLinkMotionFragment, OnchainLinkPipFragment, OnchainLinkProposalFragment, OnchainLinkReferendumFragment, OnchainLinkTechCommitteeProposalFragment, OnchainLinkTipFragment, OnchainLinkTreasuryProposalFragment, PipPostAndCommentsQueryHookResult, PipPostFragment, ProposalPostAndCommentsQueryHookResult, ProposalPostFragment, ReferendumPostAndCommentsQueryHookResult, ReferendumPostFragment, TechCommitteeProposalPostAndCommentsQueryHookResult, TechCommitteeProposalPostFragment, TipPostAndCommentsQueryHookResult, TipPostFragment, TreasuryProposalPostAndCommentsQueryHookResult, TreasuryProposalPostFragment } from 'src/generated/graphql';
import { PostCategory } from 'src/global/post_categories';
import { PostEmptyState } from 'src/ui-components/UIStates';

import OptionPoll from './ActionsBar/OptionPoll';
import TrackerButton from './ActionsBar/TrackerButton';
import EditablePostContent from './EditablePostContent';
import GovernanceSideBar from './GovernanceSideBar';
import Poll from './Poll';
import PostHeading from './PostHeading';
import PostDescription from './Tabs/PostDescription';
import PostOnChainInfo from './Tabs/PostOnChainInfo';
import PostTimeline from './Tabs/PostTimeline';

interface Props {
	className?: string
	data: (
		DiscussionPostAndCommentsQueryHookResult['data'] |
		PipPostAndCommentsQueryHookResult['data'] |
		ProposalPostAndCommentsQueryHookResult['data'] |
		ReferendumPostAndCommentsQueryHookResult['data'] |
		MotionPostAndCommentsQueryHookResult['data'] |
		TreasuryProposalPostAndCommentsQueryHookResult['data'] |
		TipPostAndCommentsQueryHookResult['data'] |
		BountyPostAndCommentsQueryHookResult['data'] |
		TechCommitteeProposalPostAndCommentsQueryHookResult['data']
	)
	isBounty?: boolean
	isUpgrade?: boolean
	isCommunity?: boolean
	isTechnical?: boolean
	isMotion?: boolean
	isProposal?: boolean
	isReferendum?: boolean
	isTreasuryProposal?: boolean
	isTechCommitteeProposal?: boolean
	isTipProposal?: boolean
	refetch: any
}

interface Redirection {
	link?: string;
	text?: string;
}

const Post = ({
	className,
	data,
	isBounty = false,
	isMotion = false,
	isProposal = false,
	isReferendum = false,
	isTipProposal = false,
	isTreasuryProposal = false,
	isTechCommitteeProposal = false,
	isUpgrade = false,
	isTechnical = false,
	isCommunity = false,
	refetch }: Props ) => {

	const post = data && data.posts && data.posts[0];
	const { id, addresses } = useContext(UserDetailsContext);
	const [isEditing, setIsEditing] = useState(false);
	const toggleEdit = () => setIsEditing(!isEditing);
	const { setMetaContextState } = useContext(MetaContext);

	useEffect(() => {
		refetch();
	}, [refetch]);

	useEffect(() => {
		const users: string[] = [];

		if (post?.author?.username) {
			users.push(post?.author?.username);
		}

		post?.comments.forEach(c => {
			if (c.author?.username && !users.includes(c.author?.username)) {
				users.push(c.author?.username);
			}
		});
		global.window.localStorage.setItem('users', users.join(','));
	}, [post]);

	useEffect(() => {
		setMetaContextState((prevState) => {
			return {
				...prevState,
				description: post?.content || prevState.description,
				title: `${post?.title || 'Polkassembly' }`
			};
		});
	}, [post, setMetaContextState]);

	const isOnchainPost = isMotion || isProposal || isUpgrade || isCommunity || isTechnical || isCommunity || isReferendum || isTreasuryProposal || isBounty || isTechCommitteeProposal || isTipProposal;

	let onchainId: string | number | null | undefined;
	let referendumPost: ReferendumPostFragment | undefined;
	let proposalPost: ProposalPostFragment | undefined;
	let pipPost: PipPostFragment | undefined;
	let motionPost: MotionPostFragment | undefined;
	let treasuryPost: TreasuryProposalPostFragment | undefined;
	let tipPost: TipPostFragment | undefined;
	let bountyPost: BountyPostFragment | undefined;
	let techCommitteeProposalPost: TechCommitteeProposalPostFragment | undefined;
	let definedOnchainLink: OnchainLinkTechCommitteeProposalFragment | OnchainLinkPipFragment |OnchainLinkBountyFragment | OnchainLinkMotionFragment | OnchainLinkReferendumFragment | OnchainLinkProposalFragment | OnchainLinkTipFragment | OnchainLinkTreasuryProposalFragment | undefined;
	let postStatus: string | undefined;
	let redirection: Redirection = {};

	if (post && isTechCommitteeProposal) {
		techCommitteeProposalPost = post as TechCommitteeProposalPostFragment;
		definedOnchainLink = techCommitteeProposalPost.onchain_link as OnchainLinkTechCommitteeProposalFragment;
		onchainId = definedOnchainLink.onchain_network_tech_committee_proposal_id;
		postStatus = techCommitteeProposalPost?.onchain_link?.onchain_tech_committee_proposal?.[0]?.status?.[0].status;
	}

	if (post && isBounty) {
		bountyPost = post as BountyPostFragment;
		definedOnchainLink = bountyPost.onchain_link as OnchainLinkBountyFragment;
		onchainId = definedOnchainLink.onchain_network_bounty_id;
		postStatus = bountyPost?.onchain_link?.onchain_bounty?.[0]?.bountyStatus?.[0].status;
	}

	if (post && isReferendum) {
		referendumPost = post as ReferendumPostFragment;
		definedOnchainLink = referendumPost.onchain_link as OnchainLinkReferendumFragment;
		onchainId = definedOnchainLink.onchain_network_referendum_id;
		postStatus = referendumPost?.onchain_link?.onchain_referendum?.[0]?.referendumStatus?.[0].status;
	}

	if (post && isProposal) {
		proposalPost = post as ProposalPostFragment;
		definedOnchainLink = proposalPost.onchain_link as OnchainLinkProposalFragment;
		onchainId = definedOnchainLink.onchain_network_proposal_id;
		postStatus = proposalPost?.onchain_link?.onchain_proposal?.[0]?.proposalStatus?.[0].status;
		if (definedOnchainLink.onchain_network_referendum_id){
			redirection = {
				link: `/referendum/${definedOnchainLink.onchain_network_referendum_id.split('_')[1]}`,
				text: `Referendum #${definedOnchainLink.onchain_network_referendum_id.split('_')[1]}`
			};
		}
	}

	// }
	if (post && (isCommunity || isUpgrade || isTechnical ) ) {
		pipPost = post as PipPostFragment;
		definedOnchainLink = pipPost.onchain_link as OnchainLinkPipFragment;
		onchainId = definedOnchainLink.onchain_network_pip_id;
		postStatus = pipPost?.onchain_link?.onchain_pip?.[0]?.pipStatus?.[0].status;
	}

	if (post && isMotion) {
		motionPost = post as MotionPostFragment;
		definedOnchainLink = motionPost.onchain_link as OnchainLinkMotionFragment;
		onchainId = definedOnchainLink.onchain_network_motion_id;
		postStatus = motionPost?.onchain_link?.onchain_motion?.[0]?.motionStatus?.[0].status;
		if (definedOnchainLink.onchain_network_referendum_id){
			redirection = {
				link: `/referendum/${definedOnchainLink.onchain_network_referendum_id.split('_')[1]}`,
				text: `Referendum #${definedOnchainLink.onchain_network_referendum_id.split('_')[1]}`
			};
		}
	}

	if (post && isTreasuryProposal) {
		treasuryPost = post as TreasuryProposalPostFragment;
		definedOnchainLink = treasuryPost.onchain_link as OnchainLinkTreasuryProposalFragment;
		onchainId = definedOnchainLink.onchain_network_treasury_proposal_id;
		postStatus = treasuryPost?.onchain_link?.onchain_treasury_spend_proposal?.[0]?.treasuryStatus?.[0].status;
		if (definedOnchainLink.onchain_network_motion_id){
			redirection = {
				link: `/motion/${definedOnchainLink.onchain_network_motion_id.split('_')[1]}`,
				text: `Motion #${definedOnchainLink.onchain_network_motion_id.split('_')[1]}`
			};
		}
	}

	if (post && isTipProposal) {
		tipPost = post as TipPostFragment;
		definedOnchainLink = tipPost.onchain_link as OnchainLinkTipFragment;
		onchainId = definedOnchainLink.onchain_network_tip_id;
		postStatus = tipPost?.onchain_link?.onchain_tip?.[0]?.tipStatus?.[0].status;
	}

	const isDiscussion = (post: TechCommitteeProposalPostFragment | BountyPostFragment | TipPostFragment | TreasuryProposalPostFragment | MotionPostFragment | PipPostFragment | ProposalPostFragment | DiscussionPostFragment | ReferendumPostFragment): post is DiscussionPostFragment => {
		if (!isTechCommitteeProposal && !isReferendum && !isProposal && !isMotion && !isTreasuryProposal && !isTipProposal && !isBounty && !isUpgrade && !isTechnical && !isCommunity) {
			return (post as DiscussionPostFragment) !== undefined;
		}

		return false;
	};

	if (!post) {
		const postCategory: PostCategory = isMotion ? PostCategory.MOTION : isProposal ? PostCategory.PROPOSAL : isReferendum ? PostCategory.REFERENDA : isTreasuryProposal ? PostCategory.TREASURY_PROPOSAL : isTipProposal ? PostCategory.TIP : isBounty ? PostCategory.BOUNTY : isTechCommitteeProposal ? PostCategory.TECH_COMMITTEE_PROPOSAL :  PostCategory.DISCUSSION;
		return <div className='mt-16'><PostEmptyState postCategory={postCategory} /></div>;
	}

	const isBountyProposer = isBounty && bountyPost?.onchain_link?.proposer_address && addresses?.includes(bountyPost.onchain_link.proposer_address);
	const isProposalProposer = isProposal && proposalPost?.onchain_link?.proposer_address && addresses?.includes(proposalPost.onchain_link.proposer_address);
	const isUpgradeProposer = isUpgrade && pipPost?.onchain_link?.proposer_address && addresses?.includes(pipPost.onchain_link.proposer_address);
	const isTechnicalProposer = isTechnical && pipPost?.onchain_link?.proposer_address && addresses?.includes(pipPost.onchain_link.proposer_address);
	const isCommunityProposer = isCommunity && pipPost?.onchain_link?.proposer_address && addresses?.includes(pipPost.onchain_link.proposer_address);
	const isReferendumProposer = isReferendum && referendumPost?.onchain_link?.proposer_address && addresses?.includes(referendumPost.onchain_link.proposer_address);
	const isMotionProposer = isMotion && motionPost?.onchain_link?.proposer_address && addresses?.includes(motionPost.onchain_link.proposer_address);
	const isTreasuryProposer = isTreasuryProposal && treasuryPost?.onchain_link?.proposer_address && addresses?.includes(treasuryPost.onchain_link.proposer_address);
	const isTipProposer = isTipProposal && tipPost?.onchain_link?.proposer_address && addresses?.includes(tipPost.onchain_link.proposer_address);
	const isTechCommitteeProposalProposer = isTechCommitteeProposal && techCommitteeProposalPost?.onchain_link?.proposer_address && addresses?.includes(techCommitteeProposalPost.onchain_link.proposer_address);
	const canEdit = !isEditing && (
		post.author?.id === id ||
		isProposalProposer ||
		isUpgradeProposer ||
		isTechnicalProposer ||
		isCommunityProposer ||
		isReferendumProposer ||
		isMotionProposer ||
		isTreasuryProposer ||
		isTipProposer ||
		isBountyProposer ||
		isTechCommitteeProposalProposer
	);

	const Sidebar = ({ className } : {className?:string}) => {
		return (
			<div className={`${className} flex flex-col w-full lg:min-w-[33%] mx-auto`}>
				<GovernanceSideBar
					isBounty={isBounty}
					isMotion={isMotion}
					isProposal={isProposal}
					isReferendum={isReferendum}
					isTipProposal={isTipProposal}
					isTreasuryProposal={isTreasuryProposal}
					isTechCommitteeProposal={isTechCommitteeProposal}
					isPip={isCommunity || isUpgrade || isTechnical}
					onchainId={onchainId}
					onchainLink={definedOnchainLink}
					status={postStatus}
					canEdit={canEdit}
					startTime={post.created_at}
				/>
				{isDiscussion(post) && <Poll postId={post.id} canEdit={post.author?.id === id} />}
				<OptionPoll postId={post.id} canEdit={post.author?.id === id} />
			</div>
		);
	};

	const TrackerButtonComp = <>
		{id && onchainId && isOnchainPost && !isEditing && (
			<TrackerButton
				onchainId={onchainId}
				isBounty={isBounty}
				isMotion={isMotion}
				isProposal={isProposal}
				isReferendum={isReferendum}
				isTipProposal={isTipProposal}
				isTreasuryProposal={isTreasuryProposal}
				isTechCommitteeProposal={isTechCommitteeProposal}
				isTechnical={isTechnical}
				isUpgrade={isUpgrade}
				isCommunity={isCommunity}
			/>)
		}
	</>;

	const getOnChainTabs = () => {
		if (isDiscussion(post)) return [];

		const onChainTabs = [
			{ label: 'Timeline',
				key: 'timeline',
				children: <PostTimeline
					isBounty={isBounty}
					isMotion={isMotion}
					isProposal={isProposal}
					isReferendum={isReferendum}
					isTipProposal={isTipProposal}
					isTreasuryProposal={isTreasuryProposal}
					isTechCommitteeProposal={isTechCommitteeProposal}
					isCommunity={isCommunity}
					isUpgrade={isUpgrade}
					isTechnical={isTechnical}
					pipPost={pipPost}
					referendumPost={referendumPost}
					proposalPost={proposalPost}
					motionPost={motionPost}
					treasuryPost={treasuryPost}
					tipPost={tipPost}
					bountyPost={bountyPost}
					techCommitteeProposalPost={techCommitteeProposalPost}
				/>
			},
			{ label: 'On Chain Info',
				key: 'onChainInfo',
				children: <PostOnChainInfo
					isBounty={isBounty}
					isMotion={isMotion}
					isProposal={isProposal}
					isReferendum={isReferendum}
					isTipProposal={isTipProposal}
					isCommunity={isCommunity}
					isUpgrade={isUpgrade}
					isTechnical={isTechnical}
					isTreasuryProposal={isTreasuryProposal}
					isTechCommitteeProposal={isTechCommitteeProposal}
					definedOnchainLink={definedOnchainLink}
				/>
			}
		];

		return onChainTabs;
	};

	const tabItems: any[] = [
		{ label: 'Description',
			key: 'description',
			children: <PostDescription
				id={id}
				post={post as any}
				pipPost={pipPost}
				isEditing={isEditing}
				canEdit={canEdit}
				toggleEdit={toggleEdit}
				isOnchainPost={isOnchainPost}
				TrackerButtonComp={TrackerButtonComp}
				Sidebar={Sidebar}
				refetch={refetch}
			/>
		},
		...getOnChainTabs()
	];

	const getLatestDiscussionState = () => {
		if(!isDiscussion(post)) return;

		const latestState = {
			link: '',
			text: ''
		};

		if(post.onchain_link?.onchain_network_referendum_id) {
			latestState.link = `/referendum/${post.onchain_link.onchain_network_referendum_id}`;
			latestState.text = `Referendum #${post.onchain_link.onchain_network_referendum_id}`;
		} else if (post.onchain_link?.onchain_network_motion_id) {
			latestState.link = `/motion/${post.onchain_link.onchain_network_motion_id}`;
			latestState.text = `Motion #${post.onchain_link.onchain_network_motion_id}`;
		} else if (post.onchain_link?.onchain_network_treasury_proposal_id) {
			latestState.link = `/treasury/${post.onchain_link.onchain_network_treasury_proposal_id}`;
			latestState.text = `Treasury Proposal #${post.onchain_link.onchain_network_treasury_proposal_id}`;
		} else if (post.onchain_link?.onchain_network_proposal_id) {
			latestState.link = `/proposal/${post.onchain_link.onchain_network_proposal_id}`;
			latestState.text = `Proposal #${post.onchain_link.onchain_network_proposal_id}`;
		}

		return latestState;
	};

	const isSidebarAvailable = isDiscussion(post) || isMotion || isProposal || isTreasuryProposal || isReferendum || isTipProposal || isCommunity || isUpgrade || isTechnical;

	return (
		<>
			<div className={`${className} flex flex-col lg:flex-row`}>
				<div className='flex-1 w-full lg:min-w-[66.6%] mx-auto lg:mr-9 mb-6 lg:mb-0'>

					{redirection.link &&
						<Link to={redirection.link}>
							<div className='bg-white drop-shadow-md p-3 md:p-6 rounded-md w-full mb-6 dashboard-heading'>
								This proposal is now <span className='text-pink_primary'>{redirection.text}</span>
							</div>
						</Link>
					}

					{
						isDiscussion(post) && getLatestDiscussionState()?.link &&
						<Link to={getLatestDiscussionState()?.link!}>
							<div className='bg-white drop-shadow-md p-3 md:p-6 rounded-md w-full mb-6 dashboard-heading'>
								This discussion is now <span className='text-pink_primary'>{getLatestDiscussionState()?.text}</span>
							</div>
						</Link>
					}

					{/* Post Content */}
					<div className='bg-white drop-shadow-md p-3 lg:p-6 rounded-md w-full mb-6'>
						{isEditing && <EditablePostContent
							post={post as any}
							refetch={refetch}
							toggleEdit={toggleEdit}
						/>}

						{!isEditing && <>
							<PostHeading className='mb-8' isTipProposal={isTipProposal} onchainId={onchainId} post={post as any} postStatus={postStatus} />

							<Tabs
								type="card"
								className='ant-tabs-tab-bg-white text-sidebarBlue font-medium'
								items={tabItems}
							/>
						</>}

					</div>
				</div>

				{!isEditing && isSidebarAvailable? <Sidebar className='hidden lg:block' />: null}
			</div>
		</>
	);
};

export default styled(Post)`
	.ant-tabs-tab-bg-white .ant-tabs-tab:not(.ant-tabs-tab-active) {
		background-color: white;
		border-top-color: white;
		border-left-color: white;
		border-right-color: white;
		border-bottom-color: #E1E6EB;
	}
	.ant-tabs-tab-bg-white .ant-tabs-tab-active{
		border-top-color: #E1E6EB;
		border-left-color: #E1E6EB;
		border-right-color: #E1E6EB;
		border-radius: 6px 6px 0 0 !important;
	}
	.ant-tabs-tab-bg-white .ant-tabs-nav:before{
		border-bottom: 1px solid #E1E6EB;
	}
`;
