// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import styled from '@xstyled/styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { PostEmptyState } from 'src/ui-components/UIStates';
import getIdFromOnchainId from 'src/util/getIdFromOnchainId';

import { AllPipPostsByCommitteeQuery } from '../../../generated/graphql';
import GovernanceCard from '../../GovernanceCard';

interface Props {
  className?: string
  data: AllPipPostsByCommitteeQuery | undefined
  committee : 'upgrade' | 'technical' | 'community'
}

const Committee = ({ className, data, committee }: Props) => {

	const noPost = !data?.posts || !data.posts.length;
	const atLeastOneCurrentProposal = data?.posts.some((post) => {
		if(post.onchain_link?.onchain_pip.length){
			// this breaks the loop as soon as
			// we find a post that has a proposal.
			return true;
		}
		return false;
	});

	if (noPost || !atLeastOneCurrentProposal) return <div className={className}><PostEmptyState /></div>;

	return (
		<ul className={`${className} proposals__list`}>
			{data?.posts.map(
				(post) => {
					const onchainId = post.onchain_link?.onchain_network_pip_id;

					return !!post.onchain_link?.onchain_pip.length &&
						<li key={post.id} className='proposals__item'>
							{<Link to={`/${committee}/${getIdFromOnchainId(onchainId)}`}>
								<GovernanceCard
									address={post.onchain_link.proposer_address}
									comments={post.comments_aggregate.aggregate?.count
										? post.comments_aggregate.aggregate.count.toString()
										: 'no'}
									onchainId={onchainId}
									status={post.onchain_link.onchain_pip[0]?.pipStatus?.[0].status}
									description={post.onchain_link.onchain_pip[0]?.optionDescription}
									title={post.title}
									topic={post.topic.name}
									method={post.onchain_link.onchain_pip[0]?.method || ''}
								/>
							</Link>}
						</li>
					;
				}
			)}
		</ul>
	);
};

export default styled(Committee)`
	margin-block-start: 0;
	margin-block-end: 0;

	li {
		list-style-type: none;
	}

	.proposals__item {
		margin: 0 0 1rem 0;
		a:hover {
			text-decoration: none;
		}
	}
`;
