// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import styled from '@xstyled/styled-components';
import { Progress, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { noTitle } from 'src/global/noTitle';
import { remarkProposalStatus } from 'src/global/statuses';
import { useCurrentBlock } from 'src/hooks';
import GovSidebarCard from 'src/ui-components/GovSidebarCard';

import CreationLabel from '../../ui-components/CreationLabel';
import Markdown from '../../ui-components/Markdown';
import StatusTag from '../../ui-components/StatusTag';
import UpdateLabel from '../../ui-components/UpdateLabel';
import GovernanceSideBar from './GovernanceSideBar';

interface Props {
	className?: string,
	post: any,
    votesRefetch: any
}
const RemarkProposalPostPage = ({ className, post, votesRefetch }:Props) => {
	const { address, created_at, description, end, id, title, updated_at, remark_proposal_options, remark_proposal_votes, start } = post;
	const currentBlock = useCurrentBlock()?.toNumber() || 0;
	const [status, setStatus] = useState<string>('');

	useEffect(() => {
		if(start > currentBlock){
			setStatus(remarkProposalStatus.YET_TO_START);
		}
		if(start < currentBlock && end > currentBlock){
			setStatus(remarkProposalStatus.IN_PROGRESS);
		}
		if(end < currentBlock){
			setStatus(remarkProposalStatus.ENDED);
		}
	}, [currentBlock, end, start]);

	useEffect(() => {
		votesRefetch();
	}, [votesRefetch]);

	const tabItems: any[] = [
		{
			children: <>
				<Markdown md={description} />
				<h5 className='my-5 font-bold text-base'>Metadata</h5>
				<ul className='list-none flex flex-col gap-y-2'>
					<li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
						<h6 className='col-span-2'>Start Block</h6>
						<div className='flex gap-x-2 col-span-4 md:col-span-6 overflow-hidden'>
							{start}
						</div>
					</li>
					<li className='grid grid-cols-6 md:grid-cols-8 gap-x-5 border-b py-1.5'>
						<h6 className='col-span-2'>End Block</h6>
						<div className='flex gap-x-2 col-span-4 md:col-span-6 overflow-hidden'>
							{end}
						</div>
					</li>
				</ul>
			</>,
			key: 'description',
			label: 'Description'
		}
	];

	if (!post || !id || !description) return <div>Post not available</div>;

	return (
		<>
			<div className={`${className} flex flex-col lg:flex-row`}>

				<div className='flex-1 w-full lg:w-8/12 mx-auto lg:mr-9 mb-6 lg:mb-0'>
					<div className='bg-white drop-shadow-md p-3 lg:p-6 rounded-md w-full mb-6'>
						{status && <StatusTag className='mb-3' status={status}/>}
						<h2 className='text-lg font-medium mb-1'>{(id || id === 0) && `#${id}`} {title || noTitle}</h2>
						<div className='post_info'>
							{id && address &&
						<>
							<CreationLabel
								className='md'
								created_at={created_at}
								defaultAddress={address}
							>
								<UpdateLabel
									className='md'
									created_at={created_at}
									updated_at={updated_at}
								/>
							</CreationLabel>
						</>
							}
						</div>
						<Tabs
							type="card"
							className='ant-tabs-tab-bg-white text-sidebarBlue font-medium'
							items={tabItems}
						/>
					</div>
				</div>

				<div className='flex flex-col w-full lg:w-4/12 mx-auto'>
					{remark_proposal_options?.length > 0  && remark_proposal_options?.[0].option !== '' &&
					<>
						<GovernanceSideBar post={post} votesRefetch={votesRefetch} isRemarkProposal  />
						<GovSidebarCard>
							<h2 className='dashboard-heading mb-4'>Results</h2>
							{remark_proposal_options.map((remarkOption: any, i: number) => {
								const totalVotes = remark_proposal_votes?.length;
								const votes = remark_proposal_votes.reduce((sum: number, vote: any) => {
									if(vote.option_id === remarkOption.id){
										sum++;
									}
									return sum;
								}, 0);
								return (
									<div key={`${i}${remarkOption.id}`} className='mb-4' >
										<h2 className='text-sidebarBlue text-md font-medium mb-1'>{remarkOption.option}</h2>
										<Progress
											className='w-[90%]'
											percent={100}
											success={{ percent: (votes/totalVotes) * 100, strokeColor: '#2ED47A' }}
											strokeWidth={12}
											strokeColor='#FF3C5F'
											format={(percent) => (
												votes ?
													<div className='text-green-400'>{percent}%</div>
													: <div className='text-sm'>No Votes</div>
											)}
										/>
									</div>
								);
							})}
						</GovSidebarCard>
					</>
					}
				</div>

			</div>
		</>
	);
};

export default styled(RemarkProposalPostPage)`
	position: relative;
	margin-bottom: 3rem;

	.onchain-title {
		max-width: calc(100% - 12rem);

		@media only screen and (max-width: 576px) {
			max-width: 100%;
		}
	}

	.post_info {
		display: inline-block;
		margin-bottom: 2rem;
	}

    .post_content {
		background-color: white;
		border-radius: 3px;
		box-shadow: box_shadow_card;
		padding: 3rem 3rem 0.8rem 3rem;
		margin-bottom: 1rem;
	}

	.post_tags {
		position: absolute;
		top: 0rem;
		right: 0rem;
		text-align: right;

		@media only screen and (max-width: 768px) {
			position: relative;
			margin-bottom: 2rem;
		}
	}

	.ant-tabs-tab-bg-white .ant-tabs-tab:not(.ant-tabs-tab-active) {
		background-color: white;
		border-top-color: white;
		border-left-color: white;
		border-right-color: white;
		border-bottom-color: #E1E6EB;
	}
	.ant-tabs-tab-bg-white .ant-tabs-tab-active{
		border-top-color: #E1E6EB;
		border-left-color: #E1E6EB;
		border-right-color: #E1E6EB;
		border-radius: 6px 6px 0 0 !important;
	}
	.ant-tabs-tab-bg-white .ant-tabs-nav:before{
		border-bottom: 1px solid #E1E6EB;
	}
`;
