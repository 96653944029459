// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import styled from '@xstyled/styled-components';
import React, { ReactNode } from 'react';
import { bountyStatus, bountyStatusMap, motionStatus, PIPStatus, proposalStatus, referendumStatus, remarkProposalStatus, tipStatus, tipStatusMap } from 'src/global/statuses';

interface Props{
	children?: ReactNode;
	className?: string;
	content?: string;
	status: string;
	colorInverted?: boolean;
}

const StatusTag = ({ className, content, status, colorInverted }: Props) => {

	if (content && tipStatusMap[content]) {
		content = tipStatusMap[content];
	}

	if (content && bountyStatusMap[content]) {
		content = bountyStatusMap[content];
	}

	return (
		<div className={`${className} ${status} ${colorInverted && 'bg-white inverted'} text-xs rounded-full border-2 px-3 py-1 whitespace-nowrap h-min`}>
			{content}
		</div>
	);
};

export default styled(StatusTag).attrs(( { status }: Props) => ({
	className: status,
	content: status
}))`
	color: #fff;
	max-width: min-content;
	background: #666;
	border-color: #666;

	&.inverted {
		color: #666;
	}


	&.${referendumStatus.STARTED},
	&.${PIPStatus.SCHEDULED},
	&.${proposalStatus.PROPOSED},
	&.${motionStatus.PROPOSED},
	&.${remarkProposalStatus.YET_TO_START},
	&.${bountyStatus.PROPOSED} {
		border-color: #6495ED;
		background: #6495ED;

		&.inverted {
			color: #6495ED;
		}
	}

	&.${tipStatus.OPENED},
	&.${tipStatus.CLOSING},
	&.${bountyStatus.AWARDED},
	&.${bountyStatus.BECAME_ACTIVE},
	&.${remarkProposalStatus.IN_PROGRESS},
	&.${bountyStatus.EXTENDED} {
		border-color: #6495ED;
		background: #6495ED;

		&.inverted {
			color: #6495ED;
		}
	}

	&.${proposalStatus.TABLED},
	&.${referendumStatus.PASSED},
	&.${referendumStatus.EXECUTED},
	&.${motionStatus.EXECUTED},
	&.${motionStatus.APPROVED},
	&.${motionStatus.CLOSED},
	&.${tipStatus.CLOSED},
	&.${bountyStatus.AWARDED},
	&.${remarkProposalStatus.ENDED},
	&.${bountyStatus.CLAIMED} {
		border-color: #5BC044;
		background: #5BC044;

		&.inverted {
			color: #5BC044;
		}
	}
	&.prime, &.Prime {
		border-color: green_primary;
		background-color: green_primary;

		&.inverted {
			color: green_primary;
		}
	}

	&.${proposalStatus.CLEARED},
	&.${PIPStatus.PIPCLOSED},
	&.${referendumStatus.CANCELLED},
	&.${referendumStatus.NOTPASSED},
	&.${referendumStatus.VETOED},
	&.${motionStatus.DISAPPROVED},
	&.${tipStatus.RETRACTED},
	&.${bountyStatus.CANCELED},
	&.${bountyStatus.REJECTED} {
		border-color: #FF0000;
		background: #FF0000;

		&.inverted {
			color: #FF0000;
		}
	}
`;
