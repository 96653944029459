// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { DislikeFilled, LeftOutlined, LikeFilled, RightOutlined } from '@ant-design/icons';
import { LoadingOutlined } from '@ant-design/icons';
import { Pagination, PaginationProps, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useApiContext } from 'src/context';
import { LoadingStatusType } from 'src/types';
import Address from 'src/ui-components/Address';
import GovSidebarCard from 'src/ui-components/GovSidebarCard';
import { PostEmptyState } from 'src/ui-components/UIStates';
import formatBnBalance from 'src/util/formatBnBalance';
import formatUSDWithUnits from 'src/util/formatUSDWithUnits';

interface Props {
	className?: string
	pipId: number
}

const VOTES_LISTING_LIMIT = 10;

const VotersList = ({ className, pipId } : Props) => {
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>({ isLoading: true, message:'Loading votes' });
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [votersList, setVotersList] = useState<any | null>(null);
	const { api, apiReady } = useApiContext();

	useEffect(() => {
		if (!api || !apiReady) {
			return;
		}

		api.query.pips.proposalVotes.entries(pipId).then((res) => {
			const voters: any[] = [];
			res.forEach((v) => {
				const vote = {
					address: '',
					passed: false,
					value: ''
				};
				let json = v[0].toHuman();
				if (json && Array.isArray(json) && json.length >= 2 && typeof json?.[1] === 'string') {
					vote.address = json?.[1];
				}
				json = v[1].toHuman();
				if (json && Array.isArray(json) && json.length >= 2) {
					if (typeof json?.[0] === 'boolean') {
						vote.passed = json?.[0];
					}
					if (typeof json?.[1] === 'string') {
						vote.value = json?.[1];
					}
				}
				voters.push(vote);
			});
			setVotersList(voters);
			setLoadingStatus({
				isLoading: false,
				message: ''
			});
		}).catch((err) => {
			console.log('error', err);
		});
	}, [api, apiReady, pipId]);

	const onChange: PaginationProps['onChange'] = page => {
		setCurrentPage(page);
	};
	return (
		<>
			{
				votersList &&
				<GovSidebarCard className={`${className}`}>
					<Spin spinning={loadingStatus.isLoading} indicator={<LoadingOutlined />}>
						<div className="flex justify-between mb-6 bg-white z-10">
							<h6 className='dashboard-heading'>Voters</h6>
						</div>

						<div className='flex flex-col text-xs xl:text-sm xl:max-h-screen gap-y-1 overflow-y-auto px-0 pr-5 text-sidebarBlue'>
							<div className='flex text-xs items-center justify-between mb-9 font-semibold'>
								<div className='w-[110px]'>Voter</div>
								<div className='w-[80px]'><span className='hidden md:inline-block'>Amount</span><span className='inline-block md:hidden'>Amt.</span></div>
								<div className='w-[30px]'>Vote</div>
							</div>
							{
								votersList && votersList.length? <>
									{
										(votersList as any[]).slice((currentPage - 1) * VOTES_LISTING_LIMIT, currentPage * VOTES_LISTING_LIMIT).map((voteData: any, index:number) => {
											if (!voteData.address) return null;
											return (
												<>
													<div className='flex items-center justify-between mb-4' key={index}>
														<div className='item overflow-ellipsis'>
															<Address address={voteData.address} />
														</div>

														<div className='w-[80px] max-w-[80px] overflow-ellipsis'>
															{
																formatUSDWithUnits(formatBnBalance(voteData?.value?.replaceAll(',', '') || 0, { numberAfterComma: 1, withThousandDelimitor: false,withUnit: true }), 1)
															}
														</div>
														{voteData.passed ?
															<div className='flex items-center text-aye_green text-md'>
																<LikeFilled className='mr-2' /> Aye
															</div>
															:
															<div className='flex items-center text-nay_red text-md'>
																<DislikeFilled className='mr-2' /> Nay
															</div>
														}
													</div></>
											);
										})
									}
								</> : <PostEmptyState />
							}
						</div>

						<div className="flex justify-center pt-6 bg-white z-10">
							<Pagination
								size="small"
								defaultCurrent={1}
								current={currentPage}
								onChange={onChange}
								total={votersList?.length || 0}
								showSizeChanger={false}
								pageSize={VOTES_LISTING_LIMIT}
								responsive={true}
								hideOnSinglePage={true}
								nextIcon={<div className={`ml-1 flex items-center justify-center h-full w-full${currentPage > Math.floor((votersList?.length || 0) / VOTES_LISTING_LIMIT)? 'text-grey_secondary': ''}`}><RightOutlined /></div>}
								prevIcon={<div className={`mr-1 flex items-center justify-center h-full w-full${currentPage <= 1? 'text-grey_secondary': ''}`}><LeftOutlined /></div>}
							/>
						</div>
					</Spin>
				</GovSidebarCard>
			}
		</>
	);
};

export default VotersList;
