// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { LoadingOutlined } from '@ant-design/icons';
import { DeriveReferendumVote } from '@polkadot/api-derive/types';
import { Balance } from '@polkadot/types/interfaces';
import { Spin } from 'antd';
import BN from 'bn.js';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { ApiContext } from 'src/context/ApiContext';
import { UserDetailsContext } from 'src/context/UserDetailsContext';
import { LoadingStatusType, VoteThreshold } from 'src/types';
import GovSidebarCard from 'src/ui-components/GovSidebarCard';
import HelperTooltip from 'src/ui-components/HelperTooltip';
import Loader from 'src/ui-components/Loader';
import PassingInfoTag from 'src/ui-components/PassingInfoTag';
import VoteProgress from 'src/ui-components/VoteProgress';
import formatBnBalance from 'src/util/formatBnBalance';
import getNetwork from 'src/util/getNetwork';
const network = getNetwork();

import VotersList from './VotersList';

interface Props {
	className?: string
	referendumId: number
	threshold?: VoteThreshold
	setLastVote: React.Dispatch<React.SetStateAction<string | null | undefined>>
}

const ZERO = new BN(0);

const sizing = ['0.1x', '1x', '2x', '3x', '4x', '5x', '6x'];
const LOCKS = [1, 10, 20, 30, 40, 50, 60];

const ReferendumVoteInfo = ({ className, referendumId, setLastVote }: Props) => {
	const { api, apiReady } = useContext(ApiContext);
	const [turnout, setTurnout] = useState(ZERO);
	const [totalIssuance, setTotalIssuance] = useState(ZERO);
	const [ayeVotes, setAyeVotes] = useState(ZERO);
	const [nayVotes, setNayVotes] = useState(ZERO);
	const [votedAccounts, setVotedAccounts] = useState([{ 'accountId': '', 'balance': '','label': '', 'voted': '' }]);
	const [nayVotesWithoutConviction, setNayVotesWithoutConviction] = useState(ZERO);
	const [ayeVotesWithoutConviction, setAyeVotesWithoutConviction] = useState(ZERO);
	const [isPassing, setIsPassing] = useState<boolean | null>(null);
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>({ isLoading: true, message:'Loading votes' });
	const turnoutPercentage = useMemo( () => {
		if (totalIssuance.isZero()) {
			return 0;
		}
		// BN doens't handle floats. If we devide a number by a bigger number (12/100 --> 0.12), the result will be 0
		// therefore, we first multiply by 10 000, which gives (120 000/100 = 1200) go to Number which supports floats
		// and devide by 100 to have percentage --> 12.00%
		return turnout.muln(10000).div(totalIssuance).toNumber()/100;
	} , [turnout, totalIssuance]);

	const { addresses } = useContext(UserDetailsContext);

	const getBalance = (balance: Balance, convictions: number) => {
		const votedBalance = balance.muln(LOCKS[convictions]).div(new BN(10));
		return formatBnBalance(votedBalance, {});
	};

	useEffect(() => {
		if (!api || !apiReady) {
			return;
		}

		if(network != 'equilibrium' || !addresses || addresses.length === 0) {
			return;
		}

		let unsubscribe: () => void;

		api.query.democracy.votingOf.multi(addresses, (voting: any) => {
			const refVoteInfoLocal: any[] = [];
			if(voting && voting.length > 0) {
				voting.forEach((vote: any) => {
					vote?.toHuman()?.Direct?.votes?.forEach((v: any) => {
						if(v?.[0] == referendumId){
							refVoteInfoLocal.push(v?.[1]?.Standard);
						}
					});
				});
				// setRefVoteInfo(refVoteInfoLocal);
			}
		}).then( unsub => {unsubscribe = unsub;})
			.catch(console.error);

		return () => unsubscribe && unsubscribe();
	}, [addresses, api, apiReady, referendumId]);

	useEffect(() => {
		if (!api || !apiReady) {
			return;
		}

		let unsubscribe: () => void;

		api.derive.democracy.referendums((referendums) => {
			console.log('referendums', referendums);
			const referendum = referendums.filter(re => re.index.toNumber() == referendumId)[0];
			if (referendum) {
				setIsPassing(referendum.isPassing);
				const totalAye: BN = referendum.allAye.reduce((acc: BN, curr: DeriveReferendumVote) => {
					return acc.add(new BN(curr.balance));
				}, ZERO);
				const totalNay: BN = referendum.allNay.reduce((acc: BN, curr: DeriveReferendumVote) => {
					return acc.add(new BN(curr.balance));
				}, ZERO);

				setNayVotesWithoutConviction(totalNay);
				setAyeVotesWithoutConviction(totalAye);

				let voteObj: any = {};
				const acc: any = [];

				if(addresses){
					referendum.votes.forEach(vote => {
						if(addresses.includes(vote.accountId.toHuman())) {
							voteObj = { 'accountId': vote.accountId.toHuman(),'balance': getBalance(vote.balance, vote.vote.conviction.toNumber()), 'label': `${sizing[vote.vote.conviction.toNumber()]}${vote.isDelegating ? '/d' : ''} - `, 'voted': vote.vote.isAye ? 'aye' : 'nay' };
							acc.push(voteObj);
						}
					});
					setVotedAccounts(acc);
				}
			}
		}).then( unsub => {unsubscribe = unsub;})
			.catch(console.error);

		return () => unsubscribe && unsubscribe();
	}, [api, apiReady, referendumId, addresses]);

	useEffect(() => {
		if(votedAccounts.length>0){
			setLastVote(votedAccounts[votedAccounts.length - 1].voted == '' ? null : votedAccounts[votedAccounts.length - 1].voted);
		}
	}, [setLastVote, votedAccounts]);

	useEffect(() => {
		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		let unsubscribe: () => void;

		api.query.democracy.referendumInfoOf(referendumId, (info) => {
			const _info = info.unwrapOr(null);

			if (_info?.isOngoing){
				setAyeVotes(_info?.asOngoing.tally.ayes);
				setNayVotes(_info?.asOngoing.tally.nays);
				setTurnout(_info?.asOngoing.tally.turnout);
			}

			setLoadingStatus({ isLoading: false, message: '' });
		})
			.then( unsub => {unsubscribe = unsub;})
			.catch(console.error);

		return () => unsubscribe && unsubscribe();
	}, [api, apiReady, referendumId]);

	useEffect(() => {
		if (!api) {
			return;
		}

		if (!apiReady) {
			return;
		}

		let unsubscribe: () => void;

		if (['equilibrium'].includes(network)){
			// (async() => {
			// 	const totalIssuanceData = await api.query.eqAggregates.totalUserGroups('Balances', { '0': 25969 }) as any;
			// 	console.log('totalIssuanceData : ', totalIssuanceData.collateral);
			// 	setTotalIssuance(new BN(totalIssuanceData.collateral.toString()));
			// })();
			setTotalIssuance(ZERO);
		}
		else if(['genshiro'].includes(network)){
			const { collateral, debt } = api.query.eqAggregates.totalUserGroup('Balances', { '0': 1734700659 }) as any;
			console.log('collatoral', collateral, 'debt', debt);
			setTotalIssuance(collateral);
		}
		else{
			api.query.balances.totalIssuance((result) => {
				setTotalIssuance(result);
			})
				.then( unsub => {unsubscribe = unsub;})
				.catch(console.error);
		}

		return () => unsubscribe && unsubscribe();
	},[api, apiReady]);

	return (
		<>
			{getNetwork() !== 'equilibrium' && <>
				{loadingStatus.isLoading ?
					<GovSidebarCard className='flex items-center justify-center min-h-[100px]'>
						<Loader />
					</GovSidebarCard>
					:
					<GovSidebarCard className={className}>
						<Spin spinning={loadingStatus.isLoading} indicator={<LoadingOutlined />}>
							<div className="flex justify-between mb-7">
								<h6 className='dashboard-heading'>Voting Status</h6>
								<PassingInfoTag isPassing={isPassing}/>
							</div>

							<div className="flex justify-between">
								<VoteProgress
									ayeVotes={ayeVotes}
									className='vote-progress'
									nayVotes={nayVotes}
								/>

								<div className='flex-1 flex flex-col justify-between ml-4 md:ml-12 py-9'>
									<div className='mb-auto flex items-center'>
										<div className='mr-auto text-sidebarBlue font-medium'>Turnout {turnoutPercentage > 0 && <span className='turnoutPercentage'>({turnoutPercentage}%)</span>}</div>
										<div className='text-navBlue'>{formatBnBalance(turnout, { numberAfterComma: 2, withUnit: true })}</div>
									</div>

									<div className='mb-auto flex items-center'>
										<div className='mr-auto text-sidebarBlue font-medium flex items-center'>Aye <HelperTooltip className='ml-2' text='Aye votes without taking conviction into account'/></div>
										<div className='text-navBlue'>{formatBnBalance(ayeVotesWithoutConviction, { numberAfterComma: 2, withUnit: true })}</div>
									</div>

									<div className='flex items-center'>
										<div className='mr-auto text-sidebarBlue font-medium flex items-center'>Nay <HelperTooltip className='ml-2' text='Nay votes without taking conviction into account'/></div>
										<div className='text-navBlue'>{formatBnBalance(nayVotesWithoutConviction, { numberAfterComma: 2, withUnit: true })}</div>
									</div>
								</div>
							</div>
						</Spin>
					</GovSidebarCard>
				}
			</>}

			<VotersList className={className} referendumId={referendumId} />
		</>
	);
};

export default memo(ReferendumVoteInfo);
