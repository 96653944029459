// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React, { useEffect } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { useNetworkSocialsLazyQuery } from 'src/generated/graphql';
import getNetwork from 'src/util/getNetwork';

const network = getNetwork();

const News = () => {
	const [refetch, { data, error }] = useNetworkSocialsLazyQuery({
		variables: {
			network
		}
	});

	useEffect(() => {
		refetch();
	}, [refetch]);

	if (error) {
		console.error(error);
		return null;
	}

	const twitter = data?.blockchain_socials[0]?.twitter;

	if (!twitter) {
		return null;
	}

	const profile = twitter.split('/')[3];
	return (
		<div className='bg-white drop-shadow-md p-4 lg:p-6 rounded-md h-[520px] lg:h-[550px]'>
			<h2 className='dashboard-heading mb-6'>News</h2>

			<div>
				<TwitterTimelineEmbed
					sourceType="profile"
					screenName={profile}
					options={ { height: 450 } }
					noHeader={true}
					noFooter={true}
				/>
			</div>
		</div>
	);
};

export default News;
