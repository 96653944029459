// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React from 'react';

import CommitteeContainer from './CommitteeContainer';

interface Props {
	className?: string
	committeeType: 'Upgrade' | 'Technical' | 'Community';
}

const OnchainPostsContainer = ({ committeeType, className }: Props) => {

	return (
		<div className={className}>
			<h1 className='dashboard-heading mb-4 md:mb-6'>On-chain {`${committeeType}${committeeType === 'Community' ? '' : ' Committee'}`} PIPs</h1>

			{/* Intro and Create Post Button */}
			<div className="flex flex-col md:flex-row">
				{committeeType === 'Community' ?
					<p className="text-sidebarBlue text-sm md:text-base font-medium bg-white p-4 md:p-8 rounded-md w-full shadow-md mb-4">
				This is the place to discuss on-chain {committeeType} PIPs. {committeeType} PIPs are proposed by the {committeeType} and
				approved by the Polymesh Governance Council. Only the {committeeType} can
				amend these PIPs
					</p> :
					<p className="text-sidebarBlue text-sm md:text-base font-medium bg-white p-4 md:p-8 rounded-md w-full shadow-md mb-4">
				This is the place to discuss on-chain {committeeType} PIPs. {committeeType} Committee PIPs are proposed by the {committeeType} Committee and
				approved by the Polymesh Governance Council. Only the {committeeType} Committee can
				amend these PIPs
					</p>
				}
			</div>
			<CommitteeContainer limit={25} committeeType = { committeeType }/>
		</div>
	);

};

export default (OnchainPostsContainer);
