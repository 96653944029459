// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.
import { coinGeckoNetworks } from 'src/global/coinGeckoNetworkMappings';
import getNetwork from 'src/util/getNetwork';

import formatUSDWithUnits from './formatUSDWithUnits';

const NETWORK = getNetwork();

export default async function fetchTokenToUSDPrice() {
	const response = await fetch('https://api.coingecko.com/api/v3/simple/price?' + new URLSearchParams({ ids: coinGeckoNetworks[NETWORK] ? coinGeckoNetworks[NETWORK] : NETWORK, include_24hr_change: 'true', vs_currencies: 'usd' }));
	const responseJSON = await response.json();
	if (Object.keys(responseJSON[coinGeckoNetworks[NETWORK] ? coinGeckoNetworks[NETWORK] : NETWORK]).length == 0) {
		return 'N/A';
	} else {
		return formatUSDWithUnits(responseJSON[coinGeckoNetworks[NETWORK] ? coinGeckoNetworks[NETWORK] : NETWORK]['usd']);
	}
}
