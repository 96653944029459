// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useApiContext } from 'src/context';
import { LoadingStatusType } from 'src/types';
import GovSidebarCard from 'src/ui-components/GovSidebarCard';
import VoteProgress from 'src/ui-components/VoteProgress';
import VotersList from './VotersList';

interface ICommunityVoteInfoProps {
    pipId: number;
    className?: string;
    proposerAddress?: string;
}

const CommunityVoteInfo: FC<ICommunityVoteInfoProps> = (props) => {
	const { pipId, className } = props;
	const { api, apiReady } = useApiContext();
	const [loadingStatus, setLoadingStatus] = useState<LoadingStatusType>({ isLoading: true, message:'Loading votes' });
	const [ayeVotes, setAyeVotes] = useState(0);
	const [nayVotes, setNayVotes] = useState(0);

	useEffect(() => {
		if (!api || !apiReady) {
			return;
		}

		api.query.pips.proposalResult(pipId).then((res) => {
			const json = res.toJSON();
			if (json) {
				setAyeVotes((json as any).ayesCount);
				setNayVotes((json as any).naysCount);
			}
			setLoadingStatus({
				isLoading: false,
				message: ''
			});
		}).catch((err) => {
			console.log('error', err);
		});
	}, [api, apiReady, pipId]);
	return (
		<>
			<GovSidebarCard className={className}>
				<Spin spinning={loadingStatus.isLoading} indicator={<LoadingOutlined />}>
					<div className="flex justify-between mb-7">
						<h6 className='dashboard-heading'>Voting Status</h6>
					</div>
					<div className="flex justify-between">
						<VoteProgress
							ayesNum={ayeVotes}
							className='vote-progress'
							naysNum={nayVotes}
						/>

						<div className='flex-1 flex flex-col justify-between ml-4 md:ml-12 py-9'>
							<div className='mb-auto flex items-center'>
								<div className='mr-auto text-sidebarBlue font-medium flex items-center'>Aye</div>
								<div className='text-navBlue'>{ayeVotes}</div>
							</div>

							<div className='flex items-center'>
								<div className='mr-auto text-sidebarBlue font-medium flex items-center'>Nay</div>
								<div className='text-navBlue'>{nayVotes}</div>
							</div>
						</div>
					</div>
				</Spin>
			</GovSidebarCard>
			<VotersList pipId={pipId} />
		</>
	);
};

export default CommunityVoteInfo;
