// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import { ThemeProvider } from '@xstyled/styled-components';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Apollo from './components/Apollo';
import AppLayout from './components/AppLayout';
import Head from './components/Head';
import { ApiContextProvider } from './context/ApiContext';
import { MetaProvider } from './context/MetaContext';
import { ModalProvider } from './context/ModalContext';
import { UserDetailsProvider } from './context/UserDetailsContext';
import { theme } from './themes/theme';
import { GlobalStyle } from './ui-components/GlobalStyle';
import getNetwork from './util/getNetwork';
import { ReactComponent as PALogoBlack } from './assets/pa-logo-black.svg';

const network = getNetwork();

const App = () => {
	return (
		<>
			<Router>
				<ThemeProvider theme={theme}>
					<ModalProvider>
						<UserDetailsProvider>
							<MetaProvider>
								<Apollo>
									<Head />
									<GlobalStyle />
									{network != 'tanganika' ?
										<ApiContextProvider>
											<AppLayout />
										</ApiContextProvider> :
										<main className='bg-white w-screen h-screen flex flex-col gap-y-6 items-center justify-center'>
											<PALogoBlack className='h-auto w-[160px]' />
											<h1 className='text-pink_secondary text-2xl'>Services disabled due to non-payment</h1>
										</main>
									}
								</Apollo>
							</MetaProvider>
						</UserDetailsProvider>
					</ModalProvider>
				</ThemeProvider>
			</Router>
		</>
	);
};

export default App;
