// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React, { useEffect } from 'react';
import CommitteeListing from 'src/components/Listing/CommitteeListing/CommitteeListing';
import { ErrorState } from 'src/ui-components/UIStates';

import { useAllPipPostsByCommitteeQuery } from '../../../generated/graphql';
import Loader from '../../../ui-components/Loader';
import getNetwork from '../../../util/getNetwork';

const network = getNetwork();

interface Props {
	className?: string
	limit: number
	committeeType: 'Upgrade' | 'Technical' | 'Community';
}

const CommitteeContainer = ({ className, limit, committeeType }:Props) => {
	// const [offset, setOffset] = useState(0);

	const { data, error, refetch } = useAllPipPostsByCommitteeQuery({ variables: {
		committee: `${ committeeType }`,
		limit: limit,
		network
	} });

	useEffect(() => {
		refetch();
	}, [refetch]);

	if (error?.message) {
		return <ErrorState errorMessage={error.message} />;
	}

	if (data) return (
		<div className={`${className} shadow-md bg-white p-3 md:p-8 rounded-md`}>
			<div className='flex items-center justify-between'>
				<h1 className='dashboard-heading'>{committeeType} PIPs</h1>
			</div>
			<CommitteeListing className='mt-6' data={data} committee={ committeeType.toLowerCase() as any }/>
		</div>
	);

	return <Loader/>;
};

export default CommitteeContainer;
