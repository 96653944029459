// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.
export const coinGeckoNetworks: any = {
	'calamari': 'calamari-network',
	'crust': 'crust-network',
	'crustshadow': 'crust-storage-market',
	'kilt': 'kilt-protocol',
	'kylin': 'kylin-network',
	'robonomics': 'robonomics-network',
	'tanganika': 'datahighway',
	'xx': 'xxcoin'
};
