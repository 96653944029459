// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React, { useEffect, useState } from 'react';
import CommitteeListing from 'src/components/Listing/CommitteeListing/CommitteeListing';
import { AllPipPostsByCommitteeQuery } from 'src/generated/graphql';
import { ErrorState } from 'src/ui-components/UIStates';

const CommitteeContainer = ({ className, committeeType } : { className?:string, committeeType: 'Upgrade' | 'Technical' | 'Community' }) => {

	const [data, setData] = useState<AllPipPostsByCommitteeQuery | undefined>();
	const [error, setErr] = useState<Error>();

	let trackMap: any = {};

	try {
		trackMap = JSON.parse(global.window.localStorage.getItem('trackMap') || '{}');
	} catch (error) {
		console.error(error);
	}

	const onchainTechnicalPipIds = Object.keys(trackMap.technicalPip || {}).map(key => `"${key}"`);
	const onchainUpgradePipIds = Object.keys(trackMap.upgradePip || {}).map(key => `"${key}"`);
	const onchainCommunityPipIds = Object.keys(trackMap.communityPip || {}).map(key => `"${key}"`);

	useEffect(() => {
		let onchainId: string[];
		switch(committeeType){
		case 'Technical':
			onchainId = onchainTechnicalPipIds;
			break;
		case 'Upgrade':
			onchainId = onchainUpgradePipIds;
			break;
		case 'Community':
			onchainId = onchainCommunityPipIds;
			break;
		}
		const fetchData = async () => {
			fetch('https://polkassembly-hasura.herokuapp.com/v1/graphql',
				{ body: JSON.stringify({
					query: `query TrackerPipPosts{
						posts(where: {onchain_link: {onchain_pip_committee: {_eq: "${committeeType}"}, onchain_network_pip_id: {_in: ${onchainId}}}}, order_by: {onchain_link: {id: desc}, last_update: {}}) {
						  id
						  title
						  created_at
						  updated_at
						  comments_aggregate {
							aggregate {
							  count
							}
						  }
						  type {
							name
							id
						  }
						  topic {
							id
							name
						  }
						  onchain_link {
							id
							proposer_address
							onchain_network_pip_id
							onchain_pip_committee
							post_id
							onchain_pip {
							  identityId
							  maybeBlockNumber
							  method
							  network
							  networkProposalId
							  optionDescription
							  optionUrl
							  proposalHash
							  proposalId
							  proposedBy
							  section
							  pipStatus {
								status
								uniqueNetworkStatus
								blockNumber {
								  number
								}
							  }
							}
						  }
						}
					  }`
				}),
				headers: {
					'Accept': 'application/json, multipart/mixed',
					'Sec-Fetch-Dest': 'empty',
					'Sec-Fetch-Mode': 'cors',
					'Sec-Fetch-Site': 'same-origin',
					'content-type': 'application/json'
				},
				method: 'POST'
				})
				.then(async (res) => {
					const response = await res.json();
					setData(response.data);
				}).catch((err) => {
					console.log(err);
					setErr(err);
					console.log('Error in fetching voters :', err);
				});
		};

		fetchData();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (error?.message) {
		return <ErrorState errorMessage={error.message} />;
	}

	return (
		<>
			<div className={`${className} shadow-md bg-white p-3 md:p-8 rounded-md`}>
				<div className='flex items-center justify-between'>
					<h1 className='dashboard-heading'>{committeeType} {committeeType !== 'Community' ? 'Committee' : ''} Pip</h1>
				</div>

				<CommitteeListing className='mt-6' committee={committeeType.toLowerCase() as any} data={data} />
			</div>
		</>
	);
};

export default CommitteeContainer;
