// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

/* eslint-disable sort-keys */
import styled from '@xstyled/styled-components';
import { Tabs } from 'antd';
import React, { useEffect } from 'react';
import {
	useBountiesCountLazyQuery,
	useCountCommitteePipsLazyQuery,
	useDemocracyProposalCountLazyQuery,
	useDemocracyTreasuryProposalCountLazyQuery,
	useDiscussionsCountLazyQuery,
	useGetLatestMotionsCountLazyQuery,
	usePostsCountLazyQuery,
	useReferundumCountLazyQuery,
	useTipProposalCountLazyQuery
} from 'src/generated/graphql';
import { post_topic } from 'src/global/post_topics';
import { post_type } from 'src/global/post_types';
import CountBadgePill from 'src/ui-components/CountBadgePill';
import getNetwork from 'src/util/getNetwork';

import AllPostsTable from './AllPostsTable';
import BountyPostsTable from './BountyPostsTable';
import CommitteePostsTable from './CommitteePostTable';
import DiscussionPostsTable from './DiscussionPostsTable';
import MotionPostsTable from './MotionPostsTable';
import ProposalPostsTable from './ProposalPostsTable';
import ReferendaPostsTable from './ReferendaPostsTable';
import TipPostsTable from './TipPostsTable';
import TreasuryPostsTable from './TreasuryPostsTable';

const network = getNetwork();

const LatestActivity = ({ className }: {className?:string}) => {

	const [postRefetch , { data: postData }] = usePostsCountLazyQuery({
		variables: {
			network
		}
	});

	const [technicalRefetch, { data: technicalData }] = useCountCommitteePipsLazyQuery({
		variables: {
			committee :	'Technical' ,
			network
		}
	});
	const [upgradeRefetch, { data: upgradeData }] = useCountCommitteePipsLazyQuery({
		variables: {
			committee :	'Upgrade' ,
			network
		}
	});
	const [communityRefetch, { data: communityData }] = useCountCommitteePipsLazyQuery({
		variables: {
			committee :	'Community' ,
			network
		}
	});
	const [discussionRefetch, { data: discussionData }] = useDiscussionsCountLazyQuery({
		variables: {
			network
		}
	});

	const [referendumRefetch, { data: referendumData }] = useReferundumCountLazyQuery({ variables: {
		network,
		postType: post_type.ON_CHAIN
	} });

	const [proposalRefetch, { data: proposalData }] = useDemocracyProposalCountLazyQuery({ variables: {
		network,
		postTopic: post_topic.DEMOCRACY,
		postType: post_type.ON_CHAIN
	} });

	const [motionRefetch, { data: motionData }] = useGetLatestMotionsCountLazyQuery({ variables: {
		network,
		postType: post_type.ON_CHAIN
	} });

	const [treasuryProposalRefetch, { data: treasuryProposalData }] = useDemocracyTreasuryProposalCountLazyQuery({ variables: {
		network,
		postTopic: post_topic.TREASURY,
		postType: post_type.ON_CHAIN
	} });

	const [bountiesRefetch, { data: bountiesData }] = useBountiesCountLazyQuery({ variables: {
		network,
		postType: post_type.ON_CHAIN
	} });

	const [tipProposalRefetch, { data: tipProposalData }] = useTipProposalCountLazyQuery({ variables: {
		network,
		postTopic: post_topic.TREASURY,
		postType: post_type.ON_CHAIN
	} });

	useEffect(() => {
		postRefetch();
	}, [postRefetch]);

	useEffect(() => {
		discussionRefetch();
	}, [discussionRefetch]);

	useEffect(() => {
		referendumRefetch();
	}, [referendumRefetch]);

	useEffect(() => {
		proposalRefetch();
	}, [proposalRefetch]);

	useEffect(() => {
		motionRefetch();
	}, [motionRefetch]);

	useEffect(() => {
		treasuryProposalRefetch();
	}, [treasuryProposalRefetch]);

	useEffect(() => {
		bountiesRefetch();
	}, [bountiesRefetch]);

	useEffect(() => {
		tipProposalRefetch();
	}, [tipProposalRefetch]);

	useEffect(() => {
		communityRefetch();
	}, [communityRefetch ]);

	useEffect(() => {
		technicalRefetch();
	}, [technicalRefetch ]);

	useEffect(() => {
		upgradeRefetch();
	}, [upgradeRefetch ]);

	const tabItems = [
		{ label: <CountBadgePill label='All' count={postData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'all', children: <AllPostsTable /> },
		{ label: <CountBadgePill label='Discussions' count={discussionData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'discussions', children: <DiscussionPostsTable /> }
	];

	if(['polymesh', 'polymesh-test'].includes(network)){
		tabItems.push(
			{ label: <CountBadgePill label='Technical' count={technicalData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'technical', children: <CommitteePostsTable committee='Technical' /> },
			{ label: <CountBadgePill label='Upgrade' count={upgradeData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'upgrade', children: <CommitteePostsTable committee='Upgrade' /> },
			{ label: <CountBadgePill label='Community' count={communityData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'community', children: <CommitteePostsTable committee='Community' /> }
		);
	}
	else{
		tabItems.push(
			{ label: <CountBadgePill label='Proposals' count={proposalData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'proposals', children: <ProposalPostsTable /> },
			{ label: <CountBadgePill label='Referenda' count={referendumData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'referenda', children: <ReferendaPostsTable /> },
			{ label: <CountBadgePill label='Motion' count={motionData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'motions', children: <MotionPostsTable /> },
			{ label: <CountBadgePill label='Treasury Proposals' count={treasuryProposalData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'treasury-proposals', children: <TreasuryPostsTable /> },
			{ label: <CountBadgePill label='Bounties' count={bountiesData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'bounties', children: <BountyPostsTable /> },
			{ label: <CountBadgePill label='Tips' count={tipProposalData?.posts_aggregate.aggregate?.count?.toString()} />, key: 'tips', children: <TipPostsTable /> }
		);
	}

	return (
		<div className={`${className} bg-white drop-shadow-md p-4 lg:p-6 rounded-md`}>
			<h2 className='dashboard-heading mb-6'>Latest Activity</h2>
			<Tabs
				type="card"
				items={tabItems}
				className='ant-tabs-tab-bg-white text-sidebarBlue font-medium'
			/>
		</div>
	);
};

export default styled(LatestActivity)`
	th {
		color: nav_link !important;
	}

	td.ant-table-cell {
		color: nav_blue !important;
	}

	tr:nth-child(2n) td {
    background-color: #fbfbfb !important;
	}

	tr {
		cursor: pointer !important;
	}

	.ant-tabs-tab-bg-white .ant-tabs-tab:not(.ant-tabs-tab-active) {
		background-color: white;
		border-top-color: white;
		border-left-color: white;
		border-right-color: white;
		border-bottom-color: #E1E6EB;
	}

	.ant-tabs-tab-bg-white .ant-tabs-tab-active{
		border-top-color: #E1E6EB;
		border-left-color: #E1E6EB;
		border-right-color: #E1E6EB;
		border-radius: 6px 6px 0 0 !important;
	}

	.ant-tabs-tab-bg-white .ant-tabs-nav:before{
		border-bottom: 1px solid #E1E6EB;
	}
`;
