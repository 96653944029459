// Copyright 2019-2020 @Premiurly/polkassembly authors & contributors
// This software may be modified and distributed under the terms
// of the Apache-2.0 license. See the LICENSE file for details.

import React, { useEffect } from 'react';
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { useNetworkSocialsLazyQuery } from 'src/generated/graphql';
import getNetwork from 'src/util/getNetwork';

const network = getNetwork();

const NewsView = ({ className } : {className?: string}) => {

	const [refetch , { data, error }] = useNetworkSocialsLazyQuery({
		variables: {
			network
		}
	});

	useEffect(() => {
		refetch();
	}, [refetch]);

	if (error) {
		console.error(error);
		return null;
	}

	const twitter = data?.blockchain_socials[0]?.twitter;

	if (!twitter) {
		return null;
	}

	const profile = twitter.split('/')[3];

	const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);

	return (
		<div className={`${className} w-full h-full`}>
			<h1 className='dashboard-heading mb-2'>News</h1>
			<div>
				<TwitterTimelineEmbed
					sourceType="profile"
					screenName={profile}
					autoHeight={false}
					noHeader={true}
					options={
						{ height: vh - 20 }
					}
				/>
			</div>
		</div>
	);

};

export default NewsView;
